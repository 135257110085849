<template>
 <div>  
<div id="LogIn"> 
         
 <div  :class="'column loginCol22222 '"   id="loginCol22222" ref="loginCol22222"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textloginCol1 '"   id="textloginCol1" ref="textloginCol1"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('main.connect') }}</div>

   </div>
 <v-text-field  :placeholder = "$t('main.username')" 
 :prepend-inner-icon = "'ion-ios-person'" 
 :rounded = "true" 
 outlined :class="'textfield userName '"   id="userName" ref="userName" v-model="name"  v-bind:style="{}"></v-text-field>
 <v-text-field  :placeholder = "$t('main.password')" 
 :prepend-inner-icon = "'ion-ios-lock'" 
 :append-icon = "showPass? 'ion-ios-eye' : 'ion-ios-eye-off'" 
 :rounded = "true" 
 :type = "showPass? 'text' : 'password'" 
 outlined :class="'textfield userPass '"   id="userPass" ref="userPass" v-model="pass"  v-bind:style="{}" @click:append="togelShow"></v-text-field>
 <v-btn tag="div" 
 :color = "'#3bb1f9'" 
 :rounded = "true" 
 :dark = "true" 
 @click="login" :class="'button buttonloginCol9 '"   id="buttonloginCol9" ref="buttonloginCol9"  v-bind:style="{}"><span>{{ $t('main.connect') }}</span>
</v-btn>
 <div  :class="'text textloginCol11 '"   id="textloginCol11" ref="textloginCol11"  v-bind:style="{color : '#19b6ff',}">
<div class="text-content" :style = "{}">{{ $t('main.forgetDatas') }}</div>

 
 <div  :class="'mousearea mouseareatextloginCol111 '"   id="mouseareatextloginCol111" ref="mouseareatextloginCol111"  v-bind:style="{}"></div>  </div>
 <div  :class="'text textloginCol222221166666 '"   id="textloginCol222221166666" ref="textloginCol222221166666"  v-bind:style="{ display: (errorVisible? 'block' : 'none'), color : '#f45547',}">
<div class="text-content" :style = "{}">{{ errorMessage }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import axios from 'axios';
	
import * as main from "../../Js/main.js";
export default {
	name: 'LogIn',

    components: {},
	methods: {
	
		togelShow: function(){		
	this.showPass = !this.showPass;

}
,
		showError: function(message) { 		
	this.errorMessage = message

	this.errorVisible = true	

}
,
		login: function(){			
	main.login(this.name, this.pass)

  .then(response => {

    // Handle success.

	

		this.$store.commit('setToken', response.data.jwt);

		this.$store.commit('setUser', response.data.user);

		this.$emit('login', response.data);

  })

  .catch(error => {

    // Handle error.

		this.errorMessage = error.response.data.message[0].messages[0].message;

		this.errorVisible = true;

  });

}
,
	},
	data(){
	return {
		
		showPass:  false		,
		language:  this.$store.state.language		,
		name:  ""		,
		pass:  ""		,
		errorVisible:  false		,
		errorMessage:  ""		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#LogIn{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.column.loginCol22222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-between ; background-color: transparent ; 

.text.textloginCol1{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

font-size:35px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.textfield.userName{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}

.text.textloginCol11{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#19b6ff ; 
font-size:15px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;text-decoration: underline
}

	.mousearea.mouseareatextloginCol111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.text.textloginCol222221166666{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#f45547 ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
}


}


}


@media (max-width: 600px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol11{


position:relative !important; 





.text-content{
width: 100%;
}

	.mousearea.mouseareatextloginCol111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.text.textloginCol222221166666{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol11{


position:relative !important; 





.text-content{
width: 100%;
}

	.mousearea.mouseareatextloginCol111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.text.textloginCol222221166666{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol11{


position:relative !important; 





.text-content{
width: 100%;
}

	.mousearea.mouseareatextloginCol111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.text.textloginCol222221166666{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol11{


position:relative !important; 





.text-content{
width: 100%;
}

	.mousearea.mouseareatextloginCol111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.text.textloginCol222221166666{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol11{


position:relative !important; 





.text-content{
width: 100%;
}

	.mousearea.mouseareatextloginCol111{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.text.textloginCol222221166666{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
