import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
/**
 * Please don' t remove this.
 * Artefact will use it to inject code
 */
//<= inject_import =>
import i18n from '../i18n'
//<= end_import =>

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  /**
   * Please don' t remove this.
   * Artefact will use it to inject code
   */
  //<= inject_store =>
state: {
	
  
		language:  i18n.locale		,
		languageList:  ["fr", "en"]		,
		offline:  false		,
		appProd:  false		,
		apiURL:  "https://api.abccode.org"			,
		server:  "https://code.abccode.org"		,
		worldServer:  "https://world.abccode.org"		,
		apkServer:  "https://apk.abccode.org"		,
		siteURL:  "https://abccode.org"		,
		user:  null		,
		token:  null		,
		pjDatas:  null		,
		project:  null		,
		iconPath:  null		,
		iconBlob:  null		,
		saved:  true		,
		currentEditor:  0		,
		primaryColor:  "#EAAE14"		,
		secondaryColor:  "#CD3A19"		,      },

        mutations: {

   
		setCurrentEditor: function(state, value) { 		
	state.currentEditor = value;

}
,
		changeLang: function(state, newLang) { 		
	i18n.locale = newLang

	state.language = newLang;

}
,
		setOffline: function(state, val) { 		
	state.offline = val

}
,
		setUser: function(state, user) { 		
	state.user = user

}
,
		setToken: function(state, token) { 		
	state.token = token

}
,
		project: function(state, datas){		
	state.project = datas

}
,
		pjDatas: function(state, datas){		
	state.pjDatas = datas

}
,
		iconPath: function(state, path) { 		
	state.iconPath = path;

}
,
		iconBlob: function(state, blob) { 		
	state.iconBlob = blob;

}
,
		setSaved: function(state, value) { 		
	state.saved = value;

}
,     },

        actions: {

   
		setProject: function(context, data) { 		
	context.commit('project', data)

	context.commit('pjDatas', data.pj_setting)

}
,     },

        
//<= end_store =>
})
