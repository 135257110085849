<template>
 <div>  
<div id="NivItem"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : '#a5a5a5',}">
 <div  :class="'rectangle rectanglerec01 '"   id="rectanglerec01" ref="rectanglerec01"  v-bind:style="{}">
 <v-img  :class="'image imagerectanglerec0111111122222 '"   id="imagerectanglerec0111111122222" ref="imagerectanglerec0111111122222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + 'Resources/App/niv/' + nivNumber + '.png' + '')" 
>  </v-img>
 <div  :class="'item itemrowrectanglerec013333333 '"   id="itemrowrectanglerec013333333" ref="itemrowrectanglerec013333333"  v-bind:style="{}">
 <div  :class="'text niv '"   id="niv" ref="niv"  v-bind:style="{color : '#969696',}">
<div class="text-content" :style = "{}">{{ 'Niveau' + nivNumber }}</div>

   </div>
 <div  :class="'text niv22222 '"   id="niv22222" ref="niv22222"  v-bind:style="{color : '#969696',}">
<div class="text-content" :style = "{}">{{ lignes + ' lignes' }}</div>

   </div>  </div>
 <div  :class="'rectangle activePoint '"   id="activePoint" ref="activePoint" v-if="active"  v-bind:style="{backgroundColor : secondaryColor,}">  </div>  </div>
 <div  :class="'mousearea mousearearec03 '"   id="mousearearec03" ref="mousearearec03"  v-bind:style="{}" @click="clo(nivNumber-1)"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'NivItem',

    components: {},
	computed: {
	
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	props: {
	
		nivNumber: {					required: false,			default: () =>  1,		},
		lignes: {					required: false,			default: () =>  156,		},
		clo: {			type: Function,			required: false,			default: () => ()=>{},		},
		active: {					required: false,			default: () =>  false,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#NivItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#a5a5a5 ; 

.rectangle.rectanglerec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 1px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

	.image.imagerectanglerec0111111122222{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left:10px ; 
	
	top: calc(50% + 0px) ; 
	width:45px!important ; 
	height:45px!important ; 
	
	display: inherit;

	flex: unset;
	border-radius:40px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}

	.item.itemrowrectanglerec013333333{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 60px ; 
	
	top: 10px ; 
	right: 10px ; 
	bottom: 10px ; 
	

		.text.niv{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		height:auto!important ; 
		
		color:#969696 ; 
		font-size:12px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.text.niv22222{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		right: 5px ; 
		bottom: 0px ; 
		height:auto!important ; 
		
		color:#969696 ; 
		font-size:12px ; 
		text-align:right;
		display: flex;
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		white-space: nowrap;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

	
}

	.rectangle.activePoint{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:10px ; 
	
	top: 10px ; 
	width:8px!important ; 
	height:8px!important ; 
	
	background-color:#ffffff ; 
	border-radius:4px ; 

	
}


}

.mousearea.mousearearec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 







	.image.imagerectanglerec0111111122222{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrowrectanglerec013333333{

	
	position:absolute !important; 
	
	
	
	
	

		.text.niv{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.niv22222{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.activePoint{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 







	.image.imagerectanglerec0111111122222{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrowrectanglerec013333333{

	
	position:absolute !important; 
	
	
	
	
	

		.text.niv{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.niv22222{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.activePoint{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 







	.image.imagerectanglerec0111111122222{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrowrectanglerec013333333{

	
	position:absolute !important; 
	
	
	
	
	

		.text.niv{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.niv22222{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.activePoint{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 







	.image.imagerectanglerec0111111122222{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrowrectanglerec013333333{

	
	position:absolute !important; 
	
	
	
	
	

		.text.niv{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.niv22222{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.activePoint{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 







	.image.imagerectanglerec0111111122222{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrowrectanglerec013333333{

	
	position:absolute !important; 
	
	
	
	
	

		.text.niv{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.niv22222{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.rectangle.activePoint{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}


}

.mousearea.mousearearec03{


position:absolute !important; 





}


}
}
}
</style>
