<template>
 <div>  
<div id="ImgToAdd"> 
         
 <div  :class="'item imgToAdd2 '"   id="imgToAdd2" ref="imgToAdd2"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageimgToAdd1 '"   id="networkimageimgToAdd1" ref="networkimageimgToAdd1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "src" 
>  </v-img>
 <div  :class="'mousearea mouseareaimgToAdd3 '"   id="mouseareaimgToAdd3" ref="mouseareaimgToAdd3"  v-bind:style="{}" @click="m_select"></div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="m_remove" :class="'button buttonimgToAdd5 '"   id="buttonimgToAdd5" ref="buttonimgToAdd5"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-close-circle-outline' !== undefined"  :color = "'#ec5151'" 
>{{ 'ion-ios-close-circle-outline' }}
</v-icon></v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ImgToAdd',

    components: {},
	methods: {
	
		m_select: function(){ 		
	this.select(this.index)

}
,
		m_remove: function(){ 		
	this.remove(this.index)

}
,
	},
	props: {
	
		src: {					required: false,			default: () =>  "https://picsum.photos/200",		},
		id: {					required: false,			default: () =>  0,		},
		index: {					required: false,			default: () =>  0,		},
		select: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		remove: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ImgToAdd{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.imgToAdd2{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.networkimage.networkimageimgToAdd1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}


}

.mousearea.mouseareaimgToAdd3{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}

.button.buttonimgToAdd5{

height: auto;

position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 5px ; 
right: 5px ; 
width:20px!important ; 
height:20px!important ; 

text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}


@media (max-width: 600px) {


.item.imgToAdd2{


position:absolute !important; 






.networkimage.networkimageimgToAdd1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareaimgToAdd3{


position:absolute !important; 





}

.button.buttonimgToAdd5{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.item.imgToAdd2{


position:absolute !important; 






.networkimage.networkimageimgToAdd1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareaimgToAdd3{


position:absolute !important; 





}

.button.buttonimgToAdd5{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.item.imgToAdd2{


position:absolute !important; 






.networkimage.networkimageimgToAdd1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareaimgToAdd3{


position:absolute !important; 





}

.button.buttonimgToAdd5{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.item.imgToAdd2{


position:absolute !important; 






.networkimage.networkimageimgToAdd1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareaimgToAdd3{


position:absolute !important; 





}

.button.buttonimgToAdd5{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.item.imgToAdd2{


position:absolute !important; 






.networkimage.networkimageimgToAdd1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareaimgToAdd3{


position:absolute !important; 





}

.button.buttonimgToAdd5{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
