<template>
 <div>  
<div id="Profile"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : '#ededed',}">
 <div  :class="'scrollarea scrollarearec01 '"   id="scrollarearec01" ref="scrollarearec01"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columnrec0311111 '"   id="columnrec0311111" ref="columnrec0311111"  v-bind:style="{}">
 <div  :class="'rectangle top11111 '"   id="top11111" ref="top11111"  v-bind:style="{backgroundColor : secondaryColor,}">
 <div  :class="'row rowtop1 '"   id="rowtop1" ref="rowtop1"  v-bind:style="{}">
 <div  :class="'item back '"   id="back" ref="back"  v-bind:style="{}">
 <div  :class="'icon iconrowtop1111111 '"   id="iconrowtop1111111" ref="iconrowtop1111111"  v-bind:style="{color : '#fff',fontSize : getUnit(25,'px'),}"><i  :class="'ion-md-arrow-back'"></i></div>
 <div  :data-balloon-length = "'medium'" 
 :aria-label = "'Retour'" 
 :data-balloon-pos = "'right'" 
 :class="'mousearea mouseareaback3 '"   id="mouseareaback3" ref="mouseareaback3"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'text textrowtop15 '"   id="textrowtop15" ref="textrowtop15"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ 'Profil' }}</div>

   </div>  </div>  </div>
 <div  :class="'item imageZone '"   id="imageZone" ref="imageZone"  v-bind:style="{}">
 <div  :class="'item itemimageZone3 '"   id="itemimageZone3" ref="itemimageZone3"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageimageZone122222 '"   id="networkimageimageZone122222" ref="networkimageimageZone122222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_profileImage" 
>  </v-img>
 <v-btn tag="div" 
 :color = "secondaryColor" 
 :fab = "true" 
 :onlyIcon = "true" 
 :dark = "true" 
 @click="chooseIcon" :class="'button buttonlogorec311111 '"   id="buttonlogorec311111" ref="buttonlogorec311111"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-camera' !== undefined"  :color = "'#fff'" 
>{{ 'ion-ios-camera' }}
</v-icon></v-btn>  </div>  </div>
 <v-card 
 :class="'card nameZone '"   id="nameZone" ref="nameZone" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'column columnnameZone1 '"   id="columnnameZone1" ref="columnnameZone1"  v-bind:style="{}">
 <div  :class="'text textcolumnnameZone11 '"   id="textcolumnnameZone11" ref="textcolumnnameZone11"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ languages.votreNom[language] }}</div>

   </div>
 <v-text-field  :placeholder = "'Votre nom'" 
 :class="'textfield nameInput '"   id="nameInput" ref="nameInput" v-model="m_profileName"  v-bind:style="{color : '#9696ee',}"></v-text-field>  </div>  </v-card>
 <div  :class="'item itemcolumnrec039 '"   id="itemcolumnrec039" ref="itemcolumnrec039"  v-bind:style="{}">  </div>
 <div  :class="'text textcolumnrec037 '"   id="textcolumnrec037" ref="textcolumnrec037"  v-bind:style="{color : '#999999',}">
<div class="text-content" :style = "{}">{{ languages.votreNomExplication[language] }}</div>

   </div>
 <v-card 
 :class="'card deleteZone6 '"   id="deleteZone6" ref="deleteZone6" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'row rowrectangletool111111111111 '"   id="rowrectangletool111111111111" ref="rowrectangletool111111111111"  v-bind:style="{}">
 <Butt :class="'object homeButt '"   id="homeButt" ref="homeButt"  v-bind:style="{}" :clo = "updateUserData" 
 :ico = "'ion-md-done-all'" 
 :toolTip = "'Acceuil'" 
 :color = "'#5bf89a'" 
 :hoverColor = "'pink'" 
>
</Butt>
 <div  :class="'text textrowrectangletool11111113 '"   id="textrowrectangletool11111113" ref="textrowrectangletool11111113"  v-bind:style="{color : '#5bf89a',}">
<div class="text-content" :style = "{}">{{ 'Enregistrer les modifications' }}</div>

   </div>  </div>  </v-card>
 <v-card 
 :class="'card deleteZone67 '"   id="deleteZone67" ref="deleteZone67" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'row rowrectangletool111111111111 '"   id="rowrectangletool111111111111" ref="rowrectangletool111111111111"  v-bind:style="{}">
 <div  :class="'text textrowrectangletool11111113 '"   id="textrowrectangletool11111113" ref="textrowrectangletool11111113"  v-bind:style="{color : '#f81244',}">
<div class="text-content" :style = "{}">{{ 'Se déconnecté' }}</div>

   </div>  </div>
 <div  :class="'mousearea mouseareadeleteZone673 '"   id="mouseareadeleteZone673" ref="mouseareadeleteZone673"  v-bind:style="{}" @click="logout"></div>  </v-card>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import Butt from '../../At/Butt'

import ac from 'artefact-controller'
import lg from '../../Js/language.json'
import axios from 'axios';
	
import * as main from "../../Js/main.js"
export default {
	name: 'Profile',

    components: {Butt,},
	methods: {
	
		setup: function(){ 		
	this.m_profileName = this.userData.username;

	

	if(this.userData && this.userData.profile_image){

		this.m_profileImage = this.$store.state.apiURL + this.userData.profile_image.url

	} else {

		this.m_profileImage = "https://picsum.photos/200"

	}

}
,
		updateUserData: function(){		
	this.userData.username = this.m_profileName;

	

	if(this.imageChanged){

		this.updateImage()

	} else {

		this.updateUserDataNext()

	}

}
,
		updateUserDataNext: function(imgData){		
	main.updateUser(this.userData, imgData)

	.then((response) => {

     this.$store.commit('setUser', response.data);

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		updateImage: function(){			
	main.uploadFile(this.imageBlob)

	.then((data) => {

		this.imageChanged = false

		this.updateUserDataNext(data.data[0])

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		chooseIcon: function(){		
	this.atController.fileOpen({multiple: false, accept: 'image/*' }, (file) => {		

  	const reader = new FileReader();

  	reader.addEventListener('load', (event) => {

    	this.m_profileImage = event.target.result;

			this.imageChanged = true

  	});

  	reader.readAsDataURL(file[0]); 

		this.imageBlob = file[0];

	});

}
,
		logout: function(){		
	main.logout()

}
,
	},
	mounted(){
			
	this.setup()

	},
	computed: {
	
		language: function(){		
	return this.$store.state.language

}	
,
		userData: function(){		
	return this.$store.state.user

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	watch: {
	
		userData: function(val){		
	this.setup()

}
,
	},
	data(){
	return {
		
		atController:  new ac()		,
		languages:  lg		,
		imagePath:  ""		,
		imageBlob:  ""		,
		m_profileName:  ''		,
		m_profileImage:  ""		,
		imageChanged:  false		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Profile{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ededed ; 

.scrollarea.scrollarearec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

overflow-x:hidden ; 
overflow-y:scroll ; 
background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #9696ee;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

	.column.columnrec0311111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:15px ; background-color: transparent ; 

		.rectangle.top11111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:108px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#ffffff ; 

			.row.rowtop1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:20px ; 
			
			bottom: 20px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:40px ; background-color:transparent ; 

				.item.back{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:30px!important ; 
				height:30px!important ; 
				

					.icon.iconrowtop1111111{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:30px!important ; 
					height:30px!important ; 
					
					color:#fff ; 
					font-size:25px ; 
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.mousearea.mouseareaback3{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					
					cursor: pointer;
					}

				
}

				.text.textrowtop15{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
				color:#fff ; 
				font-size:20px ; 
				align-items:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.imageZone{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:250px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.item.itemimageZone3{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:200px!important ; 
			height:200px!important ; 
			

				.networkimage.networkimageimageZone122222{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
				
				left: calc(50% + 0px) ; 
				
				top: calc(50% + 0px) ; 
				width:200px!important ; 
				height:200px!important ; 
				
				display: inherit;

				flex: unset;
				border-radius:1000px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

				
}

				.button.buttonlogorec311111{

				height: auto;
				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: 0px ; 
				bottom: 0px ; 
				width:56px!important ; 
				height:56px!important ; 
				
				font-size:32px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:32px ; 
				}
				}

			
}

		
}

		.card.nameZone{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:95px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.column.columnnameZone1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 20px ; 
			
			top: 10px ; 
			right: 20px ; 
			bottom: 10px ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.textcolumnnameZone11{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.nameInput{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:36px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
				color:#9696ee ; 
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

		
}

		.item.itemcolumnrec039{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:15px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

		
}

		.text.textcolumnrec037{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 20px ; 
		
		margin-right: 20px ; 
		opacity: 0.8 ;  filter: alpha(opacity=80) ; 
		height:auto!important ; 
		width: calc(100% - (20px + 20px)) !important;
		
		color:#999999 ; 
		font-size:14px ; 
		.text-content{
		width: 100%;
		}

		
}

		.card.deleteZone6{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:60px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:35px ; 
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:46px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:30px ; background-color:transparent ; 

				.object.homeButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
				color:#5bf89a ; 
				font-size:17px ; 
				align-items:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.card.deleteZone67{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:60px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:35px ; 
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:46px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:30px ; background-color:transparent ; 

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				margin-top: 0px ; 
				margin-bottom: 0px ; 
				width:auto!important ; 
				height: calc(100% - (0px + 0px)) !important;
				
				color:#f81244 ; 
				font-size:17px ; 
				align-items:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

			
}

			.mousearea.mouseareadeleteZone673{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.scrollarea.scrollarearec01{


position:absolute !important; 






	.column.columnrec0311111{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.rectangle.top11111{

		
		position:relative !important; 
		
		
		
		
		
		

			.row.rowtop1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.item.back{

				
				position:relative !important; 
				
				
				
				
				

					.icon.iconrowtop1111111{

					
					position:absolute !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.mousearea.mouseareaback3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowtop15{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.imageZone{

		
		position:relative !important; 
		
		
		
		
		

			.item.itemimageZone3{

			
			position:absolute !important; 
			
			
			
			
			

				.networkimage.networkimageimageZone122222{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec311111{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.nameZone{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnnameZone1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnnameZone11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.nameInput{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

		
}

		.item.itemcolumnrec039{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textcolumnrec037{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.card.deleteZone6{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.card.deleteZone67{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.mousearea.mouseareadeleteZone673{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.scrollarea.scrollarearec01{


position:absolute !important; 






	.column.columnrec0311111{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.rectangle.top11111{

		
		position:relative !important; 
		
		
		
		
		
		

			.row.rowtop1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.item.back{

				
				position:relative !important; 
				
				
				
				
				

					.icon.iconrowtop1111111{

					
					position:absolute !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.mousearea.mouseareaback3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowtop15{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.imageZone{

		
		position:relative !important; 
		
		
		
		
		

			.item.itemimageZone3{

			
			position:absolute !important; 
			
			
			
			
			

				.networkimage.networkimageimageZone122222{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec311111{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.nameZone{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnnameZone1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnnameZone11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.nameInput{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

		
}

		.item.itemcolumnrec039{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textcolumnrec037{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.card.deleteZone6{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.card.deleteZone67{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.mousearea.mouseareadeleteZone673{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.scrollarea.scrollarearec01{


position:absolute !important; 






	.column.columnrec0311111{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.rectangle.top11111{

		
		position:relative !important; 
		
		
		
		
		
		

			.row.rowtop1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.item.back{

				
				position:relative !important; 
				
				
				
				
				

					.icon.iconrowtop1111111{

					
					position:absolute !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.mousearea.mouseareaback3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowtop15{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.imageZone{

		
		position:relative !important; 
		
		
		
		
		

			.item.itemimageZone3{

			
			position:absolute !important; 
			
			
			
			
			

				.networkimage.networkimageimageZone122222{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec311111{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.nameZone{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnnameZone1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnnameZone11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.nameInput{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

		
}

		.item.itemcolumnrec039{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textcolumnrec037{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.card.deleteZone6{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.card.deleteZone67{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.mousearea.mouseareadeleteZone673{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.scrollarea.scrollarearec01{


position:absolute !important; 






	.column.columnrec0311111{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.rectangle.top11111{

		
		position:relative !important; 
		
		
		
		
		
		

			.row.rowtop1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.item.back{

				
				position:relative !important; 
				
				
				
				
				

					.icon.iconrowtop1111111{

					
					position:absolute !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.mousearea.mouseareaback3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowtop15{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.imageZone{

		
		position:relative !important; 
		
		
		
		
		

			.item.itemimageZone3{

			
			position:absolute !important; 
			
			
			
			
			

				.networkimage.networkimageimageZone122222{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec311111{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.nameZone{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnnameZone1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnnameZone11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.nameInput{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

		
}

		.item.itemcolumnrec039{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textcolumnrec037{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.card.deleteZone6{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.card.deleteZone67{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.mousearea.mouseareadeleteZone673{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.scrollarea.scrollarearec01{


position:absolute !important; 






	.column.columnrec0311111{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.rectangle.top11111{

		
		position:relative !important; 
		
		
		
		
		
		

			.row.rowtop1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.item.back{

				
				position:relative !important; 
				
				
				
				
				

					.icon.iconrowtop1111111{

					
					position:absolute !important; 
					
					
					
					
					
					display: flex;
					justify-content: center;
					align-items: center;
					i {
					}
}

					.mousearea.mouseareaback3{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowtop15{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.imageZone{

		
		position:relative !important; 
		
		
		
		
		

			.item.itemimageZone3{

			
			position:absolute !important; 
			
			
			
			
			

				.networkimage.networkimageimageZone122222{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec311111{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.nameZone{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnnameZone1{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textcolumnnameZone11{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.nameInput{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

		
}

		.item.itemcolumnrec039{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textcolumnrec037{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.card.deleteZone6{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.card.deleteZone67{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.row.rowrectangletool111111111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textrowrectangletool11111113{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.mousearea.mouseareadeleteZone673{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}


}
}
}
</style>
