<template>
 <div>  
<div id="HomeGlass"> 
         
 <GlassCard :class="'object glasscarditem0212 '"   id="glasscarditem0212" ref="glasscarditem0212"  v-bind:style="{}" :radius = "'50'" 
>
 <template  v-slot:child>
 <div  :class="'slottemplate slottemplateglasscarditem02121 '"   id="slottemplateglasscarditem02121" ref="slottemplateglasscarditem02121"  v-bind:style="{}">
 <v-card 
 :class="'card profileCard '"   id="profileCard" ref="profileCard" :elevation = "4" 
  v-bind:style="{}">
 <v-img  :class="'networkimage profileImage '"   id="profileImage" ref="profileImage"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>  </v-img>  </v-card>
 <div  :class="'row pjList '"   id="pjList" ref="pjList"  v-bind:style="{}">
 <WorldProject :class="'object worldprojectpjList1 '"   id="worldprojectpjList1" ref="worldprojectpjList1" v-for="(item,index) in nomberPj" :key="'worldprojectpjList1' + index"  v-bind:style="{}" :pjImage = "projectList[(index + pjIndex)].description_image.url" 
 :pjName = "projectList[(index + pjIndex)].name" 
 :pjID = "projectList[(index + pjIndex)].id" 
>
</WorldProject>  </div>
 <div  :class="'column left '"   id="left" ref="left"  v-bind:style="{}">
 <div  :class="'text textleft1 '"   id="textleft1" ref="textleft1"  v-bind:style="{color : '#ff6900',}">
<div class="text-content" :style = "{}">{{ 'abcCode' }}</div>

   </div>
 <div  :class="'text textleft3 '"   id="textleft3" ref="textleft3"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('acceuil.slogan') }}</div>

   </div>
 <div  :class="'text textleft5 '"   id="textleft5" ref="textleft5"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('acceuil.subSlogan') }}</div>

   </div>
 <div  :class="'row rowleft8 '"   id="rowleft8" ref="rowleft8"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :rounded = "true" 
 :dark = "true" 
 @click="goToApp" :class="'button buttonleft7 '"   id="buttonleft7" ref="buttonleft7"  v-bind:style="{}"><span>{{ $t('acceuil.coder') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :outlined = "true" 
 :rounded = "true" 
 :dark = "true" 
 @click="goToStore" :class="'button buttonleft72 '"   id="buttonleft72" ref="buttonleft72"  v-bind:style="{}"><span>{{ $t('acceuil.explorer') }}</span>
</v-btn>  </div>  </div>
 <div  :class="'row prevNextCol '"   id="prevNextCol" ref="prevNextCol"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#fff'" 
 :fab = "true" 
 :disabled = "prevDisAble" 
 @click="prevPj" :class="'button prevBtn '"   id="prevBtn" ref="prevBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-back' !== undefined"  :color = "'#ff6900'" 
>{{ 'ion-ios-arrow-back' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#ff6900'" 
 :fab = "true" 
 :disabled = "nextDisAble" 
 @click="nextPj" :class="'button nextBtn '"   id="nextBtn" ref="nextBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-forward' !== undefined"  :color = "'#fff'" 
>{{ 'ion-ios-arrow-forward' }}
</v-icon></v-btn>  </div></div></template>
</GlassCard>
 
      </div>
 </div> 
</template>
<script>
import GlassCard from '../../At/Utils/GlassCard'
import WorldProject from '../../At/Home/WorldProject'

import * as main from "../../Js/main.js"
export default {
	name: 'HomeGlass',

    components: {GlassCard,WorldProject,},
	methods: {
	
		goToApp: function(){ 		
	this.$router.push('/app')

}
,
		goToStore: function(){ 		
	window.open(this.$store.state.worldServer, '_blank').focus();

}
,
		getProjects: function(){ 		
	main.getProjects()

	.then((response)=>{

		this.projectList = response.data.map(e => {

			e.description_image.url = this.$store.state.apiURL + e.description_image.url

			return e

		})

		.sort((a, b) => (b.id - a.id))

	})

	.catch((error)=>{

	  console.log(error);

	});

}
,
		prevPj: function(){		
	if(this.pjIndex>0){

		this.pjIndex--

	}

}
,
		nextPj: function(){		
	if(((this.pjIndex+1)*3) < this.projectList.length){

		this.pjIndex++

	}

}
,
	},
	mounted(){
			
	this.userName = this.userData.username

	this.getProjects()

	},
	computed: {
	
		userImage: function(){		
	if(this.userData && this.userData.profile_image){

		return this.$store.state.apiURL + this.userData.profile_image.url

	} else {

		return "https://picsum.photos/200"

	}

}
,
		userData: function(){		
	return this.$store.state.user

}
,
		prevDisAble: function(){ 		
	return (this.pjIndex == 0)

}
,
		nextDisAble: function(){ 		
	return (((this.pjIndex+1)*3) >= this.projectList.length)

}
,
		nomberPj: function(){ 		
	return Math.min(3, this.projectList.length)

}
,
	},
	data(){
	return {
		
		userName:  ''		,
		projectList:  []		,
		pjIndex:  0		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#HomeGlass{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 0 15px !important;	
}



.object.glasscarditem0212{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateglasscarditem02121{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

top: 0px ; 
width:100px!important ; 
height:100px!important ; 

left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.card.profileCard{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 25px ; 
	right: 25px ; 
	width:50px!important ; 
	height:50px!important ; 
	background-color:#ffffff ; 
	border-radius:25px ; max-width: initial;min-width: initial;

		.networkimage.profileImage{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

	
}

	.row.pjList{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	right: -100px ; 
	bottom: 20px ; 
	 display:flex !important; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.object.worldprojectpjList1{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:180px!important ; 
		height:180px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.column.left{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 50px ; 
	
	top: 50px ; 
	bottom: 300px ; 
	width:80%!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.text.textleft1{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:auto!important ; 
		
		color:#ff6900 ; 
		font-size:18px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textleft3{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#fff ; 
		line-height:60px ; 
		font-size:55px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.textleft5{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#fff ; 
		font-size:18px ; 
		.text-content{
		width: 100%;
		}

		
}

		.row.rowleft8{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:20px ; background-color:transparent ; 

			.button.buttonleft7{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:auto!important ; 
			height:45px!important ; 
			
			font-size:20px ; 
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			font-size:20px ; 
			}
			}

			.button.buttonleft72{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:auto!important ; 
			height:45px!important ; 
			
			font-size:20px ; 
			text-transform:unset ; 
			min-width: auto;
			padding-left: 16px;
			padding-right: 16px;

			i.v-icon{
			font-size:20px ; 
			}
			}

		
}

	
}

	.row.prevNextCol{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 50px ; 
	
	bottom: 30px ; 
	 display:flex !important; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:50px!important ; 
		height:50px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:50px!important ; 
		height:50px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.object.glasscarditem0212{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateglasscarditem02121{


position:absolute !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.card.profileCard{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.profileImage{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.row.pjList{

	
	position:absolute !important; 
	
	
	
	
	display:none !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldprojectpjList1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.column.left{

	
	position:absolute !important; 
	
	
	
	left: 10% ; 
	
	
	
	
	

		.text.textleft1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft3{

		
		position:relative !important; 
		
		
		
		
		
		line-height:50px ; 
		font-size:45px ; 
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft5{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowleft8{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonleft7{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonleft72{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.prevNextCol{

	
	position:absolute !important; 
	
	
	
	
	display:none !important; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.object.glasscarditem0212{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateglasscarditem02121{


position:absolute !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.card.profileCard{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.profileImage{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.row.pjList{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldprojectpjList1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.column.left{

	
	position:absolute !important; 
	
	
	
	
	width:70%!important ; 
	
	
	
	

		.text.textleft1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft5{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowleft8{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonleft7{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonleft72{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.prevNextCol{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.object.glasscarditem0212{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateglasscarditem02121{


position:absolute !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.card.profileCard{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.profileImage{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.row.pjList{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldprojectpjList1{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.column.left{

	
	position:absolute !important; 
	
	
	
	
	width:60%!important ; 
	
	
	
	

		.text.textleft1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft5{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowleft8{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonleft7{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonleft72{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.prevNextCol{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.object.glasscarditem0212{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateglasscarditem02121{


position:absolute !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.card.profileCard{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.profileImage{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.row.pjList{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldprojectpjList1{

		
		position:relative !important; 
		
		
		
		
		width:200px!important ; 
		height:200px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.column.left{

	
	position:absolute !important; 
	
	
	
	
	width:50%!important ; 
	
	
	
	

		.text.textleft1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft5{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowleft8{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonleft7{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonleft72{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.prevNextCol{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.object.glasscarditem0212{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}

.slottemplate.slottemplateglasscarditem02121{


position:absolute !important; 





left: 0;
top:0;
right: 0;
bottom: 0;
width: 100% !important;
height: 100% !important;

	.card.profileCard{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.networkimage.profileImage{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

	
}

	.row.pjList{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.worldprojectpjList1{

		
		position:relative !important; 
		
		
		
		
		width:250px!important ; 
		height:250px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.column.left{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textleft1{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft3{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.textleft5{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.row.rowleft8{

		
		position:relative !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonleft7{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonleft72{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.prevNextCol{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.prevBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.button.nextBtn{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
