<template>
 <div>  
<div id="SplashScreen"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-img  :class="'image imageitem03 '"   id="imageitem03" ref="imageitem03"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/b5.png')" 
>  </v-img>
 <v-img  :class="'animatedimage animatedimageitem01 '"   id="animatedimageitem01" ref="animatedimageitem01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/source.gif')" 
>  </v-img>
 <div  :class="'row rowitem05 '"   id="rowitem05" ref="rowitem05"  v-bind:style="{}">
 <div  :class="'item itemrowitem051 '"   id="itemrowitem051" ref="itemrowitem051"  v-bind:style="{}">
 <a  :class="'link linkitemrowitem0517 '"   id="linkitemrowitem0517" ref="linkitemrowitem0517"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemrowitem0513111 '"   id="rectangleitemrowitem0513111" ref="rectangleitemrowitem0513111"  v-bind:style="{backgroundColor : '#efefef',}">  </div>
 <v-img  :class="'image imageitemrowitem0511222 '"   id="imageitemrowitem0511222" ref="imageitemrowitem0511222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/iconewhatsapp.png')" 
>  </v-img>
 <div  :class="'text textitemrowitem0515333 '"   id="textitemrowitem0515333" ref="textitemrowitem0515333"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'WhatsApp' }}</div>

   </div>  </a>  </div>
 <div  :class="'item itemrowitem051222 '"   id="itemrowitem051222" ref="itemrowitem051222"  v-bind:style="{}">
 <a  :href = "'https://www.instagram.com/caysti237/'" 
 :class="'link linkitemrowitem0517 '"   id="linkitemrowitem0517" ref="linkitemrowitem0517"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemrowitem0513111 '"   id="rectangleitemrowitem0513111" ref="rectangleitemrowitem0513111"  v-bind:style="{backgroundColor : '#efefef',}">  </div>
 <v-img  :class="'image imageitemrowitem0511222 '"   id="imageitemrowitem0511222" ref="imageitemrowitem0511222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/iconeinstagram.png')" 
>  </v-img>
 <div  :class="'text textitemrowitem0515333 '"   id="textitemrowitem0515333" ref="textitemrowitem0515333"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Instagram' }}</div>

   </div>  </a>  </div>
 <div  :class="'item itemrowitem051333 '"   id="itemrowitem051333" ref="itemrowitem051333"  v-bind:style="{}">
 <a  :href = "'https://www.facebook.com/caysti'" 
 :class="'link linkitemrowitem0517 '"   id="linkitemrowitem0517" ref="linkitemrowitem0517"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemrowitem0513111 '"   id="rectangleitemrowitem0513111" ref="rectangleitemrowitem0513111"  v-bind:style="{backgroundColor : '#efefef',}">  </div>
 <v-img  :class="'image imageitemrowitem0511222 '"   id="imageitemrowitem0511222" ref="imageitemrowitem0511222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/iconefacebook.png')" 
>  </v-img>
 <div  :class="'text textitemrowitem0515333 '"   id="textitemrowitem0515333" ref="textitemrowitem0515333"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Facebook' }}</div>

   </div>  </a>  </div>
 <div  :class="'item itemrowitem051444 '"   id="itemrowitem051444" ref="itemrowitem051444"  v-bind:style="{}">
 <a  :href = "'https://twitter.com/abcCode1'" 
 :class="'link linkitemrowitem0517 '"   id="linkitemrowitem0517" ref="linkitemrowitem0517"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemrowitem0513111 '"   id="rectangleitemrowitem0513111" ref="rectangleitemrowitem0513111"  v-bind:style="{backgroundColor : '#efefef',}">  </div>
 <v-img  :class="'image imageitemrowitem0511222 '"   id="imageitemrowitem0511222" ref="imageitemrowitem0511222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/iconetweeter.png')" 
>  </v-img>
 <div  :class="'text textitemrowitem0515333 '"   id="textitemrowitem0515333" ref="textitemrowitem0515333"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Twitter' }}</div>

   </div>  </a>  </div>  </div>
 <v-img  :class="'animatedimage animatedimageitem015 '"   id="animatedimageitem015" ref="animatedimageitem015"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/SplashScreen/anim5.gif')" 
>  </v-img>
 <v-card 
 :class="'card centerContent '"   id="centerContent" ref="centerContent"  v-bind:style="{}">
 <div  :class="'row rowcenterContent5 '"   id="rowcenterContent5" ref="rowcenterContent5"  v-bind:style="{justifyContent : 'space-around',}">
 <div  :class="'item left '"   id="left" ref="left"  v-bind:style="{}">
 <div  :class="'rectangle tiltLogo '"   id="tiltLogo" ref="tiltLogo"  v-bind:style="{backgroundColor : '#dce1f4',}">
 <v-img  :class="'image imagecenterContent11111111111 '"   id="imagecenterContent11111111111" ref="imagecenterContent11111111111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/Acceuil/abccode_logo_n_g_transparent.png')" 
>  </v-img>
 <div  :class="'rectangle rectanglerectangleleft33 '"   id="rectanglerectangleleft33" ref="rectanglerectangleleft33"  v-bind:style="{backgroundColor : 'transparent',}">  </div>
 <div  :class="'rectangle rectanglerectangleleft3333333 '"   id="rectanglerectangleleft3333333" ref="rectanglerectangleleft3333333"  v-bind:style="{ transform: 'rotate(' + (-1*20) + 'deg)', backgroundColor : 'transparent',}">  </div>
 <div  :class="'rectangle rectanglerectangleleft3344444 '"   id="rectanglerectangleleft3344444" ref="rectanglerectangleleft3344444"  v-bind:style="{backgroundColor : 'transparent',}">  </div>
 <div  :class="'rectangle rectanglerectangleleft3355555 '"   id="rectanglerectangleleft3355555" ref="rectanglerectangleleft3355555"  v-bind:style="{backgroundColor : 'transparent',}">  </div>
 <div  :class="'rectangle rectanglerectangleleft335555566666 '"   id="rectanglerectangleleft335555566666" ref="rectanglerectangleleft335555566666"  v-bind:style="{backgroundColor : 'transparent',}">  </div>
 <div  :class="'text texttiltLogo13 '"   id="texttiltLogo13" ref="texttiltLogo13"  v-bind:style="{color : '#7e89ad',}">
<div class="text-content" :style = "{}">{{ 'abcCode' }}</div>

   </div>  </div>  </div>
 <div  :class="'item rigth22222 '"   id="rigth22222" ref="rigth22222"  v-bind:style="{}">
 <LogIn :class="'object loginrigth222223 '"   id="loginrigth222223" ref="loginrigth222223" @login="login"   v-bind:style="{ display: (loginShow? 'block' : 'none'), }">
</LogIn>
 <Register :class="'object registerrigth222223 '"   id="registerrigth222223" ref="registerrigth222223" @registered="registered"   v-bind:style="{ display: (registerShow? 'block' : 'none'), }">
</Register>
 <LanguageChoose :class="'object loginrigth222226 '"   id="loginrigth222226" ref="loginrigth222226"  v-bind:style="{ display: (languageShow? 'block' : 'none'), }">
</LanguageChoose>
 <MailConfirm :class="'object mailconfirmrigth222225 '"   id="mailconfirmrigth222225" ref="mailconfirmrigth222225" v-if="confirmMailVisible"  v-bind:style="{}">
</MailConfirm>  </div>  </div>
 <div  :class="'row rowcenterContent4 '"   id="rowcenterContent4" ref="rowcenterContent4"  v-bind:style="{}">
 <div  :class="'item itemcenterContent52 '"   id="itemcenterContent52" ref="itemcenterContent52" v-if="!languageShow"  v-bind:style="{}">
 <div  :class="'row rowcenterContent311111 '"   id="rowcenterContent311111" ref="rowcenterContent311111"  v-bind:style="{}">
 <div  :class="'text textloginCol1122222register11111 '"   id="textloginCol1122222register11111" ref="textloginCol1122222register11111"  v-bind:style="{color : '#3bb1f9',}">
<div class="text-content" :style = "{}">{{ $t('main.changeLanguage') }}</div>

   </div>
 <div  :class="'icon iconrowcenterContent3111113 '"   id="iconrowcenterContent3111113" ref="iconrowcenterContent3111113"  v-bind:style="{color : '#3bb1f9',fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-arrow-round-forward'"></i></div>  </div>
 <div  :class="'mousearea mouseareatextloginCol11122222 '"   id="mouseareatextloginCol11122222" ref="mouseareatextloginCol11122222"  v-bind:style="{}" @click="showLanguageSelection"></div>  </div>
 <div  :class="'item itemcenterContent5 '"   id="itemcenterContent5" ref="itemcenterContent5" v-if="!loginShow"  v-bind:style="{}">
 <div  :class="'row rowcenterContent311111 '"   id="rowcenterContent311111" ref="rowcenterContent311111"  v-bind:style="{}">
 <div  :class="'text textloginCol1122222register11111 '"   id="textloginCol1122222register11111" ref="textloginCol1122222register11111"  v-bind:style="{color : '#3bb1f9',}">
<div class="text-content" :style = "{}">{{ $t('main.loginAccount') }}</div>

   </div>
 <div  :class="'icon iconrowcenterContent3111113 '"   id="iconrowcenterContent3111113" ref="iconrowcenterContent3111113"  v-bind:style="{color : '#3bb1f9',fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-arrow-round-forward'"></i></div>  </div>
 <div  :class="'mousearea mouseareatextloginCol11122222 '"   id="mouseareatextloginCol11122222" ref="mouseareatextloginCol11122222"  v-bind:style="{}" @click="showLogin"></div>  </div>
 <div  :class="'item itemcenterContent53 '"   id="itemcenterContent53" ref="itemcenterContent53" v-if="!registerShow"  v-bind:style="{}">
 <div  :class="'row rowcenterContent311111 '"   id="rowcenterContent311111" ref="rowcenterContent311111"  v-bind:style="{}">
 <div  :class="'text textloginCol1122222register11111 '"   id="textloginCol1122222register11111" ref="textloginCol1122222register11111"  v-bind:style="{color : '#3bb1f9',}">
<div class="text-content" :style = "{}">{{ $t('main.createAccount') }}</div>

   </div>
 <div  :class="'icon iconrowcenterContent3111113 '"   id="iconrowcenterContent3111113" ref="iconrowcenterContent3111113"  v-bind:style="{color : '#3bb1f9',fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-arrow-round-forward'"></i></div>  </div>
 <div  :class="'mousearea mouseareatextloginCol11122222 '"   id="mouseareatextloginCol11122222" ref="mouseareatextloginCol11122222"  v-bind:style="{}" @click="showSignin"></div>  </div>  </div>  </v-card>
 <OfflineCheck :class="'object offlinecheckitem011 '"   id="offlinecheckitem011" ref="offlinecheckitem011" v-if="offlinePanelOpened"  v-bind:style="{}" :close = "closeOffLineSplash" 
>
</OfflineCheck>  </div>
 
      </div>
 </div> 
</template>
<script>
import LogIn from '../At/Splash/LogIn'
import Register from '../At/Splash/Register'
import LanguageChoose from '../At/Splash/LanguageChoose'
import MailConfirm from '../At/Splash/MailConfirm'
import OfflineCheck from '../At/Splash/OfflineCheck'

import '../Js/vanilla-tilt.min.js'
export default {
	name: 'SplashScreen',

    components: {LogIn,Register,LanguageChoose,MailConfirm,OfflineCheck,},
	methods: {
	
		showLogin: function(){		
	this.loginShow = true;

	this.registerShow = false;

	this.confirmMailVisible = false;

	this.languageShow = false;

}
,
		showSignin: function(){		
	this.loginShow = false;

	this.registerShow = true;

	this.confirmMailVisible = false;

	this.languageShow = false;

}
,
		goTo: function(path) { 		
	window.location.assign('/'+path)

}
,
		registered: function(data){		
	this.loginShow = false;

	this.registerShow = false;

	this.confirmMailVisible = true;

	this.languageShow = false;

}
,
		showLanguageSelection: function(data){		
	this.loginShow = false;

	this.registerShow = false;

	this.confirmMailVisible = false;

	this.languageShow = true;

}
,
		login: function(data){		
	this.$router.push('/acceuil');

}
,
		closeOffLineSplash: function(){		
	this.offlinePanelOpened = false

}
,
	},
	mounted(){
			
	if(this.$store.state.token !== null){

			this.$router.push('/acceuil');

	}
		
	VanillaTilt.init(document.querySelector("#tiltLogo"), {

		max: 10,

		speed: 200,

		glare: true,

		perspective: 2000,

	});

	},
	created(){
			
	this.showed = true;

	},
	data(){
	return {
		
		angle:  0		,
		showed:  false		,
		language:  this.$store.state.language		,
		offlinePanelOpened:  this.$store.state.appProd		,
		confirmMailVisible:  false		,
		loginShow:  true		,
		registerShow:  false		,
		languageShow:  false		,
		}
	},
	props: {
	
		active: {					required: false,			default: () =>  true,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SplashScreen{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

*{
	font-family: 'Pacifico', fantasy, cursive !important;
	
	.text.text-content{
		font-family: 'Pacifico', fantasy, cursive !important;	
	}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.image.imageitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.animatedimage.animatedimageitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.row.rowitem05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
width:auto!important ; 
height:49px!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;
gap:10px ; background-color:transparent ; 

	.item.itemrowitem051{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	width:150px!important ; 
	height:52px!important ; 
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 25px ; 
			
			top: calc(50% + 0px) ; 
			right: 0px ; 
			opacity: 0.6 ;  filter: alpha(opacity=60) ; 
			height:22px!important ; 
			
			background-color:#efefef ; 
			border-radius:10px ; 

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			top: 0px ; 
			width:52px!important ; 
			height:52px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 52px ; 
			
			top: calc(50% + 0px) ; 
			right: 10px ; 
			
			font-size:16px ; 
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051222{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	width:150px!important ; 
	height:52px!important ; 
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 25px ; 
			
			top: calc(50% + 0px) ; 
			right: 0px ; 
			opacity: 0.6 ;  filter: alpha(opacity=60) ; 
			height:22px!important ; 
			
			background-color:#efefef ; 
			border-radius:10px ; 

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			top: 0px ; 
			width:52px!important ; 
			height:52px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 52px ; 
			
			top: calc(50% + 0px) ; 
			right: 10px ; 
			
			font-size:16px ; 
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051333{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	width:150px!important ; 
	height:52px!important ; 
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 25px ; 
			
			top: calc(50% + 0px) ; 
			right: 0px ; 
			opacity: 0.6 ;  filter: alpha(opacity=60) ; 
			height:22px!important ; 
			
			background-color:#efefef ; 
			border-radius:10px ; 

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			top: 0px ; 
			width:52px!important ; 
			height:52px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 52px ; 
			
			top: calc(50% + 0px) ; 
			right: 10px ; 
			
			font-size:16px ; 
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051444{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 0px ; 
	
	width:150px!important ; 
	height:52px!important ; 
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 25px ; 
			
			top: calc(50% + 0px) ; 
			right: 0px ; 
			opacity: 0.6 ;  filter: alpha(opacity=60) ; 
			height:22px!important ; 
			
			background-color:#efefef ; 
			border-radius:10px ; 

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0px ; 
			
			top: 0px ; 
			width:52px!important ; 
			height:52px!important ; 
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 52px ; 
			
			top: calc(50% + 0px) ; 
			right: 5px ; 
			
			font-size:16px ; 
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.animatedimage.animatedimageitem015{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

bottom: 10px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.card.centerContent{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 20px ; 

top: 100px ; 
right: 20px ; 
bottom: 100px ; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

	.row.rowcenterContent5{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 50px ; 
	
	top: 50px ; 
	right: 50px ; 
	bottom: 50px ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	justify-content:space-around ; background-color:transparent ; 

		.item.left{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		display:none !important; 
		width:210px!important ; 
		height:210px!important ; 
		

			.rectangle.tiltLogo{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0.25px ; 
			
			top: 0px ; 
			width:210px!important ; 
			height:210px!important ; 
			
			background-color:#dce1f4 ; 
			border-radius:105px ; 

				.image.imagecenterContent11111111111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 40px ; 
				
				top: 40px ; 
				right: 40px ; 
				bottom: 40px ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.rectangle.rectanglerectangleleft33{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 5px ; 
				
				top: 5px ; 
				width:20px!important ; 
				height:20px!important ; 
				
				background-color:transparent ; 
				border-radius:17px ; 
				border-style:solid ; 
				border-width:3px ; 
				border-color:#4fb0f0 ; 

				
}

				.rectangle.rectanglerectangleleft3333333{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: -20px ; 
				
				bottom: 0px ; 
				width:18px!important ; 
				height:18px!important ; 
				transform: rotate(calc(-120deg));
				
				background-color:transparent ; 
				border-radius:0px ; 
				border-style:solid ; 
				border-width:2px ; 
				border-color:#56eb88 ; 

				
}

				.rectangle.rectanglerectangleleft3344444{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				top: 10px ; 
				right: 10px ; 
				width:12px!important ; 
				height:12px!important ; 
				
				background-color:transparent ; 
				border-radius:4px ; 
				border-style:solid ; 
				border-width:2px ; 
				border-color:#cbcbcb ; 

				
}

				.rectangle.rectanglerectangleleft3355555{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				top: 40px ; 
				right: -50px ; 
				width:20px!important ; 
				height:20px!important ; 
				
				background-color:transparent ; 
				border-style:solid ; 
				border-width:2px ; 
				border-color:#56eb88 ; 

				
}

				.rectangle.rectanglerectangleleft335555566666{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: 0px ; 
				bottom: 0px ; 
				width:10px!important ; 
				height:10px!important ; 
				
				background-color:transparent ; 
				border-radius:17px ; 
				border-style:solid ; 
				border-width:2px ; 
				border-color:#ebc128 ; 

				
}

				.text.texttiltLogo13{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
				
				left: calc(50% + 0px) ; 
				
				bottom: -50px ; 
				width:auto!important ; 
				height:auto!important ; 
				
				color:#7e89ad ; 
				font-size:40px ; 
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.rigth22222{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:100%!important ; 
		height: calc(100% - (0px + 0px)) !important;
		

			.object.loginrigth222223{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:283px!important ; 
			height:400px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.registerrigth222223{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:283px!important ; 
			height:400px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.loginrigth222226{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:283px!important ; 
			height:400px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.mailconfirmrigth222225{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left: 0px ; 
			
			top: calc(50% + 0px) ; 
			right: 0px ; 
			height:auto!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.row.rowcenterContent4{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 100px ; 
	
	bottom: 25px ; 
	width:auto!important ; 
	height:20px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.item.itemcenterContent52{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:200px!important ; 
		height:20px!important ; 
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:20px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.text.textloginCol1122222register11111{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#3bb1f9 ; 
				font-size:15px ; 
				text-align:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:20px!important ; 
				height:20px!important ; 
				
				color:#3bb1f9 ; 
				font-size:20px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

		.item.itemcenterContent5{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:200px!important ; 
		height:20px!important ; 
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:20px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.text.textloginCol1122222register11111{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#3bb1f9 ; 
				font-size:15px ; 
				text-align:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:20px!important ; 
				height:20px!important ; 
				
				color:#3bb1f9 ; 
				font-size:20px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

		.item.itemcenterContent53{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:200px!important ; 
		height:20px!important ; 
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:20px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.text.textloginCol1122222register11111{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				color:#3bb1f9 ; 
				font-size:15px ; 
				text-align:center;
				display: flex;
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:20px!important ; 
				height:20px!important ; 
				
				color:#3bb1f9 ; 
				font-size:20px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

	
}


}

.object.offlinecheckitem011{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.image.imageitem03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowitem05{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.item.itemrowitem051{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051222{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051333{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051444{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.animatedimage.animatedimageitem015{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.card.centerContent{


position:absolute !important; 




max-width: initial;min-width: initial;

	.row.rowcenterContent5{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.left{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.tiltLogo{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagecenterContent11111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglerectangleleft33{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3333333{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3344444{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3355555{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft335555566666{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.text.texttiltLogo13{

				
				position:absolute !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.rigth22222{

		
		position:relative !important; 
		
		
		
		
		

			.object.loginrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.registerrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.loginrigth222226{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.mailconfirmrigth222225{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.row.rowcenterContent4{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemcenterContent52{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent5{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent53{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.object.offlinecheckitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.image.imageitem03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowitem05{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.item.itemrowitem051{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051222{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051333{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051444{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.animatedimage.animatedimageitem015{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.card.centerContent{


position:absolute !important; 



left: 50px ; 

right: 50px ; 
max-width: initial;min-width: initial;

	.row.rowcenterContent5{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.left{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.tiltLogo{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagecenterContent11111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglerectangleleft33{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3333333{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3344444{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3355555{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft335555566666{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.text.texttiltLogo13{

				
				position:absolute !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.rigth22222{

		
		position:relative !important; 
		
		
		
		
		

			.object.loginrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.registerrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.loginrigth222226{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.mailconfirmrigth222225{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.row.rowcenterContent4{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemcenterContent52{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent5{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent53{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.object.offlinecheckitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.image.imageitem03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowitem05{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.item.itemrowitem051{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051222{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051333{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051444{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.animatedimage.animatedimageitem015{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.card.centerContent{


position:absolute !important; 



left: 100px ; 

right: 100px ; 
max-width: initial;min-width: initial;

	.row.rowcenterContent5{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.left{

		
		position:relative !important; 
		
		
		
		
		 display:block !important; 
		

			.rectangle.tiltLogo{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagecenterContent11111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglerectangleleft33{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3333333{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3344444{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3355555{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft335555566666{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.text.texttiltLogo13{

				
				position:absolute !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.rigth22222{

		
		position:relative !important; 
		
		
		
		
		width:300px!important ; 
		

			.object.loginrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.registerrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.loginrigth222226{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.mailconfirmrigth222225{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.row.rowcenterContent4{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemcenterContent52{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent5{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent53{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.object.offlinecheckitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.image.imageitem03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowitem05{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.item.itemrowitem051{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051222{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051333{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051444{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.animatedimage.animatedimageitem015{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.card.centerContent{


position:absolute !important; 



left: 150px ; 

right: 150px ; 
max-width: initial;min-width: initial;

	.row.rowcenterContent5{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.left{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.tiltLogo{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagecenterContent11111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglerectangleleft33{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3333333{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3344444{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3355555{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft335555566666{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.text.texttiltLogo13{

				
				position:absolute !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.rigth22222{

		
		position:relative !important; 
		
		
		
		
		

			.object.loginrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.registerrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.loginrigth222226{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.mailconfirmrigth222225{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.row.rowcenterContent4{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemcenterContent52{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent5{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent53{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.object.offlinecheckitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.image.imageitem03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.row.rowitem05{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.item.itemrowitem051{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051222{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051333{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.itemrowitem051444{

	
	position:relative !important; 
	
	
	
	
	

		.link.linkitemrowitem0517{

		
		position:absolute !important; 
		
		
		
		
		
		display: block;

			.rectangle.rectangleitemrowitem0513111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.image.imageitemrowitem0511222{

			
			position:absolute !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			
}

			.text.textitemrowitem0515333{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}


}

.animatedimage.animatedimageitem015{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.card.centerContent{


position:absolute !important; 



left: 250px ; 

right: 250px ; 
max-width: initial;min-width: initial;

	.row.rowcenterContent5{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.left{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.tiltLogo{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagecenterContent11111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.rectanglerectangleleft33{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3333333{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3344444{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft3355555{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.rectangle.rectanglerectangleleft335555566666{

				
				position:absolute !important; 
				
				
				
				
				
				

				
}

				.text.texttiltLogo13{

				
				position:absolute !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

		
}

		.item.rigth22222{

		
		position:relative !important; 
		
		
		
		
		

			.object.loginrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.registerrigth222223{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.loginrigth222226{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.mailconfirmrigth222225{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.row.rowcenterContent4{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.item.itemcenterContent52{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent5{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.item.itemcenterContent53{

		
		position:relative !important; 
		
		
		
		
		

			.row.rowcenterContent311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.text.textloginCol1122222register11111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.icon.iconrowcenterContent3111113{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.mousearea.mouseareatextloginCol11122222{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

	
}


}

.object.offlinecheckitem011{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
