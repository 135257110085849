<template>
 <div>  
<div id="main"> 
         
 <SplashScreen :class="'object splashScreen2 '"   id="splashScreen2" ref="splashScreen2"  v-bind:style="{}">
</SplashScreen>
 
      </div>
 </div> 
</template>
<script>
import SplashScreen from '../At/SplashScreen'


export default {
	name: 'main',

    components: {SplashScreen,},
	mounted(){
			
	this.$i18n.locale = this.$store.state.language

	},
} 
</script>

<style lang = "scss" scoped>


 div#main{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

*{
	font-family: 'Pacifico', fantasy, cursive !important;
	
	.text.text-content{
		font-family: 'Pacifico', fantasy, cursive !important;	
	}
}



.object.splashScreen2{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


@media (max-width: 600px) {


.object.splashScreen2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 601px) {


.object.splashScreen2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 961px) {


.object.splashScreen2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1265px) {


.object.splashScreen2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}

@media (min-width: 1905px) {


.object.splashScreen2{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}
}
}
</style>
