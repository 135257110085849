var desi = {

    perso:{
        fr: /\S.*(?= est un| est une)/gm,
        en: /\S.*(?= is a)/gm
    },

    type:{
        fr: '(?={{nom}}{{genre}}).*',
        en: '(?={{nom}}{{genre}}).*'
    },

    typeDelete:{
        fr: ' est un | est une',
        en: ' is a | is an'
    },

    posX:{
        fr: '(?={{nom}} est situé(e)? en x à ).*\\S',
        en: '(?={{nom}} is located in x to ).*\\S'
    },

    posXDelete:{
        fr: ' est situé en x à ',
        en: ' is located in x to '
    },

    posXDeleteF:{
        fr: ' est située en x à ',
        en: ' is located in x to '
    },

    taille:{
        fr: '(?={{nom}} est de taille ).*\\S',
        en: '(?={{nom}} is size ).*\\S'
    },

    tailleDelete:{
        fr: ' est de taille ',
        en: ' is size '
    },

    posY:{
        fr: '(?={{nom}} est situé en y à ).*\\S',
        en: '(?={{nom}} is located in y to ).*\\S'
    },

    posYDelete:{
        fr: ' est situé en y à ',
        en: ' is located in y to '
    },

    posYDeleteF:{
        fr: ' est située en y à ',
        en: ' is located in y to '
    },

    rotation:{
        fr: '(?={{nom}} tourne de ).*\\S',
        en: '(?={{nom}} turn of ).*\\S'
    },

    rotationDelete:{
        fr: ' tourne de ',
        en: ' turn of '
    },

    visible:{
        fr: "{{nom}} devient.*",
        en: "{{nom}} becomes.*"
    },

    visibleDelete:{
        fr: ' devient ',
        en: ' becomes '
    },

    visi:{
        fr: "visible",
        en: "visible"
    },

    invisi:{
        fr: " devient invisible",
        en: " becomes invisible"
    },

    get:{
        fr: /début:(\n|.)*opérations:/,
        en: /begin:(\n|.)*operations:/
    },
	
	genre:{
		fr:[" est une " , " est un "],
    en:[" is an " , " is a "]
	}

}

function replaceReg( reg , valueA, valueB){
    var replaced = reg.replace(valueA , valueB)
    return new RegExp( replaced , 'gm')
}

function replaceArray(tab , valueA, valueB){
    if(tab !== null){
        for(var i=0; i< tab.length; i++){
            tab[i] = tab[i].replace( valueA , valueB)
        }
        return tab[ tab.length -1 ]
    }else{
        return 0
    }
}

function getCode(code, lang){
    var reg = desi.get[lang]
    var declaration = code.match(reg)[0]
    var elements = []

    if(declaration !== null){
        reg = desi.perso[lang]
        var perso = declaration.match(reg)

        if(perso !== null){
            for(var i=0 ; i< perso.length; i++){
                var ramplaced = perso[i]

                //----------------------------------------------------------------------
                var index = 0
                while(declaration.match(perso[i]+ desi.genre[lang][index]) === null){
                    index ++
                }
                var gen = desi.genre[lang][index]
                var genInitial = (index === 0? "f" : "m")
//---------------------------------------------------------------------------------------
                var newReg = replaceReg( desi.posX[lang] , '{{nom}}' , ramplaced)
                reg = newReg
                var ramplacedB = perso[i]+desi.posXDelete[lang]
                var posX = replaceArray( declaration.match(reg) , ramplacedB , "" )
//---------------------------------------------------------------------------------------
                newReg = replaceReg( desi.posY[lang] , '{{nom}}' , ramplaced)
                reg = newReg
                ramplacedB = perso[i]+desi.posYDelete[lang]
                var posY = replaceArray( declaration.match(reg) , ramplacedB , ""  )
//---------------------------------------------------------------------------------------
                newReg = replaceReg( desi.rotation[lang] , '{{nom}}' , ramplaced)
                reg = newReg
                ramplacedB = perso[i]+desi.rotationDelete[lang]
                var rot = replaceArray( declaration.match(reg) , ramplacedB , ""  )
//---------------------------------------------------------------------------------------
                newReg = desi.type[lang].replace('{{genre}}' , gen)
                newReg = replaceReg( newReg , '{{nom}}' , ramplaced)
                reg = newReg
                ramplacedB = perso[i]+gen
                var ty = replaceArray( declaration.match(reg) , ramplacedB , ""  )

//---------------------------------------------------------------------------------------
                newReg = replaceReg( desi.visible[lang] , '{{nom}}' , perso[i])
                reg = newReg
                ramplacedB = perso[i]+desi.visibleDelete[lang]
                var visi = replaceArray( declaration.match(reg) , ramplacedB , ""  )
                visi = ( visi === 0 ? true : (visi[visi.length-1] === desi.visi[lang]? true : false))
//---------------------------------------------------------------------------------------
                newReg = replaceReg( desi.taille[lang] , '{{nom}}' , ramplaced)
                reg = newReg
                ramplacedB = perso[i]+desi.tailleDelete[lang]
                var taille = replaceArray( declaration.match(reg) , ramplacedB , "" )
//----------------------------------------------------------------------------------------
                elements.push({ip:i, type:ty, id: perso[i] , x: posX, y:posY, rotation:rot ,
                               visible:visi , scale:(taille === 0? 1: taille) , genre:gen, genreInitial:genInitial})
            }
        }

    }

return elements
}
	
exports.desi = desi;
exports.getCode = getCode;
	
	
	
	
	