<template>
 <div>  
<div id="FileDialog"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <div  :class="'rectangle fond '"   id="fond" ref="fond"  v-bind:style="{backgroundColor : '#000000',}">
 <div  :class="'mousearea mouseareafond1 '"   id="mouseareafond1" ref="mouseareafond1"  v-bind:style="{}" @click="close"></div>  </div>
 <v-card 
 :class="'card carditem03 '"   id="carditem03" ref="carditem03"  v-bind:style="{}">
 <FDFromDrive :class="'object openfromdrivecarditem033 '"   id="openfromdrivecarditem033" ref="openfromdrivecarditem033" v-show="tab == 'tab-0'"  v-bind:style="{}" :choose = "choose" 
 :close = "close" 
>
</FDFromDrive>
 <FDFromComputer :class="'object openfromcomputercarditem035 '"   id="openfromcomputercarditem035" ref="openfromcomputercarditem035" v-show="tab == 'tab-1'"  v-bind:style="{}" :choose = "choose" 
 :close = "close" 
>
</FDFromComputer>
 <FDFromCamera :class="'object openfromcamera '"   id="openfromcamera" ref="openfromcamera" v-if="tab == 'tab-2'"  v-bind:style="{}" :choose = "choose" 
 :close = "close" 
>
</FDFromCamera><v-card class="overflow-hidden"
        
       :class="'appbar appbarcarditem031 '"   id="appbarcarditem031" ref="appbarcarditem031" v-model="tab"  v-bind:style="{}"
        ><v-app-bar
        scroll-target="#playground-appbarcarditem031"
          style="z-index: 10;"><v-toolbar-title>{{ $t('FileDialog.chooseFile') }}</v-toolbar-title>
  
        <div class = "bar-content">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="close" :class="'button buttonappbarcarditem031undefined '"   id="buttonappbarcarditem031undefined" ref="buttonappbarcarditem031undefined"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-close' !== undefined" >{{ 'ion-ios-close' }}
</v-icon></v-btn></div>

<template v-slot:extension v-if='tTitle.length > 0'>
          <v-tabs
            background-color="transparent"
           v-model="tab"
>
        <v-tabs-slider   :color = "primaryColor" 
></v-tabs-slider>
  
        <v-tab
          v-for='(i,n) in tTitle'
          :key="n"
          :href="`#tab-${n}`"
        >
          {{ i }}
          <v-icon v-if='iconName = []' >{{ iconName[n] }}</v-icon></v-tab>
  
        </v-tabs>
        </template>
      </v-app-bar>
        <v-sheet 
 id="playground-appbarcarditem031"
          class="overflow-y-auto"
        >
          <v-container style="height: -webkit-fill-available; max-width: unset; padding: unset; position: relative;">
  
          </v-container>
        </v-sheet>
      </v-card>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>
import FDFromDrive from '../../At/Dialog/FileDialog/FDFromDrive'
import FDFromComputer from '../../At/Dialog/FileDialog/FDFromComputer'
import FDFromCamera from '../../At/Dialog/FileDialog/FDFromCamera'

import lg from '../../Js/language.json'
export default {
	name: 'FileDialog',

    components: {FDFromDrive,FDFromComputer,FDFromCamera,},
	computed: {
	
		tTitle: function(){		
	return [

   		"Banque",

		 	"Ordinateur",

		 	"Camera"

		]

	}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		tab:  "tab-0"		,
		languages:  lg		,
		language:  this.$store.state.language		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
		choose: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#FileDialog{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.fond{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000000 ; 

	.mousearea.mouseareafond1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}

.card.carditem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
right: 10px ; 
bottom: 10px ; 
background-color:#ffffff ; max-width: initial;min-width: initial;

	.object.openfromdrivecarditem033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 107px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcomputercarditem035{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 107px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcamera{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 107px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.appbar.appbarcarditem031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:112px!important ; 
	
	border-radius: 0;

::v-deep header.v-sheet{
overflow: hidden;
}


::v-deep .bar-content{position: relative;
	flex-grow: 1;
	height: 48px;

	.button.buttonappbarcarditem031undefined{

	height: auto;
	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	right: 0px ; 
	width:50px!important ; 
	height:50px!important ; 
	
	font-size:50px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:50px ; 
	}
	}
}
		.overflow-y-auto{

			height: -webkit-fill-available;

		}

	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.rectangle.fond{


position:absolute !important; 







	.mousearea.mouseareafond1{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.object.openfromdrivecarditem033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcomputercarditem035{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcamera{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.appbar.appbarcarditem031{

	
	position:absolute !important; 
	
	
	
	
	
	border-radius: 0;

::v-deep header.v-sheet{
overflow: hidden;
}


::v-deep .bar-content{position: relative;
	flex-grow: 1;
	height: 48px;

	.button.buttonappbarcarditem031undefined{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}
}
		.overflow-y-auto{

			height: -webkit-fill-available;

		}

	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.rectangle.fond{


position:absolute !important; 







	.mousearea.mouseareafond1{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.object.openfromdrivecarditem033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcomputercarditem035{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcamera{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.appbar.appbarcarditem031{

	
	position:absolute !important; 
	
	
	
	
	
	border-radius: 0;

::v-deep header.v-sheet{
overflow: hidden;
}


::v-deep .bar-content{position: relative;
	flex-grow: 1;
	height: 48px;

	.button.buttonappbarcarditem031undefined{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}
}
		.overflow-y-auto{

			height: -webkit-fill-available;

		}

	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.rectangle.fond{


position:absolute !important; 







	.mousearea.mouseareafond1{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 



left: 10% ; 

top: 10% ; 
right: 10% ; 
bottom: 10% ; 
max-width: initial;min-width: initial;

	.object.openfromdrivecarditem033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcomputercarditem035{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcamera{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.appbar.appbarcarditem031{

	
	position:absolute !important; 
	
	
	
	
	
	border-radius: 0;

::v-deep header.v-sheet{
overflow: hidden;
}


::v-deep .bar-content{position: relative;
	flex-grow: 1;
	height: 48px;

	.button.buttonappbarcarditem031undefined{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}
}
		.overflow-y-auto{

			height: -webkit-fill-available;

		}

	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.rectangle.fond{


position:absolute !important; 







	.mousearea.mouseareafond1{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.object.openfromdrivecarditem033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcomputercarditem035{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcamera{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.appbar.appbarcarditem031{

	
	position:absolute !important; 
	
	
	
	
	
	border-radius: 0;

::v-deep header.v-sheet{
overflow: hidden;
}


::v-deep .bar-content{position: relative;
	flex-grow: 1;
	height: 48px;

	.button.buttonappbarcarditem031undefined{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}
}
		.overflow-y-auto{

			height: -webkit-fill-available;

		}

	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.rectangle.fond{


position:absolute !important; 







	.mousearea.mouseareafond1{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.carditem03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.object.openfromdrivecarditem033{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcomputercarditem035{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.object.openfromcamera{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}

	.appbar.appbarcarditem031{

	
	position:absolute !important; 
	
	
	
	
	
	border-radius: 0;

::v-deep header.v-sheet{
overflow: hidden;
}


::v-deep .bar-content{position: relative;
	flex-grow: 1;
	height: 48px;

	.button.buttonappbarcarditem031undefined{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}
}
		.overflow-y-auto{

			height: -webkit-fill-available;

		}

	}


}


}
}
}
</style>
