<template>
 <div>  
<div id="Register"> 
         
 <div  :class="'column loginCol22222 '"   id="loginCol22222" ref="loginCol22222"  v-bind:style="{justifyContent : 'space-between',}">
 <div  :class="'text textloginCol1 '"   id="textloginCol1" ref="textloginCol1"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('main.register') }}</div>

   </div>
 <v-text-field  :placeholder = "$t('main.username')" 
 :prepend-inner-icon = "'ion-ios-person'" 
 :rounded = "true" 
 outlined :class="'textfield userName '"   id="userName" ref="userName" v-model="name"  v-bind:style="{}"></v-text-field>
 <v-text-field  :placeholder = "$t('main.email')" 
 :prepend-inner-icon = "'ion-ios-at'" 
 :rounded = "true" 
 :type = "'email'" 
 outlined :class="'textfield email '"   id="email" ref="email" v-model="email"  v-bind:style="{}"></v-text-field>
 <v-text-field  :placeholder = "$t('main.password')" 
 :prepend-inner-icon = "'ion-ios-lock'" 
 :append-icon = "showPass? 'ion-ios-eye' : 'ion-ios-eye-off'" 
 :rounded = "true" 
 :type = "showPass? 'text' : 'password'" 
 outlined :class="'textfield userPass '"   id="userPass" ref="userPass" v-model="pass"  v-bind:style="{}" @click:append="togelShow"></v-text-field>
 <v-text-field  :placeholder = "$t('main.confirmation')" 
 :prepend-inner-icon = "'ion-ios-lock'" 
 :append-icon = "showPassConf? 'ion-ios-eye' : 'ion-ios-eye-off'" 
 :rounded = "true" 
 :type = "showPassConf? 'text' : 'password'" 
 outlined :class="'textfield userPass55555 '"   id="userPass55555" ref="userPass55555" v-model="confPass"  v-bind:style="{}" @click:append="togelShowConf"></v-text-field>
 <v-btn tag="div" 
 :color = "'#3bb1f9'" 
 :rounded = "true" 
 :dark = "true" 
 @click="register" :class="'button buttonloginCol9 '"   id="buttonloginCol9" ref="buttonloginCol9"  v-bind:style="{}"><span>{{ $t('main.register') }}</span>
</v-btn>
 <div  :class="'text textloginCol2222211 '"   id="textloginCol2222211" ref="textloginCol2222211"  v-bind:style="{ display: (errorVisible? 'block' : 'none'), color : '#f45547',}">
<div class="text-content" :style = "{}">{{ errorMessage }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import axios from 'axios';
	
import * as main from "../../Js/main.js";
export default {
	name: 'Register',

    components: {},
	methods: {
	
		togelShow: function(){		
	this.showPass = !this.showPass;

}
,
		togelShowConf: function(){		
	this.showPassConf = !this.showPassConf;

}
,
		register: function(){		
	if(this.pass == this.confPass){	

		main.register(this.name, this.email, this.pass)

  	.then(response => {	

			this.$emit('registered', response.data)

  	})

  	.catch(error => {

			this.showError(main.getErrorMessage(error))

  	});

	}

}
,
		showError: function(message) { 		
	this.errorMessage = message

	this.errorVisible = true	

}
,
	},
	data(){
	return {
		
		language:  this.$store.state.language		,
		showPass:  false		,
		showPassConf:  false		,
		name:  ""		,
		email:  ""		,
		pass:  ""		,
		confPass:  ""		,
		errorVisible:  false		,
		errorMessage:  ""		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#Register{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.column.loginCol22222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
justify-content:space-between ; background-color: transparent ; 

.text.textloginCol1{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

font-size:35px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.textfield.userName{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.email{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass55555{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:50px!important ; 
width: calc(100% - (0px + 0px)) !important;

text-transform:unset ; 
min-width: auto;
padding-left: 16px;
padding-right: 16px;

i.v-icon{
}
}

.text.textloginCol2222211{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

color:#f45547 ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
}


}


}


@media (max-width: 600px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.email{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass55555{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol2222211{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.email{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass55555{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol2222211{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.email{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass55555{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol2222211{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.email{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass55555{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol2222211{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.loginCol22222{


position:absolute !important; 









.text.textloginCol1{


position:relative !important; 





.text-content{
width: 100%;
}


}

.textfield.userName{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.email{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.textfield.userPass55555{


position:relative !important; 





::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}

::v-deep &.v-input{
	flex-grow: 0;
input{

}
}
}

.button.buttonloginCol9{

height: auto;

position:relative !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}

.text.textloginCol2222211{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
