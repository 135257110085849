<template>
 <div>  
<div id="openFromComputer"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : '#90909d',}">
 <div  :class="'rectangle content '"   id="content" ref="content"  v-bind:style="{}">
 <div  :class="'column columncontent1 '"   id="columncontent1" ref="columncontent1"  v-bind:style="{}">
 <div  :class="'text textcolumncontent11 '"   id="textcolumncontent11" ref="textcolumncontent11"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ message }}</div>

   </div>
 <div  :class="'rectangle separator '"   id="separator" ref="separator"  v-bind:style="{backgroundColor : '#616170',}">  </div>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :dark = "true" 
 @click="chooseFile" :class="'button buttoncolumncontent15 '"   id="buttoncolumncontent15" ref="buttoncolumncontent15" :elevation = "0" 
  v-bind:style="{}"><span>{{ 'Choisir' }}</span>
</v-btn>
 <div  :class="'transition transitioncontent35 '"   id="transitioncontent35" ref="transitioncontent35" v-show="loaded"  v-bind:style="{}">
 <transition  :enter-active-class = "'animated shake'" 
 :duration = "2000" 
>
 <v-card 
 :class="'card cardtransitioncontent33 '"   id="cardtransitioncontent33" ref="cardtransitioncontent33" v-if="loaded" :elevation = "2" 
  v-bind:style="{backgroundColor : '#0cffa2',}">
 <div  :class="'icon icontransitioncontent311 '"   id="icontransitioncontent311" ref="icontransitioncontent311"  v-bind:style="{color : '#fff',fontSize : getUnit(50,'px'),}"><i  :class="'ion-md-done-all'"></i></div>  </v-card>  </transition></div>  </div>  </div>
 <div  :class="'rectangle bottom '"   id="bottom" ref="bottom"  v-bind:style="{}">
 <div  :class="'row rowbottom1 '"   id="rowbottom1" ref="rowbottom1"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :disabled = "(!loaded)" 
 :dark = "(loaded)" 
 @click="createProject" :class="'button open '"   id="open" ref="open" :elevation = "1" 
  v-bind:style="{}"><span>{{ 'Ouvrir' }}</span>
</v-btn>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import ac from 'artefact-controller'
export default {
	name: 'openFromComputer',

    components: {},
	methods: {
	
		chooseFile: function(){		
	this.atController.fileOpen({multiple: false, accept: '.abc' }, (file) => {		

  	const reader = new FileReader();

  	reader.addEventListener('load', (event) => {

			this.result = JSON.parse(event.target.result)

			this.loaded = true

  	});

  	reader.readAsText(file[0]); 

		this.message = "Le fichier " + file[0].name + ' a été ouvert.'

	});

}
,
		createProject: function() { 		
	let project = {

		name: this.result.nom

	}

	this.$store.commit('project', project)

	this.$store.commit('pjDatas', this.result)

	this.$store.commit('setSaved', false)

	this.$store.commit('iconPath', null)

	this.create()

}
,
	},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		atController:  new ac()		,
		language:  this.$store.state.language		,
		result:  null		,
		loaded:  false				,
		message:  "Ouvrir un projet depuis votre ordinateur"		,
		}
	},
	props: {
	
		create: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#openFromComputer{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 0 20px !important;
}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#90909d ; 

.rectangle.content{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 71px ; 

background-color:#ffffff ; 

	.column.columncontent1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left: 10px ; 
	
	top: calc(50% + 0px) ; 
	right: 10px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.text.textcolumncontent11{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#989898 ; 
		font-size:20px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.separator{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		opacity: 0.7 ;  filter: alpha(opacity=70) ; 
		width:200px!important ; 
		height:2px!important ; 
		
		background-color:#616170 ; 

		
}

		.button.buttoncolumncontent15{

		height: auto;
		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		width:auto!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

		.transition.transitioncontent35{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		width:70px!important ; 
		height:70px!important ; 
		

			.card.cardtransitioncontent33{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			background-color:#0cffa2 ; 
			border-radius:45px ; max-width: initial;min-width: initial;

				.icon.icontransitioncontent311{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 20px ; 
				
				top: 20px ; 
				right: 20px ; 
				bottom: 20px ; 
				
				color:#fff ; 
				font-size:50px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
height:70px!important ; 

background-color:#ffffff ; 

	.row.rowbottom1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	right: 20px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.button.open{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		padding-left: 16px;
		padding-right: 16px;

		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.column.columncontent1{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncontent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.separator{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.button.buttoncolumncontent15{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.transition.transitioncontent35{

		
		position:relative !important; 
		
		
		
		
		

			.card.cardtransitioncontent33{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.icon.icontransitioncontent311{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.column.columncontent1{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncontent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.separator{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.button.buttoncolumncontent15{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.transition.transitioncontent35{

		
		position:relative !important; 
		
		
		
		
		

			.card.cardtransitioncontent33{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.icon.icontransitioncontent311{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.column.columncontent1{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncontent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.separator{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.button.buttoncolumncontent15{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.transition.transitioncontent35{

		
		position:relative !important; 
		
		
		
		
		

			.card.cardtransitioncontent33{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.icon.icontransitioncontent311{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.column.columncontent1{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncontent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.separator{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.button.buttoncolumncontent15{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.transition.transitioncontent35{

		
		position:relative !important; 
		
		
		
		
		

			.card.cardtransitioncontent33{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.icon.icontransitioncontent311{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.column.columncontent1{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.text.textcolumncontent11{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.separator{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.button.buttoncolumncontent15{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.transition.transitioncontent35{

		
		position:relative !important; 
		
		
		
		
		

			.card.cardtransitioncontent33{

			
			position:absolute !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.icon.icontransitioncontent311{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
