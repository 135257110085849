<template>
 <div>  
<div id="ItemBar"> 
         
 <div  :class="'rectangle rec '"   id="rec" ref="rec"  v-bind:style="{backgroundColor : '#29ab80',}">
 <div  :class="'text textimagerec01 '"   id="textimagerec01" ref="textimagerec01"  v-bind:style="{color : '#09ea23',}">
<div class="text-content" :style = "{}">{{ count }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ItemBar',

    components: {},
	methods: {
	
		increment: function() {		
   this.count++

}
,
	},
	data(){
	return {
		
		count:  1		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#ItemBar{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rec{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0px ; 

top: 0px ; 
width:auto!important ; 
height:100px!important ; 

background-color:#29ab80 ; 
border-radius:50px ; 
border-style:solid ; 
border-width:2px ; 
border-color:#0eeabb ; 

.text.textimagerec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:auto!important ; 
height:32px!important ; 

color:#09ea23 ; 
font-size:20px ; 
.text-content{
width: 100%;
}


}


}


@media (max-width: 600px) {


.rectangle.rec{


position:absolute !important; 







.text.textimagerec01{


position:absolute !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.rectangle.rec{


position:absolute !important; 







.text.textimagerec01{


position:absolute !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.rectangle.rec{


position:absolute !important; 







.text.textimagerec01{


position:absolute !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rec{


position:absolute !important; 







.text.textimagerec01{


position:absolute !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rec{


position:absolute !important; 







.text.textimagerec01{


position:absolute !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
