<template>
 <div>  
<div id="LeftZone"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : '#cbcbcb',}">
 <div  :class="'item language '"   id="language" ref="language"  v-bind:style="{}">
 <div  :class="'rectangle rectanglelanguage1 '"   id="rectanglelanguage1" ref="rectanglelanguage1"  v-bind:style="{backgroundColor : secondaryColor,}">
 <div  :class="'rectangle rectanglerectanglelanguage11 '"   id="rectanglerectanglelanguage11" ref="rectanglerectanglelanguage11"  v-bind:style="{backgroundColor : '#fcfcfc',}">
 <div  :class="'icon iconrectanglerectanglelanguage111 '"   id="iconrectanglerectanglelanguage111" ref="iconrectanglerectanglelanguage111"  v-bind:style="{color : secondaryColor,fontSize : getUnit(32,'px'),}"><i  :class="'ion-ios-chatbubbles'"></i></div>  </div>
 <div  :class="'text textrectanglelanguage15 '"   id="textrectanglelanguage15" ref="textrectanglelanguage15"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('app.chooseLanguage') }}</div>

   </div>
 <v-select
           :dark = "true" 
:items = "languageList" :class="'select langSelector '"   id="langSelector" ref="langSelector" v-model="selectedLanguage"  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </div>
 <div  :class="'item tool '"   id="tool" ref="tool"  v-bind:style="{}">
 <div  :class="'rectangle rectangletool1 '"   id="rectangletool1" ref="rectangletool1" :_borderTopLeftRadius = "'20px'" 
  v-bind:style="{borderTopLeftRadius : '20px',backgroundColor : '#ededed',}">
 <div  :class="'row rowrectangletool11 '"   id="rowrectangletool11" ref="rowrectangletool11"  v-bind:style="{}">
 <v-img  :class="'networkimage imagerowrectangletool111 '"   id="imagerowrectangletool111" ref="imagerowrectangletool111"  v-bind:style="{borderColor : primaryColor, backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImage" 
>
 <div  :class="'mousearea mouseareaimagerowrectangletool1111 '"   id="mouseareaimagerowrectangletool1111" ref="mouseareaimagerowrectangletool1111"  v-bind:style="{}" @click="openProfile"></div>  </v-img>
 <Butt :class="'object buttrowrectangletool113 '"   id="buttrowrectangletool113" ref="buttrowrectangletool113"  v-bind:style="{}" :clo = "openCreateD" 
 :ico = "'ion-ios-create'" 
 :toolTip = "'Nouveau projet'" 
 :size = "'22'" 
>
</Butt>
 <Butt :class="'object buttrowrectangletool11333333 '"   id="buttrowrectangletool11333333" ref="buttrowrectangletool11333333"  v-bind:style="{}" :clo = "openAddD" 
 :ico = "'ion-ios-add-circle'" 
 :toolTip = "'Ajoutr un nouvel élement'" 
 :size = "'22'" 
>
</Butt>
 <Butt :class="'object buttrowrectangletool11344444 '"   id="buttrowrectangletool11344444" ref="buttrowrectangletool11344444"  v-bind:style="{}" :clo = "copy" 
 :ico = "'ion-ios-copy'" 
 :toolTip = "'copier'" 
 :size = "'22'" 
>
</Butt>
 <Butt :class="'object buttrowrectangletool11355555 '"   id="buttrowrectangletool11355555" ref="buttrowrectangletool11355555"  v-bind:style="{}" :clo = "cut" 
 :ico = "'ion-md-cut'" 
 :toolTip = "'couper'" 
 :size = "'22'" 
>
</Butt>
 <Butt :class="'object buttrowrectangletool11366666 '"   id="buttrowrectangletool11366666" ref="buttrowrectangletool11366666"  v-bind:style="{}" :clo = "paste" 
 :ico = "'ion-md-clipboard'" 
 :toolTip = "'coller'" 
 :size = "'22'" 
>
</Butt>
 <Butt :class="'object buttrowrectangletool11377777 '"   id="buttrowrectangletool11377777" ref="buttrowrectangletool11377777"  v-bind:style="{}" :clo = "undo" 
 :ico = "'ion-ios-undo'" 
 :toolTip = "'retour'" 
 :size = "'22'" 
>
</Butt>
 <Butt :class="'object buttrowrectangletool11388888 '"   id="buttrowrectangletool11388888" ref="buttrowrectangletool11388888"  v-bind:style="{}" :clo = "redo" 
 :ico = "'ion-ios-redo'" 
 :toolTip = "'retablir'" 
 :size = "'22'" 
>
</Butt>  </div>  </div>  </div>
 <div  :class="'item search '"   id="search" ref="search"  v-bind:style="{}">
 <div  :class="'rectangle rectanglesearch1 '"   id="rectanglesearch1" ref="rectanglesearch1"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'rectangle rectanglerectanglesearch11 '"   id="rectanglerectanglesearch11" ref="rectanglerectanglesearch11"  v-bind:style="{backgroundColor : '#ededf4',}">
 <div  :class="'icon iconrectanglerectanglesearch111 '"   id="iconrectanglerectanglesearch111" ref="iconrectanglerectanglesearch111"  v-bind:style="{color : '#a8a8a8',fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-search'"></i></div>
 <v-text-field  :append-icon = "'ion-ios-close'" 
 :class="'textfield textfieldrectanglerectanglesearch113 '"   id="textfieldrectanglerectanglesearch113" ref="textfieldrectanglerectanglesearch113" v-model="filterKey"  v-bind:style="{color : primaryColor,}" @click:append="clearFilter"></v-text-field>  </div>  </div>  </div>
 <div  :class="'item menu '"   id="menu" ref="menu"  v-bind:style="{}">
 <div  :class="'scrollarea scrollarearec0111111 '"   id="scrollarearec0111111" ref="scrollarearec0111111" :_borderBottomLeftRadius = "'20px'" 
  v-bind:style="{borderBottomLeftRadius : '20px',}">
 <div  :class="'column columnscrollarearec01111111 '"   id="columnscrollarearec01111111" ref="columnscrollarearec01111111"  v-bind:style="{}">
 <ButtB :class="'object imagebuttoncolumnscrollarearec011111111 '"   id="imagebuttoncolumnscrollarearec011111111" ref="imagebuttoncolumnscrollarearec011111111" v-for="(item,index) in objSection.section" :key="'imagebuttoncolumnscrollarearec011111111' + index"  v-bind:style="{}" :clo = "showObject" 
 :source = "'Resources/App/LeftZone/MenuBar/' + item + '.png'" 
 :bgColor = "'#d3d6da'" 
 :radius = "28" 
 :sectionName = "item" 
 :active = "(currentSctionName == item)" 
>
</ButtB>  </div>  </div>
 <div  :class="'rectangle rectanglemenu3 '"   id="rectanglemenu3" ref="rectanglemenu3"  v-bind:style="{backgroundColor : '#f2f2f9',}">
 <v-img  :class="'image imagerectanglemenu31 '"   id="imagerectanglemenu31" ref="imagerectanglemenu31"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/LeftZone/fondbg.png')" 
>  </v-img>
 <div  :class="'scrollarea scrollarearec011111122222 '"   id="scrollarearec011111122222" ref="scrollarearec011111122222" v-if="objListVisible"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columnscrollarearec01111111 '"   id="columnscrollarearec01111111" ref="columnscrollarearec01111111"  v-bind:style="{}">
 <div  :class="'column columnnormalData '"   id="columnnormalData" ref="columnnormalData" v-for="(item,index) in iterableData" :key="'columnnormalData' + index"  v-bind:style="{}">
 <ObjectBubble :class="'object objectbubnormalData '"   id="objectbubnormalData" ref="objectbubnormalData" v-for="(item2, index2) in item[1]" :key="'objectbubnormalData' + index2"  v-bind:style="{}" :clo = "insertCode" 
 :getExtraMove = "getExtraMove" 
 :checkIfExtraMove = "checkIfExtraMove" 
 :name = "languages[objSection.type[item[0]]][language] + (index2+1)" 
 :keyType = "objSection.type[item[0]] + (index2+1)" 
 :type = "languages[objSection.type[item[0]]][language]" 
 :source = "getUrl('raccourcis/' + item[0] + (index2+1) + '.png')" 
 :value = "languages.declaration[language] + languages[objSection.type[item[0]]][language] + (index2+1)" 
>
</ObjectBubble>  </div>
 <div  :class="'column columnphrasalIterableData '"   id="columnphrasalIterableData" ref="columnphrasalIterableData" v-for="(item,index) in phrasalSectionData" :key="'columnphrasalIterableData' + index"  v-bind:style="{}">
 <ObjectTextBubble :class="'object objectbubphrasalSectionData '"   id="objectbubphrasalSectionData" ref="objectbubphrasalSectionData" v-for="(item2, index2) in item" :key="'objectbubphrasalSectionData' + index2"  v-bind:style="{}" :clo = "insertCode" 
 :name = "languages[item2][language]" 
 :value = "languages[item2][language]" 
>
</ObjectTextBubble>  </div>
 <ObjectBubble :class="'object objectbubble '"   id="objectbubble" ref="objectbubble" v-for="(item,index) in normalData" :key="'objectbubble' + index"  v-bind:style="{}" :clo = "insertCode" 
 :getExtraMove = "getExtraMove" 
 :keyType = "item" 
 :name = "languages[item][language]" 
 :type = "languages[item][language]" 
 :source = "getUrl('raccourcis/' + item + '.png')" 
 :value = "languages.declaration[language] + languages[item][language]" 
>
</ObjectBubble>
 <div  :class="'column columnphrasalAlphaNum '"   id="columnphrasalAlphaNum" ref="columnphrasalAlphaNum" v-for="(item,index) in alphaNumData" :key="'columnphrasalAlphaNum' + index"  v-bind:style="{}">
 <ObjectBubble :class="'object objectbubphrasalSectionData '"   id="objectbubphrasalSectionData" ref="objectbubphrasalSectionData" v-for="(item2, index2) in item[1]" :key="'objectbubphrasalSectionData' + index2"  v-bind:style="{}" :clo = "insertCode" 
 :getExtraMove = "getExtraMove" 
 :keyType = "item[0] + item2" 
 :name = "languages[item[0]][language] + item2" 
 :type = "languages[item[0]][language]" 
 :source = "getUrl('raccourcis/alphaNum/' + item2 + '.png')" 
 :value = "languages.declaration[language] + languages[item[0]][language] + item2" 
>
</ObjectBubble>  </div>
 <ObjectTextBubble :class="'object objectTextbubble '"   id="objectTextbubble" ref="objectTextbubble" v-for="(item,index) in phrasalData" :key="'objectTextbubble' + index"  v-bind:style="{}" :clo = "insertCode" 
 :name = "languages[item][language]" 
 :value = "languages[item][language]" 
>
</ObjectTextBubble>
 <div  :class="'column columncolumnscrollarearec0111111132 '"   id="columncolumnscrollarearec0111111132" ref="columncolumnscrollarearec0111111132" v-for="(item,index) in mediaData" :key="'columncolumnscrollarearec0111111132' + index"  v-bind:style="{}">
 <MusicBubble :class="'object objectbubblecolumnscrollarearec01111111111111 '"   id="objectbubblecolumnscrollarearec01111111111111" ref="objectbubblecolumnscrollarearec01111111111111" v-for="(item2, index2) in item[1]" :key="'objectbubblecolumnscrollarearec01111111111111' + index2"  v-bind:style="{}" :clo = "insertCode" 
 :name = "languages[item[0]][language] + (index2+1)" 
 :type = "languages[item[0]][language]" 
 :source = "getUrl('raccourcis/' + item[0] + (index2+1) + '.png')" 
 :value = " '\n\t' + languages.jouer[language] + ' ' + languages[item[0]][language] + (index2+1)" 
>
</MusicBubble>  </div>  </div>  </div>
 <div  :class="'scrollarea scrollFilter '"   id="scrollFilter" ref="scrollFilter" v-if="filterListVisible"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columnscrollarearec01111111 '"   id="columnscrollarearec01111111" ref="columnscrollarearec01111111"  v-bind:style="{}">
 <ObjectBubble :class="'object objectIterable '"   id="objectIterable" ref="objectIterable" v-for="(item,index) in m_mappedItarable" :key="'objectIterable' + index"  v-bind:style="{}" :clo = "insertCode" 
 :getExtraMove = "getExtraMove" 
 :name = "item.name" 
 :type = "item.type" 
 :source = "item.source" 
 :keyType = "item.key" 
 :value = "item.value" 
>
</ObjectBubble>
 <ObjectBubble :class="'object objectNormal '"   id="objectNormal" ref="objectNormal" v-for="(item,index) in m_mappedNormal" :key="'objectNormal' + index"  v-bind:style="{}" :clo = "insertCode" 
 :getExtraMove = "getExtraMove" 
 :name = "item.name" 
 :type = "item.type" 
 :source = "item.source" 
 :keyType = "item.key" 
 :value = "item.value" 
>
</ObjectBubble>
 <ObjectTextBubble :class="'object objectTextbubble '"   id="objectTextbubble" ref="objectTextbubble" v-for="(item,index) in m_mappedPhrasal" :key="'objectTextbubble' + index"  v-bind:style="{}" :clo = "insertCode" 
 :name = "item.name" 
 :value = "item.value" 
>
</ObjectTextBubble>
 <ObjectTextBubble :class="'object objectbubphrasalSectionData7 '"   id="objectbubphrasalSectionData7" ref="objectbubphrasalSectionData7" v-for="(item,index) in m_mappedPhrasalSection" :key="'objectbubphrasalSectionData7' + index"  v-bind:style="{}" :clo = "insertCode" 
 :name = "item.name" 
 :value = "item.value" 
>
</ObjectTextBubble>
 <MusicBubble :class="'object objectbubblecolumnscrollarearec011111111111114 '"   id="objectbubblecolumnscrollarearec011111111111114" ref="objectbubblecolumnscrollarearec011111111111114" v-for="(item,index) in m_mappedMedia" :key="'objectbubblecolumnscrollarearec011111111111114' + index"  v-bind:style="{}" :clo = "insertCode" 
 :name = "item.name" 
 :type = "item.type" 
 :source = "item.source" 
 :value = "item.value" 
>
</MusicBubble>  </div>  </div>  </div>  </div>
 <div  :class="'transition transitionrec012 '"   id="transitionrec012" ref="transitionrec012" v-show="extraMenuVisible"  v-bind:style="{}">
 <transition  :leave-active-class = "'animated slideInLeft'" 
 :leave-to-class = "'animated slideOutRight'" 
>
 <ExtraActionMenu :class="'object extraactionmenurec01136 '"   id="extraactionmenurec01136" ref="extraactionmenurec01136" v-if="extraMenuVisible"  v-bind:style="{}" :insertCode = "insertCode" 
 :hide = "hideExtraMenu" 
 :extraAction = "extraAction" 
>
</ExtraActionMenu>  </transition></div>
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 @click="hideZone" :class="'button hide '"   id="hide" ref="hide"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-back' !== undefined"  :color = "primaryColor" 
>{{ 'ion-ios-arrow-back' }}
</v-icon></v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>
import Butt from '../../At/Butt'
import ButtB from '../../At/Utils/ButtB'
import ObjectBubble from '../../At/Utils/ObjectBubble'
import ObjectTextBubble from '../../At/Utils/ObjectTextBubble'
import MusicBubble from '../../At/Utils/MusicBubble'
import ExtraActionMenu from '../../At/Zone/ExtraActionMenu'

import section from '../../Js/objectSection.json'
import lg from '../../Js/language.json'
import langMap from '../../Js/languageMap.json'
import {move} from '../../Js/extraMove.js'
export default {
	name: 'LeftZone',

    components: {Butt,ButtB,ObjectBubble,ObjectTextBubble,MusicBubble,ExtraActionMenu,},
	methods: {
	
		showObject: function(sectionName){		
		this.iterableData = []

		this.mediaData = []

		this.normalData = []

		this.phrasalSectionData = []

		this.phrasalData = []

		this.alphaNumData = []

	

		this.currentSctionName = sectionName



		let dataPanel = this.objSection[sectionName]



		if(this.objSection.iterable.indexOf(sectionName) > -1){

			this.iterableData = dataPanel;

		} else if(this.objSection.media.indexOf(sectionName) > -1){

			this.mediaData = dataPanel;

		} else if(this.objSection.phrasalSection.indexOf(sectionName) > -1){

			this.phrasalSectionData = dataPanel;

		} else if(this.objSection.alphaNum.indexOf(sectionName) > -1){

			this.alphaNumData = dataPanel;

		} else if(this.objSection.phrasal.indexOf(sectionName) > -1){

			this.phrasalData = dataPanel;

		} else {

			this.normalData = dataPanel;

		}

}
,
		getUrl: function(e) { 		
	return e;

}
,
		mapObjects: function(){		
	this.mappedItarable = [];

	this.mappedMedia = [];

	this.mappedPhrasal = [];

	this.mappedPhrasalSection = [];

	this.mappedNormal = [];

	this.mappedAlphaNum = [];

	

	let current;

	

	//itarable

	current = section.iterable

	for(let i=0; i<current.length; i++){

		let currentSection = current[i]

		let data = section[currentSection]

	

		for(let k=0; k < data.length; k++){

			let item = data[k]

	

			for(let j=1; j <= item[1]; j++){

				let finalItem = {

					"type": "itarable",

					"source": this.getUrl('raccourcis/' + item[0] + j + '.png'),

					"description": section.type[item[0]],

					"key": this.objSection.type[item[0]] + j,

					"name": this.languages[this.objSection.type[item[0]]][this.language] + j,

					"value": this.languages.declaration[this.language] + this.languages[this.objSection.type[item[0]]][this.language] + j

				}

				this.mappedItarable.push(finalItem)

			}

		}

	}

	

	//media

	current = section.media

	for(let i=0; i<current.length; i++){

		let currentSection = current[i]

		let data = section[currentSection]

	

		for(let k=0; k < data.length; k++){

			let item = data[k]

	

			for(let j=1; j <= item[1]; j++){

				let finalItem = {

					"type": "itarable",

					"description": section.type[item[0]],

					"source": this.getUrl('raccourcis/' + item[0] + j + '.png'),

					"name": this.languages[item[0]][this.language] + j,

					"value": this.languages.declaration[this.language] + this.languages[item[0]][this.language] + j

				}

				this.mappedMedia.push(finalItem)

			}

		}

	}

	

	//phrasal

	current = section.phrasal

	for(let i=0; i<current.length; i++){

		let currentSection = current[i]

		let data = section[currentSection]

	

		for(let k=0; k < data.length; k++){

			let item = data[k]

			let finalItem = {

				"type": "phrasal",

				"name": this.languages[item][this.language],

				"value": this.languages[item][this.language]

			}

			this.mappedPhrasal.push(finalItem)

		}

	}

	

	//Normal

	current = section.normal

	for(let i=0; i<current.length; i++){

		let currentSection = current[i]

		let data = section[currentSection]

	

		for(let k=0; k < data.length; k++){

			let item = data[k]

			let finalItem = {

				"type": "phrasal",

					"key": item,

				"name": this.languages[item][this.language],

				"source": this.getUrl('raccourcis/' + item + '.png'),

				"value": this.languages.declaration[this.language] + this.languages[item][this.language]

			}

			this.mappedNormal.push(finalItem)

		}

	}

	

	//Alpha alphaNum

	current = section.alphaNum

	for(let i=0; i<current.length; i++){

		let currentSection = current[i]

		let data = section[currentSection]

	

		for(let k=0; k < data.length; k++){

			for(let j=0; j < data[k][1].length; j++){	

				let item = data[k][j]

				let finalItem = {

					"type": "phrasal",

					"name": this.languages[data[k][0]][this.language] + item,

					"source": this.getUrl('raccourcis/alphaNum/' + item + '.png'),

					"value": this.languages.declaration[this.language] + this.languages[data[k][0]][this.language] + item

				}

				this.mappedAlphaNum.push(finalItem)

			}

		}

	}

	

	//phrasalSection

	current = section.phrasalSection

	for(let i=0; i<current.length; i++){

		let currentSection = current[i]

		let data = section[currentSection]

	

		for(let k=0; k < data.length; k++){

			for(let j=0; j < data[k].length; j++){

				let item = data[k][j]

				let finalItem = {

					"type": "itarable",

					"name": this.languages[item][this.language],

					"value": this.languages[item][this.language]

				}

				this.mappedPhrasalSection.push(finalItem)

			}

		}

	}

}
,
		filter: function(){		
	this.m_mappedItarable = this.mappedItarable.filter(this.filterIt)

	this.m_mappedMedia = this.mappedMedia.filter(this.filterIt)

	this.m_mappedPhrasal = this.mappedPhrasal.filter(this.filterIt)

	this.m_mappedPhrasalSection = this.mappedPhrasalSection.filter(this.filterIt)

	this.m_mappedNormal = this.mappedNormal.filter(this.filterIt)

	this.m_mappedAlphaNum = this.mappedAlphaNum.filter(this.filterIt)

}
,
		filterIt: function(e){		
	return e.name.includes(this.filterKey) || e.value.includes(this.filterKey);

}
,
		clearFilter: function(){		
	this.filterKey = null

}
,
		getExtraMove: function(key){		
	this.extraAction = this.extraMoveMap[key].action

	this.extraMenuVisible = true

}
,
		checkIfExtraMove: function(key){		
	if(this.extraMoveMap[key] == undefined){

		this.extraMoveMap[key] = ({})

		this.extraMoveMap[key].check = false

				

		for(let i=0; i< move.length; i++){

			let current = move[i]

			

			let rule = '\\b' + current.type.join('\\b|\\b') + '\\b'

			if(key.match(new RegExp(rule))){

				this.extraMoveMap[key].check = true

				this.extraMoveMap[key].action = current.action

				break

			}

		}

	}

	return this.extraMoveMap[key].check

}
,
		hideExtraMenu: function(){		
	this.extraMenuVisible = false

}
,
	},
	mounted(){
			
	this.showObject("fond2")

	this.mapObjects()

	},
	computed: {
	
		i18n_locale: function(){		
	return this.$i18n.locale

}
,
		languageList: function(){		
	let list = this.$store.state.languageList

	return list.map(e => langMap[e.toUpperCase()])

}
,
		userData: function(){		
	return this.$store.state.user

}
,
		userImage: function(){		
	if(this.userData && this.userData.profile_image){

		if(this.userData.profile_image.formats){

			return this.$store.state.apiURL + this.userData.profile_image.formats.small.url

		} else {

			return this.$store.state.apiURL + this.userData.profile_image.url

		}

	} else {

		return "https://picsum.photos/200"

	}

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	watch: {
	
		i18n_locale: function(val) { 		
	this.language = this.$i18n.locale

	this.selectedLanguage = langMap[this.language.toUpperCase()]

}
,
		language: function() { 		
	this.$store.commit('changeLang', this.language)

}
,
		filterKey: function(value){		
	if(value){

		this.objListVisible = false

		this.filterListVisible = true

		this.filter()

	} else {

		this.objListVisible = true

		this.filterListVisible = false

	}

}
,
		selectedLanguage: function(val){			
	let target = "Francais"

	

	switch(val){

		case 'Francais':

			target = "fr"

			break;

		case 'English':

			target = "en"

			break;

	}

		

	this.$store.commit('changeLang', target)	

}
,
	},
	data(){
	return {
		
		languages:  lg		,
		objSection:  section		,
		filterKey:   null		,
		mappedItarable:  []		,
		mappedMedia:  []		,
		mappedPhrasal:  []		,
		mappedPhrasalSection:  []		,
		mappedAlphaNum:  []		,
		mappedNormal:  []		,
		m_mappedItarable:  []		,
		m_mappedMedia:  []		,
		m_mappedPhrasal:  []		,
		m_mappedPhrasalSection:  []		,
		m_mappedAlphaNum:  []		,
		m_mappedNormal:  []		,
		objListVisible:  true		,
		filterListVisible:  false		,
		extraAction:  []		,
		extraMenuVisible:  false		,
		selectedLanguage:  langMap[this.$i18n.locale.toUpperCase()]		,
		language:  this.$i18n.locale		,
		iterableData:  []		,
		mediaData:  []		,
		normalData:  []		,
		phrasalData:  []		,
		phrasalSectionData:  []		,
		alphaNumData:  []		,
		currentSctionName:  "fond2"		,
		extraMoveMap:  ({})		,
		}
	},
	props: {
	
		insertCode: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		undo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		redo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		copy: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		cut: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		paste: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		openCreateD: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		openAddD: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		openProfile: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		hideZone: {			type: Function,			required: false,			default: () =>  ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LeftZone{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#cbcbcb ; 
border-radius:20px ; 

.item.language{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 59px ; 
right: 0px ; 
height:90px!important ; 


	.rectangle.rectanglelanguage1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	background-color:#ffffff ; 

		.rectangle.rectanglerectanglelanguage11{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:22px ; 
		
		top: calc(50% + 0px) ; 
		width:60px!important ; 
		height:60px!important ; 
		
		background-color:#fcfcfc ; 
		border-radius:30px ; 

			.icon.iconrectanglerectanglelanguage111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			font-size:32px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textrectanglelanguage15{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 100px ; 
		
		top: 5px ; 
		right: 25px ; 
		height:45px!important ; 
		
		color:#fff ; 
		font-size:12px ; 
		text-overflow: ellipsis ; 
		.text-content{
		width: 100%;
		overflow: hidden ;
		text-overflow: ellipsis ; 
		}

		
}

		.select.langSelector{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 100px ; 
		
		right: 50px ; 
		bottom: -19px ; 
		height:auto!important ; 
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}

.item.tool{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
height:58px!important ; 


	.rectangle.rectangletool1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	opacity: 1 ;  filter: alpha(opacity=100) ; 
	borderTopLeftRadius:20px !important; 
	
	background-color:#ededed ; 

		.row.rowrectangletool11{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:10px ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:5px ; background-color:transparent ; 

			.networkimage.imagerowrectangletool111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:46px!important ; 
			height:46px!important ; 
			
			display: inherit;

			flex: unset;
			border-radius:25px ; 
			border-style: solid ; 
			border-width:1px ; 
			border-color:undefined ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

				.mousearea.mouseareaimagerowrectangletool1111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				cursor: pointer;
				}

			
}

			.object.buttrowrectangletool113{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11333333{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11344444{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11355555{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11366666{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11377777{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11388888{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:30px!important ; 
			height:46px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.item.search{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 150px ; 
right: 0px ; 
height:49px!important ; 


	.rectangle.rectanglesearch1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	background-color:#fff ; 

		.rectangle.rectanglerectanglesearch11{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: 15px ; 
		
		top: calc(50% + 0px) ; 
		right: 15px ; 
		height:34px!important ; 
		
		background-color:#ededf4 ; 
		border-radius:17px ; 

			.icon.iconrectanglerectanglesearch111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:15px ; 
			
			top: calc(50% + 0px) ; 
			width:30px!important ; 
			height:30px!important ; 
			
			color:#a8a8a8 ; 
			font-size:20px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.textfield.textfieldrectanglerectanglesearch113{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 50px ; 
			
			right: 10px ; 
			bottom: 5px ; 
			height:24px!important ; 
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}


}

.item.menu{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 200px ; 
right: 0px ; 
bottom: 0px ; 


	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0px ; 
	
	top: 0px ; 
	bottom: 0px ; 
	width:74px!important ; 
	borderBottomLeftRadius:20px !important; 
	
	overflow-y:scroll ; background-color:#ffffff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #CD3A19;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

		.column.columnscrollarearec01111111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:4px ; 
		
		top: 10px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.imagebuttoncolumnscrollarearec011111111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:56px!important ; 
			height:56px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglemenu3{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 75px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	background-color:#f2f2f9 ; 

		.image.imagerectanglemenu31{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.3 ;  filter: alpha(opacity=30) ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}
	::v-deep .v-image__image.v-image__image--cover {
    background-size: unset;
    background-repeat: repeat;
}


		
}

		.scrollarea.scrollarearec011111122222{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		overflow-y:scroll ; 
		background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #CD3A19;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:4px ; 
			
			top: 10px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.column.columnnormalData{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.object.objectbubnormalData{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:220px!important ; 
					height:200px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnphrasalIterableData{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:220px!important ; 
					height:auto!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectbubble{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:200px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columnphrasalAlphaNum{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:220px!important ; 
					height:220px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columncolumnscrollarearec0111111132{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.object.objectbubblecolumnscrollarearec01111111111111{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:220px!important ; 
					height:102px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.scrollarea.scrollFilter{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		overflow-y:scroll ; 
		background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #CD3A19;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:4px ; 
			
			top: 10px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.object.objectIterable{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:200px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectNormal{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:200px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubphrasalSectionData7{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:auto!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubblecolumnscrollarearec011111111111114{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:220px!important ; 
				height:102px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.transition.transitionrec012{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 200px ; 
right: 0px ; 
bottom: 0px ; 


	.object.extraactionmenurec01136{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.button.hide{

height: auto;

position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



right: 25px ; 
bottom: 25px ; 
opacity: 0.8 ;  filter: alpha(opacity=80) ; 
 display:flex !important; 
width:45px!important ; 
height:45px!important ; 

text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.item.language{


position:absolute !important; 






	.rectangle.rectanglelanguage1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglelanguage11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglelanguage111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textrectanglelanguage15{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.langSelector{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}

.item.tool{


position:absolute !important; 






	.rectangle.rectangletool1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectangletool11{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.imagerowrectangletool111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				.mousearea.mouseareaimagerowrectangletool1111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.object.buttrowrectangletool113{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11333333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11344444{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11355555{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11366666{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11377777{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11388888{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.item.search{


position:absolute !important; 






	.rectangle.rectanglesearch1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglesearch11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglesearch111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.textfield.textfieldrectanglerectanglesearch113{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}


}

.item.menu{


position:absolute !important; 






	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnscrollarearec01111111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnscrollarearec011111111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglemenu3{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglemenu31{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.scrollarea.scrollarearec011111122222{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.column.columnnormalData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubnormalData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnphrasalIterableData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columnphrasalAlphaNum{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columncolumnscrollarearec0111111132{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubblecolumnscrollarearec01111111111111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.scrollarea.scrollFilter{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.objectIterable{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectNormal{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubphrasalSectionData7{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubblecolumnscrollarearec011111111111114{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.transition.transitionrec012{


position:absolute !important; 






	.object.extraactionmenurec01136{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.button.hide{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.item.language{


position:absolute !important; 






	.rectangle.rectanglelanguage1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglelanguage11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglelanguage111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textrectanglelanguage15{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.langSelector{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}

.item.tool{


position:absolute !important; 






	.rectangle.rectangletool1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectangletool11{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.imagerowrectangletool111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				.mousearea.mouseareaimagerowrectangletool1111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.object.buttrowrectangletool113{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11333333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11344444{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11355555{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11366666{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11377777{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11388888{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.item.search{


position:absolute !important; 






	.rectangle.rectanglesearch1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglesearch11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglesearch111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.textfield.textfieldrectanglerectanglesearch113{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}


}

.item.menu{


position:absolute !important; 






	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnscrollarearec01111111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnscrollarearec011111111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglemenu3{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglemenu31{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.scrollarea.scrollarearec011111122222{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.column.columnnormalData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubnormalData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnphrasalIterableData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columnphrasalAlphaNum{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columncolumnscrollarearec0111111132{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubblecolumnscrollarearec01111111111111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.scrollarea.scrollFilter{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.objectIterable{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectNormal{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubphrasalSectionData7{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubblecolumnscrollarearec011111111111114{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.transition.transitionrec012{


position:absolute !important; 






	.object.extraactionmenurec01136{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.button.hide{

height: auto;

position:absolute !important; 




display:none !important; 

text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.item.language{


position:absolute !important; 






	.rectangle.rectanglelanguage1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglelanguage11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglelanguage111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textrectanglelanguage15{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.langSelector{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}

.item.tool{


position:absolute !important; 






	.rectangle.rectangletool1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectangletool11{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.imagerowrectangletool111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				.mousearea.mouseareaimagerowrectangletool1111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.object.buttrowrectangletool113{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11333333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11344444{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11355555{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11366666{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11377777{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11388888{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.item.search{


position:absolute !important; 






	.rectangle.rectanglesearch1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglesearch11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglesearch111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.textfield.textfieldrectanglerectanglesearch113{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}


}

.item.menu{


position:absolute !important; 






	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnscrollarearec01111111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnscrollarearec011111111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglemenu3{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglemenu31{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.scrollarea.scrollarearec011111122222{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.column.columnnormalData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubnormalData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnphrasalIterableData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columnphrasalAlphaNum{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columncolumnscrollarearec0111111132{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubblecolumnscrollarearec01111111111111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.scrollarea.scrollFilter{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.objectIterable{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectNormal{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubphrasalSectionData7{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubblecolumnscrollarearec011111111111114{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.transition.transitionrec012{


position:absolute !important; 






	.object.extraactionmenurec01136{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.button.hide{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.item.language{


position:absolute !important; 






	.rectangle.rectanglelanguage1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglelanguage11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglelanguage111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textrectanglelanguage15{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.langSelector{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}

.item.tool{


position:absolute !important; 






	.rectangle.rectangletool1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectangletool11{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.imagerowrectangletool111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				.mousearea.mouseareaimagerowrectangletool1111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.object.buttrowrectangletool113{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11333333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11344444{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11355555{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11366666{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11377777{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11388888{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.item.search{


position:absolute !important; 






	.rectangle.rectanglesearch1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglesearch11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglesearch111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.textfield.textfieldrectanglerectanglesearch113{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}


}

.item.menu{


position:absolute !important; 






	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnscrollarearec01111111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnscrollarearec011111111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglemenu3{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglemenu31{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.scrollarea.scrollarearec011111122222{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.column.columnnormalData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubnormalData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnphrasalIterableData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columnphrasalAlphaNum{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columncolumnscrollarearec0111111132{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubblecolumnscrollarearec01111111111111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.scrollarea.scrollFilter{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.objectIterable{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectNormal{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubphrasalSectionData7{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubblecolumnscrollarearec011111111111114{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.transition.transitionrec012{


position:absolute !important; 






	.object.extraactionmenurec01136{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.button.hide{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.item.language{


position:absolute !important; 






	.rectangle.rectanglelanguage1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglelanguage11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglelanguage111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textrectanglelanguage15{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.langSelector{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}

.item.tool{


position:absolute !important; 






	.rectangle.rectangletool1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectangletool11{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.networkimage.imagerowrectangletool111{

			
			position:relative !important; 
			
			
			
			
			
			display: inherit;

			flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				.mousearea.mouseareaimagerowrectangletool1111{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.object.buttrowrectangletool113{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11333333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11344444{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11355555{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11366666{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11377777{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.buttrowrectangletool11388888{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.item.search{


position:absolute !important; 






	.rectangle.rectanglesearch1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglesearch11{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectanglerectanglesearch111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.textfield.textfieldrectanglerectanglesearch113{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}


}

.item.menu{


position:absolute !important; 






	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnscrollarearec01111111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnscrollarearec011111111{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglemenu3{

	
	position:absolute !important; 
	
	
	
	
	
	

		.image.imagerectanglemenu31{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.scrollarea.scrollarearec011111122222{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.column.columnnormalData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubnormalData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnphrasalIterableData{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columnphrasalAlphaNum{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubphrasalSectionData{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.column.columncolumnscrollarearec0111111132{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.object.objectbubblecolumnscrollarearec01111111111111{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.scrollarea.scrollFilter{

		
		position:absolute !important; 
		
		
		
		
		

			.column.columnscrollarearec01111111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.objectIterable{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectNormal{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectTextbubble{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubphrasalSectionData7{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.objectbubblecolumnscrollarearec011111111111114{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}

.transition.transitionrec012{


position:absolute !important; 






	.object.extraactionmenurec01136{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.button.hide{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
