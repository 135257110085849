<template>
 <div>  
<div id="FDFromCamera"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : '#90909d',}">
 <div  :class="'rectangle content '"   id="content" ref="content"  v-bind:style="{}">
 <div  :class="'item itemcontent4 '"   id="itemcontent4" ref="itemcontent4"  v-bind:style="{}">
<div class="artefactVid"  :class="'video cameraVideo '"   id="cameraVideo" ref="cameraVideo" v-show="!imageTaken" :playsinline = "''" 
 :autoplay = "''" 
  v-bind:style="{}"> <video width="100%" height="100%"  :controls = "false" 
 :autoplay = "true" 
>
</video> </div>
 <div  :class="'scrollarea scrollareaitemcontent47 '"   id="scrollareaitemcontent47" ref="scrollareaitemcontent47" v-show="imageTaken"  v-bind:style="{backgroundColor : '#000',backgroundColor : '#000',}">
 <canvas  :class="'canvas cameraCanvas '"   id="cameraCanvas" ref="cameraCanvas"  v-bind:style="{}"></canvas>  </div>
 <div  :class="'row rowcontent53 '"   id="rowcontent53" ref="rowcontent53"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :fab = "true" 
 :outlined = "true" 
 :onlyIcon = "true" 
 @click="snapAsBlob" :class="'button buttonrowcontent51 '"   id="buttonrowcontent51" ref="buttonrowcontent51" v-if="!imageTaken"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-camera' !== undefined"  :color = "primaryColor" 
>{{ 'ion-ios-camera' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#72e45d'" 
 :fab = "true" 
 :outlined = "true" 
 :onlyIcon = "true" 
 @click="uploadFile" :class="'button buttonrowcontent512 '"   id="buttonrowcontent512" ref="buttonrowcontent512" v-if="imageTaken"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-checkmark-circle-outline' !== undefined"  :color = "'#72e45d'" 
>{{ 'ion-md-checkmark-circle-outline' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#da3827'" 
 :fab = "true" 
 :outlined = "true" 
 :onlyIcon = "true" 
 @click="rejectImage" :class="'button buttonrowcontent513 '"   id="buttonrowcontent513" ref="buttonrowcontent513" v-if="imageTaken"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-trash' !== undefined"  :color = "'#da3827'" 
>{{ 'ion-md-trash' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'row rowcontent3 '"   id="rowcontent3" ref="rowcontent3"  v-bind:style="{}">
 <div  :class="'text textrowcontent31 '"   id="textrowcontent31" ref="textrowcontent31"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('FileDialog.chooseCamera') }}</div>

   </div>
 <v-select
          :items = "devicesList" :class="'select selectrowcontent33 '"   id="selectrowcontent33" ref="selectrowcontent33" v-model="selectedDevice" :item-text = "'label'" 
 :item-value = "'ip'" 
  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import Camera from 'easy-js-camera';
	
import * as main from "../../../Js/main.js"
export default {
	name: 'FDFromCamera',

    components: {},
	methods: {
	
		uploadFile: function(){			
	main.uploadFile(this.imageBlob)

	.then((data) => {

		this.camera.stop()

		this.choose(data.data[0].url)

		this.close()

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		rejectImage: function() { 		
	this.imageBlob = null;

	this.imageTaken = false;

}
,
		chooseDevice: function(cam) { 		


}
,
		snapAsBlob: function() { 		
	if(!this.camera) {

     console.error('Camera not found to take a picture');

     return;

 }        

 this.camera.snapAsBlob()

     .then(picture => {

         this.imageBlob = picture;

					this.imageBlob.name = `${Date.now()}_${this.$store.state.pjDatas.nom}`;

					this.imageTaken = true

     });

}
,
	},
	mounted(){
			
	this.video = this.$refs.cameraVideo.querySelector('video');

	this.canvas = this.$refs.cameraCanvas;

	

					console.log(this.canvas)

	Camera

	    .tryInvokePermission(this.video, this.canvas)

	    .then(camera => {		

				this.camera = camera;

							

				this.camera.getDevices()

				.then(list => {

					console.log(list)

					this.devicesList = list

				})

				

				this.camera.start()

	    })

	    .catch(error => {

	        // Mostly happens if the user blocks the camera or the media devices are not supported

	    });

	},
	destroyed(){
			
	this.camera.stop()

	},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		imageBlob:  null			,
		imageTaken:  false				,
		imageAccepted:  false				,
		camera:  null		,
		video:  null			,
		canvas:  null			,
		devicesList:  []		,
		selectedDevice:  null		,
		}
	},
	props: {
	
		choose: {			type: Function,			required: false,			default: () => ()=>{},		},
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#FDFromCamera{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.button{
	padding: 0 20px !important;
}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#90909d ; 

.rectangle.content{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

	.item.itemcontent4{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 20px ; 
	
	top: 50px ; 
	right: 20px ; 
	bottom: 20px ; 
	

		.video.cameraVideo{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		playsinline: !important; 
		autoplay: !important; 
		background-color: black;

		
}

		.scrollarea.scrollareaitemcontent47{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		overflow:auto ; 
		background-color:#000 ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #0ae;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

			.canvas.cameraCanvas{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			}

		
}

		.row.rowcontent53{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		bottom: 10px ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.button.buttonrowcontent51{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:70px!important ; 
			height:70px!important ; 
			
			font-size:50px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:50px ; 
			}
			}

			.button.buttonrowcontent512{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:70px!important ; 
			height:70px!important ; 
			
			font-size:50px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:50px ; 
			}
			}

			.button.buttonrowcontent513{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:70px!important ; 
			height:70px!important ; 
			
			font-size:50px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:50px ; 
			}
			}

		
}

	
}

	.row.rowcontent3{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 20px ; 
	
	top: 5px ; 
	right: 20px ; 
	height:30px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.text.textrowcontent31{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
		font-size:15px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

		.select.selectrowcontent33{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:25px!important ; 
		item-text:label !important; 
		item-value:ip !important; 
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.item.itemcontent4{

	
	position:absolute !important; 
	
	
	
	
	

		.video.cameraVideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.scrollarea.scrollareaitemcontent47{

		
		position:absolute !important; 
		
		
		
		
		

			.canvas.cameraCanvas{

			
			position:absolute !important; 
			
			
			
			
			}

		
}

		.row.rowcontent53{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcontent51{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent512{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent513{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.rowcontent3{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcontent31{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectrowcontent33{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.item.itemcontent4{

	
	position:absolute !important; 
	
	
	
	
	

		.video.cameraVideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.scrollarea.scrollareaitemcontent47{

		
		position:absolute !important; 
		
		
		
		
		

			.canvas.cameraCanvas{

			
			position:absolute !important; 
			
			
			
			
			}

		
}

		.row.rowcontent53{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcontent51{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent512{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent513{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.rowcontent3{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcontent31{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectrowcontent33{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.item.itemcontent4{

	
	position:absolute !important; 
	
	
	
	
	

		.video.cameraVideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.scrollarea.scrollareaitemcontent47{

		
		position:absolute !important; 
		
		
		
		
		

			.canvas.cameraCanvas{

			
			position:absolute !important; 
			
			
			
			
			}

		
}

		.row.rowcontent53{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcontent51{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent512{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent513{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.rowcontent3{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcontent31{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectrowcontent33{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.item.itemcontent4{

	
	position:absolute !important; 
	
	
	
	
	

		.video.cameraVideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.scrollarea.scrollareaitemcontent47{

		
		position:absolute !important; 
		
		
		
		
		

			.canvas.cameraCanvas{

			
			position:absolute !important; 
			
			
			
			
			}

		
}

		.row.rowcontent53{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcontent51{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent512{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent513{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.rowcontent3{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcontent31{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectrowcontent33{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.item.itemcontent4{

	
	position:absolute !important; 
	
	
	
	
	

		.video.cameraVideo{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.scrollarea.scrollareaitemcontent47{

		
		position:absolute !important; 
		
		
		
		
		

			.canvas.cameraCanvas{

			
			position:absolute !important; 
			
			
			
			
			}

		
}

		.row.rowcontent53{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowcontent51{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent512{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowcontent513{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.row.rowcontent3{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.text.textrowcontent31{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.select.selectrowcontent33{

		
		position:relative !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}


}


}
}
}
</style>
