<template>
 <div>  
<div id="MailConfirm"> 
         
 <div  :class="'column column0 '"   id="column0" ref="column0"  v-bind:style="{justifyContent : 'space-around',}">
 <div  :class="'rectangle rectanglecolumn01 '"   id="rectanglecolumn01" ref="rectanglecolumn01"  v-bind:style="{backgroundColor : '#96f77d',}">
 <div  :class="'icon iconrectanglecolumn011 '"   id="iconrectanglecolumn011" ref="iconrectanglecolumn011"  v-bind:style="{color : '#fff',fontSize : getUnit(100,'px'),}"><i  :class="'ion-ios-mail-unread'"></i></div>  </div>
 <div  :class="'text textcolumn03 '"   id="textcolumn03" ref="textcolumn03"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('main.confirmEmail') }}</div>

   </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'MailConfirm',

    components: {},
} 
</script>

<style lang = "scss" scoped>


 div#MailConfirm{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.column.column0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: column;
gap:20px ; 
justify-content:space-around ; background-color: transparent ; 

.rectangle.rectanglecolumn01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

width:200px!important ; 
height:200px!important ; 

background-color:#96f77d ; 
border-radius:200px ; 

	.icon.iconrectanglecolumn011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	color:#fff ; 
	font-size:100px ; 
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.text.textcolumn03{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;

font-size:20px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
}


}


}


@media (max-width: 600px) {


.column.column0{


position:relative !important; 









.rectangle.rectanglecolumn01{


position:relative !important; 







	.icon.iconrectanglecolumn011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.text.textcolumn03{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 601px) {


.column.column0{


position:relative !important; 









.rectangle.rectanglecolumn01{


position:relative !important; 







	.icon.iconrectanglecolumn011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.text.textcolumn03{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 961px) {


.column.column0{


position:relative !important; 









.rectangle.rectanglecolumn01{


position:relative !important; 







	.icon.iconrectanglecolumn011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.text.textcolumn03{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1265px) {


.column.column0{


position:relative !important; 









.rectangle.rectanglecolumn01{


position:relative !important; 







	.icon.iconrectanglecolumn011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.text.textcolumn03{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}

@media (min-width: 1905px) {


.column.column0{


position:relative !important; 









.rectangle.rectanglecolumn01{


position:relative !important; 







	.icon.iconrectanglecolumn011{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	justify-content: center;
	align-items: center;
	i {
	}
}


}

.text.textcolumn03{


position:relative !important; 





.text-content{
width: 100%;
}


}


}
}
}
</style>
