<template>
 <div>  
<div id="ObjectBubble"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card carditem01 '"   id="carditem01" ref="carditem01" :elevation = "4" 
  v-bind:style="{}">
 <div  :class="'rectangle rectanglecarditem011 '"   id="rectanglecarditem011" ref="rectanglecarditem011"  v-bind:style="{ transform: 'rotate(' + (-1*45) + 'deg)', }">  </div>
 <div  :class="'text textcarditem013 '"   id="textcarditem013" ref="textcarditem013"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <v-img  :class="'networkimage networkimagecarditem015 '"   id="networkimagecarditem015" ref="networkimagecarditem015"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "source" 
>
 <div  :class="'mousearea mouseareacarditem0191 '"   id="mouseareacarditem0191" ref="mouseareacarditem0191"  v-bind:style="{}" @click="clo(value)"></div>  </v-img>
 <div  :class="'text textcarditem01344444 '"   id="textcarditem01344444" ref="textcarditem01344444"  v-bind:style="{color : '#a9acad',}">
<div class="text-content" :style = "{}">{{ type }}</div>

   </div>
 <div  :class="'text textcarditem0136 '"   id="textcarditem0136" ref="textcarditem0136" v-if="haveExtraMove"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('app.plusAction') }}</div>

 
 <div  :class="'mousearea mouseareatextcarditem01361 '"   id="mouseareatextcarditem01361" ref="mouseareatextcarditem01361"  v-bind:style="{}" @click="getExtra"></div>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ObjectBubble',

    components: {},
	methods: {
	
		getExtra: function(){ 		
	this.getExtraMove(this.keyType)

}
,
	},
	mounted(){
			
	this.haveExtraMove = this.checkIfExtraMove(this.keyType)

	},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	watch: {
	
		keyType: function(val) { 		
	this.haveExtraMove = this.checkIfExtraMove(this.keyType)

}
,
	},
	data(){
	return {
		
		haveExtraMove:  false		,
		}
	},
	props: {
	
		name: {					required: false,			default: () =>  "fond50",		},
		keyType: {					required: false,			default: () =>  "",		},
		type: {					required: false,			default: () =>  "fond",		},
		source: {					required: false,			default: () =>  "https://picsum.photos/200",		},
		clo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		getExtraMove: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		value: {					required: false,			default: () =>  "........",		},
		checkIfExtraMove: {			type: Function,			required: false,			default: () =>  ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ObjectBubble{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.carditem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 6px ; 
right: 6px ; 
bottom: 6px ; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:-10px ; 
	
	top: 19px ; 
	width:20px!important ; 
	height:20px!important ; 
	transform: rotate(calc(-145deg));
	
	background-color:#ffffff ; 
	border-radius:4px ; 

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:8px ; 
	
	top: 5px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	font-size:15px ; 
	.text-content{
	width: 100%;
	}

	
}

	.networkimage.networkimagecarditem015{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 5px ; 
	
	top: 30px ; 
	right: 5px ; 
	bottom: 20px ; 
	
	display: inherit;

	flex: unset;
	border-radius:8px ; 
	border-style: solid ; 
	border-width:1px ; 
	border-color:#cfcfcf ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

		.mousearea.mouseareacarditem0191{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: pointer;
		}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	right: 5px ; 
	bottom: 2px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#a9acad ; 
	font-size:13px ; 
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem0136{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 5px ; 
	right: 8px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	font-size:15px ; 
	.text-content{
	width: 100%;text-decoration: underline
	}

		.mousearea.mouseareatextcarditem01361{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
		cursor: pointer;
		}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.networkimage.networkimagecarditem015{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareacarditem0191{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem0136{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareatextcarditem01361{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.networkimage.networkimagecarditem015{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareacarditem0191{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem0136{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareatextcarditem01361{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.networkimage.networkimagecarditem015{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareacarditem0191{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem0136{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareatextcarditem01361{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.networkimage.networkimagecarditem015{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareacarditem0191{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem0136{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareatextcarditem01361{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.networkimage.networkimagecarditem015{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		.mousearea.mouseareacarditem0191{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem0136{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

		.mousearea.mouseareatextcarditem01361{

		
		position:absolute !important; 
		
		
		
		
		
		}

	
}


}


}
}
}
</style>
