<template>
 <div>  
<div id="Designer"> 
         
 <div  :class="'item rec01111122222 '"   id="rec01111122222" ref="rec01111122222"  v-bind:style="{}">
 <div  :class="'rectangle designZone '"   id="designZone" ref="designZone"  v-bind:style="{}">
 <div  :class="'scrollarea scrollareadesignZone5 '"   id="scrollareadesignZone5" ref="scrollareadesignZone5"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <designerItem :class="'object designItem '"   id="designItem" ref="designItem" v-for="(item,index) in itemObj" :key="'designItem' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :ip = "item.index" 
 :src = "item.src" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</designerItem>
 <DRectangle :class="'object dRectangle '"   id="dRectangle" ref="dRectangle" v-for="(item,index) in rectangleObj" :key="'dRectangle' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,width : getUnit(item.width,'px'),height : getUnit(item.height,'px'),}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :ip = "item.index" 
 :m_color = "item.color" 
 :m_bgColor = "item.bgColor" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</DRectangle>
 <DText :class="'object dText '"   id="dText" ref="dText" v-for="(item,index) in textObj" :key="'dText' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,width : getUnit(item.width,'px'),height : getUnit(item.height,'px'),}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :ip = "item.index" 
 :m_color = "item.color" 
 :m_value = "item.value" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</DText>
 <DCircle :class="'object dCircle '"   id="dCircle" ref="dCircle" v-for="(item,index) in circleObj" :key="'dCircle' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,width : getUnit(item.width,'px'),height : getUnit(item.height,'px'),}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :ip = "item.index" 
 :m_color = "item.color" 
 :m_bgColor = "item.bgColor" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</DCircle>
 <DButton :class="'object dButton '"   id="dButton" ref="dButton" v-for="(item,index) in buttonObj" :key="'dButton' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :m_width = "item.width" 
 :m_height = "item.height" 
 :ip = "item.index" 
 :m_value = "item.value" 
 :m_color = "item.color" 
 :m_bgColor = "item.bgColor" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</DButton>
 <DTextInput :class="'object dTextInput '"   id="dTextInput" ref="dTextInput" v-for="(item,index) in textInputObj" :key="'dTextInput' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,width : getUnit(item.width,'px'),height : getUnit(item.height,'px'),}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :m_width = "item.width" 
 :m_height = "item.height" 
 :ip = "item.index" 
 :m_value = "item.value" 
 :m_color = "item.color" 
 :m_bgColor = "item.bgColor" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</DTextInput>
 <DCheckbox :class="'object dCheckbox '"   id="dCheckbox" ref="dCheckbox" v-for="(item,index) in checkboxObj" :key="'dCheckbox' + index" v-show="item.visible"  v-bind:style="{left : getUnit(item.x,'px'),top : getUnit(item.y,'px'), transform: 'rotate(' + (-1*item.rotation) + 'deg)', zIndex : item.index,width : getUnit(item.width,'px'),height : getUnit(item.height,'px'),}" :updateObject = "updateObject" 
 :selectObject = "selectItem" 
 :m_width = "item.width" 
 :m_height = "item.height" 
 :ip = "item.index" 
 :m_value = "item.value" 
 :m_color = "item.color" 
 :m_checked = "item.checked" 
 :currentSelectedItem = "currentIp" 
 :zoom = "item.scale" 
>
</DCheckbox>  </div>
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 @click="generation" :class="'button refreshButt '"   id="refreshButt" ref="refreshButt"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-refresh' !== undefined"  :color = "primaryColor" 
>{{ 'ion-ios-refresh' }}
</v-icon></v-btn>  </div>
 <div  :class="'rectangle propZone '"   id="propZone" ref="propZone"  v-bind:style="{backgroundColor : '#ededed',}">
 <div  :class="'scrollarea scrollareapropZone1 '"   id="scrollareapropZone1" ref="scrollareapropZone1"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'flow gridrectanglescrollareapropZone1111 '"   id="gridrectanglescrollareapropZone1111" ref="gridrectanglescrollareapropZone1111"  v-bind:style="{backgroundColor : '#fff',}">
 <div  :class="'column columngridrectanglescrollareapropZone1111 '"   id="columngridrectanglescrollareapropZone1111" ref="columngridrectanglescrollareapropZone1111"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.element') }}</div>

   </div>
 <v-text-field  :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentName"  v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone111122222 '"   id="columngridrectanglescrollareapropZone111122222" ref="columngridrectanglescrollareapropZone111122222"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ 'x' }}</div>

   </div>
 <v-text-field  :prepend-inner-icon = "'ion-md-radio-button-on'" 
 :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentX"  v-bind:style="{color : primaryColor,}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone11112222233333 '"   id="columngridrectanglescrollareapropZone11112222233333" ref="columngridrectanglescrollareapropZone11112222233333"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ 'y' }}</div>

   </div>
 <v-text-field  :prepend-inner-icon = "'ion-md-radio-button-on'" 
 :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentY"  v-bind:style="{color : primaryColor,}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone11112222233333 '"   id="columngridrectanglescrollareapropZone11112222233333" ref="columngridrectanglescrollareapropZone11112222233333"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.width') }}</div>

   </div>
 <v-text-field  :prepend-inner-icon = "'ion-ios-move'" 
 :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentWidth"  v-bind:style="{color : primaryColor,}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone11112222233333 '"   id="columngridrectanglescrollareapropZone11112222233333" ref="columngridrectanglescrollareapropZone11112222233333"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.height') }}</div>

   </div>
 <v-text-field  :prepend-inner-icon = "'ion-ios-move'" 
 :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentHeight"  v-bind:style="{color : primaryColor,}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone11112222244444 '"   id="columngridrectanglescrollareapropZone11112222244444" ref="columngridrectanglescrollareapropZone11112222244444"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.visible') }}</div>

   </div>
 <v-switch  :class="'switch switchcolumngridrectanglescrollareapropZone111122222444443 '"   id="switchcolumngridrectanglescrollareapropZone111122222444443" ref="switchcolumngridrectanglescrollareapropZone111122222444443" v-model="cuurentVisible"  v-bind:style="{}" :color = "primaryColor" 
 :disabled = "disableFormElem" 
></v-switch>  </div>
 <div  :class="'column depColumn '"   id="depColumn" ref="depColumn"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.deplacable') }}</div>

   </div>
 <v-switch  :class="'switch switchcolumngridrectanglescrollareapropZone111122222444443 '"   id="switchcolumngridrectanglescrollareapropZone111122222444443" ref="switchcolumngridrectanglescrollareapropZone111122222444443" v-model="cuurentDeplacable"  v-bind:style="{}" :color = "primaryColor" 
 :disabled = "disableFormElem" 
></v-switch>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone1111222223333355555 '"   id="columngridrectanglescrollareapropZone1111222223333355555" ref="columngridrectanglescrollareapropZone1111222223333355555"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.size') }}</div>

   </div>
 <v-text-field  :prepend-inner-icon = "'ion-ios-resize'" 
 :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentScale"  v-bind:style="{color : primaryColor,}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone1111222223333366666 '"   id="columngridrectanglescrollareapropZone1111222223333366666" ref="columngridrectanglescrollareapropZone1111222223333366666"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.rotation') }}</div>

   </div>
 <v-text-field  :prepend-inner-icon = "'ion-ios-refresh'" 
 :disabled = "disableFormElem" 
 :dense = "true" 
 solo :class="'textfield textfieldcolumngridrectanglescrollareapropZone11113 '"   id="textfieldcolumngridrectanglescrollareapropZone11113" ref="textfieldcolumngridrectanglescrollareapropZone11113" v-model="cuurentRotation"  v-bind:style="{color : primaryColor,}"></v-text-field>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone111122222333336666610 '"   id="columngridrectanglescrollareapropZone111122222333336666610" ref="columngridrectanglescrollareapropZone111122222333336666610"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.color') }}</div>

   </div>
 <v-card 
 :class="'card cardcolumngridrectanglescrollareapropZone1111222223333366666105 '"   id="cardcolumngridrectanglescrollareapropZone1111222223333366666105" ref="cardcolumngridrectanglescrollareapropZone1111222223333366666105" :elevation = "2" 
  v-bind:style="{}">
 <div  :class="'rectangle rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051 '"   id="rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051" ref="rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051"  v-bind:style="{ opacity: (disableFormElem? 0.5 : 1) ,  filter: 'alpha(opacity=(disableFormElem? 0.5 : 1)*100)' , backgroundColor : cuurentColor,}">
 <div  :class="'mousearea colorPickerArea1 '"   id="colorPickerArea1" ref="colorPickerArea1"  v-bind:style="{}" @click="toggelCp"></div>  </div>
 <VColorPicker :class="'importedobject colorPicker '"   id="colorPicker" ref="colorPicker" v-if="cpShowed" v-model="cuurentColor" :dot-size = "30" 
  v-bind:style="{}">
</VColorPicker>  </v-card>  </div>
 <div  :class="'column columngridrectanglescrollareapropZone11112222233333666661011 '"   id="columngridrectanglescrollareapropZone11112222233333666661011" ref="columngridrectanglescrollareapropZone11112222233333666661011"  v-bind:style="{}">
 <div  :class="'text titleOption '"   id="titleOption" ref="titleOption"  v-bind:style="{color : '#989898',}">
<div class="text-content" :style = "{}">{{ $t('Designer.bgColor') }}</div>

   </div>
 <v-card 
 :class="'card cardcolumngridrectanglescrollareapropZone1111222223333366666105 '"   id="cardcolumngridrectanglescrollareapropZone1111222223333366666105" ref="cardcolumngridrectanglescrollareapropZone1111222223333366666105" :elevation = "2" 
  v-bind:style="{}">
 <div  :class="'rectangle rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051 '"   id="rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051" ref="rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051"  v-bind:style="{ opacity: (disableFormElem? 0.5 : 1) ,  filter: 'alpha(opacity=(disableFormElem? 0.5 : 1)*100)' , backgroundColor : cuurentBgColor,}">
 <div  :class="'mousearea colorPickerArea1 '"   id="colorPickerArea1" ref="colorPickerArea1"  v-bind:style="{}" @click="toggelBgCp"></div>  </div>
 <VColorPicker :class="'importedobject colorPicker '"   id="colorPicker" ref="colorPicker" v-if="bgCpShowed" v-model="cuurentBgColor" :dot-size = "30" 
  v-bind:style="{}">
</VColorPicker>  </v-card>  </div>  </div>  </div>
 <v-img  :class="'animatedimage animatedimagepropZone3 '"   id="animatedimagepropZone3" ref="animatedimagepropZone3"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/editor/anim4.gif')" 
>  </v-img>  </div>
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 @click="hideZone" :class="'button hide3 '"   id="hide3" ref="hide3"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-forward' !== undefined"  :color = "primaryColor" 
>{{ 'ion-ios-arrow-forward' }}
</v-icon></v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>
import designerItem from '../../At/AppContent/designer/designerItem'
import DRectangle from '../../At/AppContent/designer/DRectangle'
import DText from '../../At/AppContent/designer/DText'
import DCircle from '../../At/AppContent/designer/DCircle'
import DButton from '../../At/AppContent/designer/DButton'
import DTextInput from '../../At/AppContent/designer/DTextInput'
import DCheckbox from '../../At/AppContent/designer/DCheckbox'

import Design from "../../Js/designer.js"
import lg from "../../Js/language.json"
export default {
	name: 'Designer',

    components: {designerItem,DRectangle,DText,DCircle,DButton,DTextInput,DCheckbox,},
	methods: {
	
		toggelCp: function() { 		
	if(!this.disableFormElem){

		this.cpShowed = !this.cpShowed

	}

}
,
		toggelBgCp: function() { 		
	if(!this.disableFormElem){

		this.bgCpShowed = !this.bgCpShowed

	}

}
,
		updateFields: function() { 		
	if(this.currentIp){	

		this.cuurentName = this.arrayObj[this.currentIp]['id'];

		this.cuurentX = this.arrayObj[this.currentIp]['x'];

		this.cuurentY = this.arrayObj[this.currentIp]['y'];

		this.cuurentWidth = this.arrayObj[this.currentIp]['width'];

		this.cuurentHeight = this.arrayObj[this.currentIp]['height'];

		this.cuurentVisible = this.arrayObj[this.currentIp]['visible'];

		this.cuurentDeplacable = this.arrayObj[this.currentIp]['deplacable'];

		this.cuurentScale = this.arrayObj[this.currentIp]['scale'];

		this.cuurentRotation = this.arrayObj[this.currentIp]['rotation'];

		

		let color = this.arrayObj[this.currentIp]['color']

		if(color){

			this.cuurentColor = color;

		}

		

		let bgColor = this.arrayObj[this.currentIp]['bgColor']

		if(bgColor){

			this.cuurentBgColor = bgColor;

		}

	}

}
,
		currentCodeSearch: function(){		
	let paren = arrayObj[currentIp]

  this.currentCode= paren;

}
,
		selectItem: function(ip) { 		
	this.currentIp = ip;

}	
,
		generation: function(){			
	var tab = Design.getCode(this.codeBrut, this.currentLang)

	

	for(var i=0;i<=(tab.length)-1;i++){

	   var type = tab[i].type

				

	   if(type!=="dessinBut"){

	

	       var url = ""

	       var urlCheck = type.match(this.languages.lettre[this.currentLang])

	       var urlCheck2 = type.match(this.languages.nombre[this.currentLang])

	

	       var urlCheck3 = type.match(this.languages.animationImportee[this.currentLang])

	       var urlCheck4 = type.match(this.languages.imageImportee[this.currentLang])

	       var urlCheck5 = type.match(this.languages.personnageImporte[this.currentLang])

		

					let check1 = (urlCheck3 !== null || urlCheck4 !== null || urlCheck5 !== null)

	

					if(check1){			

						let matchedIndex = parseInt(type.match(/\d+/))

						let targetType = (urlCheck3? 'animation' : (urlCheck4? 'image' : 'personnage'))

						

						for(let key in this.pjDatas.imported[targetType]){

							if(this.pjDatas.imported.personnage[key].id == matchedIndex){

								let path = this.pjDatas.imported[targetType][key].url							

							

	          		url = path

								break

							}

						}

							tab[i].src = url

					} else {								

			       if(urlCheck !== null || urlCheck2 !== null ){

			           url = "raccourcis/alphaNum/"

			       }else {

			           url = "raccourcis/"

			       }

	

       			for(var j=0; j< this.convertor.length; j++){

		           var it = this.convertor[j]

								console.log("-----", it, type, this.languages[it[0]][this.currentLang])

								if(type.match(new RegExp('^' + this.languages[it[0]][this.currentLang] + '\\d+$'))){

		           		type = type.replace(

											this.languages[it[0]][this.currentLang], 

											it[1])

									tab[i].ty = it[0]

									break

								} else if(type.match(new RegExp('^' + this.languages[it[0]][this.currentLang] + '\\w+$'))){

									if(urlCheck){

		           			type = type.replace(

												this.languages[it[0]][this.currentLang], 

												it[1])

										tab[i].ty = it[0]

										break

									}

								} else if(type.match(new RegExp('^' + this.languages[it[0]][this.currentLang] + '$'))){

									type = it[1]		

									tab[i].ty = it[0]

									break

								} else if(this.languages[it[0]][this.currentLang].match(new RegExp('^' + type + '(\\s|\\w)+(:\\s*(\\.)+)$'))){

		           		type = it[0]

										tab[i].ty = it[0]

										break

								}

		       	}

				

						if(tab[i].ty == "rectangle")

							continue	

						if(tab[i].ty == "circle")

							continue	

						if(tab[i].ty == "bouton")

							continue	

						if(tab[i].ty == "texte")

							continue

						if(tab[i].ty == "entreeTexte")

							continue

						if(tab[i].ty == "checkBox")

							continue

						if(tab[i].ty == "toggle")

							continue

				

						tab[i].src = url + type + (type.match(/^B[0-9]*$/g) !== null? ".jpg" : ".png")

				}

      }

  }

	

	this.arrayObj = tab;

	this.updateFields()

}
,
		retournment: function(){			
	var retCode=""

	for(var l = 0; l < this.arrayObj.length; l++) {		

	

		if(this.arrayObj[l].ty == "texte"){

    	retCode += "\n\n\t" + Design.valueMatch.texte[this.currentLang]

								.replace("{{nom}}", this.arrayObj[l].id)

								.replace('"(.*)"', `"${this.arrayObj[l].value}"`)

		} else if(this.arrayObj[l].ty == "bouton"){

    	retCode += "\n\n\t" + Design.valueMatch.button[this.currentLang]

								.replace("{{nom}}", this.arrayObj[l].id)

								.replace('"(.*)"', `"${this.arrayObj[l].value}"`)

		} else if(this.arrayObj[l].ty == "entreeTexte"){

    	retCode += "\n\n\t" 

								+ this.arrayObj[l].id 

								+ this.arrayObj[l].genre 

								+ this.languages[this.arrayObj[l].ty][this.currentLang]

		} else if(this.arrayObj[l].ty == "checkBox"){

    	retCode += "\n\n\t" + Design.valueMatch.checkBox[this.currentLang]

								.replace("{{nom}}", this.arrayObj[l].id)

								.replace('"(.*)"', `"${this.arrayObj[l].value}"`)

		} else if(this.arrayObj[l].ty == "toggle"){

    	retCode += "\n\n\t" + Design.valueMatch.checkBox[this.currentLang]

								.replace("{{nom}}", this.arrayObj[l].id)

								.replace('"(.*)"', `"${this.arrayObj[l].value}"`)

		}	else {

    	retCode += "\n\n\t" + this.arrayObj[l].id + this.arrayObj[l].genre + this.arrayObj[l].type

		}

	

    retCode +=(this.arrayObj[l].x !== 0? "\n\t\t" + this.arrayObj[l].id +  Design.desi.posXDelete[this.currentLang] + this.arrayObj[l].x : "")

            + (this.arrayObj[l].y !== 0? "\n\t\t" + this.arrayObj[l].id +  Design.desi.posYDelete[this.currentLang] + this.arrayObj[l].y : "")

	

            + (this.arrayObj[l].width !== 100? "\n\t\t" + this.arrayObj[l].id +  Design.desi.largeurDelete[this.currentLang] + this.arrayObj[l].width : "")

            + (this.arrayObj[l].height !== 100? "\n\t\t" + this.arrayObj[l].id +  Design.desi.longueurDelete[this.currentLang] + this.arrayObj[l].height : "")



						+ (this.arrayObj[l].rotation !== 0? "\n\t\t" + this.arrayObj[l].id +  Design.desi.rotationDelete[this.currentLang] + this.arrayObj[l].rotation : "")

        		+ (this.arrayObj[l].visible === false? "\n\t\t" + this.arrayObj[l].id +  Design.desi.invisi[this.currentLang] : "")

        		+ (this.arrayObj[l].deplacable === true? "\n\t\t" + this.arrayObj[l].id +  Design.desi.deplacable[this.currentLang] : "")

        		+ (this.arrayObj[l].scale !== 0 && this.arrayObj[l].scale !== 1? "\n\t\t" + this.arrayObj[l].id +  Design.desi.tailleDelete[this.currentLang] + this.arrayObj[l].scale : "")

	

				if(this.arrayObj[l].color){	

					retCode += "\n\t\t" + Design.colorMatch[this.currentLang]

					.replace("{{nom}}", this.arrayObj[l].id)

					.replace('"(.*)"', `"${this.arrayObj[l].color}"`)

				}

								

				if(this.arrayObj[l].bgColor){		

					retCode += "\n\t\t" + Design.bgColorMatch[this.currentLang]

						.replace("{{nom}}", this.arrayObj[l].id)

						.replace('"(.*)"', `"${this.arrayObj[l].bgColor}"`)

				}

	}

	var returnCode= this.languages.debut[this.currentLang] + retCode + "\n" + this.languages.operation[this.currentLang] + ":"

	var newCode = this.codeBrut.replace( Design.desi.get[this.currentLang] , returnCode)

	this.updateCode(newCode)

	this.updateFields()

}
,
		updateObject: function( ip,attribu, value) {		
	this.arrayObj[ip][attribu] = value; 

  this.retournment()

}
,
	},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		pjDatas: function(){ 		
	return this.$store.state.pjDatas

}
,
		convertor: function() { 		
	let convertorMap = [["fond" , "B"], ["animal" , "AN"],

        ["arbre" , "arbre"], ["buisson" , "buisson"],

        ["rocher" , "rocher"], ["nuage" , "nuage"],

        ["cactus" , "cactus"], ["personnage" , "PERS"],

        ["heros" , "SH"], ["objet" , "OBJ"],

        ["sFond" , "SB"], ["instrument" , "INST"],

        ["rampart" , "Ramp"], ["maison" , "MA"],

        ["terrain" , "ter"], ["champ" , "ter"],

        ["vehicule" , "TRANS"], ["lettre" , ""],

        ["nombre" , ""], ["bouton" , "button"],

        ["eau" , "eau"], ["mer" , "eau"],

		["texte", "texte"], ["rectangle", "rectangle"],

		["circle", "circle"], ["entreeTexte", "entreeTexte"],

		["checkBox", "checkBox"], ["toggle", "toggle"]]

	return convertorMap

}
,
		itemObj: function(){ 		
	return this.arrayObj.filter(e => (e.src != undefined))

}
,
		rectangleObj: function(){ 		
	return this.arrayObj.filter(e => (e.ty == 'rectangle'))

}
,
		circleObj: function(){ 		
	return this.arrayObj.filter(e => (e.ty == 'circle'))

}
,
		buttonObj: function(){ 		
	return this.arrayObj.filter(e => (e.ty == 'bouton'))

}
,
		textInputObj: function(){ 		
	return this.arrayObj.filter(e => (e.ty == 'entreeTexte'))

}
,
		textObj: function(){ 		
	return this.arrayObj.filter(e => (e.ty == 'texte'))

}
,
		checkboxObj: function(){ 		
	return this.arrayObj.filter(e => (e.ty == 'checkBox'))

}
,
		disableFormElem: function() { 		
	return (this.currentIp == null)

}
,
	},
	watch: {
	
		cuurentName: function(val) { 		
	this.arrayObj[this.currentIp]['id'] = val; 

  this.retournment()

}
,
		cuurentX: function(val) { 		
	this.arrayObj[this.currentIp]['x'] = val; 

  this.retournment()

}
,
		cuurentY: function(val) { 		
	this.arrayObj[this.currentIp]['y'] = val; 

  this.retournment()

}
,
		cuurentWidth: function(val) { 		
	this.arrayObj[this.currentIp]['width'] = val; 

  this.retournment()

}
,
		cuurentHeight: function(val) { 		
	this.arrayObj[this.currentIp]['height'] = val; 

  this.retournment()

}
,
		cuurentVisible: function(val) { 		
	this.arrayObj[this.currentIp]['visible'] = val; 

  this.retournment()

}
,
		cuurentDeplacable: function(val) { 		
	this.arrayObj[this.currentIp]['deplacable'] = val; 

  this.retournment()

}
,
		cuurentRotation: function(val) { 		
	this.arrayObj[this.currentIp]['rotation'] = val; 

  this.retournment()

}
,
		currentIp: function() { 		
	this.updateFields()

}
,
		cuurentScale: function(val) { 		
	this.arrayObj[this.currentIp]['scale'] = val; 

  this.retournment()

}
,
		cuurentColor: function(val) { 		
	this.arrayObj[this.currentIp]['color'] = val; 

  this.retournment()

}
,
		cuurentBgColor: function(val) { 		
	this.arrayObj[this.currentIp]['bgColor'] = val; 

  this.retournment()

}
,
	},
	data(){
	return {
		
		languages:  lg		,
		design:  Design		,
		currentLang:  this.$store.state.language		,
		arrayObj:  [({})]		,
		currentCode:  ""		,
		currentIp:  null		,
		cuurentName:  null		,
		cuurentX:  null		,
		cuurentY:  null		,
		cuurentWidth:  null		,
		cuurentHeight:  null		,
		cuurentVisible:  null		,
		cuurentDeplacable:  null		,
		cuurentRotation:  null		,
		cuurentScale:  null		,
		cuurentColor:  "#cd3a19"		,
		cuurentBgColor:  "#cd3a19"		,
		cpShowed:  false		,
		bgCpShowed:  false		,
		}
	},
	props: {
	
		codeBrut: {					required: false,			default: () =>  "",		},
		updateCode: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		hideZone: {			type: Function,			required: false,			default: () =>  ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Designer{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.designZone{
	font-family: Arial;
}



.item.rec01111122222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.designZone{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
z-index:0 ; 
height:500px!important ; 

background-color:#ffffff ; 
border-radius:20px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#cccccc ; 

	.scrollarea.scrollareadesignZone5{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	z-index:0 ; 
	
	overflow:hidden ; 
	background-color:transparent ; 

		.object.designItem{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:auto!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dRectangle{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dText{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCircle{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dButton{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:auto!important ; 
		height:auto!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dTextInput{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCheckbox{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.button.refreshButt{

	height: auto;
	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:10px ; 
	
	top: 10px ; 
	opacity: 0.6 ;  filter: alpha(opacity=60) ; 
	z-index:1 ; 
	width:45px!important ; 
	height:45px!important ; 
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.rectangle.propZone{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 510px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ededed ; 
border-radius:20px ; 
border-style:solid ; 
border-width:1px ; 
border-color:#cccccc ; 

	.scrollarea.scrollareapropZone1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	overflow-y:overlay ; 
	background-color:transparent ; 

		.flow.gridrectanglescrollareapropZone1111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;
		gap:6px ; 
		background-color:#fff ; 
		border-radius:20px ; 
		padding-top:10px ; 
		padding-bottom:10px ; 
		padding-left:10px ; 
		padding-right:10px ; 

			.column.columngridrectanglescrollareapropZone1111{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:140px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:160px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:160px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:160px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:160px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222244444{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				}

			
}

			.column.depColumn{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333355555{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:160px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333366666{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:160px!important ; 
				height:auto!important ; 
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222333336666610{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:60px!important ; 
				height:40px!important ; 
				background-color:#ffffff ; 
				border-radius:8px ; max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 5px ; 
					
					top: 5px ; 
					right: 5px ; 
					bottom: 5px ; 
					
					background-color:#ffffff ; 
					border-radius:8px ; 

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left: 0px ; 
						
						top: 0px ; 
						right: 0px ; 
						bottom: 0px ; 
						
						cursor: pointer;
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 50px ; 
					

					
}

				
}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333666661011{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:95px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.titleOption{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:25px!important ; 
				
				color:#989898 ; 
				font-size:12px ; 
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:60px!important ; 
				height:40px!important ; 
				background-color:#ffffff ; 
				border-radius:8px ; max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 5px ; 
					
					top: 5px ; 
					right: 5px ; 
					bottom: 5px ; 
					
					background-color:#ffffff ; 
					border-radius:8px ; 

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						margin: unset;
						padding: unset;
						transform-origin: top left;
						
						
						left: 0px ; 
						
						top: 0px ; 
						right: 0px ; 
						bottom: 0px ; 
						
						cursor: pointer;
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 50px ; 
					

					
}

				
}

			
}

		
}

	
}

	.animatedimage.animatedimagepropZone3{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	right: 5px ; 
	bottom: 1px ; 
	width:40px!important ; 
	height:42px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}


}

.button.hide3{

height: auto;

position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 25px ; 

bottom: 25px ; 
opacity: 0.8 ;  filter: alpha(opacity=80) ; 
 display:flex !important; 
width:45px!important ; 
height:45px!important ; 

text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}


@media (max-width: 600px) {


.item.rec01111122222{


position:absolute !important; 






.rectangle.designZone{


position:absolute !important; 







	.scrollarea.scrollareadesignZone5{

	
	position:absolute !important; 
	
	
	
	
	

		.object.designItem{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dRectangle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dText{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCircle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dButton{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dTextInput{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCheckbox{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.button.refreshButt{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.rectangle.propZone{


position:absolute !important; 







	.scrollarea.scrollareapropZone1{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridrectanglescrollareapropZone1111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columngridrectanglescrollareapropZone1111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222244444{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.depColumn{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333355555{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333366666{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222333336666610{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333666661011{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

		
}

	
}

	.animatedimage.animatedimagepropZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.button.hide3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.item.rec01111122222{


position:absolute !important; 






.rectangle.designZone{


position:absolute !important; 







	.scrollarea.scrollareadesignZone5{

	
	position:absolute !important; 
	
	
	
	
	

		.object.designItem{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dRectangle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dText{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCircle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dButton{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dTextInput{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCheckbox{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.button.refreshButt{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.rectangle.propZone{


position:absolute !important; 







	.scrollarea.scrollareapropZone1{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridrectanglescrollareapropZone1111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columngridrectanglescrollareapropZone1111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222244444{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.depColumn{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333355555{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333366666{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222333336666610{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333666661011{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

		
}

	
}

	.animatedimage.animatedimagepropZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.button.hide3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.item.rec01111122222{


position:absolute !important; 






.rectangle.designZone{


position:absolute !important; 







	.scrollarea.scrollareadesignZone5{

	
	position:absolute !important; 
	
	
	
	
	

		.object.designItem{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dRectangle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dText{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCircle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dButton{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dTextInput{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCheckbox{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.button.refreshButt{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.rectangle.propZone{


position:absolute !important; 







	.scrollarea.scrollareapropZone1{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridrectanglescrollareapropZone1111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columngridrectanglescrollareapropZone1111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222244444{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.depColumn{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333355555{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333366666{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222333336666610{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333666661011{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

		
}

	
}

	.animatedimage.animatedimagepropZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.button.hide3{

height: auto;

position:absolute !important; 




display:none !important; 

text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.item.rec01111122222{


position:absolute !important; 






.rectangle.designZone{


position:absolute !important; 







	.scrollarea.scrollareadesignZone5{

	
	position:absolute !important; 
	
	
	
	
	

		.object.designItem{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dRectangle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dText{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCircle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dButton{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dTextInput{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCheckbox{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.button.refreshButt{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.rectangle.propZone{


position:absolute !important; 







	.scrollarea.scrollareapropZone1{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridrectanglescrollareapropZone1111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columngridrectanglescrollareapropZone1111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222244444{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.depColumn{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333355555{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333366666{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222333336666610{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333666661011{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

		
}

	
}

	.animatedimage.animatedimagepropZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.button.hide3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.item.rec01111122222{


position:absolute !important; 






.rectangle.designZone{


position:absolute !important; 







	.scrollarea.scrollareadesignZone5{

	
	position:absolute !important; 
	
	
	
	
	

		.object.designItem{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dRectangle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dText{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCircle{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dButton{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dTextInput{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.dCheckbox{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.button.refreshButt{

	height: auto;
	
	position:absolute !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}

.rectangle.propZone{


position:absolute !important; 







	.scrollarea.scrollareapropZone1{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridrectanglescrollareapropZone1111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.column.columngridrectanglescrollareapropZone1111{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone11112222244444{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.depColumn{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.switch.switchcolumngridrectanglescrollareapropZone111122222444443{

				
				position:relative !important; 
				
				
				
				
				
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333355555{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone1111222223333366666{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.textfield.textfieldcolumngridrectanglescrollareapropZone11113{

				
				position:relative !important; 
				
				
				
				
				
				::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
				
::v-deep &.v-input{
					flex-grow: 0;
input{

}
				}
				}

			
}

			.column.columngridrectanglescrollareapropZone111122222333336666610{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

			.column.columngridrectanglescrollareapropZone11112222233333666661011{

			
			position:relative !important; 
			
			
			
			
			
			
			
			

				.text.titleOption{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.card.cardcolumngridrectanglescrollareapropZone1111222223333366666105{

				
				position:relative !important; 
				
				
				
				
				max-width: initial;min-width: initial;

					.rectangle.rectanglecardcolumngridrectanglescrollareapropZone11112222233333666661051{

					
					position:absolute !important; 
					
					
					
					
					
					

						.mousearea.colorPickerArea1{

						
						position:absolute !important; 
						
						
						
						
						
						}

					
}

					.importedobject.colorPicker{

					
					position:absolute !important; 
					
					
					
					
					

					
}

				
}

			
}

		
}

	
}

	.animatedimage.animatedimagepropZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.button.hide3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
