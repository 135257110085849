<template>
 <div>  
<div id="AppSplatch"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerec01 '"   id="rectanglerec01" ref="rectanglerec01"  v-bind:style="{backgroundColor : primaryColor,}">  </div>
 <v-img  :class="'animatedimage animatedimagerec03 '"   id="animatedimagerec03" ref="animatedimagerec03"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/Splash/anim6.gif')" 
>  </v-img>
 <v-img  :class="'animatedimage animatedimagerec05 '"   id="animatedimagerec05" ref="animatedimagerec05"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/Splash/anim3.gif')" 
>  </v-img>
 <div  :class="'scrollarea scrollarearec07 '"   id="scrollarearec07" ref="scrollarearec07"  v-bind:style="{}">
 <div  :class="'column columnscrollarearec071 '"   id="columnscrollarearec071" ref="columnscrollarearec071"  v-bind:style="{}">
 <div  :class="'item logo '"   id="logo" ref="logo"  v-bind:style="{}">
 <div  :class="'rectangle rectanglecolumnscrollarearec071111111 '"   id="rectanglecolumnscrollarearec071111111" ref="rectanglecolumnscrollarearec071111111"  v-bind:style="{backgroundColor : '#dde0ec',}">
 <v-img  :class="'image imagerectanglecolumnscrollarearec0711111113 '"   id="imagerectanglecolumnscrollarearec0711111113" ref="imagerectanglecolumnscrollarearec0711111113"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/editor/nuage.png')" 
>  </v-img>
 <v-img  :class="'image imagerectanglecolumnscrollarearec0711111111 '"   id="imagerectanglecolumnscrollarearec0711111111" ref="imagerectanglecolumnscrollarearec0711111111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/Splash/logo3D.png')" 
>  </v-img>
 <v-img  :class="'image imagerectanglecolumnscrollarearec071111111333333 '"   id="imagerectanglecolumnscrollarearec071111111333333" ref="imagerectanglecolumnscrollarearec071111111333333"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/editor/nuage.png')" 
>  </v-img>
 <v-img  :class="'image imagerectanglecolumnscrollarearec071111111344444 '"   id="imagerectanglecolumnscrollarearec071111111344444" ref="imagerectanglecolumnscrollarearec071111111344444"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/editor/nuage.png')" 
>  </v-img>  </div>  </div>
 <div  :class="'text slogan '"   id="slogan" ref="slogan"  v-bind:style="{color : '#9c9c9c',}">
<div class="text-content" :style = "{}">{{ $t('app.slogan') }}</div>

   </div>
 <div  :class="'text desc '"   id="desc" ref="desc"  v-bind:style="{color : '#9c9c9c',}">
<div class="text-content" :style = "{}">{{ $t('app.subSlogan') }}</div>

   </div>
 <div  :class="'item itemcolumnscrollarearec07111 '"   id="itemcolumnscrollarearec07111" ref="itemcolumnscrollarearec07111"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitemcolumnscrollarearec071111 '"   id="rectangleitemcolumnscrollarearec071111" ref="rectangleitemcolumnscrollarearec071111"  v-bind:style="{backgroundColor : '#717171',}">  </div>  </div>
 <div  :class="'flow rowcolumnscrollarearec07113 '"   id="rowcolumnscrollarearec07113" ref="rowcolumnscrollarearec07113"  v-bind:style="{}">
 <div  :class="'icon iconrowcolumnscrollarearec071131 '"   id="iconrowcolumnscrollarearec071131" ref="iconrowcolumnscrollarearec071131"  v-bind:style="{fontSize : getUnit(20,'px'),}"><i  :class="'ion-ios-phone-portrait'"></i></div>
 <div  :class="'text slogan4444422222 '"   id="slogan4444422222" ref="slogan4444422222"  v-bind:style="{color : '#9c9c9c',}">
<div class="text-content" :style = "{}">{{ $t('app.smartAvailable') }}</div>

   </div>
 <div  :class="'text slogan4444422222333331111126 '"   id="slogan4444422222333331111126" ref="slogan4444422222333331111126"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('app.getApp') }}</div>

 
 <a  :class="'link linkitemrowcolumnscrollarearec07113711 '"   id="linkitemrowcolumnscrollarearec07113711" ref="linkitemrowcolumnscrollarearec07113711"  v-bind:style="{}">  </a>  </div>  </div>
 <div  :class="'item itemcolumnscrollarearec07123 '"   id="itemcolumnscrollarearec07123" ref="itemcolumnscrollarearec07123"  v-bind:style="{}">
 <div  :class="'row butZone1 '"   id="butZone1" ref="butZone1"  v-bind:style="{justifyContent : 'space-around',}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="create" :class="'button buttonbutZone1 '"   id="buttonbutZone1" ref="buttonbutZone1"  v-bind:style="{}"><span>{{ $t('app.create') }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :rounded = "true" 
 :dark = "true" 
 @click="open" :class="'button buttonbutZone122222 '"   id="buttonbutZone122222" ref="buttonbutZone122222"  v-bind:style="{}"><span>{{ $t('app.open') }}</span>
</v-btn>  </div>  </div>
 <div  :class="'text slogan444442222233333111111010101010 '"   id="slogan444442222233333111111010101010" ref="slogan444442222233333111111010101010"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('app.openPrevSess') }}</div>

   </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AppSplatch',

    components: {},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	props: {
	
		create: {			type: Function,			required: false,			default: () => ()=>{},		},
		open: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AppSplatch{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

.rectangle.rectanglerec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
height:4px!important ; 

background-color:#ffffff ; 


}

.animatedimage.animatedimagerec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:4px ; 

top: 4px ; 
width:58px!important ; 
height:56px!important ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.animatedimage.animatedimagerec05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



right: 4px ; 
bottom: 4px ; 
width:79px!important ; 
height:45px!important ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.scrollarea.scrollarearec07{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

top: 100px ; 
bottom: 100px ; 
width:400px!important ; 

overflow-x:hidden ; 
overflow-y:overlay ; background-color:#ffffff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #838eec;    

      // =======================================Style9
    background-image: -webkit-linear-gradient(90deg,
                                              rgba(255, 255, 255, .4) 25%,
                                              transparent 25%,
                                              transparent 50%,
                                              rgba(255, 255, 255, .4) 50%,
                                              rgba(255, 255, 255, .4) 75%,
                                              transparent 75%,
                                              transparent);
      //-----------------------------------------------
        
      }

	.column.columnscrollarearec071{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:0.25px ; 
	
	top: 0.25px ; 
	width:400px!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:15px ; background-color: transparent ; 

		.item.logo{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:270px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.rectangle.rectanglecolumnscrollarearec071111111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			width:250px!important ; 
			height:250px!important ; 
			
			background-color:#dde0ec ; 
			border-radius:125px ; 

				.image.imagerectanglecolumnscrollarearec0711111113{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
				
				left: calc(50% + 0px) ; 
				
				top: 0px ; 
				width:129px!important ; 
				height:71px!important ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.image.imagerectanglecolumnscrollarearec0711111111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 80px ; 
				
				top: 80px ; 
				right: 80px ; 
				bottom: 80px ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111333333{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				
				top: calc(50% + 60px) ; 
				right: 10px ; 
				width:129px!important ; 
				height:71px!important ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111344444{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				left:-25px ; 
				
				top: calc(50% + 15px) ; 
				width:129px!important ; 
				height:71px!important ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

			
}

		
}

		.text.slogan{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#9c9c9c ; 
		font-size:15px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

		.text.desc{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		color:#9c9c9c ; 
		font-size:11px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

		.item.itemcolumnscrollarearec07111{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.rectangle.rectangleitemcolumnscrollarearec071111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 40px ; 
			
			top: 0px ; 
			right: 40px ; 
			height:1px!important ; 
			
			background-color:#717171 ; 

			
}

		
}

		.flow.rowcolumnscrollarearec07113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;background-color:transparent ; 

			.icon.iconrowcolumnscrollarearec071131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:25px!important ; 
			height:25px!important ; 
			
			font-size:20px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.slogan4444422222{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			color:#9c9c9c ; 
			font-size:11px ; 
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

			.text.slogan4444422222333331111126{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:auto!important ; 
			height:auto!important ; 
			
			font-size:11px ; 
			text-align:center;
			display: flex;
			.text-content{
			width: 100%;
			}

				.link.linkitemrowcolumnscrollarearec07113711{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: block;

				
}

			
}

		
}

		.item.itemcolumnscrollarearec07123{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:37px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.row.butZone1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			right: 0px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			justify-content:space-around ; background-color:transparent ; 

				.button.buttonbutZone1{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:100px!important ; 
				height:37px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonbutZone122222{

				height: auto;
				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:100px!important ; 
				height:37px!important ; 
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.slogan444442222233333111111010101010{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:auto!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		font-size:11px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 








}

.animatedimage.animatedimagerec03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerec05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarearec07{


position:absolute !important; 






	.column.columnscrollarearec071{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.logo{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectanglecolumnscrollarearec071111111{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectanglecolumnscrollarearec0711111113{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec0711111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111333333{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111344444{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

		.text.slogan{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.desc{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemcolumnscrollarearec07111{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumnscrollarearec071111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

		.flow.rowcolumnscrollarearec07113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconrowcolumnscrollarearec071131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.slogan4444422222{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.slogan4444422222333331111126{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.link.linkitemrowcolumnscrollarearec07113711{

				
				position:absolute !important; 
				
				
				
				
				
				display: block;

				
}

			
}

		
}

		.item.itemcolumnscrollarearec07123{

		
		position:relative !important; 
		
		
		
		
		

			.row.butZone1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonbutZone1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonbutZone122222{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.slogan444442222233333111111010101010{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 








}

.animatedimage.animatedimagerec03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerec05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarearec07{


position:absolute !important; 






	.column.columnscrollarearec071{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.logo{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectanglecolumnscrollarearec071111111{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectanglecolumnscrollarearec0711111113{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec0711111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111333333{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111344444{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

		.text.slogan{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.desc{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemcolumnscrollarearec07111{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumnscrollarearec071111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

		.flow.rowcolumnscrollarearec07113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconrowcolumnscrollarearec071131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.slogan4444422222{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.slogan4444422222333331111126{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.link.linkitemrowcolumnscrollarearec07113711{

				
				position:absolute !important; 
				
				
				
				
				
				display: block;

				
}

			
}

		
}

		.item.itemcolumnscrollarearec07123{

		
		position:relative !important; 
		
		
		
		
		

			.row.butZone1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonbutZone1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonbutZone122222{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.slogan444442222233333111111010101010{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 








}

.animatedimage.animatedimagerec03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerec05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarearec07{


position:absolute !important; 






	.column.columnscrollarearec071{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.logo{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectanglecolumnscrollarearec071111111{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectanglecolumnscrollarearec0711111113{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec0711111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111333333{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111344444{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

		.text.slogan{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.desc{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemcolumnscrollarearec07111{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumnscrollarearec071111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

		.flow.rowcolumnscrollarearec07113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconrowcolumnscrollarearec071131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.slogan4444422222{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.slogan4444422222333331111126{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.link.linkitemrowcolumnscrollarearec07113711{

				
				position:absolute !important; 
				
				
				
				
				
				display: block;

				
}

			
}

		
}

		.item.itemcolumnscrollarearec07123{

		
		position:relative !important; 
		
		
		
		
		

			.row.butZone1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonbutZone1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonbutZone122222{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.slogan444442222233333111111010101010{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 








}

.animatedimage.animatedimagerec03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerec05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarearec07{


position:absolute !important; 






	.column.columnscrollarearec071{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.logo{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectanglecolumnscrollarearec071111111{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectanglecolumnscrollarearec0711111113{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec0711111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111333333{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111344444{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

		.text.slogan{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.desc{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemcolumnscrollarearec07111{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumnscrollarearec071111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

		.flow.rowcolumnscrollarearec07113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconrowcolumnscrollarearec071131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.slogan4444422222{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.slogan4444422222333331111126{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.link.linkitemrowcolumnscrollarearec07113711{

				
				position:absolute !important; 
				
				
				
				
				
				display: block;

				
}

			
}

		
}

		.item.itemcolumnscrollarearec07123{

		
		position:relative !important; 
		
		
		
		
		

			.row.butZone1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonbutZone1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonbutZone122222{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.slogan444442222233333111111010101010{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.rectanglerec01{


position:absolute !important; 








}

.animatedimage.animatedimagerec03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerec05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarearec07{


position:absolute !important; 






	.column.columnscrollarearec071{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.logo{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectanglecolumnscrollarearec071111111{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectanglecolumnscrollarearec0711111113{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec0711111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111333333{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.imagerectanglecolumnscrollarearec071111111344444{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

			
}

		
}

		.text.slogan{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.text.desc{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.item.itemcolumnscrollarearec07111{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.rectangleitemcolumnscrollarearec071111{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

		
}

		.flow.rowcolumnscrollarearec07113{

		
		position:relative !important; 
		
		
		
		
		
		
		
		

			.icon.iconrowcolumnscrollarearec071131{

			
			position:relative !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.text.slogan4444422222{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.text.slogan4444422222333331111126{

			
			position:relative !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.link.linkitemrowcolumnscrollarearec07113711{

				
				position:absolute !important; 
				
				
				
				
				
				display: block;

				
}

			
}

		
}

		.item.itemcolumnscrollarearec07123{

		
		position:relative !important; 
		
		
		
		
		

			.row.butZone1{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.button.buttonbutZone1{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

				.button.buttonbutZone122222{

				height: auto;
				
				position:relative !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.text.slogan444442222233333111111010101010{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}
}
</style>
