<template>
 <div>  
<div id="ExtraActionMenu"> 
         
 <div  :class="'rectangle item0 '"   id="item0" ref="item0"  v-bind:style="{backgroundColor : '#f2f2f9',}">
 <div  :class="'rectangle rectangleitem03 '"   id="rectangleitem03" ref="rectangleitem03"  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'row rowrectangleitem032 '"   id="rowrectangleitem032" ref="rowrectangleitem032"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 :dark = "true" 
 @click="hide" :class="'button buttonrectangleitem031 '"   id="buttonrectangleitem031" ref="buttonrectangleitem031"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-arrow-back' !== undefined"  :color = "'#fff'" 
>{{ 'ion-ios-arrow-back' }}
</v-icon></v-btn>
 <div  :class="'text textrowrectangleitem0323 '"   id="textrowrectangleitem0323" ref="textrowrectangleitem0323"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ 'Extra Actions' }}</div>

   </div>  </div>  </div>
 <v-img  :class="'image imageitem05 '"   id="imageitem05" ref="imageitem05"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/LeftZone/fondbg.png')" 
>  </v-img>
 <div  :class="'scrollarea scrollarea0 '"   id="scrollarea0" ref="scrollarea0"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columnscrollarea01 '"   id="columnscrollarea01" ref="columnscrollarea01"  v-bind:style="{}">
 <ObjectTextBubble :class="'object objectbubphrasalSectionData '"   id="objectbubphrasalSectionData" ref="objectbubphrasalSectionData" v-for="(item,index) in extraAction" :key="'objectbubphrasalSectionData' + index"  v-bind:style="{}" :clo = "insertCode" 
 :name = "languages[item][language]" 
 :value = "languages[item][language]" 
>
</ObjectTextBubble>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import ObjectTextBubble from '../../At/Utils/ObjectTextBubble'

import lg from '../../Js/language.json'
export default {
	name: 'ExtraActionMenu',

    components: {ObjectTextBubble,},
	computed: {
	
		language: function(){		
	return this.$i18n.locale

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		languages:  lg		,
		}
	},
	props: {
	
		extraAction: {					required: false,			default: () =>  [],		},
		insertCode: {			type: Function,			required: false,			default: () =>  ({}),		},
		hide: {			type: Function,			required: false,			default: () =>  ({}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ExtraActionMenu{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#f2f2f9 ; 

.rectangle.rectangleitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
height:50px!important ; 

background-color:#ffffff ; 

	.row.rowrectangleitem032{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left: 10px ; 
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:40px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;
	gap:10px ; background-color:transparent ; 

		.button.buttonrectangleitem031{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		width:40px!important ; 
		height:40px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textrowrectangleitem0323{

		align-self: flex-start;
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#fff ; 
		font-size:25px ; 
		.text-content{
		width: 100%;
		font-weight: bold;
		}

		
}

	
}


}

.image.imageitem05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 50px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.4 ;  filter: alpha(opacity=40) ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}
	::v-deep .v-image__image.v-image__image--cover {
    background-size: unset;
    background-repeat: repeat;
}



}

.scrollarea.scrollarea0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 50px ; 
right: 0px ; 
bottom: 0px ; 

overflow:scroll ; 
overflow-x:scroll ; 
overflow-y:scroll ; 
background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #EAAE14;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

	.column.columnscrollarea01{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 10px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.object.objectbubphrasalSectionData{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:220px!important ; 
		height:auto!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.rectangle.item0{


position:absolute !important; 







.rectangle.rectangleitem03{


position:absolute !important; 







	.row.rowrectangleitem032{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrectangleitem031{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textrowrectangleitem0323{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.image.imageitem05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea01{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.objectbubphrasalSectionData{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.item0{


position:absolute !important; 







.rectangle.rectangleitem03{


position:absolute !important; 







	.row.rowrectangleitem032{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrectangleitem031{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textrowrectangleitem0323{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.image.imageitem05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea01{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.objectbubphrasalSectionData{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.item0{


position:absolute !important; 







.rectangle.rectangleitem03{


position:absolute !important; 







	.row.rowrectangleitem032{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrectangleitem031{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textrowrectangleitem0323{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.image.imageitem05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea01{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.objectbubphrasalSectionData{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.item0{


position:absolute !important; 







.rectangle.rectangleitem03{


position:absolute !important; 







	.row.rowrectangleitem032{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrectangleitem031{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textrowrectangleitem0323{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.image.imageitem05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea01{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.objectbubphrasalSectionData{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.item0{


position:absolute !important; 







.rectangle.rectangleitem03{


position:absolute !important; 







	.row.rowrectangleitem032{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.buttonrectangleitem031{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.text.textrowrectangleitem0323{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.image.imageitem05{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.scrollarea.scrollarea0{


position:absolute !important; 






	.column.columnscrollarea01{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.objectbubphrasalSectionData{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
