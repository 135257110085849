import { render, staticRenderFns } from "./ExtraActionMenu.vue?vue&type=template&id=32275caf&scoped=true&"
import script from "./ExtraActionMenu.vue?vue&type=script&lang=js&"
export * from "./ExtraActionMenu.vue?vue&type=script&lang=js&"
import style0 from "./ExtraActionMenu.vue?vue&type=style&index=0&id=32275caf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32275caf",
  null
  
)

export default component.exports