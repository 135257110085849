<template>
  <v-app>
      <Router-view></Router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang = "scss">
  html,body{    
    overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
</style>
