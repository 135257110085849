<template>
 <div>  
<div id="AppEditor"> 
         
 <div  :class="'item rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <div  :class="'item editorZone '"   id="editorZone" ref="editorZone"  v-bind:style="{right : (mqVal({default:0+'px',md:getUnit(editorZoneMargin,'px')}, $mq)),}">
 <div  :class="'rectangle editor '"   id="editor" ref="editor" :_borderTopRightRadius = "'20px'" 
 :_borderBottomRightRadius = "'20px'" 
  v-bind:style="{right : getUnit(editorZoneMarginR,'px'),borderTopRightRadius : '20px',borderBottomRightRadius : '20px',backgroundColor : '#a5a5a5',}">
 <div  :class="'rectangle rectangleeditorZone511111 '"   id="rectangleeditorZone511111" ref="rectangleeditorZone511111"  v-bind:style="{backgroundColor : 'transparent',}">
 <div  :class="'rectangle writingZone '"   id="writingZone" ref="writingZone"  v-bind:style="{backgroundColor : '#f4f4f9',}">
 <v-img  :class="'image imagerectangleeditorZone5111111 '"   id="imagerectangleeditorZone5111111" ref="imagerectangleeditorZone5111111"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/LeftZone/fondbg.png')" 
>  </v-img>
 <v-img  :class="'image topPapirus '"   id="topPapirus" ref="topPapirus"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/editor/bg_header.png')" 
>  </v-img>
 <v-img  :class="'image bottomPapirus '"   id="bottomPapirus" ref="bottomPapirus"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/editor/bg_footer.png')" 
>  </v-img>
 <div  :class="'rectangle codeArea '"   id="codeArea" ref="codeArea"  v-bind:style="{}">
 <SimpleTextEditor :class="'object simpletexteditor '"   id="simpletexteditor" ref="simpletexteditor" v-for="(item,index) in 20" :key="'simpletexteditor' + index" v-if="(currentEditor == index)" @updateCode="setStreamCode($event, index)"   v-bind:style="{}" :value = "m_pjData.code['stage' + (index+1)]" 
 :indexEditor = "index" 
>
</SimpleTextEditor>  </div>
 <div  :class="'column columnrectanglerectangleeditorZone559 '"   id="columnrectanglerectangleeditorZone559" ref="columnrectanglerectangleeditorZone559"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 @click="togleStage" :class="'button togelStage '"   id="togelStage" ref="togelStage"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-quote' !== undefined"  :color = "secondaryColor" 
>{{ 'ion-ios-quote' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 :class="'button zoomInBut '"   id="zoomInBut" ref="zoomInBut"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-add-circle-outline' !== undefined"  :color = "secondaryColor" 
>{{ 'ion-ios-add-circle-outline' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 :class="'button zoomOutBut '"   id="zoomOutBut" ref="zoomOutBut"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-remove-circle-outline' !== undefined"  :color = "secondaryColor" 
>{{ 'ion-ios-remove-circle-outline' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "'#eaeffd'" 
 :fab = "true" 
 :outlined = "false" 
 :rounded = "true" 
 :text = "false" 
 :dark = "true" 
 :class="'button zoomEqualBut '"   id="zoomEqualBut" ref="zoomEqualBut"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-close-circle-outline' !== undefined"  :color = "secondaryColor" 
>{{ 'ion-ios-close-circle-outline' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'rectangle togleStage '"   id="togleStage" ref="togleStage"  v-bind:style="{ display: ((mqVal({default:false,xs:stagesVisible}, $mq))? 'block' : 'none'), backgroundColor : '#000',}">
 <div  :class="'mousearea mouseareatogleStage1 '"   id="mouseareatogleStage1" ref="mouseareatogleStage1"  v-bind:style="{}" @click="togleStage"></div>  </div>
 <div  :class="'scrollarea scrollarearectangleeditorZone53 '"   id="scrollarearectangleeditorZone53" ref="scrollarearectangleeditorZone53" v-show="stagesVisible"  v-bind:style="{}">
 <div  :class="'column columnscrollarearectangleeditorZone531 '"   id="columnscrollarearectangleeditorZone531" ref="columnscrollarearectangleeditorZone531"  v-bind:style="{}">
 <NivItem :class="'object niv '"   id="niv" ref="niv" v-for="(item,index) in 20" :key="'niv' + index"  v-bind:style="{}" :clo = "setCurrentEditor" 
 :lignes = "numberLines[index]" 
 :nivNumber = "(index+1)" 
 :active = "(currentEditor == index)" 
>
</NivItem>  </div>  </div>  </div>
 <div  :class="'rectangle rectangleeditorZone133333 '"   id="rectangleeditorZone133333" ref="rectangleeditorZone133333" :_borderTopRightRadius = "'20px'" 
  v-bind:style="{borderTopRightRadius : '20px',backgroundColor : '#ededed',}">
 <div  :class="'row rowrectangletool1122222 '"   id="rowrectangletool1122222" ref="rowrectangletool1122222"  v-bind:style="{}">
 <v-img  :class="'networkimage projectIcon '"   id="projectIcon" ref="projectIcon"  v-bind:style="{borderColor : primaryColor, backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "pjImage" 
>
 <div  :class="'mousearea mouseareaimagerowrectangletool1111 '"   id="mouseareaimagerowrectangletool1111" ref="mouseareaimagerowrectangletool1111"  v-bind:style="{}" @click="togelDetail"></div>  </v-img>
 <div  :class="'text textrowrectangletool11222223 '"   id="textrowrectangletool11222223" ref="textrowrectangletool11222223"  v-bind:style="{color : '#777682',}">
<div class="text-content" :style = "{}">{{ pjName }}</div>

   </div>  </div>
 <div  :class="'row rowrectangletool1111111 '"   id="rowrectangletool1111111" ref="rowrectangletool1111111"  v-bind:style="{}">
 <Butt :class="'object homeButt '"   id="homeButt" ref="homeButt"  v-bind:style="{}" :clo = "goHome" 
 :ico = "'ion-ios-home'" 
 :toolTip = "'Acceuil'" 
>
</Butt>
 <Butt :class="'object openButt '"   id="openButt" ref="openButt"  v-bind:style="{}" :clo = "openOpenD" 
 :ico = "'ion-ios-folder-open'" 
 :toolTip = "'Ouvrir'" 
>
</Butt>
 <Butt :class="'object saveButt '"   id="saveButt" ref="saveButt"  v-bind:style="{}" :clo = "downloadAPK" 
 :ico = "'ion-ios-cloud-download'" 
 :toolTip = "'Sauvegarder'" 
>
</Butt>
 <Butt :class="'object sendButt '"   id="sendButt" ref="sendButt"  v-bind:style="{}" :clo = "compile" 
 :ico = "'ion-ios-send'" 
 :toolTip = "'Executer'" 
>
</Butt>
 <Butt :class="'object uploadButt '"   id="uploadButt" ref="uploadButt"  v-bind:style="{}" :clo = "onlineSave" 
 :ico = "'ion-ios-cloud-upload'" 
 :toolTip = "'Publier'" 
>
</Butt>  </div>  </div>
 <div  :class="'rectangle rectangleeditorZone12222222222 '"   id="rectangleeditorZone12222222222" ref="rectangleeditorZone12222222222" :_borderBottomRightRadius = "'20px'" 
  v-bind:style="{borderBottomRightRadius : '20px',backgroundColor : '#ededed',}">
 <div  :class="'row rowrectangleeditorZone122222222224 '"   id="rowrectangleeditorZone122222222224" ref="rowrectangleeditorZone122222222224"  v-bind:style="{}">
 <div  :class="'row saveStateBlock '"   id="saveStateBlock" ref="saveStateBlock"  v-bind:style="{backgroundColor : '#cbcdd0',}">
 <div  :class="'text textsaveStateBlock1 '"   id="textsaveStateBlock1" ref="textsaveStateBlock1"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ saveState }}</div>

   </div>  </div>
 <div  :class="'text totalLines '"   id="totalLines" ref="totalLines"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ ('Total: ' + totalLines + ' lignes')  }}</div>

   </div>  </div>
 <div  :class="'row rowrectangletool111111112112 '"   id="rowrectangletool111111112112" ref="rowrectangletool111111112112"  v-bind:style="{}">
 <Butt :class="'object leftButt '"   id="leftButt" ref="leftButt"  v-bind:style="{}" :clo = "m_togelLeftPanel" 
 :ico = "'ion-ios-arrow-dropleft'" 
>
</Butt>
 <Butt :class="'object consoleButt '"   id="consoleButt" ref="consoleButt"  v-bind:style="{}" :clo = "togelPanel" 
 :ico = "'ion-ios-bug'" 
>
</Butt>
 <Butt :class="'object rigthButt '"   id="rigthButt" ref="rigthButt"  v-bind:style="{}" :clo = "togelDesigner" 
 :ico = "'ion-ios-arrow-dropright'" 
>
</Butt>  </div>  </div>
 <div  :class="'rectangle rectangleeditorZone744444 '"   id="rectangleeditorZone744444" ref="rectangleeditorZone744444" v-if="consoleShowed"  v-bind:style="{backgroundColor : '#d5d5d6',}">
 <ConsoleTextArea :class="'object errorZone1 '"   id="errorZone1" ref="errorZone1"  v-bind:style="{}" :log = "alertLog" 
>
</ConsoleTextArea>  </div>  </div>
 <div  :class="'item details '"   id="details" ref="details" v-show="detailShowed"  v-bind:style="{}">
 <PjDetail :class="'object pjdetaildetails1 '"   id="pjdetaildetails1" ref="pjdetaildetails1" :_borderRadius = "'20px'" 
 :_overflow = "'hidden'" 
  v-bind:style="{borderRadius : '20px',overflow : 'hidden',}" :close = "togelDetail" 
>
</PjDetail>  </div>  </div>
 <div  :class="'item rec011111 '"   id="rec011111" ref="rec011111" v-show="designShowed"  v-bind:style="{}">
 <Designer :class="'object designerrec0111111 '"   id="designerrec0111111" ref="designerrec0111111"  v-bind:style="{}" :updateCode = "updateCode" 
 :hideZone = "togelDesigner" 
 :codeBrut = "m_pjData.code['stage' + (currentEditor+1)]" 
>
</Designer>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import SimpleTextEditor from '../../At/Utils/SimpleTextEditor'
import NivItem from '../../At/Utils/NivItem'
import Butt from '../../At/Butt'
import ConsoleTextArea from '../../At/Utils/ConsoleTextArea'
import PjDetail from '../../At/Zone/PjDetail'
import Designer from '../../At/AppContent/Designer'

import axios from 'axios';
import ac from 'artefact-controller'
import JSZip from 'jszip'
import { saveAs } from 'file-saver';

import languages from "../../Js/language.json"
import {compileFunction} from "../../Js/compilerFunction.js"

import * as main from "../../Js/main.js"
import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.min.css'
export default {
	name: 'AppEditor',

    components: {SimpleTextEditor,NivItem,Butt,ConsoleTextArea,PjDetail,Designer,},
	methods: {
	
		m_togelLeftPanel: function() { 		
	this.togelLeftPanel()

	this.refresh()

}
,
		getNumberLines: function(index){		
	return this.m_pjData.code['stage' + (index + 1)].split('\n').length

}
,
		updateResponsive: function(mq){ 		
	if(mq == "xs" || mq == "sm" || mq == "md"){

		this.designShowed = false

		this.editorZoneMarginR = 0

		this.editorZoneMargin = 0

	} else {

		this.designShowed = true

		this.editorZoneMarginR = 0

		this.editorZoneMargin = 510

	}

	

	if(mq == "xs"){

		this.stagesVisible = false

	} else {

		this.stagesVisible = true

	}

}
,
		togelPanel: function(){		
	this.consoleShowed = !this.consoleShowed;

	this.refresh()

}
,
		togelDesigner: function(){		
	this.designShowed = !this.designShowed

	

	if(this.designShowed){

		this.editorZoneMargin = 510

	} else {

		this.editorZoneMargin = 0

	}

	this.refresh()

}
,
		togelDetail: function(){		
	this.detailShowed = !this.detailShowed

	if(this.detailShowed){

		this.editorZoneMarginR = 419

	} else {

		this.editorZoneMarginR = 0

	}

	

	if(this.detailShowed && this.designShowed){

		this.togelDesigner()

	}

}
,
		togleStage: function(){		
	this.stagesVisible = !this.stagesVisible

}
,
		goHome: function(){		
	this.$router.push('/');

}
,
		showConsole: function(msg){		
}
,
		setCurrentEditor: function(id){		
	this.currentEditor = id;

	this.currentCode = this.m_pjData.code['stage' + (id+1)]

	

	this.$store.commit('setCurrentEditor', id)

}
,
		compile: function(){		
	this.consoleShowed = false

		

	let res = compileFunction(this.m_pjData, this.language, this.currentEditor+1)

	this.compileRes = res

	

	if(res.console.alerted){

		this.consoleShowed = true

	} else {

		this.$emit('compilefinish', res.final)

	}

}
,
		insertCode: function(data){		
	this.$refs['simpletexteditor'][0].insertCode(data, this.currentEditor);

}
,
		undo: function(){		
	this.$refs['simpletexteditor'][0].undo(this.currentEditor);

}
,
		redo: function(){		
	this.$refs['simpletexteditor'][0].redo(this.currentEditor);

}
,
		copy: function(){		
	this.$refs['simpletexteditor'][0].copy(this.currentEditor);

}
,
		cut: function(){		
	this.$refs['simpletexteditor'][0].cut(this.currentEditor);

}
,
		paste: function(){		
	this.$refs['simpletexteditor'][0].paste(this.currentEditor);

}
,
		updateCode: function(e) { 		
	this.$refs['simpletexteditor'][0].updateCode(e);

	this.$refs['simpletexteditor'][0].setCode(e);

	this.currentCode = e;

}
,
		refresh: function(){		
	this.$refs['simpletexteditor'][0].refresh(this.currentEditor);

}
,
		setStreamCode: function(event, index){		
	let tmp = JSON.parse(JSON.stringify(this.m_pjData))

	tmp.code['stage' + (index+1)] = event

	this.$store.commit('pjDatas', tmp)

	this.$store.commit('setSaved', false)

	

	this.numberLines[index] = this.getNumberLines(index)

}
,
		onlineSave: function(){		
	// Check if the project was not already saved

	if(this.m_project.id == null){

  	main.uploadFile(this.iconBlob)

		.then((data) => {

			this.onlineSaveNext(data.data[0])

    })

    .catch((error) => {

	  	console.log(error);

	 });

	} else {

		main.updateProject(this.m_pjData)

  	.then((response) => {

	    this.$store.dispatch('setProject', response.data);

			this.$store.commit('setSaved', true)

		

			this.notify({

    		status: 'success',

		    title: this.$t('app.saved'),

		    text: this.$t('app.savedProject')

		  })

	  })

	  .catch((error) => {

	    console.log(error);

	  });

	}	

}
,
		onlineSaveNext: function(imgData){		
  var dt = {

		description_image: imgData.id,

  	name: this.m_project.name,

	  user: this.$store.state.user.id,

		pj_setting: this.m_pjData,

	};



  var config = {

    url: this.$store.state.apiURL + '/projects',

   	headers: {

      Authorization: `Bearer ${this.$store.state.token}`,

    },

		data: dt

  };



	if(this.m_project.id){

		config.url = config.url + `/${this.$store.state.project.id}`

		config.method = "put"

	} else {

		config.method = "post"

	}

	

  axios(config)

  .then((response) => {

    this.$store.dispatch('setProject', response.data);

		this.$store.commit('setSaved', true)

		

		this.notify({

   		status: 'success',

	    title: this.$t('app.saved'),

	    text: this.$t('app.savedProject')

	  })

  })

  .catch((error) => {

    console.log(error);

  });

}
,
		download: function(){		
  var data = JSON.stringify(this.m_pjData);

	

	// create a file

	this.zip.file("projet.abc", data);

	// create asset folder

	let asset = this.zip.folder("asset");

	// save icon

	if(this.m_project.description_image){

		fetch(this.$store.state.apiURL + this.m_project.description_image.url)

		.then(res => res.blob())

		.then(blob => {

			asset.file("icon.png", blob, {base64: true});

			this.downloadImportedFiles()

			this.downloadNext()

		})

	} else {

		asset.file("icon.png", this.$store.state.iconBlob, {base64: true});

		this.downloadImportedFiles()

		this.downloadNext()

	}

}
,
		downloadImportedFiles: function(asset){		
}
,
		downloadNext: function(){		
	//Save

	this.zip.generateAsync({type:"blob"})

	.then((content)=>{

	    // see FileSaver.js

	    saveAs(content, this.m_pjData.nom + ".zip");

	});

}
,
		notify: function(config){ 		
	let def = {

    effect: 'fade',

    speed: 300,

    showIcon: true,

    showCloseButton: true,

    autoclose: true,

    autotimeout: 3000,

    gap: 20,

    distance: 20,

    type: 3,

    position: 'x-center bottom'

  }

	new Notify({...def, ...config})

}
,
		downloadAPK: function(){		
	let def = {

		status: 'warning',

    effect: 'fade',

    showIcon: true,

    showCloseButton: false,

    autoclose: false,

    gap: 20,

    distance: 20,

    type: 3,

    position: 'x-center bottom',

		title: 'APK (Android)',

   	text: this.$t('app.creatingAPK'),

  }

	

	this.apkNotify = new Notify(def)

	

	main.compileAPK()

	.then(res => {

		saveAs(res.data, this.m_pjData.nom + ".apk");

		this.apkNotify.close()

	})

}
,
		getTotalLineNumbers: function() { 		
	for(let i=0; i<20; i++){

		this.numberLines[i] = this.getNumberLines(i)

	}

}
,
	},
	mounted(){
			
	this.updateResponsive(this.$mq)

	this.setCurrentEditor(this.$store.state.currentEditor)

	},
	created(){
			
	this.getTotalLineNumbers()

	},
	computed: {
	
		language: function(){		
	return this.$store.state.language

}
,
		vp: function() { 		
	return this.$mq;

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
		m_project: function(){		
	return this.$store.state.project

}
,
		m_pjData: function(){		
	return this.$store.state.pjDatas

}
,
		iconPath: function(){		
	return this.$store.state.iconPath

}
,
		iconBlob: function(){		
	return this.$store.state.iconBlob

}
,
		pjImage: function(){			
	if(this.m_project && (this.m_project.description_image)){

		if(this.m_project.description_image.formats && this.m_project.description_image.formats.small){

			return this.$store.state.apiURL + this.m_project.description_image.formats.small.url

		} else {

			return this.$store.state.apiURL + this.m_project.description_image.url

		}

	} else {

		if(this.iconPath == "" || this.iconPath == undefined){

			return "https://picsum.photos/200"

		} else {

				return this.iconPath

		}

	}

}
,
		pjName: function(){		
	if(this.m_project && this.m_project.name){

		return this.m_project.name

	} else {

		return "Aucun nom"

	}

}
,
		totalLines: function(){		
	let total = 0

	for(let i=1; i<21; i++){

		total += this.m_pjData.code['stage' + i].split('\n').length

	}

	return total

}
,
		alertLog: function(){ 		
	return this.compileRes.console.alert

}
,
		checkLog: function(){ 		
	return this.compileRes.console.check

}
,
		saveState: function() { 		
	if(this.$store.state.saved){

		return this.$t('app.saved')

	} else {

		return this.$t('app.notSaved')

	}

}
,
	},
	watch: {
	
		vp: function(newValue, oldValue) {		
	this.updateResponsive(newValue)

}
,
		m_pjData: function(val) { 		
	this.getTotalLineNumbers()

}
,
	},
	data(){
	return {
		
		atController:  new ac()		,
		zip:  new JSZip()		,
		numberLines:  []		,
		apkNotify:  null		,
		consoleShowed:  false		,
		detailShowed:  false		,
		currentCode:  ""		,
		stagesVisible:  true		,
		compileRes:  {final: "", console: {check: "", alert: ""}}		,
		designShowed:  true		,
		editorZoneMargin:  510		,
		editorZoneMarginR:  0		,
		conso:  {check: "", alert: ""}		,
		currentEditor:  0		,
		}
	},
	props: {
	
		openOpenD: {			type: Function,			required: false,			default: () => ()=>{},		},
		togelLeftPanel: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AppEditor{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.item.editorZone{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


	.rectangle.editor{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	bottom: 0px ; 
	borderTopRightRadius:20px !important; 
	borderBottomRightRadius:20px !important; 
	
	background-color:#a5a5a5 ; 

		.rectangle.rectangleeditorZone511111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 1px ; 
		
		top: 59px ; 
		right: 0px ; 
		bottom: 59px ; 
		
		background-color:transparent ; 

			.rectangle.writingZone{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 131px ; 
			bottom: 0px ; 
			
			background-color:#f4f4f9 ; 

				.image.imagerectangleeditorZone5111111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				opacity: 0.1 ;  filter: alpha(opacity=10) ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.image.topPapirus{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				display:none !important; 
				height:100px!important ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.image.bottomPapirus{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				right: 0px ; 
				bottom: 0px ; 
				display:none !important; 
				height:100px!important ; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.rectangle.codeArea{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 28px ; 
				
				top: 0px ; 
				right: 28px ; 
				bottom: 0px ; 
				
				background-color:#ffffff ; 

					.object.simpletexteditor{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					z-index:0 ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnrectanglerectangleeditorZone559{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: 20px ; 
				bottom: 20px ; 
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;
				gap:15px ; background-color: transparent ; 

					.button.togelStage{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					opacity: 0.6 ;  filter: alpha(opacity=60) ; 
					display:none !important; 
					width:45px!important ; 
					height:45px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomInBut{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					opacity: 0.6 ;  filter: alpha(opacity=60) ; 
					width:45px!important ; 
					height:45px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomOutBut{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					opacity: 0.6 ;  filter: alpha(opacity=60) ; 
					width:45px!important ; 
					height:45px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomEqualBut{

					height: auto;
					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					opacity: 0.6 ;  filter: alpha(opacity=60) ; 
					width:45px!important ; 
					height:45px!important ; 
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.rectangle.togleStage{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			opacity: 0.7 ;  filter: alpha(opacity=70) ; 
			
			background-color:#000 ; 

				.mousearea.mouseareatogleStage1{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				cursor: pointer;
				}

			
}

			.scrollarea.scrollarearectangleeditorZone53{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			width:130px!important ; 
			
			overflow-x:hidden ; 
			overflow-y:overlay ; background-color:#ffffff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #CD3A19;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

				.column.columnscrollarearectangleeditorZone531{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left:0.25px ; 
				
				top: 0.25px ; 
				width:130px!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.object.niv{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:70px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone133333{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 1px ; 
		
		top: 0.25px ; 
		right: 0px ; 
		height:58px!important ; 
		borderTopRightRadius:20px !important; 
		
		background-color:#ededed ; 

			.row.rowrectangletool1122222{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:10px ; 
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:10px ; background-color:transparent ; 

				.networkimage.projectIcon{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:46px!important ; 
				height:46px!important ; 
				
				display: inherit;

				flex: unset;
				border-radius:25px ; 
				border-style: solid ; 
				border-width:1px ; 
				border-color:undefined ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

					.mousearea.mouseareaimagerowrectangletool1111{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					left: 0px ; 
					
					top: 0px ; 
					right: 0px ; 
					bottom: 0px ; 
					
					cursor: pointer;
					}

				
}

				.text.textrowrectangletool11222223{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:46px!important ; 
				
				color:#777682 ; 
				align-items:center;
				display: flex;
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				}

				
}

			
}

			.row.rowrectangletool1111111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			right: 10px ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;background-color:transparent ; 

				.object.homeButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.openButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.saveButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.sendButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.uploadButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone12222222222{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 1px ; 
		
		right: 0px ; 
		bottom: 0px ; 
		height:58px!important ; 
		borderBottomRightRadius:20px !important; 
		
		background-color:#ededed ; 

			.row.rowrectangleeditorZone122222222224{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			right: 10px ; 
			width:auto!important ; 
			height:30px!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:5px ; background-color:transparent ; 

				.row.saveStateBlock{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				opacity: 0.7 ;  filter: alpha(opacity=70) ; 
				width:auto!important ; 
				height:auto!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				background-color:#cbcdd0 ; 
				border-radius:8px ; 
				border-style:solid ; 
				border-width:1px ; 
				border-color:#a9a9a9 ; 
				padding-top:3px ; 
				padding-bottom:3px ; 
				padding-left:3px ; 
				padding-right:3px ; 

					.text.textsaveStateBlock1{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:12px ; 
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.totalLines{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				
				top: calc(50% + 0px) ; 
				width:auto!important ; 
				height:auto!important ; 
				
				font-size:12px ; 
				text-align:right;
				display: flex;
				text-overflow: ellipsis ; 
				.text-content{
				width: 100%;
				white-space: nowrap;
				overflow: hidden ;
				text-overflow: ellipsis ; 
				}

				
}

			
}

			.row.rowrectangletool111111112112{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: row;
			gap:5px ; background-color:transparent ; 

				.object.leftButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.consoleButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.rigthButt{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:35px!important ; 
				height:46px!important ; 
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone744444{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		right: 0px ; 
		bottom: 59px ; 
		height:320px!important ; 
		
		background-color:#d5d5d6 ; 

			.object.errorZone1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.item.details{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	width:418px!important ; 
	

		.object.pjdetaildetails1{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 10px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		borderRadius:20px !important; 
		overflow:hidden !important; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.item.rec011111{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 0px ; 
right: 0px ; 
bottom: 0px ; 
width:100%!important ; 


	.object.designerrec0111111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.item.rec0{


position:absolute !important; 






.item.editorZone{


position:absolute !important; 






	.rectangle.editor{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectangleeditorZone511111{

		
		position:absolute !important; 
		
		
		
		
		
		

			.rectangle.writingZone{

			
			position:absolute !important; 
			
			
			
			
			right: 0px ; 
			
			

				.image.imagerectangleeditorZone5111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.topPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.bottomPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.codeArea{

				
				position:absolute !important; 
				
				
				
				
				
				

					.object.simpletexteditor{

					
					position:absolute !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnrectanglerectangleeditorZone559{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.button.togelStage{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					 display:flex !important; 
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomInBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomOutBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomEqualBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.rectangle.togleStage{

			
			position:absolute !important; 
			
			
			
			
			
			

				.mousearea.mouseareatogleStage1{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.scrollarea.scrollarearectangleeditorZone53{

			
			position:absolute !important; 
			
			
			
			
			

				.column.columnscrollarearectangleeditorZone531{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.object.niv{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone133333{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangletool1122222{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.networkimage.projectIcon{

				
				position:relative !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					.mousearea.mouseareaimagerowrectangletool1111{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowrectangletool11222223{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool1111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.openButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.saveButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.sendButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.uploadButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone12222222222{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangleeditorZone122222222224{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.row.saveStateBlock{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textsaveStateBlock1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.totalLines{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool111111112112{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.leftButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.consoleButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.rigthButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone744444{

		
		position:absolute !important; 
		
		
		
		
		
		

			.object.errorZone1{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.item.details{

	
	position:absolute !important; 
	
	
	
	
	

		.object.pjdetaildetails1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.item.rec011111{


position:absolute !important; 






	.object.designerrec0111111{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.item.rec0{


position:absolute !important; 






.item.editorZone{


position:absolute !important; 






	.rectangle.editor{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectangleeditorZone511111{

		
		position:absolute !important; 
		
		
		
		
		
		

			.rectangle.writingZone{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectangleeditorZone5111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.topPapirus{

				
				position:absolute !important; 
				
				
				
				
				 display:block !important; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.bottomPapirus{

				
				position:absolute !important; 
				
				
				
				
				 display:block !important; 
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.codeArea{

				
				position:absolute !important; 
				
				
				
				
				top: 100px ; 
				bottom: 100px ; 
				
				

					.object.simpletexteditor{

					
					position:absolute !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnrectanglerectangleeditorZone559{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.button.togelStage{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomInBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomOutBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomEqualBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.rectangle.togleStage{

			
			position:absolute !important; 
			
			
			
			
			
			

				.mousearea.mouseareatogleStage1{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.scrollarea.scrollarearectangleeditorZone53{

			
			position:absolute !important; 
			
			
			
			
			

				.column.columnscrollarearectangleeditorZone531{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.object.niv{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone133333{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangletool1122222{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.networkimage.projectIcon{

				
				position:relative !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					.mousearea.mouseareaimagerowrectangletool1111{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowrectangletool11222223{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool1111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.openButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.saveButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.sendButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.uploadButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone12222222222{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangleeditorZone122222222224{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.row.saveStateBlock{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textsaveStateBlock1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.totalLines{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool111111112112{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.leftButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.consoleButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.rigthButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone744444{

		
		position:absolute !important; 
		
		
		
		
		
		

			.object.errorZone1{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.item.details{

	
	position:absolute !important; 
	
	
	
	
	

		.object.pjdetaildetails1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.item.rec011111{


position:absolute !important; 






	.object.designerrec0111111{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.item.rec0{


position:absolute !important; 






.item.editorZone{


position:absolute !important; 






	.rectangle.editor{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectangleeditorZone511111{

		
		position:absolute !important; 
		
		
		
		
		
		

			.rectangle.writingZone{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectangleeditorZone5111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.topPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.bottomPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.codeArea{

				
				position:absolute !important; 
				
				
				
				
				
				

					.object.simpletexteditor{

					
					position:absolute !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnrectanglerectangleeditorZone559{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.button.togelStage{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomInBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomOutBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomEqualBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.rectangle.togleStage{

			
			position:absolute !important; 
			
			
			
			
			
			

				.mousearea.mouseareatogleStage1{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.scrollarea.scrollarearectangleeditorZone53{

			
			position:absolute !important; 
			
			
			
			
			

				.column.columnscrollarearectangleeditorZone531{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.object.niv{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone133333{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangletool1122222{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.networkimage.projectIcon{

				
				position:relative !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					.mousearea.mouseareaimagerowrectangletool1111{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowrectangletool11222223{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool1111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.openButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.saveButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.sendButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.uploadButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone12222222222{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangleeditorZone122222222224{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.row.saveStateBlock{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textsaveStateBlock1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.totalLines{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool111111112112{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.leftButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.consoleButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.rigthButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone744444{

		
		position:absolute !important; 
		
		
		
		
		
		

			.object.errorZone1{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.item.details{

	
	position:absolute !important; 
	
	
	
	
	

		.object.pjdetaildetails1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.item.rec011111{


position:absolute !important; 




width:500px!important ; 


	.object.designerrec0111111{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.item.rec0{


position:absolute !important; 






.item.editorZone{


position:absolute !important; 






	.rectangle.editor{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectangleeditorZone511111{

		
		position:absolute !important; 
		
		
		
		
		
		

			.rectangle.writingZone{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectangleeditorZone5111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.topPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.bottomPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.codeArea{

				
				position:absolute !important; 
				
				
				
				
				
				

					.object.simpletexteditor{

					
					position:absolute !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnrectanglerectangleeditorZone559{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.button.togelStage{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomInBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomOutBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomEqualBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.rectangle.togleStage{

			
			position:absolute !important; 
			
			
			
			
			
			

				.mousearea.mouseareatogleStage1{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.scrollarea.scrollarearectangleeditorZone53{

			
			position:absolute !important; 
			
			
			
			
			

				.column.columnscrollarearectangleeditorZone531{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.object.niv{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone133333{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangletool1122222{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.networkimage.projectIcon{

				
				position:relative !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					.mousearea.mouseareaimagerowrectangletool1111{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowrectangletool11222223{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool1111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.openButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.saveButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.sendButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.uploadButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone12222222222{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangleeditorZone122222222224{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.row.saveStateBlock{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textsaveStateBlock1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.totalLines{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool111111112112{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.leftButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.consoleButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.rigthButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone744444{

		
		position:absolute !important; 
		
		
		
		
		
		

			.object.errorZone1{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.item.details{

	
	position:absolute !important; 
	
	
	
	
	

		.object.pjdetaildetails1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.item.rec011111{


position:absolute !important; 






	.object.designerrec0111111{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.item.rec0{


position:absolute !important; 






.item.editorZone{


position:absolute !important; 






	.rectangle.editor{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectangleeditorZone511111{

		
		position:absolute !important; 
		
		
		
		
		
		

			.rectangle.writingZone{

			
			position:absolute !important; 
			
			
			
			
			
			

				.image.imagerectangleeditorZone5111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.topPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.image.bottomPapirus{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.rectangle.codeArea{

				
				position:absolute !important; 
				
				
				
				
				
				

					.object.simpletexteditor{

					
					position:absolute !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

				.column.columnrectanglerectangleeditorZone559{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.button.togelStage{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomInBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomOutBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

					.button.zoomEqualBut{

					height: auto;
					
					position:relative !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.rectangle.togleStage{

			
			position:absolute !important; 
			
			
			
			
			
			

				.mousearea.mouseareatogleStage1{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

			.scrollarea.scrollarearectangleeditorZone53{

			
			position:absolute !important; 
			
			
			
			
			

				.column.columnscrollarearectangleeditorZone531{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.object.niv{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone133333{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangletool1122222{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.networkimage.projectIcon{

				
				position:relative !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					.mousearea.mouseareaimagerowrectangletool1111{

					
					position:absolute !important; 
					
					
					
					
					
					}

				
}

				.text.textrowrectangletool11222223{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool1111111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.homeButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.openButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.saveButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.sendButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.uploadButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone12222222222{

		
		position:absolute !important; 
		
		
		
		
		
		

			.row.rowrectangleeditorZone122222222224{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.row.saveStateBlock{

				
				position:relative !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.text.textsaveStateBlock1{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

				.text.totalLines{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

			
}

			.row.rowrectangletool111111112112{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;

				.object.leftButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.consoleButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.rigthButt{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.rectangle.rectangleeditorZone744444{

		
		position:absolute !important; 
		
		
		
		
		
		

			.object.errorZone1{

			
			position:absolute !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.item.details{

	
	position:absolute !important; 
	
	
	
	
	

		.object.pjdetaildetails1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.item.rec011111{


position:absolute !important; 






	.object.designerrec0111111{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
