<template>
 <div>  
<div id="OfflineCheck"> 
         
 <div  :class="'item NaN '"   id="NaN" ref="NaN"  v-bind:style="{}">
 <div  :class="'rectangle rectangleNaN1 '"   id="rectangleNaN1" ref="rectangleNaN1"  v-bind:style="{backgroundColor : '#000000',}">  </div>
 <div  :class="'text textNaN5 '"   id="textNaN5" ref="textNaN5"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ 'Travailler en mode Offline?' }}</div>

   </div>
 <div  :class="'row rowNaN5 '"   id="rowNaN5" ref="rowNaN5"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "'#4ff7b1'" 
 :rounded = "true" 
 :dark = "true" 
 @click="setOffline" :class="'button buttonrowNaN51 '"   id="buttonrowNaN51" ref="buttonrowNaN51"  v-bind:style="{}"><span>{{ 'Oui' }}</span>
</v-btn>
 <v-btn tag="div" 
 :color = "'#f7569c'" 
 :rounded = "true" 
 :dark = "true" 
 @click="disableOffline" :class="'button buttonrowNaN5122222 '"   id="buttonrowNaN5122222" ref="buttonrowNaN5122222"  v-bind:style="{}"><span>{{ 'Non' }}</span>
</v-btn>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'OfflineCheck',

    components: {},
	methods: {
	
		setOffline: function(){		
	this.$store.commit('setOffline', true)

	this.close()

}
,
		disableOffline: function() { 		
	this.$store.commit('setOffline', true)

	this.close()

}
,
	},
	mounted(){
			
	this.offline = this.$store.offline;

	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OfflineCheck{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.NaN{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleNaN1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.6 ;  filter: alpha(opacity=60) ; 

background-color:#000000 ; 


}

.text.textNaN5{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;
 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);

left: calc(50% + 0px) ; 

top: 20px ; 
width:auto!important ; 
height:auto!important ; 

color:#fff ; 
font-family:Brush Script MT ; 
font-size:60px ; 
.text-content{
width: 100%;
}


}

.row.rowNaN5{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:291px!important ; 
height:auto!important ; 

display: flex;
flex-wrap: nowrap;
flex: initial;
flex-direction: row;background-color:transparent ; 

	.button.buttonrowNaN51{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:100px!important ; 
	height:50px!important ; 
	
	font-size:25px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:25px ; 
	}
	}

	.button.buttonrowNaN5122222{

	height: auto;
	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	width:100px!important ; 
	height:50px!important ; 
	
	font-size:25px ; 
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	font-size:25px ; 
	}
	}


}


}


@media (max-width: 600px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 








}

.text.textNaN5{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.row.rowNaN5{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowNaN51{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowNaN5122222{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 601px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 








}

.text.textNaN5{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.row.rowNaN5{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowNaN51{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowNaN5122222{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 961px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 








}

.text.textNaN5{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.row.rowNaN5{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowNaN51{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowNaN5122222{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1265px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 








}

.text.textNaN5{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.row.rowNaN5{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowNaN51{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowNaN5122222{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}

@media (min-width: 1905px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 








}

.text.textNaN5{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.row.rowNaN5{


position:absolute !important; 





display: flex;
flex-wrap: nowrap;
flex: initial;

	.button.buttonrowNaN51{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}

	.button.buttonrowNaN5122222{

	height: auto;
	
	position:relative !important; 
	
	
	
	
	
	text-transform:unset ; 
	min-width: auto;
	i.v-icon{
	}
	}


}


}
}
}
</style>
