<template>
 <div>  
<div id="LoadingScreen"> 
         
 <div  :class="'rectangle rectangle0 '"   id="rectangle0" ref="rectangle0"  v-bind:style="{}">
 <v-img  :class="'image imagerectangle03 '"   id="imagerectangle03" ref="imagerectangle03"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/App/LeftZone/fondbg.png')" 
>  </v-img>
 <v-img  :class="'animatedimage animatedimagerectangle01 '"   id="animatedimagerectangle01" ref="animatedimagerectangle01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../Resources/SplashScreen/loader.gif')" 
>  </v-img>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'LoadingScreen',

    components: {},
	methods: {
	
		increment: function() {		
   this.count++

}
,
	},
	data(){
	return {
		
		count:  1		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#LoadingScreen{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rectangle0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

.image.imagerectangle03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.2 ;  filter: alpha(opacity=20) ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}
	::v-deep .v-image__image.v-image__image--cover {
    background-size: unset;
    background-repeat: repeat;
}



}

.animatedimage.animatedimagerectangle01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:100px!important ; 
height:100px!important ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}


}


@media (max-width: 600px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}


}
}

@media (min-width: 601px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}


}
}

@media (min-width: 961px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}


}
}

@media (min-width: 1265px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}


}
}

@media (min-width: 1905px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle03{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.animatedimage.animatedimagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}


}
}
}
</style>
