<template>
 <div>  
<div id="LanguageItem"> 
         
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0"  v-bind:style="{}">
 <div  :class="'rectangle rectanglecard01 '"   id="rectanglecard01" ref="rectanglecard01"  v-bind:style="{backgroundColor : color,}">
 <v-img  :class="'image imagerectanglecard011 '"   id="imagerectanglecard011" ref="imagerectanglecard011"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + icon + '')" 
>  </v-img>  </div>
 <div  :class="'text textcard03 '"   id="textcard03" ref="textcard03"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ value }}</div>

   </div>
 <div  :class="'mousearea mouseareacard05 '"   id="mouseareacard05" ref="mouseareacard05"  v-bind:style="{}" @click="choose"></div>  </v-card>
 
      </div>
 </div> 
</template>
<script>

import langMap from '../../Js/languageMap.json'
export default {
	name: 'LanguageItem',

    components: {},
	methods: {
	
		choose: function() { 		
	this.$store.commit('changeLang', this.langKey)

}
,
	},
	computed: {
	
		color: function() { 		
	if(this.active){

		return this.$store.state.primaryColor

	}

	return "#3bb1f9"

}
,
		value: function() { 		
	return langMap[this.langKey.toUpperCase()]

}
,
		icon: function() { 		
	return "Resources/Languages/" + this.langKey + ".png"

}
,
		active: function() { 		
	return (this.langKey == this.$i18n.locale)

}
,
	},
	props: {
	
		langKey: {					required: false,			default: () =>  "fr",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#LanguageItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:8px ; max-width: initial;min-width: initial;

.rectangle.rectanglecard01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left:0.25px ; 

top: 0px ; 
bottom: 0px ; 
width:50px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 

	.image.imagerectanglecard011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
	
	left: calc(50% + 0px) ; 
	
	top: calc(50% + 0px) ; 
	width:31.4375px!important ; 
	height:36.06640625px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}


}

.text.textcard03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 55px ; 

top: 5px ; 
right: 10px ; 
bottom: 5px ; 

font-size:18px ; 
text-align:right;
align-items:center;
display: flex;
.text-content{
width: 100%;
}


}

.mousearea.mouseareacard05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.rectangle.rectanglecard01{


position:absolute !important; 







	.image.imagerectanglecard011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textcard03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareacard05{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.rectangle.rectanglecard01{


position:absolute !important; 







	.image.imagerectanglecard011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textcard03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareacard05{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.rectangle.rectanglecard01{


position:absolute !important; 







	.image.imagerectanglecard011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textcard03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareacard05{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.rectangle.rectanglecard01{


position:absolute !important; 







	.image.imagerectanglecard011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textcard03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareacard05{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

.rectangle.rectanglecard01{


position:absolute !important; 







	.image.imagerectanglecard011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textcard03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareacard05{


position:absolute !important; 





}


}
}
}
</style>
