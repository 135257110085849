/* Example definition of a simple mode that understands a subset of
 * JavaScript:
 */
import keyWord from "./keyword.json" 
import CodeMirror from "codemirror/lib/codemirror.js"
import store from "../../store/index";
import RandExp from 'randexp';

import "codemirror/addon/mode/simple.js";

let cLang = store.state.language
let fonction = new RegExp( "(" + keyWord[cLang].fonction.join("|") + ")" )
let animations = new RegExp( "(" + keyWord[cLang].animations.join("|") + ")" )
let structureMin = new RegExp( "(" + keyWord[cLang].structureMin.join("|") + ")" )
let motCle = new RegExp( "(" + keyWord[cLang].motCle.join("|") + ")" )
let actions = new RegExp( "(" + keyWord[cLang].actions.join("|") + ")" )
let proprite = new RegExp( "(" + keyWord[cLang].proprite.join("|") + ")" )
let object = new RegExp( "(" + keyWord[cLang].object.join("|") + ")" )

let error = new RegExp( "(" + keyWord[cLang].error.join("|") + ")" )

let dictionary = keyWord[cLang].fonction
									.concat(keyWord[cLang].structureMin)
									.concat(keyWord[cLang].fonction)
									.concat(keyWord[cLang].animations)
									.concat(keyWord[cLang].motCle)
									.concat(keyWord[cLang].actions)
									.concat(keyWord[cLang].proprite)
									.concat(keyWord[cLang].object)

const start = [
    {regex: fonction, token: "abc-fonction", indent: true},
    {regex: animations, token: "abc-animations"},
    {regex: motCle, token: "abc-motCle"},
    {regex: actions, token: "abc-actions"},
    {regex: proprite, token: "abc-proprite"},
    {regex: structureMin, token: "abc-structureMin", indent: true},
    {regex: object, token: "abc-object"},
    //js zone
    {regex: /\(.*\)/, token: "abc-jszone"},


    // The regex matches the token, the token property contains the type
    {regex: /"(?:[^\\]|\\.)*?(?:"|$)/, token: "string"},
    // You can match multiple tokens at once. Note that the captured
    // groups must span the whole string in this case
    {regex: /(function)(\s+)([a-z$][\w$]*)/, token: ["keyword", null, "variable-2"]},
    // Rules are matched in the order in which they appear, so there is
    // no ambiguity between this one and the one above
    //{regex: /(?:function|var|return|if|for|while|else|do|this)\b/,token: "keyword"},
    //{regex: /true|false|null|undefined/, token: "atom"},
    {regex: /0x[a-f\d]+|[-+]?(?:\.\d+|\d+\.?\d*)(?:e[-+]?\d+)?/i, token: "number"},
    {regex: /#.*/, token: "abc-comment"},
    {regex: /\/(?:[^\\]|\\.)*?\//, token: "variable-3"},
    // A next property will cause the mode to move to a different state
    {regex: /\/\*/, token: "abc-comment", next: "comment"},
    {regex: /[-+\/*=<>!]+/, token: "operator"},
    // indent and dedent properties guide autoindentation
    {regex: /[\{\[\(]/, indent: true},
    {regex: /[\}\]\)]/, dedent: true},
    {regex: /[a-z$][\w$]*/, token: "variable"},
    // You can embed other modes with the mode property. This rule
    // causes all code between << and >> to be highlighted with the XML
    // mode.
    //{regex: /<</, token: "meta", mode: {spec: "xml", end: />>/}}
  ]
	
const comment = [
    {regex: /.*?\*\//, token: "abc-comment", next: "start"},
    {regex: /.*/, token: "abc-comment"}
  ]
	
const meta = {
    dontIndentStates: ["comment"],
    lineComment: "#",
		fold: "indent"
  }

CodeMirror.defineSimpleMode("abccode", {
  // The start state contains the rules that are intially used
  start: start,
  // The multi-line comment state.
  comment: comment,
  // The meta property contains global information about the mode. It
  // can contain properties like lineComment, which are supported by
  // all modes, and also directives like dontIndentStates, which are
  // specific to simple modes.
  meta: meta
});


CodeMirror.defineSimpleMode("abccode-console", {
  // The start state contains the rules that are intially used
  start: start.concat([
		{regex: error, token: "abc-error"},
	]),
  // The multi-line comment state.
  comment: comment,
  // The meta property contains global information about the mode. It
  // can contain properties like lineComment, which are supported by
  // all modes, and also directives like dontIndentStates, which are
  // specific to simple modes.
  meta: meta
});

CodeMirror.commands.autocomplete = function(cm) {
  CodeMirror.showHint(cm, CodeMirror.hint.abccodeHint);
};

// Register our custom Codemirror hint plugin.
CodeMirror.registerHelper('hint', 'abccodeHint', function(editor) {
    var cur = editor.getCursor();
    var curLine = editor.getLine(cur.line);
    var start = cur.ch;
    var end = start;
    while (end < curLine.length && /[\w$]/.test(curLine.charAt(end))) ++end;
    while (start && /[\w$]/.test(curLine.charAt(start - 1))) --start;
    var curWord = start !== end && curLine.slice(start, end);
    var regex = new RegExp('^' + curWord, 'i');
    return {
        list: (!curWord ? [] : dictionary.filter(function(item) {
            return item.match(regex);
        	}))
					.map(item => {
						const randexp = new RandExp(item);
						randexp.max = 2
						return randexp.gen()
					})
					.sort(),
        from: CodeMirror.Pos(cur.line, start),
        to: CodeMirror.Pos(cur.line, end)
    }
});
