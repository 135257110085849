export let move = [	
	{
	    type: ["heros1", "heros3"],
	    action: ["resteDeboutAcc", "marcheAcc", "coursAcc", "voleAcc"]
	},
	{
	    type: ["heros4","heros5","heros6","heros21","heros22","heros23","heros25"],
	    action: ["resteDeboutAcc", "marcheAcc"]
	},
	{
	    type: ["heros7"],
	    action: ["resteDeboutAcc", "marcheAcc", "pleureAcc"]
	},
	{
	    type: ["heros8"],
	    action: ["flotteAcc"]
	},
	{
	    type: ["heros2"],
	    action: ["resteDeboutAcc", "marcheAcc", "sauteAcc", "attaqueAcc"]
	},
	{
	    type: ["heros9","heros10","heros11","heros12","heros13","heros14","heros15","heros16","heros17","heros18"],
	    action: ["resteDeboutAcc"]
	},
	{
	    type: ["heros19"],
	    action: ["resteDeboutAcc", "avanceRoulantAcc", "joueMusicAcc"]
	},
	{
	    type: ["heros20"],
	    action: ["marchePleurantAcc", "pleureAcc", "boiComprimeAcc"]
	},
	{
	    type: ["heros22"],
	    action: ["resteDeboutAcc", "parleAcc"]
	},
	{
	    type: ["heros26"],
	    action: ["tousAcc"]
	},
	{
	    type: ["butRdesc", "butCdesc"],
	    action: ["greenWrCol", "redWrCol", "yellowWrCol", "blueWrCol", "orangeWrCol",
	        "blackWrCol", "whiteWrCol", "pinkWrCol", "purpleWrCol", "brownWrCol",
	
	        "greenBaCol", "redBaCol", "yellowBaCol", "blueBaCol", "orangeBaCol",
	        "blackBaCol", "whiteBaCol", "pinkBaCol", "purpleBaCol", "brownBaCol", ]
	},
	{
	    type: ["sFond\\d+"],
	    action: ["moveSpeed", "moveL", "moveR", "moveP", "moveCD"]
	},
	{
	    type: ["rectangle"],
	    action: ["largeur", "longueur", "estDeCouleur", "radius"]
	},
]
	
