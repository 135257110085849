<template>
 <div>  
<div id="GlassCard"> 
         
 <div  :class="'rectangle item0 ' + 'glassCard'"   id="item0" ref="item0"  v-bind:style="{borderRadius : getUnit(radius,'px'),}">
 <div  :class="'slot slotitem01 '"   id="slotitem01" ref="slotitem01"  v-bind:style="{}"><slot  :name = "'child'" 
></slot></div>  </div>
 
      </div>
 </div> 
</template>
<script>

import '../../Js/vanilla-tilt.min.js'
export default {
	name: 'GlassCard',

    components: {},
	methods: {
	
		increment: function() {		
   this.count++

}
,
	},
	mounted(){
			
	VanillaTilt.init(document.querySelector(".glassCard"), {

		max: 10,

		speed: 100,

		glare: false,

		perspective: 10000000,

		max: 20

	});

	},
	data(){
	return {
		
		count:  1		,
		}
	},
	props: {
	
		radius: {					required: false,			default: () =>  15,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#GlassCard{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.glassCard{
	box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
	background: rgba(255, 255, 255, 0.1) !important;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
	transform-style: preserve-3d;
	transform: perspective(2000px);
	
	*{
		transform: translateZ(20px);
	}
}



.rectangle.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

.slot.slotitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
}


}


@media (max-width: 600px) {


.rectangle.item0{


position:absolute !important; 







.slot.slotitem01{


position:absolute !important; 




}


}
}

@media (min-width: 601px) {


.rectangle.item0{


position:absolute !important; 







.slot.slotitem01{


position:absolute !important; 




}


}
}

@media (min-width: 961px) {


.rectangle.item0{


position:absolute !important; 







.slot.slotitem01{


position:absolute !important; 




}


}
}

@media (min-width: 1265px) {


.rectangle.item0{


position:absolute !important; 







.slot.slotitem01{


position:absolute !important; 




}


}
}

@media (min-width: 1905px) {


.rectangle.item0{


position:absolute !important; 







.slot.slotitem01{


position:absolute !important; 




}


}
}
}
</style>
