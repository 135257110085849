<template>
 <div>  
<div id="ImageButton"> 
         
 <div  :class="'item but '"   id="but" ref="but"  v-bind:style="{}">
 <v-img  :class="'image imagebut1 '"   id="imagebut1" ref="imagebut1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + source + '')" 
>  </v-img>
 <div  :class="'mousearea mouseareabut3 '"   id="mouseareabut3" ref="mouseareabut3"  v-bind:style="{}" @click="clo"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ImageButton',

    components: {},
	props: {
	
		clo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		source: {					required: false,			default: () =>  "Resources/Acceuil/sound.png",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ImageButton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#but{
	&:hover{
		transform: scale(1.02);
	}
}



.item.but{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.image.imagebut1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 4px ; 

top: 4px ; 
right: 4px ; 
bottom: 4px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.item.but{


position:absolute !important; 






.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.item.but{


position:absolute !important; 






.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.item.but{


position:absolute !important; 






.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.item.but{


position:absolute !important; 






.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.item.but{


position:absolute !important; 






.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}
}
</style>
