<template>
 <div>  
<div id="acceuil"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-img  :class="'networkimage imageitem01 '"   id="imageitem01" ref="imageitem01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "bgImage" 
>  </v-img>
 <div  :class="'item logoZone '"   id="logoZone" ref="logoZone"  v-bind:style="{}">
 <v-img  :class="'image imagelogoZone1 '"   id="imagelogoZone1" ref="imagelogoZone1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/Acceuil/abccode_logo_n_g_transparent.png')" 
>  </v-img>
 <v-img  :class="'image imagelogoZone3 '"   id="imagelogoZone3" ref="imagelogoZone3"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/Acceuil/logoCAYSTI.png')" 
>  </v-img>
 <v-img  :class="'image imagelogoZone5 '"   id="imagelogoZone5" ref="imagelogoZone5"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/Acceuil/abccode.png')" 
>  </v-img>  </div>
 <div  :class="'item itemitem017 '"   id="itemitem017" ref="itemitem017"  v-bind:style="{}">
 <v-img  :class="'animatedimage animatedimageitemitem0171 '"   id="animatedimageitemitem0171" ref="animatedimageitemitem0171"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/Acceuil/Animated/16.gif')" 
>  </v-img>
 <v-img  :class="'animatedimage animatedimageitemitem0173 '"   id="animatedimageitemitem0173" ref="animatedimageitemitem0173"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/Acceuil/Animated/14.gif')" 
>  </v-img>  </div>
 <HomeGlass :class="'object glasscarditem021 '"   id="glasscarditem021" ref="glasscarditem021"  v-bind:style="{}">
</HomeGlass>
 <div  :class="'item bottomButtGroup1 '"   id="bottomButtGroup1" ref="bottomButtGroup1"  v-bind:style="{}">
 <div  :class="'item itembottomButtGroup11 '"   id="itembottomButtGroup11" ref="itembottomButtGroup11" v-if="socialMenu"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitembottomButtGroup111 '"   id="rectangleitembottomButtGroup111" ref="rectangleitembottomButtGroup111"  v-bind:style="{backgroundColor : 'black',}">  </div>
 <div  :class="'column columnitembottomButtGroup113 '"   id="columnitembottomButtGroup113" ref="columnitembottomButtGroup113"  v-bind:style="{}">
 <ImageButton :class="'object imagebuttoncolumnitembottomButtGroup1131 '"   id="imagebuttoncolumnitembottomButtGroup1131" ref="imagebuttoncolumnitembottomButtGroup1131"  v-bind:style="{}" :clo = "goToFacebook" 
 :source = "'Resources/Acceuil/facebook.png'" 
>
</ImageButton>
 <ImageButton :class="'object imagebuttoncolumnitembottomButtGroup113123333 '"   id="imagebuttoncolumnitembottomButtGroup113123333" ref="imagebuttoncolumnitembottomButtGroup113123333"  v-bind:style="{}" :clo = "goToTweeter" 
 :source = "'Resources/Acceuil/twit.png'" 
>
</ImageButton>  </div>  </div>
 <RotateImageButton :class="'object rotateimagebuttonbottomButtGroup13 '"   id="rotateimagebuttonbottomButtGroup13" ref="rotateimagebuttonbottomButtGroup13"  v-bind:style="{}" :clo = "showSocialMenu" 
 :source = "'Resources/Acceuil/flexh.png'" 
>
</RotateImageButton>  </div>
 <div  :class="'item bottomButtGroup166666 '"   id="bottomButtGroup166666" ref="bottomButtGroup166666"  v-bind:style="{}">
 <div  :class="'item itembottomButtGroup11 '"   id="itembottomButtGroup11" ref="itembottomButtGroup11" v-if="paramMenu"  v-bind:style="{}">
 <div  :class="'rectangle rectangleitembottomButtGroup111 '"   id="rectangleitembottomButtGroup111" ref="rectangleitembottomButtGroup111"  v-bind:style="{backgroundColor : 'black',}">  </div>
 <div  :class="'column columnitembottomButtGroup113 '"   id="columnitembottomButtGroup113" ref="columnitembottomButtGroup113"  v-bind:style="{}">
 <ImageButton :class="'object imagebuttoncolumnitembottomButtGroup1131 '"   id="imagebuttoncolumnitembottomButtGroup1131" ref="imagebuttoncolumnitembottomButtGroup1131"  v-bind:style="{}" :clo = "goToSite" 
 :source = "'Resources/Acceuil/info.png'" 
>
</ImageButton>
 <ImageButton :class="'object imagebuttoncolumnitembottomButtGroup113133333 '"   id="imagebuttoncolumnitembottomButtGroup113133333" ref="imagebuttoncolumnitembottomButtGroup113133333"  v-bind:style="{}" :clo = "toggelFullScreen" 
 :source = "'Resources/Acceuil/full.png'" 
>
</ImageButton>  </div>  </div>
 <RotateImageButton :class="'object rotateimagebuttonbottomButtGroup13 '"   id="rotateimagebuttonbottomButtGroup13" ref="rotateimagebuttonbottomButtGroup13"  v-bind:style="{}" :clo = "showParamMenu" 
>
</RotateImageButton>  </div>
 <ImageButton :class="'object logoutBtn '"   id="logoutBtn" ref="logoutBtn"  v-bind:style="{}" :clo = "logOut" 
 :source = "'Resources/Acceuil/out.png'" 
>
</ImageButton>
 <LoadingScreen :class="'object loadingscreen '"   id="loadingscreen" ref="loadingscreen" v-if="loaderVisible"  v-bind:style="{}">
</LoadingScreen>  </div>
 
      </div>
 </div> 
</template>
<script>
import HomeGlass from '../At/Home/HomeGlass'
import ImageButton from '../At/Utils/ImageButton'
import RotateImageButton from '../At/Utils/RotateImageButton'
import LoadingScreen from '../At/Splash/LoadingScreen'

import * as main from "../Js/main.js"
export default {
	name: 'acceuil',

    components: {HomeGlass,ImageButton,RotateImageButton,LoadingScreen,},
	methods: {
	
		showParamMenu: function(){		
	this.paramMenu = !this.paramMenu

}
,
		showSocialMenu: function(){		
	this.socialMenu = !this.socialMenu

}
,
		logOut: function(){		
	main.logout()

}
,
		getBgImg: function(){		
	let index = Math.round(Math.random()*(this.imagesList.length-1))

	this.bgImage =this.imagesList[index].url

}
,
		goToSite: function(){ 		
	window.open(this.$store.state.siteURL, '_blank').focus();

}
,
		goToTweeter: function(){ 		
	window.open('https://twitter.com/abcCode1', '_blank').focus();

}
,
		goToFacebook: function(){ 		
	window.open('https://www.facebook.com/caysti', '_blank').focus();

}
,
		openFullScreen: function(){		
	let elem = document.documentElement;

	

  if (elem.requestFullscreen) {

    elem.requestFullscreen();

		this.isFullScreen = true

  } else if (elem.webkitRequestFullscreen) { /* Safari */

    elem.webkitRequestFullscreen();

		this.isFullScreen = true

  } else if (elem.msRequestFullscreen) { /* IE11 */

    elem.msRequestFullscreen();

		this.isFullScreen = true

  }

}
,
		closeFullScreen: function() {		
	let elem = document.documentElement;

	

  if (document.exitFullscreen) {

    document.exitFullscreen();

		this.isFullScreen = false

  } else if (document.webkitExitFullscreen) { /* Safari */

    document.webkitExitFullscreen();

		this.isFullScreen = false

  } else if (document.msExitFullscreen) { /* IE11 */

    document.msExitFullscreen();

		this.isFullScreen = false

  }

}
,
		toggelFullScreen: function(){		
	if(this.isFullScreen){

		this.closeFullScreen()

	} else {

		this.openFullScreen()

	}

}
,
	},
	mounted(){
			
	this.$i18n.locale = this.$store.state.language

	

	if(this.$store.state.token == null){

		this.$router.push('/');

	}

	main.getImages()

	.then((response)=>{

	  this.imagesList = response.data.filter(e => {

			return (e.mime.match("image/") != null)

		})

		.filter(e => {

			return (e.name.match('code_bg_') != null)

		})

		.map(e => {

			e.url = this.$store.state.apiURL + e.url

			return e

		})

	

		// Get bg imageitem

		this.getBgImg()

	})

	.catch((error)=>{

	  console.log(error);

	});

	

	//Hide loader

	setTimeout(() => { this.loaderVisible = false }, 2000);

	},
	data(){
	return {
		
		loaderVisible:  true		,
		pos:  0		,
		active:  true		,
		paramMenu:  false		,
		socialMenu:  false		,
		language:  this.$store.state.language		,
		imagesList:  []		,
		token:  ""		,
		bgImage:  ""		,
		isFullScreen:  false		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#acceuil{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

*{
	font-family: 'Pacifico', fantasy, cursive !important;
	
	.text.text-content{
		font-family: 'Pacifico', fantasy, cursive !important;	
	}
}

#tree {
  animation-name: animeTree;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes animeTree {
  from {left: 0;}
  to {left: -2000;}
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.networkimage.imageitem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}


}

.item.logoZone{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
right: 10px ; 
height:100px!important ; 


	.image.imagelogoZone1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	width:100px!important ; 
	height:100px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

	
}

	.image.imagelogoZone3{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	right: 0px ; 
	width:100px!important ; 
	height:100px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

	
}

	.image.imagelogoZone5{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
	
	left: calc(50% + 0px) ; 
	
	top: calc(50% + 80px) ; 
	width:692px!important ; 
	height:auto!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: contain;
	}

	
}


}

.item.itemitem017{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 20px ; 

top: 160px ; 
right: 20px ; 
height:100px!important ; 


	.animatedimage.animatedimageitemitem0171{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left: 0px ; 
	
	top: calc(50% + 0px) ; 
	width:88px!important ; 
	height:116px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}

	.animatedimage.animatedimageitemitem0173{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	right: 0px ; 
	width:109px!important ; 
	height:217px!important ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

	
}


}

.object.glasscarditem021{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
right: 10px ; 
bottom: 120px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.item.bottomButtGroup1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



right: 15px ; 
bottom: 0px ; 
width:91px!important ; 
height:225px!important ; 


	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	top: -5px ; 
	width:60px!important ; 
	height:200px!important ; 
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.5 ;  filter: alpha(opacity=50) ; 
		
		background-color:black ; 
		border-radius:6px ; 

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:60px!important ; 
			height:60px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113123333{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:60px!important ; 
			height:60px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	bottom: 0px ; 
	width:120px!important ; 
	height:120px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.item.bottomButtGroup166666{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



right: 130px ; 
bottom: 0px ; 
width:91px!important ; 
height:225px!important ; 


	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	top: -5px ; 
	width:60px!important ; 
	height:200px!important ; 
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		opacity: 0.5 ;  filter: alpha(opacity=50) ; 
		
		background-color:black ; 
		border-radius:6px ; 

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left:0px ; 
		
		top: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;background-color: transparent ; 

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:60px!important ; 
			height:60px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113133333{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:60px!important ; 
			height:60px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
	
	left: calc(50% + 0px) ; 
	
	bottom: 0px ; 
	width:120px!important ; 
	height:120px!important ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.logoutBtn{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



bottom: 0px ; 
width:120px!important ; 
height:120px!important ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loadingscreen{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.networkimage.imageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.logoZone{


position:absolute !important; 






	.image.imagelogoZone1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone5{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.item.itemitem017{


position:absolute !important; 






	.animatedimage.animatedimageitemitem0171{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.animatedimage.animatedimageitemitem0173{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.object.glasscarditem021{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.item.bottomButtGroup1{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113123333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.item.bottomButtGroup166666{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113133333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.logoutBtn{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loadingscreen{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.networkimage.imageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.logoZone{


position:absolute !important; 






	.image.imagelogoZone1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone5{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.item.itemitem017{


position:absolute !important; 






	.animatedimage.animatedimageitemitem0171{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.animatedimage.animatedimageitemitem0173{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.object.glasscarditem021{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.item.bottomButtGroup1{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113123333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.item.bottomButtGroup166666{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113133333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.logoutBtn{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loadingscreen{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.networkimage.imageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.logoZone{


position:absolute !important; 






	.image.imagelogoZone1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone5{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.item.itemitem017{


position:absolute !important; 



left: 180px ; 

right: 180px ; 


	.animatedimage.animatedimageitemitem0171{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.animatedimage.animatedimageitemitem0173{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.object.glasscarditem021{


position:absolute !important; 



left: 10% ; 

top: 10% ; 
right: 10% ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.item.bottomButtGroup1{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113123333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.item.bottomButtGroup166666{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113133333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.logoutBtn{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loadingscreen{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.networkimage.imageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.logoZone{


position:absolute !important; 






	.image.imagelogoZone1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone5{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.item.itemitem017{


position:absolute !important; 






	.animatedimage.animatedimageitemitem0171{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.animatedimage.animatedimageitemitem0173{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.object.glasscarditem021{


position:absolute !important; 



left: 15% ; 

right: 15% ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.item.bottomButtGroup1{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113123333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.item.bottomButtGroup166666{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113133333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.logoutBtn{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loadingscreen{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.networkimage.imageitem01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.item.logoZone{


position:absolute !important; 






	.image.imagelogoZone1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone3{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.image.imagelogoZone5{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.item.itemitem017{


position:absolute !important; 






	.animatedimage.animatedimageitemitem0171{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.animatedimage.animatedimageitemitem0173{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.object.glasscarditem021{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.item.bottomButtGroup1{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113123333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.item.bottomButtGroup166666{


position:absolute !important; 






	.item.itembottomButtGroup11{

	
	position:absolute !important; 
	
	
	
	
	

		.rectangle.rectangleitembottomButtGroup111{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.column.columnitembottomButtGroup113{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.imagebuttoncolumnitembottomButtGroup1131{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

			.object.imagebuttoncolumnitembottomButtGroup113133333{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.object.rotateimagebuttonbottomButtGroup13{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}

.object.logoutBtn{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.loadingscreen{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
