<template>
 <div>  
<div id="AppViewer"> 
         
 <div  :class="'item NaN '"   id="NaN" ref="NaN"  v-bind:style="{}">
 <div  :class="'rectangle rectangleNaN1 '"   id="rectangleNaN1" ref="rectangleNaN1"  v-bind:style="{backgroundColor : 'black',}">
 <div  :class="'mousearea mousearearectangleNaN11 '"   id="mousearearectangleNaN11" ref="mousearearectangleNaN11"  v-bind:style="{}" @click="beforeClose"></div>  </div>
 <div  :class="'rectangle canvasBox '"   id="canvasBox" ref="canvasBox" v-on:click="setFocusOnIFrame"   v-bind:style="{}">  </div>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="beforeClose" :class="'button buttonNaN3 '"   id="buttonNaN3" ref="buttonNaN3"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-close-circle-outline' !== undefined"  :color = "primaryColor" 
>{{ 'ion-ios-close-circle-outline' }}
</v-icon></v-btn>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AppViewer',

    components: {},
	methods: {
	
		load: function(finalCode) {		
	this.code = finalCode;



  const contentDiv = document.getElementById("canvasBox");

  contentDiv.innerHTML = '<iframe id="abcFrame" style="border: none; width: 100%; height: 100%" width="100%" height="100%"></iframe>'



	let iframe = document.querySelector('#abcFrame')

	iframe.srcdoc = finalCode

	

	this.setFocusOnIFrame()

	

	document.addEventListener('keydown', e => {		

		const iframe = document.querySelector('#abcFrame')

		if(iframe){

			let iDoc = iframe.contentWindow     // sometimes glamorous naming of variable

	        || iframe.contentDocument;  // makes your code working :)

	

	  	// dispatch a new event

			if(iDoc.document){

				iDoc = iDoc.document;

		  	iDoc.body.dispatchEvent(

			    new KeyboardEvent('keydown', {key: e.key})

			  );

			}

		}

	})

}
,
		setFocusOnIFrameNext: function() { 		
	let iframe = document.querySelector('#abcFrame')

  iframe.contentWindow.focus();

}
,
		setFocusOnIFrame: function(){		
	setTimeout(this.setFocusOnIFrameNext, 100);

}
,
		beforeClose: function() { 		
	const e = document.getElementById('abcFrame')

	e.parentElement.removeChild(e);

	this.close()

}
,
	},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		code:  ""		,
		language:  this.$store.state.language		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AppViewer{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.NaN{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleNaN1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.8 ;  filter: alpha(opacity=80) ; 

background-color:black ; 

	.mousearea.mousearearectangleNaN11{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	}


}

.rectangle.canvasBox{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 100px ; 

top: 80px ; 
right: 100px ; 
bottom: 80px ; 

background-color:#ffffff ; 
border-radius:8px ; 


}

.button.buttonNaN3{

height: auto;

position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: 10px ; 
right: 10px ; 
width:48px!important ; 
height:48px!important ; 

font-size:35px ; 
text-transform:unset ; 
min-width: auto;
i.v-icon{
font-size:35px ; 
}
}


}


@media (max-width: 600px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 







	.mousearea.mousearearectangleNaN11{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.canvasBox{


position:absolute !important; 



left: 10px ; 

top: 10px ; 
right: 10px ; 
bottom: 10px ; 




}

.button.buttonNaN3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 601px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 







	.mousearea.mousearearectangleNaN11{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.canvasBox{


position:absolute !important; 



left: 30px ; 

top: 30px ; 
right: 30px ; 
bottom: 30px ; 




}

.button.buttonNaN3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 961px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 







	.mousearea.mousearearectangleNaN11{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.canvasBox{


position:absolute !important; 








}

.button.buttonNaN3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1265px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 







	.mousearea.mousearearectangleNaN11{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.canvasBox{


position:absolute !important; 








}

.button.buttonNaN3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}

@media (min-width: 1905px) {


.item.NaN{


position:absolute !important; 






.rectangle.rectangleNaN1{


position:absolute !important; 







	.mousearea.mousearearectangleNaN11{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.rectangle.canvasBox{


position:absolute !important; 








}

.button.buttonNaN3{

height: auto;

position:absolute !important; 





text-transform:unset ; 
min-width: auto;
i.v-icon{
}
}


}
}
}
</style>
