<template>
 <div>  
<div id="designerItem"> 
         
 <v-img  :class="'networkimage networkimage '"   id="networkimage" ref="networkimage" v-on:click="select"   v-bind:style="{zoom : zoom, backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "src" 
>
 <div  :class="'rectangle borderCard '"   id="borderCard" ref="borderCard" v-if="activeItem" v-on:mousedown="mousedown"   v-bind:style="{backgroundColor : 'transparent',borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerNW ' + 'nw'"   id="resizerNW" ref="resizerNW" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerNE ' + 'ne'"   id="resizerNE" ref="resizerNE" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerSW ' + 'sw'"   id="resizerSW" ref="resizerSW" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerSE ' + 'se'"   id="resizerSE" ref="resizerSE" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>  </v-img>
 
      </div>
 </div> 
</template>
<script>

import $ from 'jquery'
export default {
	name: 'designerItem',

    components: {},
	methods: {
	
		select: function(e){		
	e.stopPropagation();

  this.selectObject(this.ip)

}
,
		actualise: function(){		
  this.canvas.currentIp=ip

  this.canvas.currentCodeSearch()

}
,
		mousedown: function(e) { 		
	let el = $($($(e.target).parents()).parents()[2])

	console.log("************", el )



  let prevX = e.clientX;

  let prevY = e.clientY;



  let mousemove = (e)=>{

    if (!this.isResizing) {

      let newX = prevX - e.clientX;

      let newY = prevY - e.clientY;



      let position = el.position();

			this.newTop = position.top - newY

			this.newLeft = position.left - newX



      $(el).css({top: this.newTop, left: this.newLeft});



      prevX = e.clientX;

      prevY = e.clientY;

    }

  }



  let mouseup = ()=>{

    window.removeEventListener("mousemove", mousemove);

    window.removeEventListener("mouseup", mouseup);

		this.updateObject(this.ip, 'x', this.newLeft);

		this.updateObject(this.ip, 'y', this.newTop);

  }

	

	window.addEventListener("mousemove", mousemove);

  window.addEventListener("mouseup", mouseup);

}
,
		resizerMousedown: function(e) { 			
	let el = $($(e.target).parents()[3])

  let currentResizer = e.target;

  this.isResizing = true;



  let prevX = e.clientX;

  let prevY = e.clientY;



  let mousemove = (e)=>{

    let position = el.position();

		let zoom = this.zoom

		let _width = el.width();

		let _height = el.height();

    let newX = prevX - e.clientX;

    let newY = prevY - e.clientY;

	

	console.log("###########>", e.clientX, e.clientY)

	console.log("==========>", newX, newY)

	

		this.newTop = position.top - newY

		this.newLeft = position.left - newX

		let newWidth = _width + newX

		let newHeight = _height + newY



    if (currentResizer.classList.contains("se")) {

    	/*$(el).attr('style', `width: ${newWidth}px !important;

														height: ${newHeight}px !important;`);*/

			this.scale = Math.min(newWidth/_width, newHeight/_height)

    } else if (currentResizer.classList.contains("sw")) {

    	/*$(el).attr('style', `width: ${_width + newX}px !important;

														height: ${newHeight}px !important;`);*/

			this.scale = Math.min((_width + newX)/_width, newHeight/_height)

    } else if (currentResizer.classList.contains("ne")) {

    	/*$(el).attr('style', `width: ${newWidth}px !important;

														height: ${_height + newY}px !important;`);*/

			this.scale = Math.min(newWidth/_width, (_height + newY)/_height)

    } else {

    	/*$(el).attr('style', `width: ${_width + newX}px !important;

														height: ${_height + newY}px !important;`);*/

			this.scale = Math.min((_width + newX)/_width, (_height + newY)/_height)

    }

		this.scale = this.scale * zoom

    $(el).css({top: this.newTop, left: this.newLeft});

    prevX = e.clientX;

    prevY = e.clientY;

  }



  let mouseup = ()=>{	

    window.removeEventListener("mousemove", mousemove);

    window.removeEventListener("mouseup", mouseup);

		this.updateObject(this.ip, 'x', this.newLeft);

		this.updateObject(this.ip, 'y', this.newTop);

		this.updateObject(this.ip, 'scale', this.scale);

    this.isResizing = false;

  }

	

  window.addEventListener("mousemove", mousemove);

  window.addEventListener("mouseup", mouseup);

}
,
	},
	computed: {
	
		activeItem: function(){		
	return (this.ip == this.currentSelectedItem)

} 
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		isResizing:  false		,
		scale:  1		,
		newTop:  0		,
		newLeft:  0		,
		}
	},
	props: {
	
		ip: {					required: false,			default: () =>  0,		},
		src: {					required: false,			default: () =>  "https://picsum.photos/200",		},
		updateObject: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		selectObject: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		currentSelectedItem: {					required: false,			default: () =>  0,		},
		zoom: {					required: false,			default: () =>  1,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#designerItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#networkimage{
	cursor: pointer;
	
	::v-deep .v-image__image{
		z-index: 0;
	}
	.nw{
		cursor: nwse-resize;
	}
	.ne{
		cursor: nesw-resize;
	}
	.sw{
		cursor: nesw-resize;
	}
	.se{
		cursor: nwse-resize;
	}
}



.networkimage.networkimage{


position:inherit !important; 
margin: unset;
padding: unset;
transform-origin: top left;



width:auto!important ; 
height:auto!important ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

.rectangle.borderCard{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:transparent ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerNW{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: -8px ; 

top: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerNE{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: -8px ; 
right: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerSW{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: -8px ; 

bottom: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerSE{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



right: -8px ; 
bottom: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}


}


@media (max-width: 600px) {


.networkimage.networkimage{


position:inherit !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 601px) {


.networkimage.networkimage{


position:inherit !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 961px) {


.networkimage.networkimage{


position:inherit !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 1265px) {


.networkimage.networkimage{


position:inherit !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 1905px) {


.networkimage.networkimage{


position:inherit !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}
}
</style>
