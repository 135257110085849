<template>
 <div>  
<div id="AddButton"> 
         
 <div  :class="'rectangle rectangle0 '"   id="rectangle0" ref="rectangle0"  v-bind:style="{backgroundColor : bgColor,}">
 <v-img  :class="'image imagerectangle01 '"   id="imagerectangle01" ref="imagerectangle01"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../../' + source + '')" 
>  </v-img>
 <div  :class="'rectangle rectanglerectangle03 '"   id="rectanglerectangle03" ref="rectanglerectangle03"  v-bind:style="{backgroundColor : '#a5a5a5',}">  </div>
 <div  :class="'text textrectangle05 ' + 'noHoverFlow'"   id="textrectangle05" ref="textrectangle05"  v-bind:style="{color : textColor,}">
<div class="text-content" :style = "{}">{{ value }}</div>

   </div>
 <div  :class="'mousearea mousearearectangle07 '"   id="mousearearectangle07" ref="mousearearectangle07" v-on:mouseover="hoverIn"  v-on:mouseout="hoverOut"   v-bind:style="{}" @click="action"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'AddButton',

    components: {},
	methods: {
	
		hoverIn: function(){		
	this.bgColor = this.hoverColor

	this.textColor = "#fff"

}
,
		hoverOut: function(){		
	this.bgColor = "#fff"

	this.textColor = "#807e99"

}
,
	},
	data(){
	return {
		
		bgColor:  "#fff"		,
		textColor:  "#807e99"		,
		}
	},
	props: {
	
		value: {					required: false,			default: () =>  "...",		},
		source: {					required: false,			default: () =>  "Resources/App/niv/1.png",		},
		action: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		hoverColor: {					required: false,			default: () =>  "#838eec",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AddButton{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.noHoverFlow{
	overflow: hidden;
}



.rectangle.rectangle0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 

.image.imagerectangle01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);

left: 10px ; 

top: calc(50% + 0px) ; 
width:64px!important ; 
height:64px!important ; 

display: inherit;

flex: unset;
border-radius:38px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.rectangle.rectanglerectangle03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 80px ; 

top: 0px ; 
right: 30px ; 
height:1px!important ; 

background-color:#a5a5a5 ; 


}

.text.textrectangle05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 85px ; 

top: 5px ; 
right: 10px ; 
bottom: 5px ; 

font-size:15px ; 
.text-content{
width: 100%;
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.rectangle.rectanglerectangle03{


position:absolute !important; 








}

.text.textrectangle05{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.rectangle.rectanglerectangle03{


position:absolute !important; 








}

.text.textrectangle05{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.rectangle.rectanglerectangle03{


position:absolute !important; 








}

.text.textrectangle05{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.rectangle.rectanglerectangle03{


position:absolute !important; 








}

.text.textrectangle05{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.rectangle.rectangle0{


position:absolute !important; 







.image.imagerectangle01{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.rectangle.rectanglerectangle03{


position:absolute !important; 








}

.text.textrectangle05{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mousearearectangle07{


position:absolute !important; 





}


}
}
}
</style>
