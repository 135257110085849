export function generateStage(number, code) {
  return `
		function stage${number}() {
			page = new Container(stageW, stageH);
      		page.addTo();
			${code}
    	},
	`;
}

export function generateCode(number, code, assets) {
  return `
	<!DOCTYPE html>	
	<html>
	  <head>
	    <meta charset="utf-8" />
	    <title>abcCode</title>
	
	    <link rel="icon" href="assets/logoabc.png" />
	
	    <script src="bin/abccode.js"></script>

		<style>
			.logo{
				width: 80px;
				position: absolute;
				top: 50px;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 3;
			}
		</style>
	
	    <script>
	      let assets = ${JSON.stringify(assets)};
	
	      var page; // assigned once created
	      let stage, stageW, stageH;
	      const frame = new Frame(FIT, 500, 500, white, dark, assets);
	      frame.on("ready", () => {
	        stage = frame.stage;
	        stageW = frame.width;
	        stageH = frame.height;
	
	        var pageNum = ${number};
	        gotoPage(pageNum);
	      });
	
	      function gotoPage(pageN) {
	        if (page) page.removeFrom();
	
	        pages[pageN]();
	
	        stage.update();
	      }
	
	      let pages = [
					(()=>{}),
					${code}
				];
	    </script>
	    <meta name="viewport" content="width=device-width, user-scalable=no" />
	  </head>
	  <body>
		<img src="assets/logoabc.png" class="logo" />
	  </body>
	</html>
	`;
}
