<template>
 <div>  
<div id="OnboardingStepper"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card card0 '"   id="card0" ref="card0" :elevation = "4" 
  v-bind:style="{height : getUnit(getHeight,'px'),}">
 <div  :class="'item header '"   id="header" ref="header"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageheader1 '"   id="networkimageheader1" ref="networkimageheader1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "userImg" 
>
 <div  :class="'icon iconnetworkimageheader11 '"   id="iconnetworkimageheader11" ref="iconnetworkimageheader11"  v-bind:style="{color : '#417dff',fontSize : getUnit(30,'px'),}"><i  :class="'ion-ios-chatbubbles'"></i></div>  </v-img>
 <div  :class="'row rowheader3 '"   id="rowheader3" ref="rowheader3"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="minimize" :class="'button buttonrowheader31 '"   id="buttonrowheader31" ref="buttonrowheader31" v-if="(!minimized)"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-remove' !== undefined"  :color = "'#717171'" 
>{{ 'ion-ios-remove' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :fab = "true" 
 :text = "true" 
 @click="expend" :class="'button buttonrowheader312 '"   id="buttonrowheader312" ref="buttonrowheader312" v-if="minimized"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-resize' !== undefined"  :color = "'#717171'" 
>{{ 'ion-ios-resize' }}
</v-icon></v-btn>  </div>  </div>
 <div  :class="'item body '"   id="body" ref="body" v-show="!minimized"  v-bind:style="{}">
<div class="artefactVid"  :class="'networkvideo networkvideobody1 '"   id="networkvideobody1" ref="networkvideobody1" v-for="(item,index) in videoList.length" :key="'networkvideobody1' + index" v-show="(index == ci)"  v-bind:style="{}"> <video width="100%" height="100%"  :controls = "true" 
>
<source  v-for='(itemVideoUrl,index) in [m_videoList[index]]' :key="index" :src="itemVideoUrl" >
</video> </div>
 <div  :class="'rectangle rectanglebody3 '"   id="rectanglebody3" ref="rectanglebody3"  v-bind:style="{backgroundColor : '#e2ebff',}">
 <div  :class="'text textrectanglebody31 '"   id="textrectanglebody31" ref="textrectanglebody31"  v-bind:style="{color : '#686868',}">
<div class="text-content" :style = "{}">{{ message }}</div>

   </div>  </div>  </div>
 <div  :class="'item footer '"   id="footer" ref="footer" v-show="!minimized"  v-bind:style="{}">
 <div  :class="'row rowfooter1 '"   id="rowfooter1" ref="rowfooter1"  v-bind:style="{justifyContent : 'space-between',}">
 <v-btn tag="div" 
 :color = "'#d1d1d1'" 
 :fab = "true" 
 :dark = "true" 
 @click="previousVideo" :class="'button buttonrowfooter11 '"   id="buttonrowfooter11" ref="buttonrowfooter11"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-arrow-dropleft' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-arrow-dropleft' }}
</v-icon></v-btn>
 <div  :class="'rectangle rectanglerowfooter15 '"   id="rectanglerowfooter15" ref="rectanglerowfooter15" v-for="(item,index) in getNumberVideo" :key="'rectanglerowfooter15' + index"  v-bind:style="{width : getUnit((index == ci? 15 : 10),'px'),height : getUnit((index == ci? 15 : 10),'px'),backgroundColor : (index == ci? activeColor : '#8f8f8f'),}">
 <div  :class="'icon iconrectanglerowfooter151 '"   id="iconrectanglerowfooter151" ref="iconrectanglerowfooter151" v-if="(index == ci)"  v-bind:style="{color : '#fff',fontSize : getUnit(10,'px'),}"><i  :class="'ion-md-checkmark'"></i></div>  </div>
 <v-btn tag="div" 
 :color = "'#d1d1d1'" 
 :fab = "true" 
 :dark = "true" 
 @click="nextVideo" :class="'button buttonrowfooter112 '"   id="buttonrowfooter112" ref="buttonrowfooter112"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-arrow-dropright' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-arrow-dropright' }}
</v-icon></v-btn>  </div>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'OnboardingStepper',

    components: {},
	methods: {
	
		minimize: function(){		
	this.minim = true

	this.minifyOnBoard()

}
,
		expend: function(){		
	this.minim = false

	this.expendOnBoard()

}
,
		nextVideo: function() { 		
	if(this.ci == (this.getNumberVideo - 1)){

		this.ci = 0

	} else {

		this.ci++

	}

}
,
		previousVideo: function() { 		
	if(this.ci == 0){

		this.ci = (this.getNumberVideo - 1)

	} else {

		this.ci--

	}

}
,
	},
	computed: {
	
		minimized: function() { 		
	return this.minim? this.minim : this.mini

}
,
		getNumberVideo: function() { 		
	return this.videoList.length

}
,
		m_videoList: function() { 		
	return this.videoList.map(v => {

		return this.$store.state.apiURL + v.url

	})

}
,
		getHeight: function() { 		
	if(this.minimized){

		return 33

	} else {

		return 318

	}

}
,
	},
	data(){
	return {
		
		minim:  false		,
		ci:  this.currentIndex		,
		}
	},
	props: {
	
		userImg: {					required: false,			default: () =>  "https://picsum.photos/500",		},
		videoList: {					required: false,			default: () =>  ["https://www.w3schools.com/html/mov_bbb.mp4",
											"https://www.w3schools.com/html/movie.mp4",

											"https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm"]
,		},
		currentIndex: {					required: false,			default: () =>  0,		},
		message: {					required: false,			default: () =>  "Nous sommes heureux de vous voire ici",		},
		mini: {			type: Boolean,			required: false,			default: () =>  false,		},
		activeColor: {					required: false,			default: () =>  "#ff6b00",		},
		minifyOnBoard: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		expendOnBoard: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#OnboardingStepper{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.card0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
background-color:#ffffff ; 
border-radius:15px ; max-width: initial;min-width: initial;

	.item.header{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 10px ; 
	
	top: 0.25px ; 
	right: 10px ; 
	height:24px!important ; 
	

		.networkimage.networkimageheader1{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:0px ; 
		
		top: calc(50% + 0px) ; 
		width:40px!important ; 
		height:40px!important ; 
		
		display: inherit;

		flex: unset;
		border-radius:20px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

			.icon.iconnetworkimageheader11{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			top: -15px ; 
			right: -10px ; 
			width:30px!important ; 
			height:30px!important ; 
			
			color:#417dff ; 
			font-size:30px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.row.rowheader3{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		right: 0px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;background-color:transparent ; 

			.button.buttonrowheader31{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:18px!important ; 
			height:18px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowheader312{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:18px!important ; 
			height:18px!important ; 
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.item.body{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 10px ; 
	
	top: 35px ; 
	right: 10px ; 
	bottom: 54px ; 
	

		.networkvideo.networkvideobody1{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 55px ; 
		background-color: black;

		
}

		.rectangle.rectanglebody3{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		right: 0px ; 
		bottom: 0px ; 
		height:42px!important ; 
		
		background-color:#e2ebff ; 
		border-radius:8px ; 

			.text.textrectanglebody31{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 8px ; 
			
			top: 8px ; 
			right: 8px ; 
			bottom: 8px ; 
			
			color:#686868 ; 
			font-size:15px ; 
			text-align:center;
			align-items:center;
			display: flex;
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

		
}

	
}

	.item.footer{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	bottom: 0px ; 
	height:53px!important ; 
	

		.row.rowfooter1{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
		
		left: calc(50% + 0px) ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:30px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:5px ; 
		justify-content:space-between ; background-color:transparent ; 

			.button.buttonrowfooter11{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:30px!important ; 
			height:30px!important ; 
			
			font-size:36px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:36px ; 
			}
			}

			.rectangle.rectanglerowfooter15{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			
			background-color:#ffffff ; 
			border-radius:10px ; 

				.icon.iconrectanglerowfooter151{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				color:#fff ; 
				font-size:10px ; 
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.button.buttonrowfooter112{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:30px!important ; 
			height:30px!important ; 
			
			border-radius:10px ; 
			font-size:36px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:36px ; 
			}
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.item.header{

	
	position:absolute !important; 
	
	
	
	
	

		.networkimage.networkimageheader1{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.icon.iconnetworkimageheader11{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.row.rowheader3{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowheader31{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowheader312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.item.body{

	
	position:absolute !important; 
	
	
	
	
	

		.networkvideo.networkvideobody1{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.rectangle.rectanglebody3{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglebody31{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.footer{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowfooter1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowfooter11{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.rectanglerowfooter15{

			
			position:relative !important; 
			
			
			
			
			
			

				.icon.iconrectanglerowfooter151{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.button.buttonrowfooter112{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.item.header{

	
	position:absolute !important; 
	
	
	
	
	

		.networkimage.networkimageheader1{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.icon.iconnetworkimageheader11{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.row.rowheader3{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowheader31{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowheader312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.item.body{

	
	position:absolute !important; 
	
	
	
	
	

		.networkvideo.networkvideobody1{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.rectangle.rectanglebody3{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglebody31{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.footer{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowfooter1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowfooter11{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.rectanglerowfooter15{

			
			position:relative !important; 
			
			
			
			
			
			

				.icon.iconrectanglerowfooter151{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.button.buttonrowfooter112{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.item.header{

	
	position:absolute !important; 
	
	
	
	
	

		.networkimage.networkimageheader1{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.icon.iconnetworkimageheader11{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.row.rowheader3{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowheader31{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowheader312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.item.body{

	
	position:absolute !important; 
	
	
	
	
	

		.networkvideo.networkvideobody1{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.rectangle.rectanglebody3{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglebody31{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.footer{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowfooter1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowfooter11{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.rectanglerowfooter15{

			
			position:relative !important; 
			
			
			
			
			
			

				.icon.iconrectanglerowfooter151{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.button.buttonrowfooter112{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.item.header{

	
	position:absolute !important; 
	
	
	
	
	

		.networkimage.networkimageheader1{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.icon.iconnetworkimageheader11{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.row.rowheader3{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowheader31{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowheader312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.item.body{

	
	position:absolute !important; 
	
	
	
	
	

		.networkvideo.networkvideobody1{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.rectangle.rectanglebody3{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglebody31{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.footer{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowfooter1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowfooter11{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.rectanglerowfooter15{

			
			position:relative !important; 
			
			
			
			
			
			

				.icon.iconrectanglerowfooter151{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.button.buttonrowfooter112{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.card0{


position:absolute !important; 




max-width: initial;min-width: initial;

	.item.header{

	
	position:absolute !important; 
	
	
	
	
	

		.networkimage.networkimageheader1{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

			.icon.iconnetworkimageheader11{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.row.rowheader3{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowheader31{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.buttonrowheader312{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}

	.item.body{

	
	position:absolute !important; 
	
	
	
	
	

		.networkvideo.networkvideobody1{

		
		position:absolute !important; 
		
		
		
		
		background-color: black;

		
}

		.rectangle.rectanglebody3{

		
		position:absolute !important; 
		
		
		
		
		
		

			.text.textrectanglebody31{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

		
}

	
}

	.item.footer{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowfooter1{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.buttonrowfooter11{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.rectangle.rectanglerowfooter15{

			
			position:relative !important; 
			
			
			
			
			
			

				.icon.iconrectanglerowfooter151{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				justify-content: center;
				align-items: center;
				i {
				}
}

			
}

			.button.buttonrowfooter112{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

	
}


}


}
}
}
</style>
