<template>
 <div>  
<div id="SimpleTextEditor"> 
         
 <div  :class="'item rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <codemirror  :class="'codemirror editor '"   id="editor" ref="editor" v-model="code"  v-bind:style="{}" :options = "options" 
@input = "updateCode" 
></codemirror>  </div>
 
      </div>
 </div> 
</template>
<script>
import {codemirror} from 'vue-codemirror'

import $ from 'jquery';

import CodeMirror from 'codemirror'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldcode'
import 'codemirror/addon/fold/foldgutter'
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/comment-fold'
import 'codemirror/addon/fold/indent-fold'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/css-hint'
import '../../Js/highlighter.js'
import '../../Js/buffer.js'
import 'codemirror/lib/codemirror.css'
import '../../Style/main.css'
import '../../Style/highlighter.css'
export default {
	name: 'SimpleTextEditor',

    components: {codemirror,},
	methods: {
	
		updateCode: function(e){		
	this.$emit('updateCode', e);

}
,
		setCode: function(value){		
	this.code = value;

}
,
		insertCode: function(data, index){		
	if(index == this.indexEditor){

		var cm = $('.CodeMirror')[0].CodeMirror;

    var doc = cm.getDoc();

    var cursor = doc.getCursor(); // gets the line number in the cursor position

    var line = doc.getLine(cursor.line); // get the line contents

    var pos = { // create a new object to avoid mutation of the original selection

        line: cursor.line,

        ch: line.length // set the character position to the end of the line

    }

    doc.replaceRange(data, pos); // adds a new line

	}

}
,
		refresh: function(index){		
	if(index == this.indexEditor){

		var cm = $('.CodeMirror')[0].CodeMirror;

		cm.refresh();

	}

}
,
		undo: function(index){		
	if(index == this.indexEditor){

		var cm = $('.CodeMirror')[0].CodeMirror;

		cm.undo();

	}

}
,
		redo: function(index){		
	if(index == this.indexEditor){

		var cm = $('.CodeMirror')[0].CodeMirror;

		cm.redo();

	}

}
,
		copy: function(index){		
	if(index == this.indexEditor){

		var cm = $('.CodeMirror')[0].CodeMirror;

    var editor = cm.getDoc();



		let text = editor.getSelection(); 

		window.buffer.set(text);



		$('#buffer-' + this.indexEditor + ' > textarea').val(text).get(0).select(); 

		document.execCommand("copy");

	}

}
,
		cut: function(index){		
	if(index == this.indexEditor){

		var cm = $('.CodeMirror')[0].CodeMirror;

    var editor = cm.getDoc();



		let text = editor.getSelection(); 

		window.buffer.set(text); 

		editor.replaceSelection('');



		$('#buffer-' + this.indexEditor + ' > textarea').val(text).get(0).select(); 

		document.execCommand("copy");

	}

}
,
		paste: function(index){		
	if(index == this.indexEditor){

		let text = window.buffer.get(); 

		var cm = $('.CodeMirror')[0].CodeMirror;

    var doc = cm.getDoc();

		doc.replaceSelection(text);

	}

}
,
	},
	mounted(){
			
	$('.CodeMirror')[0].CodeMirror.foldCode(CodeMirror.Pos(0, 0));

	$('.CodeMirror')[0].CodeMirror.on("keyup", function (cm, event) {

        if (!cm.state.completionActive && /*Enables keyboard navigation in autocomplete list*/

            event.keyCode != 13) {        /*Enter - do not open autocomplete list just after item has been selected in it*/ 

            CodeMirror.commands.autocomplete(cm, null, {completeSingle: false});

        }

    });

	$('<div class="buffer" id="buffer-' + this.indexEditor + '"><textarea></textarea></div>').appendTo('body')

	},
	watch: {
	
		value: function(val) { 		
	this.code = val

}
,
	},
	data(){
	return {
		
		code:  this.value		,
		}
	},
	props: {
	
		value: {					required: false,			default: () =>  "abcCode",		},
		indexEditor: {					required: false,			default: () =>  0,		},
		options: {					required: false,			default: () =>  ({
  tabSize: 4,

	highlightSelectionMatches: true,

  styleActiveLine: true,

  lineNumbers: true,

  line: true,

  mode: 'abccode',

  theme: 'default',

	lineWrapping: true,

	autohint: true,

	autoRefresh:true,

  extraKeys: {

		"Ctrl-Q": function(cm){ cm.foldCode(cm.getCursor()); },

		"Ctrl-Space": "autocomplete",

    "Shift-Tab": "indentLess",

    "Ctrl-F": function(cm) {

      cm.setOption("fullScreen", !cm.getOption("fullScreen"))
    },

    "Esc": function(cm) {

      if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false)
    },

	},

  foldGutter: true,

  gutters: [

		"CodeMirror-linenumbers", 

		"CodeMirror-foldgutter"

	] 

})
,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#SimpleTextEditor{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

*{
	font-family: 'Pacifico', fantasy, cursive !important;
	
	.text.text-content{
		font-family: 'Pacifico', fantasy, cursive !important;	
	}
}






.item.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.codemirror.editor{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
z-index:0 ; 


.CodeMirror{

	height: 100%;

	z-index: 0;

}}


}


@media (max-width: 600px) {


.item.rec0{


position:absolute !important; 






.codemirror.editor{


position:absolute !important; 






.CodeMirror{

	height: 100%;

	z-index: 0;

}}


}
}

@media (min-width: 601px) {


.item.rec0{


position:absolute !important; 






.codemirror.editor{


position:absolute !important; 






.CodeMirror{

	height: 100%;

	z-index: 0;

}}


}
}

@media (min-width: 961px) {


.item.rec0{


position:absolute !important; 






.codemirror.editor{


position:absolute !important; 






.CodeMirror{

	height: 100%;

	z-index: 0;

}}


}
}

@media (min-width: 1265px) {


.item.rec0{


position:absolute !important; 






.codemirror.editor{


position:absolute !important; 






.CodeMirror{

	height: 100%;

	z-index: 0;

}}


}
}

@media (min-width: 1905px) {


.item.rec0{


position:absolute !important; 






.codemirror.editor{


position:absolute !important; 






.CodeMirror{

	height: 100%;

	z-index: 0;

}}


}
}
}
</style>
