<template>
 <div>  
<div id="ObjectTextBubble"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card carditem01 ' + 'padded'"   id="carditem01" ref="carditem01" :elevation = "4" 
  v-bind:style="{}">
 <div  :class="'rectangle rectanglecarditem011 '"   id="rectanglecarditem011" ref="rectanglecarditem011"  v-bind:style="{ transform: 'rotate(' + (-1*45) + 'deg)', }">  </div>
 <div  :class="'text textcarditem013 '"   id="textcarditem013" ref="textcarditem013"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <div  :class="'mousearea mouseareacarditem019 '"   id="mouseareacarditem019" ref="mouseareacarditem019"  v-bind:style="{}" @click="clo(value)"></div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ObjectTextBubble',

    components: {},
	computed: {
	
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	props: {
	
		name: {					required: false,			default: () =>  "fond50",		},
		clo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		value: {					required: false,			default: () =>  "........",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ObjectTextBubble{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

.padded{
		padding-bottom: 8px;
}



.item.item0{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 0px ; 

margin-top: 0px ; 
margin-right: 0px ; 
height:auto!important ; 
width: calc(100% - (0px + 0px)) !important;


.card.carditem01{


position:relative !important; 
margin: unset;
padding: unset;
transform-origin: top left;


margin-left: 6px ; 

margin-top: 6px ; 
margin-right: 6px ; 
height:auto!important ; 
width: calc(100% - (6px + 6px)) !important;
background-color:#ffffff ; 
border-radius:4px ; max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:-5px ; 
	
	top: 10px ; 
	width:10px!important ; 
	height:10px!important ; 
	transform: rotate(calc(-145deg));
	
	background-color:#ffffff ; 

	
}

	.text.textcarditem013{

	
	position:relative !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	margin-left: 8px ; 
	
	margin-top: 8px ; 
	margin-right: 8px ; 
	width:auto!important ; 
	height:auto!important ; 
	width: calc(100% - (8px + 8px)) !important;
	
	font-size:20px ; 
	text-align:left;
	display: flex;
	.text-content{
	width: 100%;
	}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:relative !important; 






.card.carditem01{


position:relative !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:relative !important; 






.card.carditem01{


position:relative !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:relative !important; 






.card.carditem01{


position:relative !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:relative !important; 






.card.carditem01{


position:relative !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:relative !important; 






.card.carditem01{


position:relative !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:relative !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}
}
</style>
