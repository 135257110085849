<template>
 <div>  
<div id="Butt"> 
         
 <div  :class="'item rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <div  :class="'icon iconrec0 '"   id="iconrec0" ref="iconrec0"  v-bind:style="{color : hColor,fontSize : getUnit(size,'px'),}"><i  :class="ico"></i></div>
 <div  :aria-label = "toolTip" 
 :data-balloon-pos = "'down'" 
 :class="'mousearea mousearearec0 '"   id="mousearearec0" ref="mousearearec0" v-on:mouseover="(hovered = true)"  v-on:mouseout="(hovered = false)"   v-bind:style="{}" @click="clo"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'Butt',

    components: {},
	computed: {
	
		hColor: function(){		
	return (this.hovered? this.hoverColor : this.color)

}
,
	},
	data(){
	return {
		
		hovered:  false		,
		}
	},
	props: {
	
		ico: {					required: false,			default: () =>  "ion-ios-add",		},
		color: {					required: false,			default: () =>  "#6f6f6f",		},
		hoverColor: {					required: false,			default: () =>  "#EAAE14",		},
		clo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		size: {					required: false,			default: () =>  25,		},
		toolTip: {					required: false,			default: () =>  "tool",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#Butt{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

$mainColor: #9696ee;

.custonIcon{
	&:hover{
		color: $mainColor;
	}
}



.item.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.icon.iconrec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

display: flex;
justify-content: center;
align-items: center;
i {
}
}

.mousearea.mousearearec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.item.rec0{


position:absolute !important; 






.icon.iconrec0{


position:absolute !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.mousearea.mousearearec0{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.item.rec0{


position:absolute !important; 






.icon.iconrec0{


position:absolute !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.mousearea.mousearearec0{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.item.rec0{


position:absolute !important; 






.icon.iconrec0{


position:absolute !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.mousearea.mousearearec0{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.item.rec0{


position:absolute !important; 






.icon.iconrec0{


position:absolute !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.mousearea.mousearearec0{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.item.rec0{


position:absolute !important; 






.icon.iconrec0{


position:absolute !important; 





display: flex;
justify-content: center;
align-items: center;
i {
}
}

.mousearea.mousearearec0{


position:absolute !important; 





}


}
}
}
</style>
