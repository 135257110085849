<template>
 <div>  
<div id="DialogItem"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card rec011111 '"   id="rec011111" ref="rec011111" :elevation = "1" 
  v-bind:style="{}">
 <v-img  :class="'networkimage imageNaN1 '"   id="imageNaN1" ref="imageNaN1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_image" 
>  </v-img>
 <div  :class="'item itemrec03 '"   id="itemrec03" ref="itemrec03"  v-bind:style="{}">
 <div  :class="'text textitemrec031 '"   id="textitemrec031" ref="textitemrec031"  v-bind:style="{color : '#74777e',}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <div  :class="'rectangle rectangleitemrec033 '"   id="rectangleitemrec033" ref="rectangleitemrec033"  v-bind:style="{backgroundColor : '#9c9ea7',}">  </div>
 <div  :class="'rectangle select3 '"   id="select3" ref="select3" v-if="selected"  v-bind:style="{backgroundColor : 'transparent',borderColor : primaryColor,}">  </div>  </div>
 <div  :class="'mousearea mousearearec0111115 '"   id="mousearearec0111115" ref="mousearearec0111115"  v-bind:style="{}" @click="select(index)"></div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'DialogItem',

    components: {},
	computed: {
	
		m_image: function(){		
	if(this.image){

		let url = (this.image.formats && this.image.formats.small? 

								this.image.formats.small.url

								:this.image.url)

							

		return this.urlPrefix + url

	} else {

		return "https://picsum.photos/200"

	}

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	props: {
	
		name: {					required: false,			default: () =>  "Projet",		},
		image: {					required: false,			default: () =>  "https://picsum.photos/400",		},
		index: {					required: false,			default: () =>  "",		},
		selected: {					required: false,			default: () =>  false,		},
		select: {			type: Function,			required: false,			default: () => ()=>{},		},
		urlPrefix: {					required: false,			default: () =>  "",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#DialogItem{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.rec011111{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
right: 10px ; 
bottom: 10px ; 
background-color:#ffffff ; 
border-radius:4px ; max-width: initial;min-width: initial;

	.networkimage.imageNaN1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 48px ; 
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}

	.item.itemrec03{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	bottom: 0px ; 
	height:48px!important ; 
	

		.text.textitemrec031{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left: 10px ; 
		
		top: calc(50% + 0px) ; 
		right: 10px ; 
		height:auto!important ; 
		
		color:#74777e ; 
		font-size:15px ; 
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectangleitemrec033{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		height:1px!important ; 
		
		background-color:#9c9ea7 ; 

		
}

		.rectangle.select3{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		right: 10px ; 
		width:14px!important ; 
		height:14px!important ; 
		
		background-color:transparent ; 
		border-radius:10px ; 
		border-style:solid ; 
		border-width:2px ; 
		border-color:undefined ; 

		
}

	
}

	.mousearea.mousearearec0111115{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.rec011111{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imageNaN1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrec03{

	
	position:absolute !important; 
	
	
	
	
	

		.text.textitemrec031{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectangleitemrec033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.select3{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}

	.mousearea.mousearearec0111115{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.rec011111{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imageNaN1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrec03{

	
	position:absolute !important; 
	
	
	
	
	

		.text.textitemrec031{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectangleitemrec033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.select3{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}

	.mousearea.mousearearec0111115{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.rec011111{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imageNaN1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrec03{

	
	position:absolute !important; 
	
	
	
	
	

		.text.textitemrec031{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectangleitemrec033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.select3{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}

	.mousearea.mousearearec0111115{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.rec011111{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imageNaN1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrec03{

	
	position:absolute !important; 
	
	
	
	
	

		.text.textitemrec031{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectangleitemrec033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.select3{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}

	.mousearea.mousearearec0111115{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.rec011111{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.imageNaN1{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}

	.item.itemrec03{

	
	position:absolute !important; 
	
	
	
	
	

		.text.textitemrec031{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

		.rectangle.rectangleitemrec033{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.rectangle.select3{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

	
}

	.mousearea.mousearearec0111115{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}
}
</style>
