<template>
 <div>  
<div id="WorldProject"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card carditem01 ' + 'glow'"   id="carditem01" ref="carditem01" :elevation = "4" 
  v-bind:style="{}">
 <v-img  :class="'networkimage networkimagecarditem011 '"   id="networkimagecarditem011" ref="networkimagecarditem011"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "pjImage" 
>  </v-img>  </v-card>
 <div  :class="'text textitem03 '"   id="textitem03" ref="textitem03"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ pjName }}</div>

   </div>
 <div  :class="'mousearea mouseareaitem05 '"   id="mouseareaitem05" ref="mouseareaitem05"  v-bind:style="{}" @click="goToStore"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'WorldProject',

    components: {},
	methods: {
	
		goToStore: function(){ 		
	window.open(this.$store.state.worldServer + '/#/explore/' + this.pjID, '_blank').focus();

}
,
	},
	props: {
	
		pjImage: {					required: false,			default: () =>  "https://picsum.photos/600",		},
		pjName: {					required: false,			default: () =>  "abc",		},
		pjID: {					required: false,			default: () =>  null,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#WorldProject{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 


.glow::before,
.glow::after {
    content: "";
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(
-45deg
, #00ff665c, #00ffff7a, #ff00ff6b, #ff009978, #099fff);
    background-size: 400%;
    height: calc(100% + 5px);
    width: calc(100% + 5px);
    z-index: -1;
    -webkit-animation: change-data-v-24b20f9a 40s linear infinite;
    animation: change-data-v-24b20f9a 40s linear infinite;
    border-radius: inherit;
    backdrop-filter: blur(26px);
}

@keyframes change{
   0%{
      background-position: 0 0;
   }
   50%{
      background-position: 400% 0;
   }
   100%{
      background-position: 0 0;
   }
}

.glow::before,
.glow::after{
   filter: blur(60px);
   opacity: .5;
}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.carditem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 25px ; 
background-color:#ffffff ; 
border-radius:18px ; max-width: initial;min-width: initial;

	.networkimage.networkimagecarditem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	display: inherit;

	flex: unset;
	border-radius:18px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

	
}


}

.text.textitem03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
height:20px!important ; 

color:#fff ; 
font-size:15px ; 
text-align:center;
align-items:center;
display: flex;
text-overflow: ellipsis ; 
.text-content{
width: 100%;
white-space: nowrap;
overflow: hidden ;
text-overflow: ellipsis ; 
font-weight: bold;
}


}

.mousearea.mouseareaitem05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textitem03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareaitem05{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textitem03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareaitem05{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textitem03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareaitem05{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textitem03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareaitem05{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.networkimage.networkimagecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	display: inherit;

	flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

	
}


}

.text.textitem03{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.mousearea.mouseareaitem05{


position:absolute !important; 





}


}
}
}
</style>
