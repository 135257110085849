var desi = {
  perso: {
    fr: /\S.*(?= est un| est une)/gm,
    en: /\S.*(?= is a)/gm,
  },

  type: {
    fr: "{{nom}}{{genre}}([^\\s]+)",
    en: "{{nom}}{{genre}}([^\\s]+)",
  },

  typeDelete: {
    fr: " est un | est une",
    en: " is a | is an",
  },

  posX: {
    fr: "{{nom}} est située? en x à (\\w+)",
    en: "{{nom}} is located in x to (\\w+)",
  },

  posXDelete: {
    fr: " est situé en x à ",
    en: " is located in x to ",
  },

  posXDeleteF: {
    fr: " est située en x à ",
    en: " is located in x to ",
  },

  taille: {
    fr: "{{nom}} est de taille (\\w+)",
    en: "{{nom}} is size (\\w+)",
  },

  tailleDelete: {
    fr: " est de taille ",
    en: " is size ",
  },

  posY: {
    fr: "{{nom}} est situé en y à (\\w+)",
    en: "{{nom}} is located in y to (\\w+)",
  },

  posYDelete: {
    fr: " est situé en y à ",
    en: " is located in y to ",
  },

  posYDeleteF: {
    fr: " est située en y à ",
    en: " is located in y to ",
  },

  rotation: {
    fr: "{{nom}} tourne de (\\w+)",
    en: "{{nom}} turn of (\\w+)",
  },

  rotationDelete: {
    fr: " tourne de ",
    en: " turn of ",
  },

  visible: {
    fr: "{{nom}} devient (\\w+)",
    en: "{{nom}} becomes (\\w+)",
  },

  visibleDelete: {
    fr: " devient ",
    en: " becomes ",
  },

  visi: {
    fr: "visible",
    en: "visible",
  },

  invisi: {
    fr: " devient invisible",
    en: " becomes invisible",
  },

  deplacable: {
    fr: " est déplaçable",
    en: " is draggable",
  },

  largeur: {
    fr: "{{nom}} a une largeur de (\\w+)",
    en: "{{nom}} has a width of (\\w+)",
  },

  largeurDelete: {
    fr: " a une largeur de ",
    en: " has a width of ",
  },

  longueur: {
    fr: "{{nom}} a une longueur de (\\w+)",
    en: "{{nom}} has a length of (\\w+)",
  },

  longueurDelete: {
    fr: " a une longueur de ",
    en: " has a length of ",
  },

  color: {
    fr: "{{nom}} est de couleur \"?([^\"]+)\"?",
    en: "{{nom}} is of color \"?([^\"]+)\"?",
  },

  bgColor: {
    fr: "{{nom}} a pour couleur de fond \"?([^\"]+)\"?",
    en: "{{nom}} have a Background color \"?([^\"]+)\"?",
  },

  get: {
    fr: /début:(\n|.)*opérations:/,
    en: /begin:(\n|.)*operations:/,
  },

  genre: {
    fr: [" est une ", " est un "],
    en: [" is an ", " is a "],
  },
};

let valueMatch = {
	button: {
		fr: '{{nom}} est un bouton de valeur: "(.*)"',
		en: '{{nom}} is a button of value: "(.*)"'
	},
	texte: {
		fr: '{{nom}} est un texte qui dit: "(.*)"',
		en: '{{nom}} is a text that says: "(.*)"'
	},
	checkBox: {
		fr: '{{nom}} est une case a cocher de valeur: "(.*)"',
		en: '{{nom}} is a checkbox with value: "(.*)"'
	},
	toggle: {
		fr: '{{nom}} est un toggle de valeur: "(.*)"',
		en: '{{nom}} is a toggle with value: "(.*)"'
	},
}

let colorMatch = {
	fr: '{{nom}} est de couleur "(.*)"',
	en: '{{nom}} is of color "(.*)"'
}

let bgColorMatch = {
	fr: '{{nom}} a pour couleur de fond "(.*)"',
	en: '{{nom}} have a Background color "(.*)"'
}

function replaceReg(reg, valueA, valueB, options) {
  var replaced = reg.replace(valueA, valueB);
  return new RegExp(replaced, options);
}

function matchArray(tab, rule) {
  if (tab !== null) {
    return tab[tab.length - 1].match(new RegExp(rule))[1];
  } else {
    return 0;
  }
}

function getCode(code, lang) {
  var reg = desi.get[lang];
  var declaration = code.match(reg)[0];
	//delete comments
	declaration = declaration.replaceAll(/^\s*#.*$/gm, '')
  var elements = [];

  if (declaration !== null) {
    reg = desi.perso[lang];
    var perso = declaration.match(reg);
	
    if (perso !== null) {
			let count = 0;
			
      for (var i = 0; i < perso.length; i++) {
        var ramplaced = perso[i];

        //----------------------------------------------------------------------
        var index = 0;
        while (declaration.match(perso[i] + desi.genre[lang][index]) === null) {
          index++;
        }
        var gen = desi.genre[lang][index];
        var genInitial = index === 0 ? "f" : "m";
        //---------------------------------------------------------------------------------------
        var newReg = replaceReg(desi.posX[lang], "{{nom}}", ramplaced, '');
				let match = declaration.match(newReg)
        var posX = (match? match[1] : 0);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.posY[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var posY = (match? match[1] : 0);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.rotation[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var rot = (match? match[1] : 0);
        //---------------------------------------------------------------------------------------
        newReg = desi.type[lang].replace("{{genre}}", gen).replace("{{nom}}", ramplaced);
				match = declaration.match(newReg)
        var ty = (match? match[1] : 'rectangle');
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.visible[lang], "{{nom}}", perso[i], '');
				match = declaration.match(newReg)
        var visi = (match? (match[1] === desi.visi[lang]? true : false) : true);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.taille[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var taille = (match? match[1] : 1);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.largeur[lang], "{{nom}}", ramplaced);
				match = declaration.match(newReg)
        var largeur = (match? match[1] : 100);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.longueur[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var longueur = (match? match[1] : 100);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.deplacable[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var deplacable = (match? true : false);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.color[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var color = (match? match[1] : null);
        //---------------------------------------------------------------------------------------
        newReg = replaceReg(desi.bgColor[lang], "{{nom}}", ramplaced, '');
				match = declaration.match(newReg)
        var bgColor = (match? match[1] : null);
        //---------------------------------------------------------------------------------------
				for(let va in valueMatch){
        	newReg = replaceReg(valueMatch[va][lang], "{{nom}}", ramplaced, '');
					match = declaration.match(newReg)
					if(match){
						break
					}
				}
        var value = (match ? match[1] : "");
        //----------------------------------------------------------------------------------------
        elements.push({
					index: count,
          ip: i,
          type: ty,
          id: perso[i],
          x: posX,
          y: posY,
          rotation: rot,
          visible: visi,
          scale: taille,
          genre: gen,
          genreInitial: genInitial,
					width: largeur,
					height: longueur,
					color: color,
					bgColor: bgColor,
					deplacable: deplacable,
					value: value
        });
				count++
      }
    }
  }

	console.log("------------------", elements)
  return elements;
}

exports.desi = desi;
exports.valueMatch = valueMatch;
exports.colorMatch = colorMatch;
exports.bgColorMatch = bgColorMatch;
exports.getCode = getCode;
