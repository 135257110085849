<template>
 <div>  
<div id="FDFromDrive"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : '#90909d',}">
 <div  :class="'rectangle content '"   id="content" ref="content"  v-bind:style="{}">
 <div  :class="'rectangle rectanglecontent1 '"   id="rectanglecontent1" ref="rectanglecontent1"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerectanglecontent11 '"   id="rectanglerectanglecontent11" ref="rectanglerectanglecontent11"  v-bind:style="{backgroundColor : '#d3d3d3',}">  </div>
 <v-text-field  :placeholder = "'Le nom de votre projet'" 
 :append-icon = "'ion-md-search'" 
 solo :class="'textfield textfieldrectanglecontent13 '"   id="textfieldrectanglecontent13" ref="textfieldrectanglecontent13" v-model="filterName"  v-bind:style="{}"></v-text-field>  </div>
 <div  :class="'scrollarea scrollareacontent3 '"   id="scrollareacontent3" ref="scrollareacontent3"  v-bind:style="{}">
 <div  :class="'flow gridscrollareacontent31 '"   id="gridscrollareacontent31" ref="gridscrollareacontent31"  v-bind:style="{}">
 <DialogItem :class="'object dialogitemgridscrollareacontent311 '"   id="dialogitemgridscrollareacontent311" ref="dialogitemgridscrollareacontent311" v-for="(item,index) in assets" :key="'dialogitemgridscrollareacontent311' + index"  v-bind:style="{}" :select = "select" 
 :name = "item.name" 
 :image = "item" 
 :index = "index" 
 :selected = "selectId == index" 
 :urlPrefix = "$store.state.apiURL" 
>
</DialogItem>  </div>  </div>  </div>
 <div  :class="'rectangle bottom '"   id="bottom" ref="bottom"  v-bind:style="{}">
 <div  :class="'row rowbottom1 '"   id="rowbottom1" ref="rowbottom1"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :disabled = "selectId == null" 
 :dark = "selectId != null" 
 @click="open" :class="'button open '"   id="open" ref="open" :elevation = "1" 
  v-bind:style="{}"><span>{{ 'Ouvrir' }}</span>
</v-btn>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import DialogItem from '../../../At/Dialog/openDialog/DialogItem'

import axios from 'axios';

import * as main from "../../../Js/main.js"
export default {
	name: 'FDFromDrive',

    components: {DialogItem,},
	methods: {
	
		select: function(index){			
	this.selectId = index;

}
,
		open: function(){		
	if(this.selectId != null){

		this.choose(this.assets[this.selectId].url)

		this.close()

	}

}
,
	},
	created(){
			
  axios.get(this.$store.state.apiURL + '/upload/files', {

 		headers: {

      'Content-Type': 'multipart/form-data',

  		Authorization: `Bearer ${this.$store.state.token}`,

    }

	})

  .then((response) => {

		this.totalAssets = response.data.filter(e => {

			return (e.mime.match("image"))

		})

  })

  .catch((error) => {

    console.log(error);

  });

	},
	computed: {
	
		assets: function(){		
	return this.totalAssets.filter((e) => {

		let rule = new RegExp(this.filterName, 'i')

		return (e.name.match(rule) != null)

	});

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		totalAssets:  []		,
		filterName:  ''		,
		selectId:  null		,
		language:  this.$store.state.language		,
		}
	},
	props: {
	
		choose: {			type: Function,			required: false,			default: () => ()=>{},		},
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#FDFromDrive{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#90909d ; 

.rectangle.content{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 71px ; 

background-color:#ffffff ; 

	.rectangle.rectanglecontent1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:70px!important ; 
	
	background-color:#ffffff ; 

		.rectangle.rectanglerectanglecontent11{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		right: 0px ; 
		bottom: 0px ; 
		height:1px!important ; 
		
		background-color:#d3d3d3 ; 

		
}

		.textfield.textfieldrectanglecontent13{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:18px ; 
		
		top: calc(50% + 0px) ; 
		width:556px!important ; 
		height:46px!important ; 
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	.scrollarea.scrollareacontent3{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 71px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	overflow-x:scroll ; 
	overflow-y:scroll ; background-color:#ffffff ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #cccccc;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #cccccc;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #9696ee;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

		.flow.gridscrollareacontent31{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 10px ; 
		
		top: 0px ; 
		right: 10px ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: wrap;
		flex: initial;background-color:transparent ; 

			.object.dialogitemgridscrollareacontent311{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:202px!important ; 
			height:200px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
bottom: 0px ; 
height:70px!important ; 

background-color:#ffffff ; 

	.row.rowbottom1{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	
	top: calc(50% + 0px) ; 
	right: 20px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.button.open{

		height: auto;
		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:100px!important ; 
		height:30px!important ; 
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.rectangle.rectanglecontent1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecontent11{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.textfield.textfieldrectanglecontent13{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	.scrollarea.scrollareacontent3{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridscrollareacontent31{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.dialogitemgridscrollareacontent311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.rectangle.rectanglecontent1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecontent11{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.textfield.textfieldrectanglecontent13{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	.scrollarea.scrollareacontent3{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridscrollareacontent31{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.dialogitemgridscrollareacontent311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.rectangle.rectanglecontent1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecontent11{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.textfield.textfieldrectanglecontent13{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	.scrollarea.scrollareacontent3{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridscrollareacontent31{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.dialogitemgridscrollareacontent311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.rectangle.rectanglecontent1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecontent11{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.textfield.textfieldrectanglecontent13{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	.scrollarea.scrollareacontent3{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridscrollareacontent31{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.dialogitemgridscrollareacontent311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.content{


position:absolute !important; 







	.rectangle.rectanglecontent1{

	
	position:absolute !important; 
	
	
	
	
	
	

		.rectangle.rectanglerectanglecontent11{

		
		position:absolute !important; 
		
		
		
		
		
		

		
}

		.textfield.textfieldrectanglecontent13{

		
		position:absolute !important; 
		
		
		
		
		
		::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
		
::v-deep &.v-input{
			flex-grow: 0;
input{

}
		}
		}

	
}

	.scrollarea.scrollareacontent3{

	
	position:absolute !important; 
	
	
	
	
	

		.flow.gridscrollareacontent31{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.object.dialogitemgridscrollareacontent311{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}


}

.rectangle.bottom{


position:absolute !important; 







	.row.rowbottom1{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.button.open{

		height: auto;
		
		position:relative !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

	
}


}


}
}
}
</style>
