<template>
 <div>  
<div id="AddDialog"> 
         
 <div  :class="'item rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerec01 '"   id="rectanglerec01" ref="rectanglerec01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectanglerec011 '"   id="mousearearectanglerec011" ref="mousearearectanglerec011"  v-bind:style="{}" @click="close"></div>  </div>
 <v-card 
 :class="'card cardrec03 '"   id="cardrec03" ref="cardrec03"  v-bind:style="{backgroundColor : '#f3f3f3',}">
 <v-card 
 :class="'card cardcardrec031 '"   id="cardcardrec031" ref="cardcardrec031"  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'item itemcardcardrec0315 '"   id="itemcardcardrec0315" ref="itemcardcardrec0315"  v-bind:style="{}">
 <div  :class="'icon iconcardcardrec0311111 '"   id="iconcardcardrec0311111" ref="iconcardcardrec0311111"  v-bind:style="{color : '#fff',fontSize : getUnit(26,'px'),}"><i  :class="'ion-md-close'"></i></div>
 <div  :class="'mousearea mouseareaitemcardcardrec03153 '"   id="mouseareaitemcardcardrec03153" ref="mouseareaitemcardcardrec03153"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'text textcardcardrec0313 '"   id="textcardcardrec0313" ref="textcardcardrec0313"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ 'Ajouter un élément' }}</div>

   </div>  </v-card>
 <div  :class="'column columncardrec033 '"   id="columncardrec033" ref="columncardrec033"  v-bind:style="{}">
 <v-card 
 :class="'card graphik '"   id="graphik" ref="graphik"  v-bind:style="{}">
 <div  :class="'text textgraphik1 '"   id="textgraphik1" ref="textgraphik1"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ $t('AddDialog.graphique') }}</div>

   </div>
 <div  :class="'column columngraphik3 '"   id="columngraphik3" ref="columngraphik3"  v-bind:style="{}">
 <AddButton :class="'object addPerso '"   id="addPerso" ref="addPerso"  v-bind:style="{}" :action = "openAddPersonnage" 
 :value = "$t('AddDialog.addPerso')" 
 :source = "'Resources/App/niv/21.png'" 
 :hoverColor = "primaryColor" 
>
</AddButton>
 <AddButton :class="'object addAnimation '"   id="addAnimation" ref="addAnimation"  v-bind:style="{}" :action = "openAddAnimation" 
 :value = "$t('AddDialog.addAnimation')" 
 :source = "'Resources/App/niv/22.png'" 
 :hoverColor = "primaryColor" 
>
</AddButton>
 <AddButton :class="'object addImage '"   id="addImage" ref="addImage"  v-bind:style="{}" :action = "openAddImage" 
 :value = "$t('AddDialog.addImg')" 
 :source = "'Resources/App/niv/23.png'" 
 :hoverColor = "primaryColor" 
>
</AddButton>  </div>  </v-card>
 <v-card 
 :class="'card audio '"   id="audio" ref="audio"  v-bind:style="{}">
 <div  :class="'text textgraphik1 '"   id="textgraphik1" ref="textgraphik1"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ 'Audio' }}</div>

   </div>
 <div  :class="'column columngraphik3 '"   id="columngraphik3" ref="columngraphik3"  v-bind:style="{}">
 <AddButton :class="'object addAudio '"   id="addAudio" ref="addAudio"  v-bind:style="{}" :action = "openAddAudio" 
 :value = "$t('AddDialog.addMusic')" 
 :source = "'Resources/App/niv/12.png'" 
 :hoverColor = "primaryColor" 
>
</AddButton>  </div>  </v-card>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>
import AddButton from '../../At/Dialog/AddDialog/AddButton'


export default {
	name: 'AddDialog',

    components: {AddButton,},
	computed: {
	
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	props: {
	
		openAddPersonnage: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		openAddAnimation: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		openAddImage: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		openAddAudio: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AddDialog{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectanglerec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	}


}

.card.cardrec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:364px!important ; 
height:510px!important ; 

background-color:#f3f3f3 ; 
border-radius:4px ; max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:55px!important ; 
	background-color:#ffffff ; 
	border-radius:4px ; max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:14px ; 
		
		top: calc(50% + 0px) ; 
		width:44px!important ; 
		height:33px!important ; 
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:0px ; 
			
			top: calc(50% + 0px) ; 
			width:44px!important ; 
			height:36px!important ; 
			
			color:#fff ; 
			font-size:26px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:77px ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#fff ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 70px ; 
	right: 0px ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:20px ; background-color: transparent ; 

		.card.graphik{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:280px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 10px ; 
			
			top: 10px ; 
			right: 10px ; 
			height:auto!important ; 
			
			font-size:16px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 50px ; 
			right: 0px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.object.addPerso{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:70px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addAnimation{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:70px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addImage{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:70px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.card.audio{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:130px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 10px ; 
			
			top: 10px ; 
			right: 10px ; 
			height:auto!important ; 
			
			font-size:16px ; 
			text-overflow: ellipsis ; 
			.text-content{
			width: 100%;
			white-space: nowrap;
			overflow: hidden ;
			text-overflow: ellipsis ; 
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 50px ; 
			right: 0px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.object.addAudio{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:70px!important ; 
				width: calc(100% - (0px + 0px)) !important;
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.graphik{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addPerso{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addAnimation{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addImage{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.card.audio{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addAudio{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.graphik{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addPerso{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addAnimation{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addImage{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.card.audio{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addAudio{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.graphik{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addPerso{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addAnimation{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addImage{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.card.audio{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addAudio{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.graphik{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addPerso{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addAnimation{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addImage{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.card.audio{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addAudio{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.graphik{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addPerso{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addAnimation{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

				.object.addImage{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

		.card.audio{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textgraphik1{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.column.columngraphik3{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.object.addAudio{

				
				position:relative !important; 
				
				
				
				
				
					::v-deep > * {
						min-width: unset;
						min-height: unset;
					}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
