<template>
 <div>  
<div id="appCreate"> 
         
 <div  :class="'item rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerec01 '"   id="rectanglerec01" ref="rectanglerec01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectanglerec011 '"   id="mousearearectanglerec011" ref="mousearearectanglerec011"  v-bind:style="{}" @click="close"></div>  </div>
 <v-card 
 :class="'card cardrec03 '"   id="cardrec03" ref="cardrec03"  v-bind:style="{backgroundColor : '#f3f3f3',}">
 <v-card 
 :class="'card cardcardrec031 '"   id="cardcardrec031" ref="cardcardrec031"  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'item itemcardcardrec0315 '"   id="itemcardcardrec0315" ref="itemcardcardrec0315"  v-bind:style="{}">
 <div  :class="'icon iconcardcardrec0311111 '"   id="iconcardcardrec0311111" ref="iconcardcardrec0311111"  v-bind:style="{color : '#fff',fontSize : getUnit(26,'px'),}"><i  :class="'ion-md-close'"></i></div>
 <div  :class="'mousearea mouseareaitemcardcardrec03153 '"   id="mouseareaitemcardcardrec03153" ref="mouseareaitemcardcardrec03153"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'text textcardcardrec0313 '"   id="textcardcardrec0313" ref="textcardcardrec0313"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ 'Nouveau projet' }}</div>

   </div>  </v-card>
 <div  :class="'column columncardrec033 '"   id="columncardrec033" ref="columncardrec033"  v-bind:style="{}">
 <div  :class="'item itemcolumncardrec0331 '"   id="itemcolumncardrec0331" ref="itemcolumncardrec0331"  v-bind:style="{}">
 <div  :class="'rectangle logorec '"   id="logorec" ref="logorec"  v-bind:style="{}">
 <v-img  :class="'networkimage imagelogorec1 '"   id="imagelogorec1" ref="imagelogorec1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "icon" 
>  </v-img>
 <v-btn tag="div" 
 :color = "primaryColor" 
 :fab = "true" 
 :onlyIcon = "true" 
 :dark = "true" 
 @click="chooseIcon" :class="'button buttonlogorec3 '"   id="buttonlogorec3" ref="buttonlogorec3"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-camera' !== undefined"  :color = "'#fff'" 
>{{ 'ion-ios-camera' }}
</v-icon></v-btn>  </div>  </div>
 <v-card 
 :class="'card cardcolumncardrec0333 '"   id="cardcolumncardrec0333" ref="cardcolumncardrec0333"  v-bind:style="{}">
 <v-text-field  :placeholder = "'nom du projet'" 
 :class="'textfield textfieldcardcolumncardrec03331 '"   id="textfieldcardcolumncardrec03331" ref="textfieldcardcolumncardrec03331" v-model="name"  v-bind:style="{color : primaryColor,}"></v-text-field>
 <div  :class="'icon iconcardcolumncardrec03333 '"   id="iconcardcolumncardrec03333" ref="iconcardcolumncardrec03333"  v-bind:style="{color : primaryColor,fontSize : getUnit(20,'px'),}"><i  :class="'ion-md-create'"></i></div>  </v-card>
 <div  :class="'text textcolumncardrec0335 '"   id="textcolumncardrec0335" ref="textcolumncardrec0335"  v-bind:style="{color : '#797a83',}">
<div class="text-content" :style = "{}">{{ 'Le nom du projet ne doit pas contenir des charactères spéciaux' }}</div>

   </div>  </div>
 <div  :class="'column columncardrec035 '"   id="columncardrec035" ref="columncardrec035"  v-bind:style="{}">
 <v-card 
 :class="'card bottomcard33333 '"   id="bottomcard33333" ref="bottomcard33333" :elevation = "0" 
  v-bind:style="{}">
 <div  :class="'column columnbottomcard511111 '"   id="columnbottomcard511111" ref="columnbottomcard511111"  v-bind:style="{}">
 <div  :class="'text textbottomcard111111 '"   id="textbottomcard111111" ref="textbottomcard111111"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ 'Langue' }}</div>

   </div>
 <div  :class="'item itemcolumnbottomcard55 '"   id="itemcolumnbottomcard55" ref="itemcolumnbottomcard55"  v-bind:style="{}">
 <v-select
          :items = "languageList" solo :class="'select selectitemcolumnbottomcard551 '"   id="selectitemcolumnbottomcard551" ref="selectitemcolumnbottomcard551" v-model="selectedLanguage"  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </div>  </v-card>
 <div  :class="'rectangle divider44444 '"   id="divider44444" ref="divider44444"  v-bind:style="{backgroundColor : '#f3f3f3',}">  </div>
 <v-card 
 :class="'card bottomcard3333355555 '"   id="bottomcard3333355555" ref="bottomcard3333355555" :elevation = "0" 
  v-bind:style="{}">
 <div  :class="'text textbottomcard33333555551 '"   id="textbottomcard33333555551" ref="textbottomcard33333555551"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ 'Enregistrer' }}</div>

 
 <div  :class="'mousearea mouseareatextbottomcard333335555511 '"   id="mouseareatextbottomcard333335555511" ref="mouseareatextbottomcard333335555511"  v-bind:style="{}" @click="createProject"></div>  </div>  </v-card>
 <div  :class="'text errorText '"   id="errorText" ref="errorText" v-if="errorVisible"  v-bind:style="{color : '#ff5c0a',}">
<div class="text-content" :style = "{}">{{ error }}</div>

   </div>  </div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>

import lg from '../../Js/language.json'
import ac from 'artefact-controller'
import langMap from '../../Js/languageMap.json'
export default {
	name: 'appCreate',

    components: {},
	methods: {
	
		showError: function(message) { 		
	this.errorVisible = true

	this.error = message

}
,
		chooseIcon: function(){		
	this.atController.fileOpen({multiple: false, accept: 'image/*' }, (file) => {

		

  	const reader = new FileReader();

  	reader.addEventListener('load', (event) => {

    	this.icon = event.target.result;

			this.$store.commit('iconPath', this.icon)

  	});

  	reader.readAsDataURL(file[0]); 

		this.iconBlob = file[0];

		this.$store.commit('iconBlob', file[0])

	});

}
,
		createProject: function() { 		
	if(this.iconBlob){

		if(this.name){

			this.$store.commit('project', this.project)

			this.$store.commit('pjDatas', this.pjDatas)

			this.$store.commit('setSaved', false)

			this.$store.commit('changeLang', this.currentLang)

			this.create()

		} else {

			this.showError('Il faut donner un nom à votre projet.')

		}

	} else {

		this.showError('Il faut choisir une image pour votre projet.')

	}

}
,
	},
	computed: {
	
		languageList: function(){		
	let list = this.$store.state.languageList

	return list.map(e => langMap[e.toUpperCase()])

}
,
		currentLang: function(){			
	let target = "Francais"

	

	switch(this.selectedLanguage){

		case 'Francais':

			target = "fr"

			break;

		case 'English':

			target = "en"

			break;

	}

		

	return target	

}
,
		project: function(){ 		
	return {

		name: this.name

	}

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
		pjDatas: function(){		
   return {

	"nom": this.name,

	   "langue": this.currentLang,

	   "curStage": "stage1",

	"url": "",

	   "code": {

	       "stage1": this.languages.defaultCode[this.currentLang],

	       "stage2": this.languages.defaultCode[this.currentLang],

	       "stage3": this.languages.defaultCode[this.currentLang],

	       "stage4": this.languages.defaultCode[this.currentLang],

	       "stage5": this.languages.defaultCode[this.currentLang],

	       "stage6": this.languages.defaultCode[this.currentLang],

	       "stage7": this.languages.defaultCode[this.currentLang],

	       "stage8": this.languages.defaultCode[this.currentLang],

	       "stage9": this.languages.defaultCode[this.currentLang],

	       "stage10": this.languages.defaultCode[this.currentLang],

	       "stage11": this.languages.defaultCode[this.currentLang],

	       "stage12": this.languages.defaultCode[this.currentLang],

	       "stage13": this.languages.defaultCode[this.currentLang],

	       "stage14": this.languages.defaultCode[this.currentLang],

	       "stage15": this.languages.defaultCode[this.currentLang],

	       "stage16": this.languages.defaultCode[this.currentLang],

	       "stage17": this.languages.defaultCode[this.currentLang],

	       "stage18": this.languages.defaultCode[this.currentLang],

	       "stage19": this.languages.defaultCode[this.currentLang],

	       "stage20": this.languages.defaultCode[this.currentLang]

	   },

	   "imported": {

	      "image": [],

       "animation": [],

       "personnage": [],

       "song": []

	   }

	}

}
,
	},
	data(){
	return {
		
		languages:  lg		,
		language:  this.$store.state.language		,
		atController:  new ac()		,
		error:  null		,
		errorVisible:  false		,
		icon:  "https://picsum.photos/500"		,
		iconBlob:  null		,
		name:  null		,
		selectedLanguage:  "Francais"		,
		}
	},
	props: {
	
		create: {			type: Function,			required: false,			default: () => ()=>{},		},
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#appCreate{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectanglerec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	}


}

.card.cardrec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;

transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);

left: calc(50% + 0px) ; 

top: calc(50% + 0px) ; 
width:364px!important ; 
height:520px!important ; 

background-color:#f3f3f3 ; 
border-radius:4px ; max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	height:55px!important ; 
	background-color:#ffffff ; 
	border-radius:4px ; max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:14px ; 
		
		top: calc(50% + 0px) ; 
		width:44px!important ; 
		height:33px!important ; 
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:0px ; 
			
			top: calc(50% + 0px) ; 
			width:44px!important ; 
			height:36px!important ; 
			
			color:#fff ; 
			font-size:26px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:77px ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#fff ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 70px ; 
	right: 0px ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:4px ; background-color: transparent ; 

		.item.itemcolumncardrec0331{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:195px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		

			.rectangle.logorec{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
			
			left: calc(50% + 0px) ; 
			
			top: 0px ; 
			width:170px!important ; 
			height:170px!important ; 
			
			background-color:#ffffff ; 
			border-radius:85px ; 

				.networkimage.imagelogorec1{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				display: inherit;

				flex: unset;
				border-radius:80px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

				
}

				.button.buttonlogorec3{

				height: auto;
				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				right: 0px ; 
				bottom: 0px ; 
				width:56px!important ; 
				height:56px!important ; 
				
				font-size:32px ; 
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				font-size:32px ; 
				}
				}

			
}

		
}

		.card.cardcolumncardrec0333{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:46px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.textfield.textfieldcardcolumncardrec03331{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 15px ; 
			
			top: 0px ; 
			right: 50px ; 
			bottom: 0px ; 
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.icon.iconcardcolumncardrec03333{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			
			top: calc(50% + 0px) ; 
			right: 5px ; 
			width:45px!important ; 
			height:45px!important ; 
			
			font-size:20px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncardrec0335{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 10px ; 
		
		margin-right: 10px ; 
		height:auto!important ; 
		width: calc(100% - (10px + 10px)) !important;
		
		color:#797a83 ; 
		font-size:12px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec035{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	bottom: 15px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;background-color: transparent ; 

		.card.bottomcard33333{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:90px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.column.columnbottomcard511111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 15px ; 
			
			top: 5px ; 
			right: 15px ; 
			height:auto!important ; 
			
			display: flex;
			flex-wrap: nowrap;
			flex: initial;
			flex-direction: column;background-color: transparent ; 

				.text.textbottomcard111111{

				align-self: flex-start;
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				
				width:auto!important ; 
				height:auto!important ; 
				
				font-size:15px ; 
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumnbottomcard55{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 0px ; 
				
				margin-right: 0px ; 
				height:auto!important ; 
				width: calc(100% - (0px + 0px)) !important;
				

					.select.selectitemcolumnbottomcard551{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
					
					left: calc(50% + 0px) ; 
					
					top: 0px ; 
					width:172px!important ; 
					height:25px!important ; 
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

		
}

		.rectangle.divider44444{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:1px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
		background-color:#f3f3f3 ; 

		
}

		.card.bottomcard3333355555{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:50px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		background-color:#ffffff ; 
		border-radius:0px ; max-width: initial;min-width: initial;

			.text.textbottomcard33333555551{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
			
			left: calc(50% + 0px) ; 
			
			top: calc(50% + 0px) ; 
			width:auto!important ; 
			height:auto!important ; 
			
			font-size:20px ; 
			.text-content{
			width: 100%;
			}

				.mousearea.mouseareatextbottomcard333335555511{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 0px ; 
				
				top: 0px ; 
				right: 0px ; 
				bottom: 0px ; 
				
				cursor: pointer;
				}

			
}

		
}

		.text.errorText{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 5px ; 
		
		margin-right: 5px ; 
		height:auto!important ; 
		width: calc(100% - (5px + 5px)) !important;
		
		color:#ff5c0a ; 
		font-size:15px ; 
		text-align:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncardrec0331{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.logorec{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.imagelogorec1{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec3{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.cardcolumncardrec0333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.textfield.textfieldcardcolumncardrec03331{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.icon.iconcardcolumncardrec03333{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncardrec0335{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec035{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.bottomcard33333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnbottomcard511111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textbottomcard111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumnbottomcard55{

				
				position:relative !important; 
				
				
				
				
				

					.select.selectitemcolumnbottomcard551{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

		
}

		.rectangle.divider44444{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.card.bottomcard3333355555{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textbottomcard33333555551{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.mousearea.mouseareatextbottomcard333335555511{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

		.text.errorText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncardrec0331{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.logorec{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.imagelogorec1{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec3{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.cardcolumncardrec0333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.textfield.textfieldcardcolumncardrec03331{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.icon.iconcardcolumncardrec03333{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncardrec0335{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec035{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.bottomcard33333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnbottomcard511111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textbottomcard111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumnbottomcard55{

				
				position:relative !important; 
				
				
				
				
				

					.select.selectitemcolumnbottomcard551{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

		
}

		.rectangle.divider44444{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.card.bottomcard3333355555{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textbottomcard33333555551{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.mousearea.mouseareatextbottomcard333335555511{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

		.text.errorText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncardrec0331{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.logorec{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.imagelogorec1{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec3{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.cardcolumncardrec0333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.textfield.textfieldcardcolumncardrec03331{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.icon.iconcardcolumncardrec03333{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncardrec0335{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec035{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.bottomcard33333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnbottomcard511111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textbottomcard111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumnbottomcard55{

				
				position:relative !important; 
				
				
				
				
				

					.select.selectitemcolumnbottomcard551{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

		
}

		.rectangle.divider44444{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.card.bottomcard3333355555{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textbottomcard33333555551{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.mousearea.mouseareatextbottomcard333335555511{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

		.text.errorText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncardrec0331{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.logorec{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.imagelogorec1{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec3{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.cardcolumncardrec0333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.textfield.textfieldcardcolumncardrec03331{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.icon.iconcardcolumncardrec03333{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncardrec0335{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec035{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.bottomcard33333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnbottomcard511111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textbottomcard111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumnbottomcard55{

				
				position:relative !important; 
				
				
				
				
				

					.select.selectitemcolumnbottomcard551{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

		
}

		.rectangle.divider44444{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.card.bottomcard3333355555{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textbottomcard33333555551{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.mousearea.mouseareatextbottomcard333335555511{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

		.text.errorText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.item.itemcolumncardrec0331{

		
		position:relative !important; 
		
		
		
		
		

			.rectangle.logorec{

			
			position:absolute !important; 
			
			
			
			
			
			

				.networkimage.imagelogorec1{

				
				position:absolute !important; 
				
				
				
				
				
				display: inherit;

				flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

				
}

				.button.buttonlogorec3{

				height: auto;
				
				position:absolute !important; 
				
				
				
				
				
				text-transform:unset ; 
				min-width: auto;
				i.v-icon{
				}
				}

			
}

		
}

		.card.cardcolumncardrec0333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.textfield.textfieldcardcolumncardrec03331{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

			.icon.iconcardcolumncardrec03333{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

		.text.textcolumncardrec0335{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}

	.column.columncardrec035{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.card.bottomcard33333{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.column.columnbottomcard511111{

			
			position:absolute !important; 
			
			
			
			
			
			
			
			

				.text.textbottomcard111111{

				
				position:relative !important; 
				
				
				
				
				
				.text-content{
				width: 100%;
				}

				
}

				.item.itemcolumnbottomcard55{

				
				position:relative !important; 
				
				
				
				
				

					.select.selectitemcolumnbottomcard551{

					
					position:absolute !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

		
}

		.rectangle.divider44444{

		
		position:relative !important; 
		
		
		
		
		
		

		
}

		.card.bottomcard3333355555{

		
		position:relative !important; 
		
		
		
		
		max-width: initial;min-width: initial;

			.text.textbottomcard33333555551{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

				.mousearea.mouseareatextbottomcard333335555511{

				
				position:absolute !important; 
				
				
				
				
				
				}

			
}

		
}

		.text.errorText{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}


}
}
}
</style>
