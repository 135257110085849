<template>
 <div>  
<div id="ButtB"> 
         
 <div  :class="'item but '"   id="but" ref="but"  v-bind:style="{}">
 <div  :class="'rectangle rectanglebut5 '"   id="rectanglebut5" ref="rectanglebut5"  v-bind:style="{backgroundColor : bgColor,borderRadius : getUnit(radius,'px'),borderWidth : getUnit((active ? 2 : 0),'px'),borderColor : activeColor, borderStyle:'solid' ,  }">  </div>
 <v-img  :class="'image imagebut1 '"   id="imagebut1" ref="imagebut1"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../../' + source + '')" 
>  </v-img>
 <div  :class="'mousearea mouseareabut3 '"   id="mouseareabut3" ref="mouseareabut3"  v-bind:style="{}" @click="clo(sectionName)"></div>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'ButtB',

    components: {},
	props: {
	
		clo: {			type: Function,			required: false,			default: () => ()=>{},		},
		source: {					required: false,			default: () =>  "Resources/Acceuil/sound.png",		},
		sectionName: {					required: false,			default: () =>  "fond2",		},
		active: {					required: false,			default: () =>  false,		},
		activeColor: {					required: false,			default: () =>  "#EAAE14",		},
		radius: {					required: false,			default: () =>  50,		},
		bgColor: {					required: false,			default: () =>  "gray",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ButtB{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.but{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectanglebut5{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 2px ; 

top: 2px ; 
right: 2px ; 
bottom: 2px ; 

background-color:#ffffff ; 


}

.image.imagebut1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 6px ; 

top: 5px ; 
right: 6px ; 
bottom: 6px ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

cursor: pointer;
}


}


@media (max-width: 600px) {


.item.but{


position:absolute !important; 






.rectangle.rectanglebut5{


position:absolute !important; 








}

.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 601px) {


.item.but{


position:absolute !important; 






.rectangle.rectanglebut5{


position:absolute !important; 








}

.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 961px) {


.item.but{


position:absolute !important; 






.rectangle.rectanglebut5{


position:absolute !important; 








}

.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 1265px) {


.item.but{


position:absolute !important; 






.rectangle.rectanglebut5{


position:absolute !important; 








}

.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}

@media (min-width: 1905px) {


.item.but{


position:absolute !important; 






.rectangle.rectanglebut5{


position:absolute !important; 








}

.image.imagebut1{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.mousearea.mouseareabut3{


position:absolute !important; 





}


}
}
}
</style>
