<template>
 <div>  
<div id="PjDetail"> 
         
 <div  :class="'item details22222 '"   id="details22222" ref="details22222"  v-bind:style="{}">
 <div  :class="'rectangle rectangleeditorZone133333 '"   id="rectangleeditorZone133333" ref="rectangleeditorZone133333"  v-bind:style="{backgroundColor : '#ededed',}">
 <div  :class="'row rowrectangletool1111111 '"   id="rowrectangletool1111111" ref="rowrectangletool1111111"  v-bind:style="{}">
 <Butt :class="'object homeButt '"   id="homeButt" ref="homeButt"  v-bind:style="{}" :clo = "close" 
 :ico = "'ion-md-close'" 
 :toolTip = "'Acceuil'" 
>
</Butt>
 <div  :class="'item separator '"   id="separator" ref="separator"  v-bind:style="{}">  </div>
 <div  :class="'text textrowrectangletool11111113 '"   id="textrowrectangletool11111113" ref="textrowrectangletool11111113"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ 'Informations du projet' }}</div>

   </div>  </div>  </div>
 <div  :class="'rectangle rectangledetails3 '"   id="rectangledetails3" ref="rectangledetails3"  v-bind:style="{backgroundColor : '#ededed',}">
 <div  :class="'scrollarea scrollarearec0111111 '"   id="scrollarearec0111111" ref="scrollarearec0111111"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columnrec0311111 '"   id="columnrec0311111" ref="columnrec0311111"  v-bind:style="{}">
 <v-card 
 :class="'card imageZone '"   id="imageZone" ref="imageZone"  v-bind:style="{}">
 <div  :class="'item itemimageZone3 '"   id="itemimageZone3" ref="itemimageZone3"  v-bind:style="{}">
 <v-img  :class="'networkimage networkimageimageZone122222 '"   id="networkimageimageZone122222" ref="networkimageimageZone122222"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "m_pjImage" 
>  </v-img>
 <v-btn tag="div" 
 :color = "secondaryColor" 
 :fab = "true" 
 :onlyIcon = "true" 
 :dark = "true" 
 @click="chooseIcon" :class="'button buttonlogorec311111 '"   id="buttonlogorec311111" ref="buttonlogorec311111"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-camera' !== undefined"  :color = "'#fff'" 
>{{ 'ion-ios-camera' }}
</v-icon></v-btn>  </div>  </v-card>
 <v-card 
 :class="'card nameZone '"   id="nameZone" ref="nameZone" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'column columnnameZone1 '"   id="columnnameZone1" ref="columnnameZone1"  v-bind:style="{}">
 <div  :class="'text textcolumnnameZone11 '"   id="textcolumnnameZone11" ref="textcolumnnameZone11"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ $t('app.pjName') }}</div>

   </div>
 <v-text-field  :placeholder = "'Nom du projet'" 
 :class="'textfield nameInput '"   id="nameInput" ref="nameInput" v-model="m_pjName"  v-bind:style="{color : secondaryColor,}"></v-text-field>  </div>  </v-card>
 <v-card 
 :class="'card tagZone '"   id="tagZone" ref="tagZone" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'column columnnameZone1 '"   id="columnnameZone1" ref="columnnameZone1"  v-bind:style="{}">
 <div  :class="'text textcolumnnameZone11 '"   id="textcolumnnameZone11" ref="textcolumnnameZone11"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ $t('app.pjTag') }}</div>

   </div>
 <v-select
           :placeholder = "$t('app.pjTag')" 
 :prepend-inner-icon = "'ion-ios-at'" 
:items = "tagsList" :class="'select tagsInput '"   id="tagsInput" ref="tagsInput" v-model="m_pjTags" :multiple = "true" 
 :chips = "true" 
 :item-text = "'name'" 
 :item-value = "'id'" 
  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </v-card>
 <v-card 
 :class="'card nameZone55555 '"   id="nameZone55555" ref="nameZone55555" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'column columnnameZone1 '"   id="columnnameZone1" ref="columnnameZone1"  v-bind:style="{}">
 <div  :class="'text textcolumnnameZone11 '"   id="textcolumnnameZone11" ref="textcolumnnameZone11"  v-bind:style="{color : secondaryColor,}">
<div class="text-content" :style = "{}">{{ 'Description' }}</div>

   </div>
 <v-textarea  :class="'textarea textareacolumnnameZone13 '"   id="textareacolumnnameZone13" ref="textareacolumnnameZone13" v-model="m_pjDesc"  v-bind:style="{color : secondaryColor,}"></v-textarea>  </div>  </v-card>
 <v-card 
 :class="'card deleteZone '"   id="deleteZone" ref="deleteZone" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'row rowrectangletool111111111111 '"   id="rowrectangletool111111111111" ref="rowrectangletool111111111111"  v-bind:style="{}">
 <Butt :class="'object homeButt '"   id="homeButt" ref="homeButt"  v-bind:style="{}" :clo = "updatePjData" 
 :ico = "'ion-md-done-all'" 
 :toolTip = "'Acceuil'" 
 :color = "'#5bf89a'" 
 :hoverColor = "'pink'" 
>
</Butt>
 <div  :class="'text textrowrectangletool11111113 '"   id="textrowrectangletool11111113" ref="textrowrectangletool11111113"  v-bind:style="{color : '#5bf89a',}">
<div class="text-content" :style = "{}">{{ 'Enregistrer les modifications' }}</div>

   </div>  </div>  </v-card>
 <v-card 
 :class="'card deleteZone8 '"   id="deleteZone8" ref="deleteZone8" :elevation = "1" 
  v-bind:style="{}">
 <div  :class="'row rowrectangletool111111111111 '"   id="rowrectangletool111111111111" ref="rowrectangletool111111111111"  v-bind:style="{}">
 <Butt :class="'object homeButt '"   id="homeButt" ref="homeButt"  v-bind:style="{}" :clo = "deletePj" 
 :ico = "'ion-md-trash'" 
 :toolTip = "'Acceuil'" 
 :color = "'#f85656'" 
 :hoverColor = "'pink'" 
>
</Butt>
 <div  :class="'text textrowrectangletool11111113 '"   id="textrowrectangletool11111113" ref="textrowrectangletool11111113"  v-bind:style="{color : '#f85656',}">
<div class="text-content" :style = "{}">{{ 'Supprimer le projet' }}</div>

   </div>  </div>  </v-card>  </div>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import Butt from '../../At/Butt'

import ac from 'artefact-controller'
import axios from 'axios';
	
import * as main from "../../Js/main.js"
export default {
	name: 'PjDetail',

    components: {Butt,},
	methods: {
	
		setup: function(){ 		
	if(this.m_project){

		this.m_pjName = this.m_project.name

	} else {

		this.m_pjName = null

	}

	

	if(this.m_project && this.m_project.tags){

		this.m_pjTags = this.m_project.tags

	} else {

		this.m_pjTags = null

	}

	

	if(this.pjDesc == "" || this.m_pjData.description == undefined){

		this.m_pjDesc = null

	} else {

		this.m_pjDesc = this.m_pjData.description

	}

	

	if(this.m_project && (this.m_project.description_image)){

		this.m_pjImage =  this.$store.state.apiURL + this.m_project.description_image.url

	} else {

		if(this.iconPath == "" || this.iconPath == undefined){

			this.m_pjImage =  "https://picsum.photos/200"

		} else {

			this.m_pjImage =  this.iconPath

		}

	}

}
,
		updatePjData: function(){		
	this.m_project.name = this.m_pjName;

	this.m_pjData.tags = this.m_pjTags;

	this.m_pjData.nom = this.m_pjName;

	this.m_pjData.description = this.m_pjDesc;

	

	if(this.m_project.id){	

		if(this.imageChanged){

			this.updateImage()

		} else {

			this.updatePjDataNext()

		}

	} else {

			this.$store.commit('project', this.m_project)

			this.$store.commit('pjDatas', this.m_pjData)

	}	

}
,
		updatePjDataNext: function(imgData){		
	main.updateProject(this.m_pjData, imgData)

	.then((response) => {

     this.$store.dispatch('setProject', response.data);

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		updateImage: function(){			
	main.uploadFile(this.iconBlob)

	.then((data) => {

		this.imageChanged = false

		this.updatePjDataNext(data.data[0])

  })

  .catch((error) => {

 		console.log(error);

	});

}
,
		deletePj: function(){		
	console.log("delete it")

}
,
		getTags: function() { 		
	main.getTags()

	.then((response)=>{

	  this.tagsList = response.data

	})

}
,
		chooseIcon: function(){		
	this.atController.fileOpen({multiple: false, accept: 'image/*' }, (file) => {

		

  	const reader = new FileReader();

  	reader.addEventListener('load', (event) => {

    	this.m_pjImage = event.target.result;

			this.$store.commit('iconPath', this.m_pjImage)

			this.imageChanged = true

  	});

  	reader.readAsDataURL(file[0]); 

		console.log(file[0])

		this.$store.commit('iconBlob', file[0])

	});

}
,
	},
	mounted(){
			
	this.setup()

	this.getTags()

	},
	computed: {
	
		language: function(){		
	return this.$store.state.language

}	
,
		m_project: function(){		
	return this.$store.state.project

}
,
		m_pjData: function(){		
	return this.$store.state.pjDatas

}
,
		iconPath: function(){		
	return this.$store.state.iconPath

}
,
		iconBlob: function(){		
	return this.$store.state.iconBlob

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	watch: {
	
		m_project: function(val) { 		
	this.setup()

}
,
	},
	data(){
	return {
		
		m_pjName:  null		,
		m_pjDesc:  null		,
		m_pjImage:  ""		,
		m_pjTags:  null		,
		tagsList:  []		,
		imageChanged:  false		,
		atController:  new ac()		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () => ()=>{},		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#PjDetail{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.details22222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectangleeditorZone133333{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 1px ; 

top: 0px ; 
right: 0px ; 
height:58px!important ; 

background-color:#ededed ; 

	.row.rowrectangletool1111111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left:10px ; 
	
	top: calc(50% + 0px) ; 
	width:auto!important ; 
	height:46px!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: row;background-color:transparent ; 

		.object.homeButt{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:35px!important ; 
		height:46px!important ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.item.separator{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		width:50px!important ; 
		height:46px!important ; 
		

		
}

		.text.textrowrectangletool11111113{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		margin-top: 0px ; 
		margin-bottom: 0px ; 
		width:auto!important ; 
		height: calc(100% - (0px + 0px)) !important;
		
		font-size:18px ; 
		align-items:center;
		display: flex;
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectangledetails3{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 59px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ededed ; 

	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	overflow-x:hidden ; 
	overflow-y:scroll ; 
	background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;

          }

    &::-webkit-scrollbar
    {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #CD3A19;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

		.column.columnrec0311111{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: column;
		gap:15px ; background-color: transparent ; 

			.card.imageZone{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:250px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:0px ; max-width: initial;min-width: initial;

				.item.itemimageZone3{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
				
				left: calc(50% + 0px) ; 
				
				top: calc(50% + 0px) ; 
				width:200px!important ; 
				height:200px!important ; 
				

					.networkimage.networkimageimageZone122222{

					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					transform: translate(-50% ,-50%) ; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);
					
					left: calc(50% + 0px) ; 
					
					top: calc(50% + 0px) ; 
					width:200px!important ; 
					height:200px!important ; 
					
					display: inherit;

					flex: unset;
					border-radius:1000px ; 
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}

					
}

					.button.buttonlogorec311111{

					height: auto;
					
					position:absolute !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					right: 0px ; 
					bottom: 0px ; 
					width:56px!important ; 
					height:56px!important ; 
					
					font-size:32px ; 
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					font-size:32px ; 
					}
					}

				
}

			
}

			.card.nameZone{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:95px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:0px ; max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 20px ; 
				
				top: 10px ; 
				right: 20px ; 
				bottom: 10px ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.text.textcolumnnameZone11{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:15px ; 
					.text-content{
					width: 100%;
					}

					
}

					.textfield.nameInput{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:36px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.tagZone{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:auto!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:0px ; max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:relative !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				margin-left: 20px ; 
				
				margin-top: 0px ; 
				margin-right: 20px ; 
				width: calc(100% - (20px + 20px)) !important;
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 
				padding-top:20px ; 
				padding-bottom:20px ; 

					.text.textcolumnnameZone11{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:15px ; 
					.text-content{
					width: 100%;
					}

					
}

					.select.tagsInput{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:auto!important ; 
					item-text:name !important; 
					item-value:id !important; 
					width: calc(100% - (0px + 0px)) !important;
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.nameZone55555{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:160px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:0px ; max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				
				left: 20px ; 
				
				top: 10px ; 
				right: 20px ; 
				bottom: 10px ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: column;background-color: transparent ; 

					.text.textcolumnnameZone11{

					align-self: flex-start;
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:auto!important ; 
					height:auto!important ; 
					
					font-size:15px ; 
					.text-content{
					width: 100%;
					}

					
}

					.textarea.textareacolumnnameZone13{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					margin-left: 0px ; 
					
					margin-right: 0px ; 
					height:100px!important ; 
					width: calc(100% - (0px + 0px)) !important;
					
					
::v-deep &.v-input{
input{

}
					}
					}

				
}

			
}

			.card.deleteZone{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:60px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:0px ; max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				left:35px ; 
				
				top: calc(50% + 0px) ; 
				width:auto!important ; 
				height:46px!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:30px ; background-color:transparent ; 

					.object.homeButt{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:35px!important ; 
					height:46px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					margin-bottom: 0px ; 
					width:auto!important ; 
					height: calc(100% - (0px + 0px)) !important;
					
					color:#5bf89a ; 
					font-size:17px ; 
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.card.deleteZone8{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			margin-left: 0px ; 
			
			margin-right: 0px ; 
			height:60px!important ; 
			width: calc(100% - (0px + 0px)) !important;
			background-color:#ffffff ; 
			border-radius:0px ; max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				margin: unset;
				padding: unset;
				transform-origin: top left;
				
				transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
				
				left:35px ; 
				
				top: calc(50% + 0px) ; 
				width:auto!important ; 
				height:46px!important ; 
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;
				flex-direction: row;
				gap:30px ; background-color:transparent ; 

					.object.homeButt{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					width:35px!important ; 
					height:46px!important ; 
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					margin: unset;
					padding: unset;
					transform-origin: top left;
					
					
					
					margin-top: 0px ; 
					margin-bottom: 0px ; 
					width:auto!important ; 
					height: calc(100% - (0px + 0px)) !important;
					
					color:#f85656 ; 
					font-size:17px ; 
					align-items:center;
					display: flex;
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.details22222{


position:absolute !important; 






.rectangle.rectangleeditorZone133333{


position:absolute !important; 







	.row.rowrectangletool1111111{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.homeButt{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.item.separator{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textrowrectangletool11111113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectangledetails3{


position:absolute !important; 







	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnrec0311111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.card.imageZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.itemimageZone3{

				
				position:absolute !important; 
				
				
				
				
				

					.networkimage.networkimageimageZone122222{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.button.buttonlogorec311111{

					height: auto;
					
					position:absolute !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.card.nameZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.nameInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.tagZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.select.tagsInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.nameZone55555{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textarea.textareacolumnnameZone13{

					
					position:relative !important; 
					
					
					
					
					
					
::v-deep &.v-input{
input{

}
					}
					}

				
}

			
}

			.card.deleteZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.card.deleteZone8{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.details22222{


position:absolute !important; 






.rectangle.rectangleeditorZone133333{


position:absolute !important; 







	.row.rowrectangletool1111111{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.homeButt{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.item.separator{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textrowrectangletool11111113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectangledetails3{


position:absolute !important; 







	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnrec0311111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.card.imageZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.itemimageZone3{

				
				position:absolute !important; 
				
				
				
				
				

					.networkimage.networkimageimageZone122222{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.button.buttonlogorec311111{

					height: auto;
					
					position:absolute !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.card.nameZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.nameInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.tagZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.select.tagsInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.nameZone55555{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textarea.textareacolumnnameZone13{

					
					position:relative !important; 
					
					
					
					
					
					
::v-deep &.v-input{
input{

}
					}
					}

				
}

			
}

			.card.deleteZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.card.deleteZone8{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.details22222{


position:absolute !important; 






.rectangle.rectangleeditorZone133333{


position:absolute !important; 







	.row.rowrectangletool1111111{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.homeButt{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.item.separator{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textrowrectangletool11111113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectangledetails3{


position:absolute !important; 







	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnrec0311111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.card.imageZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.itemimageZone3{

				
				position:absolute !important; 
				
				
				
				
				

					.networkimage.networkimageimageZone122222{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.button.buttonlogorec311111{

					height: auto;
					
					position:absolute !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.card.nameZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.nameInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.tagZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.select.tagsInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.nameZone55555{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textarea.textareacolumnnameZone13{

					
					position:relative !important; 
					
					
					
					
					
					
::v-deep &.v-input{
input{

}
					}
					}

				
}

			
}

			.card.deleteZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.card.deleteZone8{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.details22222{


position:absolute !important; 






.rectangle.rectangleeditorZone133333{


position:absolute !important; 







	.row.rowrectangletool1111111{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.homeButt{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.item.separator{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textrowrectangletool11111113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectangledetails3{


position:absolute !important; 







	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnrec0311111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.card.imageZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.itemimageZone3{

				
				position:absolute !important; 
				
				
				
				
				

					.networkimage.networkimageimageZone122222{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.button.buttonlogorec311111{

					height: auto;
					
					position:absolute !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.card.nameZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.nameInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.tagZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.select.tagsInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.nameZone55555{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textarea.textareacolumnnameZone13{

					
					position:relative !important; 
					
					
					
					
					
					
::v-deep &.v-input{
input{

}
					}
					}

				
}

			
}

			.card.deleteZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.card.deleteZone8{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.details22222{


position:absolute !important; 






.rectangle.rectangleeditorZone133333{


position:absolute !important; 







	.row.rowrectangletool1111111{

	
	position:absolute !important; 
	
	
	
	
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;

		.object.homeButt{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.item.separator{

		
		position:relative !important; 
		
		
		
		
		

		
}

		.text.textrowrectangletool11111113{

		
		position:relative !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.rectangle.rectangledetails3{


position:absolute !important; 







	.scrollarea.scrollarearec0111111{

	
	position:absolute !important; 
	
	
	
	
	

		.column.columnrec0311111{

		
		position:absolute !important; 
		
		
		
		
		
		
		
		

			.card.imageZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.item.itemimageZone3{

				
				position:absolute !important; 
				
				
				
				
				

					.networkimage.networkimageimageZone122222{

					
					position:absolute !important; 
					
					
					
					
					
					display: inherit;

					flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

					
}

					.button.buttonlogorec311111{

					height: auto;
					
					position:absolute !important; 
					
					
					
					
					
					text-transform:unset ; 
					min-width: auto;
					i.v-icon{
					}
					}

				
}

			
}

			.card.nameZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textfield.nameInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.tagZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:relative !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.select.tagsInput{

					
					position:relative !important; 
					
					
					
					
					
					::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
					
::v-deep &.v-input{
						flex-grow: 0;
input{

}
					}
					}

				
}

			
}

			.card.nameZone55555{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.column.columnnameZone1{

				
				position:absolute !important; 
				
				
				
				
				
				
				
				

					.text.textcolumnnameZone11{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

					.textarea.textareacolumnnameZone13{

					
					position:relative !important; 
					
					
					
					
					
					
::v-deep &.v-input{
input{

}
					}
					}

				
}

			
}

			.card.deleteZone{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

			.card.deleteZone8{

			
			position:relative !important; 
			
			
			
			
			max-width: initial;min-width: initial;

				.row.rowrectangletool111111111111{

				
				position:absolute !important; 
				
				
				
				
				
				display: flex;
				flex-wrap: nowrap;
				flex: initial;

					.object.homeButt{

					
					position:relative !important; 
					
					
					
					
					
						::v-deep > * {
							min-width: unset;
							min-height: unset;
						}

					
}

					.text.textrowrectangletool11111113{

					
					position:relative !important; 
					
					
					
					
					
					.text-content{
					width: 100%;
					}

					
}

				
}

			
}

		
}

	
}


}


}
}
}
</style>
