<template>
 <div>  
<div id="MusicBubble"> 
         
 <div  :class="'item item0 '"   id="item0" ref="item0"  v-bind:style="{}">
 <v-card 
 :class="'card carditem01 '"   id="carditem01" ref="carditem01" :elevation = "4" 
  v-bind:style="{}">
 <div  :class="'rectangle rectanglecarditem011 '"   id="rectanglecarditem011" ref="rectanglecarditem011"  v-bind:style="{ transform: 'rotate(' + (-1*45) + 'deg)', }">  </div>
 <div  :class="'text textcarditem013 '"   id="textcarditem013" ref="textcarditem013"  v-bind:style="{color : '#EAAE14',}">
<div class="text-content" :style = "{}">{{ name }}</div>

   </div>
 <div  :class="'text textcarditem01344444 '"   id="textcarditem01344444" ref="textcarditem01344444"  v-bind:style="{color : '#a9acad',}">
<div class="text-content" :style = "{}">{{ type }}</div>

   </div>
 <div  :class="'item itemcarditem019 '"   id="itemcarditem019" ref="itemcarditem019"  v-bind:style="{}">
 <v-btn tag="div" 
 :fab = "true" 
 :onlyIcon = "true" 
 :text = "true" 
 :class="'button buttonitemcarditem0191 '"   id="buttonitemcarditem0191" ref="buttonitemcarditem0191"  v-bind:style="{}"><span v-if = "(!true) && (!true)"></span>
<v-icon  :style = "{}"  v-if = "'ion-ios-play' !== undefined"  :color = "'#7e7e7e'" 
>{{ 'ion-ios-play' }}
</v-icon></v-btn>
 <div  :class="'rectangle rectangleitemcarditem0193 '"   id="rectangleitemcarditem0193" ref="rectangleitemcarditem0193"  v-bind:style="{backgroundColor : '#7e7e7e',}">
 <div  :class="'icon iconrectangleitemcarditem01931 '"   id="iconrectangleitemcarditem01931" ref="iconrectangleitemcarditem01931"  v-bind:style="{color : '#fff',fontSize : getUnit(35,'px'),}"><i  :class="'ion-ios-musical-note'"></i></div>
 <div  :class="'icon iconrectangleitemcarditem01933 '"   id="iconrectangleitemcarditem01933" ref="iconrectangleitemcarditem01933"  v-bind:style="{color : '#EAAE14',fontSize : getUnit(40,'px'),}"><i  :class="'ion-ios-pulse'"></i></div>  </div>  </div>
 <div  :class="'mousearea mouseareacarditem019 '"   id="mouseareacarditem019" ref="mouseareacarditem019"  v-bind:style="{}" @click="clo(value)"></div>  </v-card>  </div>
 
      </div>
 </div> 
</template>
<script>


export default {
	name: 'MusicBubble',

    components: {},
	props: {
	
		name: {					required: false,			default: () =>  "fond50",		},
		type: {					required: false,			default: () =>  "fond",		},
		source: {					required: false,			default: () =>  "https://picsum.photos/200",		},
		clo: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		value: {					required: false,			default: () =>  "........",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#MusicBubble{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.item0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.card.carditem01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 6px ; 

top: 6px ; 
right: 6px ; 
bottom: 6px ; 
background-color:#ffffff ; 
border-radius:4px ; max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:-5px ; 
	
	top: 10px ; 
	width:10px!important ; 
	height:10px!important ; 
	transform: rotate(calc(-145deg));
	
	background-color:#ffffff ; 

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left:8px ; 
	
	top: 8px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#EAAE14 ; 
	font-size:15px ; 
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	right: 5px ; 
	bottom: 2px ; 
	width:auto!important ; 
	height:auto!important ; 
	
	color:#a9acad ; 
	font-size:13px ; 
	.text-content{
	width: 100%;
	}

	
}

	.item.itemcarditem019{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
	
	left: 10px ; 
	
	top: calc(50% + 0px) ; 
	right: 10px ; 
	height:52px!important ; 
	

		.button.buttonitemcarditem0191{

		height: auto;
		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:0px ; 
		
		top: calc(50% + 0px) ; 
		width:40px!important ; 
		height:40px!important ; 
		
		font-size:40px ; 
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		font-size:40px ; 
		}
		}

		.rectangle.rectangleitemcarditem0193{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		
		top: calc(50% + 0px) ; 
		right: 0px ; 
		width:50px!important ; 
		height:50px!important ; 
		
		background-color:#7e7e7e ; 
		border-radius:25px ; 

			.icon.iconrectangleitemcarditem01931{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			color:#fff ; 
			font-size:35px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.icon.iconrectangleitemcarditem01933{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: -16px ; 
			
			bottom: -16px ; 
			width:50px!important ; 
			height:50px!important ; 
			
			color:#EAAE14 ; 
			font-size:40px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	cursor: pointer;
	}


}


}


@media (max-width: 600px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemcarditem019{

	
	position:absolute !important; 
	
	
	
	
	

		.button.buttonitemcarditem0191{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.rectangle.rectangleitemcarditem0193{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectangleitemcarditem01931{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.icon.iconrectangleitemcarditem01933{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 601px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemcarditem019{

	
	position:absolute !important; 
	
	
	
	
	

		.button.buttonitemcarditem0191{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.rectangle.rectangleitemcarditem0193{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectangleitemcarditem01931{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.icon.iconrectangleitemcarditem01933{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 961px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemcarditem019{

	
	position:absolute !important; 
	
	
	
	
	

		.button.buttonitemcarditem0191{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.rectangle.rectangleitemcarditem0193{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectangleitemcarditem01931{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.icon.iconrectangleitemcarditem01933{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1265px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemcarditem019{

	
	position:absolute !important; 
	
	
	
	
	

		.button.buttonitemcarditem0191{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.rectangle.rectangleitemcarditem0193{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectangleitemcarditem01931{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.icon.iconrectangleitemcarditem01933{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}

@media (min-width: 1905px) {


.item.item0{


position:absolute !important; 






.card.carditem01{


position:absolute !important; 




max-width: initial;min-width: initial;

	.rectangle.rectanglecarditem011{

	
	position:absolute !important; 
	
	
	
	
	
	

	
}

	.text.textcarditem013{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.text.textcarditem01344444{

	
	position:absolute !important; 
	
	
	
	
	
	.text-content{
	width: 100%;
	}

	
}

	.item.itemcarditem019{

	
	position:absolute !important; 
	
	
	
	
	

		.button.buttonitemcarditem0191{

		height: auto;
		
		position:absolute !important; 
		
		
		
		
		
		text-transform:unset ; 
		min-width: auto;
		i.v-icon{
		}
		}

		.rectangle.rectangleitemcarditem0193{

		
		position:absolute !important; 
		
		
		
		
		
		

			.icon.iconrectangleitemcarditem01931{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.icon.iconrectangleitemcarditem01933{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

		
}

	
}

	.mousearea.mouseareacarditem019{

	
	position:absolute !important; 
	
	
	
	
	
	}


}


}
}
}
</style>
