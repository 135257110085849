<template>
 <div>  
<div id="ConsoleTextArea"> 
         
 <div  :class="'scrollarea rec0 '"   id="rec0" ref="rec0"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <SimpleTextEditor :class="'object logEditor '"   id="logEditor" ref="logEditor"  v-bind:style="{}" :value = "log" 
 :options = "options" 
>
</SimpleTextEditor>  </div>
 
      </div>
 </div> 
</template>
<script>
import SimpleTextEditor from '../../At/Utils/SimpleTextEditor'


export default {
	name: 'ConsoleTextArea',

    components: {SimpleTextEditor,},
	watch: {
	
		log: function() { 		
	this.$refs['logEditor'].refresh(0)

}
,
	},
	data(){
	return {
		
		options:  {		
  tabSize: 4,

  styleActiveLine: true,

  lineNumbers: true,

  line: true,

  mode: 'abccode-console',

  theme: 'default',

	lineWrapping: true,

  extraKeys: {"Ctrl-Q": function(cm){ cm.foldCode(cm.getCursor()); }},

  foldGutter: true,

	readOnly: 'nocursor',

  gutters: [

		"CodeMirror-linenumbers", 

		"CodeMirror-foldgutter"

	] 

}
,
		}
	},
	props: {
	
		log: {					required: false,			default: () =>  "\n\n\n\n\n\n\n\n\n\n\n\n\n\n",		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#ConsoleTextArea{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.scrollarea.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

overflow:scroll ; 
overflow-x:hidden ; 
overflow-y:scroll ; 
background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #CD3A19;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

.object.logEditor{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 10px ; 

top: 10px ; 
right: 10px ; 
bottom: 10px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.scrollarea.rec0{


position:absolute !important; 






.object.logEditor{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.scrollarea.rec0{


position:absolute !important; 






.object.logEditor{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.scrollarea.rec0{


position:absolute !important; 






.object.logEditor{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.scrollarea.rec0{


position:absolute !important; 






.object.logEditor{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.scrollarea.rec0{


position:absolute !important; 






.object.logEditor{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
