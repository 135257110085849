<template>
 <div>  
<div id="AddPersoDialog"> 
         
 <div  :class="'item rec0 '"   id="rec0" ref="rec0"  v-bind:style="{}">
 <div  :class="'rectangle rectanglerec01 '"   id="rectanglerec01" ref="rectanglerec01"  v-bind:style="{backgroundColor : '#000',}">
 <div  :class="'mousearea mousearearectanglerec011 '"   id="mousearearectanglerec011" ref="mousearearectanglerec011"  v-bind:style="{}" @click="close"></div>  </div>
 <v-card 
 :class="'card cardrec03 '"   id="cardrec03" ref="cardrec03"  v-bind:style="{backgroundColor : '#f3f3f3',}">
 <div  :class="'scrollarea scrollareacardrec035 '"   id="scrollareacardrec035" ref="scrollareacardrec035"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'row rowscrollareacardrec0351 '"   id="rowscrollareacardrec0351" ref="rowscrollareacardrec0351"  v-bind:style="{}">
 <ImgToAdd :class="'object imgToAdd '"   id="imgToAdd" ref="imgToAdd" v-for="(item,index) in imgList" :key="'imgToAdd' + index"  v-bind:style="{}" :remove = "removeFile" 
 :select = "selectFile" 
 :src = "item.url" 
 :id = "item.id" 
 :index = "index" 
>
</ImgToAdd>  </div>  </div>
 <div  :class="'rectangle rectanglecardrec033 '"   id="rectanglecardrec033" ref="rectanglecardrec033" :border-top-left-radius = "'4px'" 
 :border-top-right-radius = "'4px'" 
  v-bind:style="{}">
 <div  :class="'row rowrectanglecardrec0336 '"   id="rowrectanglecardrec0336" ref="rowrectanglecardrec0336"  v-bind:style="{}">
 <v-btn tag="div" 
 :color = "primaryColor" 
 :fab = "true" 
 :disabled = "disableAdd" 
 :dark = "disableAdd" 
 @click="openFileDialog" :class="'button addBtn '"   id="addBtn" ref="addBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-add' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-add' }}
</v-icon></v-btn>
 <v-btn tag="div" 
 :color = "secondaryColor" 
 :fab = "true" 
 :disabled = "(!modified)" 
 :dark = "(!modified)" 
 @click="save" :class="'button okBtn '"   id="okBtn" ref="okBtn"  v-bind:style="{}"><span v-if = "(!true) && (!false)"></span>
<v-icon  :style = "{}"  v-if = "'ion-md-send' !== undefined"  :color = "'#fff'" 
>{{ 'ion-md-send' }}
</v-icon></v-btn>  </div>
 <v-img  :class="'networkimage preview '"   id="preview" ref="preview"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "previewImg" 
>  </v-img>
 <div  :class="'item itemrectanglecardrec0335 '"   id="itemrectanglecardrec0335" ref="itemrectanglecardrec0335"  v-bind:style="{}">
 <div  :class="'text textitemrectanglecardrec03351 '"   id="textitemrectanglecardrec03351" ref="textitemrectanglecardrec03351"  v-bind:style="{color : primaryColor,}">
<div class="text-content" :style = "{}">{{ 'personnageImporté' }}</div>

   </div>
 <div  :class="'rectangle rectangleitemrectanglecardrec03353 '"   id="rectangleitemrectanglecardrec03353" ref="rectangleitemrectanglecardrec03353"  v-bind:style="{backgroundColor : primaryColor,}">  </div>
 <v-select
          :items = "imgNumber" solo :class="'select selectitemrectanglecardrec03355 '"   id="selectitemrectanglecardrec03355" ref="selectitemrectanglecardrec03355" v-model="targetId"  v-bind:style="{}">
          <template v-if="false" v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ model.length - 1 }} others)</span>
          </template>
        </v-select>  </div>  </div>
 <v-card 
 :class="'card cardcardrec031 '"   id="cardcardrec031" ref="cardcardrec031"  v-bind:style="{backgroundColor : primaryColor,}">
 <div  :class="'item itemcardcardrec0315 '"   id="itemcardcardrec0315" ref="itemcardcardrec0315"  v-bind:style="{}">
 <div  :class="'icon iconcardcardrec0311111 '"   id="iconcardcardrec0311111" ref="iconcardcardrec0311111"  v-bind:style="{color : '#fff',fontSize : getUnit(26,'px'),}"><i  :class="'ion-md-close'"></i></div>
 <div  :class="'mousearea mouseareaitemcardcardrec03153 '"   id="mouseareaitemcardcardrec03153" ref="mouseareaitemcardcardrec03153"  v-bind:style="{}" @click="close"></div>  </div>
 <div  :class="'text textcardcardrec0313 '"   id="textcardcardrec0313" ref="textcardcardrec0313"  v-bind:style="{color : '#fff',}">
<div class="text-content" :style = "{}">{{ $t('AddDialog.choosePersonnage') }}</div>

   </div>  </v-card>  </v-card>
 <FileDialog :class="'object filedialogrec05 '"   id="filedialogrec05" ref="filedialogrec05" v-if="fileDialogVisible"  v-bind:style="{}" :close = "closeFileDialog" 
 :choose = "addFile" 
>
</FileDialog>  </div>
 
      </div>
 </div> 
</template>
<script>
import ImgToAdd from '../../../At/Dialog/AddDialog/ImgToAdd'
import FileDialog from '../../../At/Dialog/FileDialog'


export default {
	name: 'AddPersoDialog',

    components: {ImgToAdd,FileDialog,},
	methods: {
	
		save: function(){ 		
	let pjDatas = this.$store.state.pjDatas

	pjDatas.imported.personnage = this.imgList

	this.$store.commit('pjDatas', pjDatas)

	this.close()

}
,
		sortImgList: function(){ 		
	this.modified = true

	this.imgList.sort((a, b) => {

		return (a.id < b.id)

	})

}
,
		openFileDialog: function(){		
	this.fileDialogVisible = true

}
,
		closeFileDialog: function(){		
	this.fileDialogVisible = false

}
,
		addFile: function(path){		
	this.imgList.push({

		id: 0, 

		url: this.$store.state.apiURL + path

	})

	this.sortImgList()

}
,
		removeFile: function(index){		
	this.modified = true

	

	if(this.imgList.length == 1){

		this.imgList = []

	} else {

		this.imgList = this.imgList.splice(index, 1)

		this.sortImgList()

	}

}
,
		selectFile: function(index){ 		
	this.currentIndex = index

	this.currentImg = this.imgList[index]

	this.targetId = this.currentImg.id

	this.previewImg = this.currentImg.url

}
,
	},
	mounted(){
			
	this.imgList = this.$store.state.pjDatas.imported.personnage

	},
	computed: {
	
		imgNumber: function(){		
	let list = []

	for(let i=1; i<=20; i++){

		list.push(i)

	}

	return list

}
,
		disableAdd: function(){ 		
	return this.imgList.length >= 20

}
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
	},
	watch: {
	
		targetId: function(val){ 		
	this.imgList[this.currentIndex].id = val

	this.sortImgList()

}
,
	},
	data(){
	return {
		
		imgList:  []		,
		currentIndex:  null		,
		targetId:  null		,
		fileDialogVisible:  false		,
		currentImg:  null			,
		previewImg:  null		,
		modified:  false		,
		}
	},
	props: {
	
		close: {			type: Function,			required: false,			default: () =>  (()=>{}),		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#AddPersoDialog{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 





.item.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.rectangle.rectanglerec01{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
opacity: 0.5 ;  filter: alpha(opacity=50) ; 

background-color:#000 ; 

	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
	}


}

.card.cardrec03{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 100px ; 

top: 50px ; 
right: 100px ; 
bottom: 50px ; 

background-color:#f3f3f3 ; 
border-radius:4px ; max-width: initial;min-width: initial;

	.scrollarea.scrollareacardrec035{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	bottom: 10px ; 
	height:100px!important ; 
	
	overflow:scroll ; 
	overflow-x:scroll ; 
	overflow-y:hidden ; 
	background-color:transparent ; 
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #a3a3a3;

          }

    &::-webkit-scrollbar
    {
        width: 4px;
        height: 4px;
        background-color: #a3a3a3;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #838eec;    

      // =======================================Style5
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .4)),
                       color-stop(.5, transparent), to(transparent));
      //-----------------------------------------------
        
      }

		.row.rowscrollareacardrec0351{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		top: 0px ; 
		width:auto!important ; 
		height:86px!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.object.imgToAdd{

			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			margin-top: 0px ; 
			width:86px!important ; 
			height:86px!important ; 
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglecardrec033{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 120px ; 
	border-top-left-radius:4px !important; 
	border-top-right-radius:4px !important; 
	
	background-color:#ffffff ; 

		.row.rowrectanglecardrec0336{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		
		right: 40px ; 
		bottom: -27px ; 
		width:auto!important ; 
		height:auto!important ; 
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;
		flex-direction: row;
		gap:10px ; background-color:transparent ; 

			.button.addBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:54px!important ; 
			height:54px!important ; 
			
			font-size:30px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:30px ; 
			}
			}

			.button.okBtn{

			height: auto;
			
			position:relative !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			
			width:54px!important ; 
			height:54px!important ; 
			
			font-size:30px ; 
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			font-size:30px ; 
			}
			}

		
}

		.networkimage.preview{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		top: 80px ; 
		bottom: 90px ; 
		width:auto!important ; 
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: cover;
	}

		
}

		.item.itemrectanglecardrec0335{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		 transform:  translateX(-50%) ; -webkit-transform: translateX(-50%); -ms-transform: translateX(-50%);
		
		left: calc(50% + 0px) ; 
		
		bottom: 20px ; 
		width:319.9990234375px!important ; 
		height:29px!important ; 
		

			.text.textitemrectanglecardrec03351{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left:0.25px ; 
			
			top: 0.25px ; 
			width:198px!important ; 
			height:27px!important ; 
			
			font-size:20px ; 
			text-align:center;
			align-items:center;
			display: flex;
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectangleitemrectanglecardrec03353{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			right: 0px ; 
			bottom: 0px ; 
			height:2px!important ; 
			
			background-color:#ffffff ; 

			
}

			.select.selectitemrectanglecardrec03355{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 210px ; 
			
			right: 0px ; 
			bottom: 0px ; 
			height:59px!important ; 
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}

	.card.cardcardrec031{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0.25px ; 
	right: 0px ; 
	height:55px!important ; 
	background-color:#ffffff ; 
	border-radius:4px ; max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:14px ; 
		
		top: calc(50% + 0px) ; 
		width:44px!important ; 
		height:33px!important ; 
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
			
			left:0px ; 
			
			top: calc(50% + 0px) ; 
			width:44px!important ; 
			height:36px!important ; 
			
			color:#fff ; 
			font-size:26px ; 
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			margin: unset;
			padding: unset;
			transform-origin: top left;
			
			
			left: 0px ; 
			
			top: 0px ; 
			right: 0px ; 
			bottom: 0px ; 
			
			cursor: pointer;
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		transform:  translateY(-50%) ; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%);
		
		left:77px ; 
		
		top: calc(50% + 0px) ; 
		width:auto!important ; 
		height:auto!important ; 
		
		color:#fff ; 
		font-size:20px ; 
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.filedialogrec05{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}


@media (max-width: 600px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacardrec035{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowscrollareacardrec0351{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.imgToAdd{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglecardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectanglecardrec0336{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.addBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.okBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.networkimage.preview{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemrectanglecardrec0335{

		
		position:absolute !important; 
		
		
		
		
		

			.text.textitemrectanglecardrec03351{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectangleitemrectanglecardrec03353{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.select.selectitemrectanglecardrec03355{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.filedialogrec05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 601px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacardrec035{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowscrollareacardrec0351{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.imgToAdd{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglecardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectanglecardrec0336{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.addBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.okBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.networkimage.preview{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemrectanglecardrec0335{

		
		position:absolute !important; 
		
		
		
		
		

			.text.textitemrectanglecardrec03351{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectangleitemrectanglecardrec03353{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.select.selectitemrectanglecardrec03355{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.filedialogrec05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 961px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacardrec035{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowscrollareacardrec0351{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.imgToAdd{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglecardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectanglecardrec0336{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.addBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.okBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.networkimage.preview{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemrectanglecardrec0335{

		
		position:absolute !important; 
		
		
		
		
		

			.text.textitemrectanglecardrec03351{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectangleitemrectanglecardrec03353{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.select.selectitemrectanglecardrec03355{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.filedialogrec05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1265px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacardrec035{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowscrollareacardrec0351{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.imgToAdd{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglecardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectanglecardrec0336{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.addBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.okBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.networkimage.preview{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemrectanglecardrec0335{

		
		position:absolute !important; 
		
		
		
		
		

			.text.textitemrectanglecardrec03351{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectangleitemrectanglecardrec03353{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.select.selectitemrectanglecardrec03355{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.filedialogrec05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}

@media (min-width: 1905px) {


.item.rec0{


position:absolute !important; 






.rectangle.rectanglerec01{


position:absolute !important; 







	.mousearea.mousearearectanglerec011{

	
	position:absolute !important; 
	
	
	
	
	
	}


}

.card.cardrec03{


position:absolute !important; 




max-width: initial;min-width: initial;

	.scrollarea.scrollareacardrec035{

	
	position:absolute !important; 
	
	
	
	
	

		.row.rowscrollareacardrec0351{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.object.imgToAdd{

			
			position:relative !important; 
			
			
			
			
			
				::v-deep > * {
					min-width: unset;
					min-height: unset;
				}

			
}

		
}

	
}

	.rectangle.rectanglecardrec033{

	
	position:absolute !important; 
	
	
	
	
	
	

		.row.rowrectanglecardrec0336{

		
		position:absolute !important; 
		
		
		
		
		
		display: flex;
		flex-wrap: nowrap;
		flex: initial;

			.button.addBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

			.button.okBtn{

			height: auto;
			
			position:relative !important; 
			
			
			
			
			
			text-transform:unset ; 
			min-width: auto;
			i.v-icon{
			}
			}

		
}

		.networkimage.preview{

		
		position:absolute !important; 
		
		
		
		
		
		display: inherit;

		flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}

		
}

		.item.itemrectanglecardrec0335{

		
		position:absolute !important; 
		
		
		
		
		

			.text.textitemrectanglecardrec03351{

			
			position:absolute !important; 
			
			
			
			
			
			.text-content{
			width: 100%;
			}

			
}

			.rectangle.rectangleitemrectanglecardrec03353{

			
			position:absolute !important; 
			
			
			
			
			
			

			
}

			.select.selectitemrectanglecardrec03355{

			
			position:absolute !important; 
			
			
			
			
			
			::v-deep .v-input__control{ 		height: 100% !important; 		 		.v-input__slot{ 			height: 100% !important; 			margin-bottom: 0 !important;     	padding: 0 10px; 			 			input{ 				max-height: unset !important; 				padding: unset !important; 				height:100%; 			}		 		}		 	}
			
::v-deep &.v-input{
				flex-grow: 0;
input{

}
			}
			}

		
}

	
}

	.card.cardcardrec031{

	
	position:absolute !important; 
	
	
	
	
	max-width: initial;min-width: initial;

		.item.itemcardcardrec0315{

		
		position:absolute !important; 
		
		
		
		
		

			.icon.iconcardcardrec0311111{

			
			position:absolute !important; 
			
			
			
			
			
			display: flex;
			justify-content: center;
			align-items: center;
			i {
			}
}

			.mousearea.mouseareaitemcardcardrec03153{

			
			position:absolute !important; 
			
			
			
			
			
			}

		
}

		.text.textcardcardrec0313{

		
		position:absolute !important; 
		
		
		
		
		
		.text-content{
		width: 100%;
		}

		
}

	
}


}

.object.filedialogrec05{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}


}
}
}
</style>
