/**
 * @summmary
 * window.utils
 * ------------
 * @desc
 * A global object with utility functions.
 * @var {Object} window.utils
 */
window.utils = window.utils || {};

/**
 * @summary
 * window.utils.type
 * -----------------
 * @desc
 * Returns a lowercase string representation of an object's constructor.
 * @function window.utils.type
 * @param {Type} obj Object of any type, native, host or custom
 * @return {String} Returns a lowercase string representing the object's 
 *    constructor or NaN for this specific object
 * @see {@link http://perfectionkills.com/instanceof-considered-harmful-or-how-to-write-a-robust-isarray/|perfectionkills.com}, 
 * {@link http://stackoverflow.com/questions/3215046/differentiating-between-arrays-and-hashes-in-javascript|stackoverflow.com} and 
 * {@link http://javascript.info/tutorial/type-detection|javascript.info}
 */
window.utils.type = function (obj){
   if(obj === null)
      return 'null';
   else if(typeof obj === 'undefined')
      return 'undefined';
   
   var type = Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1].toLowerCase();
   
   // hack for NaN type so we can test: window.utils.type(obj) == 'nan'
   if((type == 'number') && isNaN(obj))
      type = 'nan';
      
   return type;
};

/**
 * @summary window.buffer
 * ----------------------
 * @desc
 * A static multilevel buffer for cut/copy/paste events.
 * 
 * It creates an object that sets/gets strings to/from specific keys.
 * Keys are integers from 0 up to a depth (not including) like arrays.
 * @var {Object} buffer
 * @memberof window
 */
window.buffer = (function(){
   var depth = 3,
       b = {},
       ret = {
         'set': set,
         'get': get,
         reset: reset,
         depth: setGetDepth
      };

   function set(str, i){
      if(!i || (g3.utils.type(i*1) != 'number'))
         i = 0;
      if((utils.type(i*1) == 'number') && (i >= depth))
         throw new Error('window.buffer.set():: 2nd argument should be less than depth ' + depth);
      b[i] = str;
      return ret;
   }
   function get(i){
      if(!i || (utils.type(i*1) != 'number'))
         i = 0;
      if((utils.type(i*1) == 'number') && (i >= depth))
         throw new Error('window.buffer.get():: 2nd argument should be less than depth ' + depth);
      i = i*1;
      return b[i];
   }
   function reset(){
      var i;
      for(i = 0; i < depth; i++)
         b[i] = '';
      return ret;
   }
   function setGetDepth(i){
      var j;

      if(!i || (i == depth) || (g3.utils.type(i*1) != 'number'))
         return depth;
      else{
         i = i*1;
         depth = i;
         if(i > depth){
            for(j = depth; j < i; j++)
               b[j] = '';
         }else if(i < depth){
            for(j = i; j < depth; j++)
               delete b[j];
         }
         return i;
      }
      return ret;
   }
   reset();
   return ret;
}());