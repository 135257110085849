<template>
 <div>  
<div id="DCircle"> 
         
 <div  :class="'rectangle rec0 '"   id="rec0" ref="rec0" v-on:click="select"   v-bind:style="{zoom : zoom,backgroundColor : bgColor,}">
 <div  :class="'rectangle borderCard '"   id="borderCard" ref="borderCard" v-if="activeItem" v-on:mousedown="mousedown"   v-bind:style="{backgroundColor : 'transparent',borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerNW ' + 'nw'"   id="resizerNW" ref="resizerNW" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerNE ' + 'ne'"   id="resizerNE" ref="resizerNE" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerSW ' + 'sw'"   id="resizerSW" ref="resizerSW" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>
 <div  :class="'rectangle resizerSE ' + 'se'"   id="resizerSE" ref="resizerSE" v-show="activeItem" v-on:mousedown="resizerMousedown"   v-bind:style="{zoom : (1/zoom),borderColor : primaryColor,}">  </div>  </div>
 
      </div>
 </div> 
</template>
<script>

import $ from 'jquery'
export default {
	name: 'DCircle',

    components: {},
	methods: {
	
		select: function(e){		
	e.stopPropagation();

  this.selectObject(this.ip)

}
,
		actualise: function(){		
  this.canvas.currentIp=ip

  this.canvas.currentCodeSearch()

}
,
		mousedown: function(e) { 		
	let el = $($(e.target).parents()[2])



  let prevX = e.clientX;

  let prevY = e.clientY;



  let mousemove = (e)=>{

    if (!this.isResizing) {

      let newX = prevX - e.clientX;

      let newY = prevY - e.clientY;



      let position = el.position();

			this.newTop = position.top - newY

			this.newLeft = position.left - newX



      $(el).css({top: this.newTop, left: this.newLeft});



      prevX = e.clientX;

      prevY = e.clientY;

    }

  }



  let mouseup = ()=>{

    window.removeEventListener("mousemove", mousemove);

    window.removeEventListener("mouseup", mouseup);

		this.updateObject(this.ip, 'x', this.newLeft);

		this.updateObject(this.ip, 'y', this.newTop);

  }

	

	window.addEventListener("mousemove", mousemove);

  window.addEventListener("mouseup", mouseup);

}
,
		resizerMousedown: function(e) { 			
	let el = $($(e.target).parents()[2])

  let currentResizer = e.target;

  this.isResizing = true;

	

  let prevX = e.clientX;

  let prevY = e.clientY;



  let mousemove = (e)=>{

    let position = el.position();

		let zoom = this.zoom

		let _width = el.width();

		let _height = el.height();

    let newX = prevX - e.clientX;

    let newY = prevY - e.clientY;

	

		this.newTop = position.top - newY

		this.newLeft = position.left - newX

		this.newWidth = _width - newX

		this.newHeight = _height - newY



    if (currentResizer.classList.contains("se")) {

    	$(el).attr('style', `width: ${this.newWidth}px !important;

														height: ${this.newHeight}px !important;`);

    	$(el).css({top: position.top, left: position.left});

    } else if (currentResizer.classList.contains("sw")) {

    	$(el).attr('style', `width: ${_width + newX}px !important;

														height: ${this.newHeight}px !important;`);

    	$(el).css({top: position.top, left: this.newLeft});

    } else if (currentResizer.classList.contains("ne")) {

    	$(el).attr('style', `width: ${this.newWidth}px !important;

														height: ${_height + newY}px !important;`);

    	$(el).css({top: this.newTop, left: position.left});

    } else {

    	$(el).attr('style', `width: ${_width + newX}px !important;

														height: ${_height + newY}px !important;`);

    	$(el).css({top: this.newTop, left: this.newLeft});

    }

		this.scale = this.scale * zoom

    prevX = e.clientX;

    prevY = e.clientY;

  }



  let mouseup = ()=>{	

    window.removeEventListener("mousemove", mousemove);

    window.removeEventListener("mouseup", mouseup);

		this.updateObject(this.ip, 'x', this.newLeft);

		this.updateObject(this.ip, 'y', this.newTop);

		this.updateObject(this.ip, 'scale', this.scale);

		this.updateObject(this.ip, 'width', this.newWidth);

		this.updateObject(this.ip, 'height', this.newHeight);

    this.isResizing = false;

  }

	

  window.addEventListener("mousemove", mousemove);

  window.addEventListener("mouseup", mouseup);

}
,
	},
	computed: {
	
		color: function() { 		
	return (this.m_color ?? "#fff")

}
,
		bgColor: function() { 		
	return (this.m_bgColor ?? "#cd3a19")

}
,
		activeItem: function(){		
	return (this.ip == this.currentSelectedItem)

} 
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
	},
	data(){
	return {
		
		isResizing:  false		,
		scale:  1		,
		newTop:  0		,
		newLeft:  0		,
		newWidth:  0		,
		newHeight:  0		,
		}
	},
	props: {
	
		ip: {					required: false,			default: () =>  0,		},
		m_color: {					required: false,			default: () =>  "#fff",		},
		m_bgColor: {					required: false,			default: () =>  "#cd3a19",		},
		updateObject: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		selectObject: {			type: Function,			required: false,			default: () =>  ()=>{},		},
		currentSelectedItem: {					required: false,			default: () =>  0,		},
		zoom: {					required: false,			default: () =>  1,		},
	},
} 
</script>

<style lang = "scss" scoped>


 div#DCircle{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

#rec0{
	cursor: pointer;
	
	::v-deep .v-image__image{
		z-index: 0;
	}
	.nw{
		cursor: nwse-resize;
	}
	.ne{
		cursor: nesw-resize;
	}
	.sw{
		cursor: nesw-resize;
	}
	.se{
		cursor: nwse-resize;
	}
}



.rectangle.rec0{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#ffffff ; 
border-radius:100% ; 

.rectangle.borderCard{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:transparent ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerNW{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: -8px ; 

top: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerNE{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



top: -8px ; 
right: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerSW{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: -8px ; 

bottom: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}

.rectangle.resizerSE{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;



right: -8px ; 
bottom: -8px ; 
width:16px!important ; 
height:16px!important ; 

background-color:#ffffff ; 
border-radius:8px ; 
border-style:solid ; 
border-width:2px ; 
border-color:undefined ; 


}


}


@media (max-width: 600px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 601px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 961px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 1265px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}

@media (min-width: 1905px) {


.rectangle.rec0{


position:absolute !important; 







.rectangle.borderCard{


position:absolute !important; 








}

.rectangle.resizerNW{


position:absolute !important; 








}

.rectangle.resizerNE{


position:absolute !important; 








}

.rectangle.resizerSW{


position:absolute !important; 








}

.rectangle.resizerSE{


position:absolute !important; 








}


}
}
}
</style>
