<template>
 <div>  
<div id="app"> 
         
 <div  :class="'rectangle fond '"   id="fond" ref="fond"  v-bind:style="{backgroundColor : '#d2dbdc',}">
 <div  :class="'rectangle fondBar '"   id="fondBar" ref="fondBar"  v-bind:style="{backgroundColor : '#EAAE14',}">  </div>
 <div  :class="'rectangle cardfond3 '"   id="cardfond3" ref="cardfond3" :_overflow = "'hidden'" 
  v-bind:style="{overflow : 'hidden',backgroundColor : 'transparent',}">
 <div  :class="'item centerZone '"   id="centerZone" ref="centerZone"  v-bind:style="{left : getUnit(mainZoneMargin,'px'),}">
 <AppEditor :class="'object appEditor '"   id="appEditor" ref="appEditor" v-if="editorOpened" @compilefinish="showResult"   v-bind:style="{}" :openOpenD = "openOpenDialog" 
 :togelLeftPanel = "togelLeft" 
>
</AppEditor>
 <AppSplatch :class="'object appSplatch '"   id="appSplatch" ref="appSplatch" v-if="splashOpened"  v-bind:style="{}" :create = "openCreate" 
 :open = "openOpenDialog" 
>
</AppSplatch>  </div>
 <div  :class="'item lestZone '"   id="lestZone" ref="lestZone" v-show="leftZoneShowed"  v-bind:style="{}">
 <LeftZone :class="'object leftzonelestZone1 '"   id="leftzonelestZone1" ref="leftzonelestZone1"  v-bind:style="{}" :insertCode = "insertCode" 
 :openCreateD = "openCreate" 
 :undo = "undo" 
 :redo = "redo" 
 :copy = "copy" 
 :cut = "cut" 
 :paste = "paste" 
 :openProfile = "openProfilePanel" 
 :hideZone = "togelLeft" 
 :openAddD = "openAddDialog" 
>
</LeftZone>
 <Profile :class="'object profilelestZone3 '"   id="profilelestZone3" ref="profilelestZone3" v-if="profilePanelVisible"  v-bind:style="{}" :close = "closeProfilePanel" 
>
</Profile>  </div>  </div>
 <v-img  :class="'animatedimage animatedimagefond5 '"   id="animatedimagefond5" ref="animatedimagefond5"  v-bind:style="{ backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',}" :src = "require('../Resources/App/anim7.gif')" 
>  </v-img>
 <appCreate :class="'object appcreatefond7 '"   id="appcreatefond7" ref="appcreatefond7" v-if="createPanelOpened"  v-bind:style="{}" :create = "create" 
 :close = "closeCreatePanel" 
>
</appCreate>
 <AppViewer :class="'object appViewer '"   id="appViewer" ref="appViewer" v-show="viewerPanelOpened"  v-bind:style="{}" :close = "closeAppViewer" 
>
</AppViewer>
 <OpenDialog :class="'object opendialogfond11 '"   id="opendialogfond11" ref="opendialogfond11" v-if="openDialogOpened"  v-bind:style="{}" :close = "closeOpenDialog" 
 :create = "open" 
>
</OpenDialog>
 <AddDialog :class="'object addDialog '"   id="addDialog" ref="addDialog" v-if="addDialogOpened"  v-bind:style="{}" :close = "closeAddDialog" 
 :openAddPersonnage = "openAddPersoDialog" 
 :openAddImage = "openAddImageDialog" 
>
</AddDialog>
 <AddPersoDialog :class="'object addPersoDialog '"   id="addPersoDialog" ref="addPersoDialog" v-if="addPersoDialogOpened"  v-bind:style="{}" :close = "closeAddPersoDialog" 
>
</AddPersoDialog>
 <AddImageDialog :class="'object addImageDialog '"   id="addImageDialog" ref="addImageDialog" v-if="addImageDialogOpened"  v-bind:style="{}" :close = "closeAddImageDialog" 
>
</AddImageDialog>
 <div  :class="'transition transitionitem04 '"   id="transitionitem04" ref="transitionitem04" v-if="haveVideos"  v-bind:style="{height : getUnit(onboardingStepperH,'px'),}">
 <transition  :enter-active-class = "'animated bounce'" 
>
 <OnboardingStepper :class="'object onboardingstepperscrollarea032 '"   id="onboardingstepperscrollarea032" ref="onboardingstepperscrollarea032" v-show="showOnBoard"  v-bind:style="{}" :minifyOnBoard = "minifyOnBoard" 
 :expendOnBoard = "expendOnBoard" 
 :videoList = "videos" 
>
</OnboardingStepper>  </transition></div>  </div>
 
      </div>
 </div> 
</template>
<script>
import AppEditor from '../At/AppContent/AppEditor'
import AppSplatch from '../At/AppContent/AppSplatch'
import LeftZone from '../At/Zone/LeftZone'
import Profile from '../At/Zone/Profile'
import appCreate from '../At/AppContent/appCreate'
import AppViewer from '../At/AppContent/AppViewer'
import OpenDialog from '../At/Dialog/OpenDialog'
import AddDialog from '../At/Dialog/AddDialog'
import AddPersoDialog from '../At/Dialog/AddDialog/AddPersoDialog'
import AddImageDialog from '../At/Dialog/AddDialog/AddImageDialog'
import OnboardingStepper from '../At/AppContent/OnboardingStepper'

import 'jquery';
import axios from 'axios';
	
import * as main from "../Js/main.js"
export default {
	name: 'app',

    components: {AppEditor,AppSplatch,LeftZone,Profile,appCreate,AppViewer,OpenDialog,AddDialog,AddPersoDialog,AddImageDialog,OnboardingStepper,},
	methods: {
	
		updateResponsive: function(mq){		
	if(mq == "xs" || mq == "sm"){

		this.leftZoneShowed = false

		this.mainZoneMargin = 0

	} else {

		this.leftZoneShowed = true

		this.mainZoneMargin = 309

	}

}
,
		togelLeft: function(){		
	this.leftZoneShowed = !this.leftZoneShowed

	if(this.leftZoneShowed){

		this.mainZoneMargin = 309

	} else {

		this.mainZoneMargin = 0

	}

}
,
		openCreate: function(){		
	this.createPanelOpened = true

}
,
		create: function(){		
	this.splashOpened = false;

	this.createPanelOpened = false;

	this.openDialogOpened = false;

	this.editorOpened = true;

	this.refresh()

}
,
		open: function(){		
	this.create();

}
,
		showResult: function(code){		
	this.finalCode = code;

	this.$refs.appViewer.load(code);

	this.viewerPanelOpened = true;

}
,
		closeCreatePanel: function(){		
	this.createPanelOpened = false;

}
,
		openProfilePanel: function(){		
	this.profilePanelVisible = true;

}
,
		closeProfilePanel: function(){		
	this.profilePanelVisible = false;

}
,
		closeAppViewer: function(){		
	this.viewerPanelOpened = false;

}
,
		insertCode: function(data){		
	this.$refs.appEditor.insertCode(data);

}
,
		refresh: function(){		
	this.$refs.appEditor.refresh();

}
,
		undo: function(){		
	this.$refs.appEditor.undo();

}
,
		redo: function(data){		
	this.$refs.appEditor.redo();

}
,
		copy: function(data){		
	this.$refs.appEditor.copy();

}
,
		cut: function(data){		
	this.$refs.appEditor.cut();

}
,
		paste: function(data){		
	this.$refs.appEditor.paste();

}
,
		openOpenDialog: function(){		
	this.openDialogOpened = true;

}
,
		closeOpenDialog: function(){		
	this.openDialogOpened = false;

}
,
		openAddDialog: function(){		
	this.addDialogOpened = true;

}
,
		closeAddDialog: function(){		
	this.addDialogOpened = false;

}
,
		openAddPersoDialog: function(){		
	this.closeAddDialog()

	this.addPersoDialogOpened = true;

}
,
		closeAddPersoDialog: function(){		
	this.addPersoDialogOpened = false;

}
,
		openAddImageDialog: function(){		
	this.closeAddDialog()

	this.addImageDialogOpened = true;

}
,
		closeAddImageDialog: function(){		
	this.addImageDialogOpened = false;

}
,
		mix: function(id) { 		
	main.getMixinProjectData(id)

	.then(response => {

		let project = {

			name: response.data.name

		}

		this.$store.commit('project', project)

		this.$store.commit('pjDatas', response.data.pj_setting)

		this.$store.commit('setSaved', false)

		this.$store.commit('iconPath', null)

	})

	.catch(e => {})



}
,
		getTuto: function(id) { 		
	main.getCollectionData('tutoriels', id, true)

	.then(response => {

		this.tuto = response.data

		

		if(this.tuto.project){

			this.mix(this.tuto.project.id)

		}

		

		if(this.tuto.videos){

			setTimeout(function(){ this.showOnBoard = true }.bind(this), 3000);

		}

	})

	.catch(e => {})

}
,
		getChallenge: function(id) { 		
	main.getCollectionData('challenges', id, true)

	.then(response => {

		this.challenge = response.data

		

		if(this.challenge.project){

			this.mix(this.challenge.project.id)

		}

		

		if(this.challenge.videos){

			setTimeout(function(){ this.showOnBoard = true }.bind(this), 3000);

		}

	})

	.catch(e => {})

}
,
		minifyOnBoard: function(){ 		
	this.onboardingStepperH = 33

}
,
		expendOnBoard: function(){ 		
	this.onboardingStepperH = 318

}
,
	},
	mounted(){
			
	this.$i18n.locale = this.$store.state.language

	this.updateResponsive(this.$mq)
		
	if(this.$store.state.token == null){

		this.$router.push('/');

	} else {

		// Get user data

		main.getUserData()

  	.then(response => {

			this.$store.commit('setUser', response.data);

	  })

	  .catch(error => {

			main.logout()

	  });

	

		// Check if tuto

		if(this.$route.params.tutoId){

			this.getTuto(this.$route.params.tutoId)

		}	else if(this.$route.params.challengeId){

		// Check if challenge

			this.getChallenge(this.$route.params.challengeId)

		}	else if(this.$route.params.projectId){

		// Check if remix 

			this.mix(this.$route.params.projectId)

		}	

	}

	

	if(this.$store.state.project !== null){	

		if(this.$store.state.project.id){

			if(this.$store.state.saved){

				main.getProjectData()

		  	.then(response => {

					this.$store.dispatch('setProject', response.data);

			  })

			  .catch(error => {

					//this.$store.commit('project', null);

			  });

			}

		}

		

		this.splashOpened = false

		this.editorOpened = true

	}

	},
	computed: {
	
		userData: function(){		
	return this.$store.state.user;

}
,
		vp: function() { 		
	return this.$mq;

}	
,
		primaryColor: function(){ 		
	return this.$store.state.primaryColor

}
,
		secondaryColor: function(){ 		
	return this.$store.state.secondaryColor

}
,
		videos: function() { 		
	if(this.tuto){

		return this.tuto.videos

	} else if(this.challenge){

		return this.challenge.videos

	} else {

		return []

	}

}
,
		haveVideos: function() { 		
	return this.videos.length > 0

}
,
	},
	watch: {
	
		vp: function(newValue, oldValue) { 		
	this.updateResponsive(newValue)

}
,
	},
	data(){
	return {
		
		splashOpened:  true		,
		editorOpened:  false		,
		createPanelOpened:  false		,
		viewerPanelOpened:  false		,
		openDialogOpened:  false		,
		addDialogOpened:  false		,
		addPersoDialogOpened:  false		,
		addImageDialogOpened:  false		,
		tuto:  null		,
		challenge:  null		,
		profilePanelVisible:  false		,
		finalCode:  ""		,
		showOnBoard:  false		,
		onboardingStepperH:  318		,
		leftZoneShowed:  true		,
		mainZoneMargin:  309		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#app{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

*{
	font-family: 'Pacifico', fantasy, cursive !important;
	
	.text.text-content{
		font-family: 'Pacifico', fantasy, cursive !important;	
	}
}



.rectangle.fond{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

background-color:#d2dbdc ; 

.rectangle.fondBar{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
height:120px!important ; 

background-color:#EAAE14 ; 


}

.rectangle.cardfond3{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 5px ; 

top: 5px ; 
right: 5px ; 
bottom: 5px ; 
overflow:hidden !important; 

background-color:transparent ; 
border-radius:20px ; 

	.item.centerZone{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	

		.object.appEditor{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.appSplatch{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.item.lestZone{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	
	top: 0px ; 
	bottom: 0px ; 
	width:100%!important ; 
	

		.object.leftzonelestZone1{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilelestZone3{

		
		position:absolute !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		left: 0px ; 
		
		top: 0px ; 
		right: 0px ; 
		bottom: 0px ; 
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.animatedimage.animatedimagefond5{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

bottom: 0px ; 
width:55px!important ; 
height:44px!important ; 

display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 background-size: inherit;
	}


}

.object.appcreatefond7{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.appViewer{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 
z-index:500 ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.opendialogfond11{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addDialog{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addPersoDialog{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addImageDialog{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 

	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.transition.transitionitem04{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 15px ; 

bottom: 15px ; 
width:340px!important ; 


	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	top: 0px ; 
	right: 0px ; 
	bottom: 0px ; 
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}


@media (max-width: 600px) {


.rectangle.fond{


position:absolute !important; 







.rectangle.fondBar{


position:absolute !important; 








}

.rectangle.cardfond3{


position:absolute !important; 







	.item.centerZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.appEditor{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.appSplatch{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.item.lestZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.leftzonelestZone1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilelestZone3{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.animatedimage.animatedimagefond5{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.object.appcreatefond7{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.appViewer{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.opendialogfond11{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addPersoDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addImageDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.transition.transitionitem04{


position:absolute !important; 




width:90%!important ; 


	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 601px) {


.rectangle.fond{


position:absolute !important; 







.rectangle.fondBar{


position:absolute !important; 








}

.rectangle.cardfond3{


position:absolute !important; 



left: 15px ; 

top: 15px ; 
right: 15px ; 
bottom: 15px ; 



	.item.centerZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.appEditor{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.appSplatch{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.item.lestZone{

	
	position:absolute !important; 
	
	
	
	
	width:309px!important ; 
	

		.object.leftzonelestZone1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilelestZone3{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.animatedimage.animatedimagefond5{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.object.appcreatefond7{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.appViewer{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.opendialogfond11{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addPersoDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addImageDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 961px) {


.rectangle.fond{


position:absolute !important; 







.rectangle.fondBar{


position:absolute !important; 








}

.rectangle.cardfond3{


position:absolute !important; 







	.item.centerZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.appEditor{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.appSplatch{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.item.lestZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.leftzonelestZone1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilelestZone3{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.animatedimage.animatedimagefond5{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.object.appcreatefond7{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.appViewer{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.opendialogfond11{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addPersoDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addImageDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1265px) {


.rectangle.fond{


position:absolute !important; 







.rectangle.fondBar{


position:absolute !important; 








}

.rectangle.cardfond3{


position:absolute !important; 







	.item.centerZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.appEditor{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.appSplatch{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.item.lestZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.leftzonelestZone1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilelestZone3{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.animatedimage.animatedimagefond5{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.object.appcreatefond7{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.appViewer{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.opendialogfond11{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addPersoDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addImageDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}

@media (min-width: 1905px) {


.rectangle.fond{


position:absolute !important; 







.rectangle.fondBar{


position:absolute !important; 








}

.rectangle.cardfond3{


position:absolute !important; 







	.item.centerZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.appEditor{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.appSplatch{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}

	.item.lestZone{

	
	position:absolute !important; 
	
	
	
	
	

		.object.leftzonelestZone1{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

		.object.profilelestZone3{

		
		position:absolute !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}

.animatedimage.animatedimagefond5{


position:absolute !important; 





display: inherit;

flex: unset;
	::v-deep .v-image__image.v-image__image--cover {
		 
	}


}

.object.appcreatefond7{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.appViewer{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.opendialogfond11{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addPersoDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.object.addImageDialog{


position:absolute !important; 





	::v-deep > * {
		min-width: unset;
		min-height: unset;
	}


}

.transition.transitionitem04{


position:absolute !important; 






	.object.onboardingstepperscrollarea032{

	
	position:absolute !important; 
	
	
	
	
	
		::v-deep > * {
			min-width: unset;
			min-height: unset;
		}

	
}


}


}
}
}
</style>
