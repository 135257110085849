<template>
 <div>  
<div id="LanguageChoose"> 
         
 <div  :class="'item loginCol22222 '"   id="loginCol22222" ref="loginCol22222"  v-bind:style="{}">
 <div  :class="'text textloginCol1 '"   id="textloginCol1" ref="textloginCol1"  v-bind:style="{}">
<div class="text-content" :style = "{}">{{ $t('main.connect') }}</div>

   </div>
 <div  :class="'scrollarea scrollarealoginCol222224 '"   id="scrollarealoginCol222224" ref="scrollarealoginCol222224"  v-bind:style="{backgroundColor : 'transparent',backgroundColor : 'transparent',}">
 <div  :class="'column columnscrollarealoginCol2222242 '"   id="columnscrollarealoginCol2222242" ref="columnscrollarealoginCol2222242"  v-bind:style="{}">
 <LanguageItem :class="'object languageitemloginCol222223 '"   id="languageitemloginCol222223" ref="languageitemloginCol222223" v-for="(item,index) in list" :key="'languageitemloginCol222223' + index"  v-bind:style="{}" :langKey = "item" 
>
</LanguageItem>  </div>  </div>  </div>
 
      </div>
 </div> 
</template>
<script>
import LanguageItem from '../../At/Utils/LanguageItem'


export default {
	name: 'LanguageChoose',

    components: {LanguageItem,},
	data(){
	return {
		
		list:  this.$store.state.languageList		,
		}
	},
} 
</script>

<style lang = "scss" scoped>


 div#LanguageChoose{
	

	width:100%;
	height:100%;
	min-width: fit-content;
	min-height: fit-content; 

body{

}



.item.loginCol22222{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 0px ; 
right: 0px ; 
bottom: 0px ; 


.text.textloginCol1{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

right: 0px ; 
height:auto!important ; 

font-size:35px ; 
text-align:center;
display: flex;
.text-content{
width: 100%;
font-weight: bold;
}


}

.scrollarea.scrollarealoginCol222224{


position:absolute !important; 
margin: unset;
padding: unset;
transform-origin: top left;


left: 0px ; 

top: 60px ; 
right: 0px ; 
bottom: 0px ; 

background-color:transparent ; 

	.column.columnscrollarealoginCol2222242{

	
	position:absolute !important; 
	margin: unset;
	padding: unset;
	transform-origin: top left;
	
	
	left: 0px ; 
	
	right: 0px ; 
	height:auto!important ; 
	
	display: flex;
	flex-wrap: nowrap;
	flex: initial;
	flex-direction: column;
	gap:10px ; background-color: transparent ; 

		.object.languageitemloginCol222223{

		
		position:relative !important; 
		margin: unset;
		padding: unset;
		transform-origin: top left;
		
		
		margin-left: 0px ; 
		
		margin-right: 0px ; 
		height:50px!important ; 
		width: calc(100% - (0px + 0px)) !important;
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}


@media (max-width: 600px) {


.item.loginCol22222{


position:absolute !important; 






.text.textloginCol1{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.scrollarea.scrollarealoginCol222224{


position:absolute !important; 






	.column.columnscrollarealoginCol2222242{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.languageitemloginCol222223{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 601px) {


.item.loginCol22222{


position:absolute !important; 






.text.textloginCol1{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.scrollarea.scrollarealoginCol222224{


position:absolute !important; 






	.column.columnscrollarealoginCol2222242{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.languageitemloginCol222223{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 961px) {


.item.loginCol22222{


position:absolute !important; 






.text.textloginCol1{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.scrollarea.scrollarealoginCol222224{


position:absolute !important; 






	.column.columnscrollarealoginCol2222242{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.languageitemloginCol222223{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1265px) {


.item.loginCol22222{


position:absolute !important; 






.text.textloginCol1{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.scrollarea.scrollarealoginCol222224{


position:absolute !important; 






	.column.columnscrollarealoginCol2222242{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.languageitemloginCol222223{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}

@media (min-width: 1905px) {


.item.loginCol22222{


position:absolute !important; 






.text.textloginCol1{


position:absolute !important; 





.text-content{
width: 100%;
}


}

.scrollarea.scrollarealoginCol222224{


position:absolute !important; 






	.column.columnscrollarealoginCol2222242{

	
	position:absolute !important; 
	
	
	
	
	
	
	
	

		.object.languageitemloginCol222223{

		
		position:relative !important; 
		
		
		
		
		
			::v-deep > * {
				min-width: unset;
				min-height: unset;
			}

		
}

	
}


}


}
}
}
</style>
