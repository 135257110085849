import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = [];

/**
 * Please don' t remove this.
 * Artefact will use it to inject code
 */
//<= inject_routes =>
routes = routes.concat([{"path":"/","component":require('../artefact/At/main').default},{"name":"application","path":"/app","component":require('../artefact/At/app').default},{"name":"remix-application","path":"/app/mix/:projectId","component":require('../artefact/At/app').default},{"name":"tuto-application","path":"/app/tuto/:tutoId","component":require('../artefact/At/app').default},{"name":"challenge-application","path":"/app/challenge/:challengeId","component":require('../artefact/At/app').default},{"name":"acceuil","path":"/acceuil","component":require('../artefact/At/acceuil').default,"redirect":""}])
//<= end_routes =>

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
