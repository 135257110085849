import axios from "axios"
import store from '../../store/index'
import router from '../../router/index'

const qs = require('qs');
	
function $axios(){
	return axios.create({
		headers: {
      Authorization: `Bearer ${store.state.token}`,
    }
	})
}

// Login
export function login(name, pass){
	return axios
  			.post(store.state.apiURL + '/auth/local', {
			    identifier: name,
			    password: pass,
			  })
}

// Logout
export function logout(){
	store.commit('setToken', null)
	store.commit('setUser', null)
	store.commit('project', null)
	router.push('/');
}
		
// Register
export function register(name, email, pass){
	return axios
  	.post(store.state.apiURL + '/auth/local/register', {
    	username: name,
    	email: email,
    	password: pass,
  	})
}
		
// Compile to Apk
export function compileAPK(){
	return axios
  	.post(`${store.state.apkServer}/build`, {
    	code: store.state.pjDatas
  	}, {
			responseType: 'blob'
		})
}

// Request API.
export function sendMailConfirmation(mail){
	return axios
  .post(`${store.state.apiURL}/auth/send-email-confirmation`, {
    email: mail, // user's email
  })
}

	
// Get user data
export function getUserData(){
	return $axios().get(store.state.apiURL + `/users/${store.state.user.id}`)
}
	
// Get project data
export function getProjectData(){
	return axios.get(store.state.apiURL + `/projects/${store.state.project.id}`)
}	
	
// Get data
export function getCollectionData(collection, id, auth = false){
	if(auth){
		return $axios().get(store.state.apiURL + `/${collection}/${id}`)
	} else {
		return axios.get(store.state.apiURL + `/${collection}/${id}`)
	}
}	
	
// Get project data for mix
export function getMixinProjectData(id){
	return axios.get(store.state.apiURL + `/projects/${id}`)
}	
	
//Upload file
export function uploadFile(iconUrl){
	let apiUrl = store.state.apiURL

	let name = `${Date.now()}_${store.state.pjDatas.nom}.jpg`;
 	var formData = new FormData();
	formData.append('files', iconUrl, name);

	return axios.post(apiUrl + '/upload',
   	formData,
		{
   		headers: {
        'Content-Type': 'multipart/form-data',
    		Authorization: `Bearer ${store.state.token}`,
 	    }
 	  }
	) 	
}
	
//Update project
export function updateProject(pjData, imgData){
	let apiUrl = store.state.apiURL + '/projects' + `/${store.state.project.id}`
	
  var dt = {
  	name: pjData.nom,
  	tags: pjData.tags ?? [],
		pj_setting: pjData
	};
	
	if(imgData){
		dt.description_image = imgData.id
	}

  var config = {
    url: apiUrl,
		method: "put",
		data: dt
  };
	
  return $axios()(config)
}
	
//Update user
export function updateUser(userData, imgData){
	let apiUrl = store.state.apiURL + '/users' + `/${store.state.user.id}`
	
  let dt = {
  	username: userData.username
	};
	
	if(imgData){
		dt.profile_image = imgData.id
	}

  let config = {
    url: apiUrl,
		method: "put",
		data: dt
  };
	
  return $axios()(config)
}
	
// Get images from API
export function getImages(){	
	var config = {
  	method: 'get',
	  url: store.state.apiURL + '/upload/files'
	};	
	return axios(config)
}
	
// Get tags from API
export function getTags(){	
	var config = {
  	method: 'get',
	  url: store.state.apiURL + '/tags'
	};	
	return axios(config)
}
	
export function getProjects(filter = {}){
	const query = qs.stringify(filter)
	var config = {
  	method: 'get',
	  url: store.state.apiURL + `/projects?${query}`
	};	
	return axios(config)
}

export function getErrorMessage(error) {
	console.log(error)
  return error.response.data.message[0].messages[0].message;
}

